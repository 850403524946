import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import './OnlinePhysiotherapy.css';
import Accordion from './Accordion';
import PatientStories from '../Home/PatientStories';
import PatientStoriesMobile from '../Home/PatientStoriesMobile';
import PatientStoriesTablet from '../Home/PatientStoriesTablet';

import DR_JIM from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_drjim.png';
import DR_STEPH from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_drsteph.png';
import DR_AFSHIN from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_afshin.png';
import DR_SCOTT from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_scott.png';
import {
  Book1,
  Vector1,
  Meet2,
  Vector2,
  Diagnosis3,
  CheckButton,
  OnlinePhysioAbstract,
  OnlinePhysioMockupVideo,
  OnlinePhysioAbstract1,
  Why_Phxyable1,
  Why_Phxyable2,
  Why_Phxyable3,
  Why_Phxyable4,
  Why_Phxyable5,
  Why_Phxyable6,
} from './imageAssets';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function OnlinePhysiotherapy(props) {
  const { t, i18n } = useTranslation();

  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [laptopView, setLaptopView] = useState(false);
  const [desktopView, setDesktopView] = useState(false);
  const [viewPort, setViewPort] = useState('');
  const teamInfo = [
    {
      id: 1,
      name: 'Dr. Jim',
      title: i18next.t(`CHIROPRACTOR`),
      image: DR_JIM,
    },
    {
      id: 2,
      name: 'Dr. Steph ND',
      title: i18next.t(`NATUROPATH`),
      image: DR_STEPH,
    },
    {
      id: 3,
      name: 'Afshin',
      title: i18next.t(`PHYSIOTHERAPIST`),
      image: DR_AFSHIN,
    },
    {
      id: 4,
      name: 'Scott',
      title: i18next.t(`PHYSIOTHERAPIST`),
      image: DR_SCOTT,
    },
  ];

  const priceTable = [
    [
      i18next.t(`HOW_WE_COMPARE`),
      i18next.t(`TRADITIONAL_COUNSELLING`),
      'IMAGE',
    ],
    [
      i18next.t(`COST_PER_30_MINUTES`),
      i18next.t(`80_100`),
      i18next.t(`65PRICE`),
    ],
    [i18next.t(`FIRST_SESSION`), i18next.t(`PAID`), i18next.t(`FREE`)],
    [
      i18next.t(`ACCESSIBILITY`),
      i18next.t(`IN_AN_OFFICE`),
      i18next.t(`FROM_HOME`),
    ],
    [
      i18next.t(`TRAVEL_TIME`),
      i18next.t(`VARIABLE`),
      i18next.t(`NO_TRAVEL_TIME_NEEDED`),
    ],
    [
      i18next.t(`SUPPORT`),
      i18next.t(`ONLY_DURING_TREATMENT`),
      i18next.t(`IN_APP_VIDEO_EXERCISES_AND_REMOTE_MONITORING`),
    ],
    [
      i18next.t(`SESSION_TIME`),
      i18next.t(`BASED_ON_PROVIDERS_CHOSEN_SHEDULE`),
      i18next.t(`CHOSE_A_CARE_PROVIDER_THAT_FITS_YOUR_SCHEDULE`),
    ],
  ];

  const pointInfo = [
    {
      image: Why_Phxyable1,
      point: i18next.t(`SELECTED_VETTED_HIGHLY_RATED_CONT`),
    },
    { image: Why_Phxyable2, point: i18next.t(`why_phyxable2`) },
    { image: Why_Phxyable3, point: i18next.t(`why_phyxable3`) },
    { image: Why_Phxyable4, point: i18next.t(`why_phyxable4`) },
    { image: Why_Phxyable5, point: i18next.t(`why_phyxable5`) },
    { image: Why_Phxyable6, point: i18next.t(`why_phyxable6`) },
  ];

  useEffect(() => {
    window.scroll(0, 0);
    document.title = 'Phyxable | Online Physiotherapy';
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);

  return (
    <>
      {viewPort === 'MOBILE' ? (
        <Mobile
          teamInfo={teamInfo}
          priceTable={priceTable}
          pointInfo={pointInfo}
        />
      ) : viewPort === 'TABLET' ? (
        <Tablet
          teamInfo={teamInfo}
          priceTable={priceTable}
          pointInfo={pointInfo}
        />
      ) : viewPort === 'LAPTOP' ? (
        <Laptop
          teamInfo={teamInfo}
          priceTable={priceTable}
          pointInfo={pointInfo}
        />
      ) : (
        <Desktop
          teamInfo={teamInfo}
          priceTable={priceTable}
          pointInfo={pointInfo}
        />
      )}
    </>
  );
}

const Mobile = props => {
  return (
    <div>
      <div>
        <div className='bg-mobile-mobile'>
          <div className='w-90per mrAuto mlAuto ptop100 cAlign'>
            <h1 className='main-font interRegular priColor ftSz13 ltSp11 ftWght600 '>
              {i18next.t(`ONLINE`)}
              <br />
              {i18next.t(`PHYSIOTHERAPY`)}
              <br />
              {i18next.t(`CONSULTATION`)}
            </h1>
            <h3 className='black interThin priColor ltSpREG ftSz3 mtop-1 pbottom2 ftWght600'>
              {i18next.t(`WITH_THE_BEST_VETTED_HIGHLY_RATED`)}
            </h3>
          </div>
        </div>

        <div className='pink-container'>
          <Link to='/booking-select'>
            <button className='button mtop2 mbottom10'>
              {i18next.t(`BOOK_FREE_CONSULTATION`)}
            </button>
          </Link>
          <div className='w-100per mrAuto mlAuto'>
            <h1 className='white interRegular ftSz9  ltSp8'>
              {i18next.t(`WE_WANT_YOU_TO_BE_PAIN_FREE`)}
            </h1>
            <h4 className='white mtop-1 interThin ftSz3'>
              {i18next.t(`AND_WE_WILL_MAKE_SURE_THAT_YOU_FEEL_BETTER`)}
            </h4>
          </div>
          <div className='padding-top-1rem' />
        </div>

        <div className='Content'>
          <div>
            <h1 className='dark'>{i18next.t(`HOW_IT_WORKS`)}</h1>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/1_book_appointment_woman_921529404e.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP1`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`BOOK_APPOINTMENT`)}
                </h3>
                <p className='regular'>
                  {i18next.t(`PICK_THE_TIME_SLOT_THAT_FITS_YOUR_SCHEDULE_CONT`)}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/2_meet_bc7808b17d.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP2`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`MEET_YOUR_ONLINE_PRACTITIONER`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `YOU_WILL_RECEIVE_INSTRUCTIONS_AND_REMINDERS_ON_HOW_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/3_diagnosis_f600cbb613.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP3`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`GET_YOUR_DIAGNOSIS_TREATMENT_ONLINE`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/4_access_treatment_69ccdafcc0.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP4`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`ACCESS_YOUR_CUSTOMZIED_TREATMENT_PLAN`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `THE_PHYXABLE_PLATFORM_PROVIDES_EASY_ACCESS_TO_THE_TREATMENT_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/5_track_1220a5c1c8.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP5`)}STEP 5</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`TRACK_YOUR_PROGRESS`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <h1 className='dark ftSz9'>
              {i18next.t(`WHY_SHOULD_YOU_CHOOSE_PHYXABLE`)}
            </h1>
            <div>
              <ul className='pin-point-flex pbottom2 w-80per mrAuto mlAuto'>
                {props.pointInfo.map(data => (
                  <li key={data.point} className='grid-flex'>
                    <img
                      src={data.image}
                      alt='checkmark'
                      style={{ width: '45px' }}
                    />
                    <p className='padding-left-1rem'>{data.point}</p>
                  </li>
                ))}
              </ul>
              <Link to='/booking-select'>
                <button className='button '>
                  {i18next.t(`BOOK_FREE_CONSULTATION`)}
                </button>
              </Link>
            </div>
          </div>
          <div className='padding-top-4rem lAlign '>
            <PatientStoriesMobile />
          </div>
          <div className='price-table-mobile'>
            <h1 className='ftSz6  blackColor'>
              {i18next.t(`SIMPLE_PRICING_TO`)}
              <br />
              {i18next.t(`FIT_YOUR_NEEDS`)}
            </h1>
            <h3 className='interRegular ftSz0  blackColor'>
              {i18next.t(`AND_THE_BEST_PART`)}
              <br /> {i18next.t(`YOUR_FIRST_SESSION_IS_ALWAYS_FREE_CONT`)}
              fit{' '}
            </h3>
            <div className='padding-top-2rem' />
            {/* <div className="compare-price">
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h2 className="header-price pink">{content[0]}</h2>
                  ) : (
                    <p className="padding-price">{content[0]}</p>
                  )}
                </div>
              ))}
            </div> */}
            <div className='traditional-price-mobile'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h3 className='header-price'>
                      {i18next.t(`TRADITIONAL`)}
                      <br />
                      {i18next.t(`COUNSELLING`)}
                    </h3>
                  ) : index === 6 ? (
                    <p className='padding-price ftSz0'>
                      {i18next.t(`SESSION_TIME`)}
                      <br />
                      <span className='interRegular ftSz3'>
                        {i18next.t(`BASED_ON_PROVIDERS`)}
                        <br />
                        {i18next.t(`CHOSEN_SHEDULE`)}
                      </span>
                    </p>
                  ) : (
                    <p className='underline-border ftSz0'>
                      {content[0]}
                      <br />
                      <span className='interRegular ftSz3 '>{content[1]}</span>
                    </p>
                  )}
                </div>
              ))}
            </div>
            <div className='phyxable-price-mobile'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <div className='header-price'></div>
                  ) : index === 1 ? (
                    <p className='underline-border pink'>
                      {i18next.t(`65PRICE`)}
                    </p>
                  ) : index === 6 ? (
                    <p className='padding-price ftSz0'>
                      {i18next.t(`SESSION_TIME`)}
                      <br />
                      <span className='interRegular ftSz3'>
                        {' '}
                        {i18next.t(`CHOOSE_A_CARE_PROVIDER_THAT`)}
                        <br />
                        {i18next.t(`FITS_YOUR_SCHEDULE`)}{' '}
                      </span>
                    </p>
                  ) : (
                    <p className='underline-border ftSz0'>
                      {content[0]}
                      <br />
                      <span className='interRegular ftSz3 '>{content[2]}</span>
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div style={{ backgroundColor: '#EFF2F6' }}>
            {/* <div className="right-align">
              <img
                className="max-100"
                src={OnlinePhysioAbstract}
                alt="mockup"
              />
            </div> */}
            <div className='responsive-container'>
              <div className='cAlign w-90per'>
                <h1 className='secColor1 interRegular ftSz9 ltSp8'>
                  {i18next.t(
                    `PERSONALIZED_VIDEO_BASED_EXERCISE_SOLUTIONS_CREATED_BY_YOUR_PHYSIO`
                  )}
                </h1>
                <p className='regular'>
                  {i18next.t(
                    `FIX_YOUR_PAIN_AND_THEN_KEEP_FOLLOWING_THE_VIDEO_LEVELS_CONT`
                  )}
                </p>
              </div>
              <div className='right'>
                <img
                  className='max-100'
                  src={OnlinePhysioMockupVideo}
                  alt='mockup'
                />
              </div>
            </div>
            {/* <div className="left-align">
              <img
                className="max-100"
                src={OnlinePhysioAbstract1}
                alt="mockup"
              />
            </div> */}
          </div>
          <div className='padding-top-4rem'>
            <h1 className='dark'>{i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}</h1>
            <div className='pain-container'>
              {props.teamInfo.map(dr => (
                <div className='pain-wrap-corporation' key={dr.id}>
                  <img src={dr.image} alt={dr.name} />
                  <h4 className='secColor1 interRegular ftSz5'>{dr.name}</h4>
                  <p className='regular interRegular ftSz3'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='accordion-container'>
            <h1 className='secColor1 interRegular ftSz9 ltSp8 mbottom10'>
              {i18next.t(`WE_KNOW_WHAT_YOURE_THINKING`)}
              <br />
              <span className='ftSz3 ftWght1'>
                {i18next.t(`THIS_SOUNDS_TOO_GOOD_TO_BE_TRUE`)}
              </span>
            </h1>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};

const Tablet = props => {
  return (
    <div>
      <div className=''>
        <div className='bg-desktop-online-physio-tablet'>
          <div className='home-container-tablet'>
            <h1 className='blackColor ftSz15'>
              {i18next.t(`ONLINE`)}
              <br />
              {i18next.t(`PHYSIOTHERAPY`)}
              <br />
              {i18next.t(`CONSULTATION`)}
            </h1>
            <h3 className='black ftSz1 '>
              {i18next.t(`WITH_THE_BEST_VETTED_HIGHLY_RATED`)}
            </h3>{' '}
            <div className='padding-top-1rem' />
            <Link to='/booking-select'>
              <button className='button '>
                {i18next.t(`BOOK_FREE_CONSULTATION`)}
              </button>
            </Link>
          </div>
        </div>

        <div className='pink-container '>
          <h1 className='white ftSz10'>
            {i18next.t(`WE_WANT_YOU`)}
            <br />
            {i18next.t(`TO_BE_PAIN_FREE`)}
          </h1>
          <h4 className='white ftSz1 '>
            {i18next.t(`AND_WE_WILL_MAKE_SURE_THAT_YOU_FEEL_BETTER`)}
          </h4>
        </div>

        <div className='Content'>
          <div>
            <h1 className='dark'>{i18next.t(`HOW_IT_WORKS`)}</h1>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP1`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`BOOK_APPOINTMENT`)}
                </h3>
                <p className='regular'>
                  {i18next.t(`PICK_THE_TIME_SLOT_THAT_FITS_YOUR_SCHEDULE_CONT`)}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/1_book_appointment_woman_921529404e.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/2_meet_bc7808b17d.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP2`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`MEET_YOUR_ONLINE_PRACTITIONER`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `YOU_WILL_RECEIVE_INSTRUCTIONS_AND_REMINDERS_ON_HOW_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP3`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`GET_YOUR_DIAGNOSIS_TREATMENT_ONLINE`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/3_diagnosis_f600cbb613.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/4_access_treatment_69ccdafcc0.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP4`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`ACCESS_YOUR_CUSTOMZIED_TREATMENT_PLAN`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `THE_PHYXABLE_PLATFORM_PROVIDES_EASY_ACCESS_TO_THE_TREATMENT_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP5`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`TRACK_YOUR_PROGRESS`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/5_track_1220a5c1c8.svg'
                  alt='book'
                />
              </div>
            </div>
          </div>

          <div>
            <h1 className='dark ftSz13'>
              {i18next.t(`WHY_SHOULD_YOU_CHOOSE_PHYXABLE`)}
            </h1>
            <div className='white-container'>
              <ul className='pin-point-flex'>
                {props.pointInfo.map(data => (
                  <li key={data.point} className='grid-flex-tablet'>
                    <img
                      src={data.image}
                      alt='checkmark'
                      style={{ width: '45px' }}
                    />
                    <p className='padding-left-1rem'>{data.point}</p>
                  </li>
                ))}
              </ul>
              <Link to='/booking-select'>
                <button className='button'>
                  {i18next.t(`BOOK_FREE_CONSULTATION`)}
                </button>
              </Link>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <PatientStoriesTablet />
          </div>

          <div className='price-table'>
            <h1 className='ftSz10 blackColor'>
              {i18next.t(`SIMPLE_PRICING_TO`)}
              <br />
              {i18next.t(`FIT_YOUR_NEEDS`)}
            </h1>
            <h3 className='ftSz1 blackColor '>
              {i18next.t(`AND_THE_BEST_PART`)}
              <br /> {i18next.t(`YOUR_FIRST_SESSION_IS_ALWAYS_FREE_CONT`)}{' '}
            </h3>
            <div className='padding-top-2rem' />
            <div className='compare-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h2 className='header-price pink'>{content[0]}</h2>
                  ) : (
                    <p className='padding-price'>{content[0]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='traditional-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h3 className='header-price'>
                      {i18next.t(`TRADITIONAL`)}
                      <br />
                      {i18next.t(`COUNSELLING`)}
                    </h3>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`BASED_ON_PROVIDERS`)} <br />
                      {i18next.t(`CHOSEN_SHEDULE`)}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[1]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='phyxable-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <div className='header-price'></div>
                  ) : index === 1 ? (
                    <p className='underline-border pink'>
                      {i18next.t(`65PRICE`)}
                    </p>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`CHOOSE_A_CARE_PROVIDER_THAT`)}
                      {i18next.t(`FITS_YOUR_SCHEDULE`)}{' '}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[2]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div style={{ backgroundColor: '#EFF2F6' }}>
            <div className='right-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract}
                alt='mockup'
              />
            </div>
            <div className='dFlex jSpace w-80per mlAuto mrAuto flexAlCenter'>
              <div className='lAlign w-50per'>
                <h1 className='ftSz10 blackColor '>
                  {i18next.t(`PERSONALIZED`)}
                  <br />
                  {i18next.t(`VIDEO_BASED_EXERCISE`)}
                  <br /> {i18next.t(`SOLUTIONS_CREATED_BY`)}
                  <br />
                  {i18next.t(`YOUR_PHYSIO`)}
                </h1>
                <p className='ftSz1 blackColor '>
                  {i18next.t(
                    `FIX_YOUR_PAIN_AND_THEN_KEEP_FOLLOWING_THE_VIDEO_LEVELS_CONT`
                  )}
                </p>
              </div>
              <div className='rAlign w-50per'>
                <img
                  className='w-90per'
                  src={OnlinePhysioMockupVideo}
                  alt='mockup'
                />
              </div>
            </div>
            <div className='left-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract1}
                alt='mockup'
              />
            </div>
          </div>

          <div className='padding-top-4rem '>
            <h1 className='dark ftSz12'>
              {i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}
            </h1>
            <div className='w-55per mlAuto mrAuto'>
              <p className='secColor2 ftSz1 '></p>
            </div>

            <div className='dFlex jSpace w-90per mlAuto mrAuto mtop7 '>
              {props.teamInfo.map(dr => (
                <div className='w-25per cAlign ' key={dr.id}>
                  <img src={dr.image} alt={dr.name} className='w-80per pad0' />
                  <h4 className='dark ftSz2'>{dr.name}</h4>
                  <p className='regular ftSz0'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='w-90per mrAuto mlAuto'>
            <h1 className='blackColor lAlign'>
              {i18next.t(`WE_KNOW_WHAT_YOURE_THINKING`)}
              <br />
              <span style={{ fontSize: '75%' }}>
                {i18next.t(`THIS_SOUNDS_TOO_GOOD_TO_BE_TRUE`)}
              </span>
            </h1>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};

const Laptop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-online-physio'>
          <div className='home-container'>
            <h1 className='main-font '>
              {i18next.t(`ONLINE`)}
              <br />
              {i18next.t(`PHYSIOTHERAPY`)}
              <br />
              {i18next.t(`CONSULTATION`)}
            </h1>
            <h3 className='black'>
              {i18next.t(`WITH_THE_BEST_VETTED_HIGHLY_RATED`)}
            </h3>
            <div className='padding-top-1rem' />
            <Link to='/booking-select'>
              <button className='button'>
                {i18next.t(`BOOK_FREE_CONSULTATION`)}
              </button>
            </Link>
          </div>
        </div>

        <div className='pink-container'>
          <h1 className='white'>
            {i18next.t(`WE_WANT_YOU`)}
            <br />
            {i18next.t(`TO_BE_PAIN_FREE`)}
          </h1>
          <h4 className='white'>
            {i18next.t(`AND_WE_WILL_MAKE_SURE_THAT_YOU_FEEL_BETTER`)}
          </h4>
        </div>

        <div className='Content'>
          <div>
            <h1 className='dark'>{i18next.t(`HOW_IT_WORKS`)}</h1>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP1`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`BOOK_APPOINTMENT`)}
                </h3>
                <p className='regular'>
                  {i18next.t(`PICK_THE_TIME_SLOT_THAT_FITS_YOUR_SCHEDULE_CONT`)}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/1_book_appointment_woman_921529404e.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/2_meet_bc7808b17d.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP2`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`MEET_YOUR_ONLINE_PRACTITIONER`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `YOU_WILL_RECEIVE_INSTRUCTIONS_AND_REMINDERS_ON_HOW_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP2`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`MEET_YOUR_ONLINE_PRACTITIONER`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOU_WILL_RECEIVE_INSTRUCTIONS_AND_REMINDERS_ON_HOW_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/3_diagnosis_f600cbb613.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/4_access_treatment_69ccdafcc0.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP4`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`ACCESS_YOUR_CUSTOMZIED_TREATMENT_PLAN`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `THE_PHYXABLE_PLATFORM_PROVIDES_EASY_ACCESS_TO_THE_TREATMENT_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP5`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`TRACK_YOUR_PROGRESS`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/5_track_1220a5c1c8.svg'
                  alt='book'
                />
              </div>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <h1 className='dark'>
              {i18next.t(`WHY_SHOULD_YOU_CHOOSE_PHYXABLE`)}
            </h1>
            <div className='white-container'>
              <ul className='pin-point-flex'>
                {props.pointInfo.map(data => (
                  <li key={data.point} className='grid-flex'>
                    <img
                      src={data.image}
                      alt='checkmark'
                      style={{ width: '45px' }}
                    />
                    <p className='padding-left-1rem'>{data.point}</p>
                  </li>
                ))}
              </ul>
              <Link to='/booking-select'>
                <button className='button'>
                  {i18next.t(`BOOK_FREE_CONSULTATION`)}
                </button>
              </Link>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <PatientStories />
          </div>

          <div className='price-table'>
            <h1>
              {i18next.t(`SIMPLE_PRICING_TO`)}
              <br />
              {i18next.t(`FIT_YOUR_NEEDS`)}
            </h1>
            <h3>
              {i18next.t(`AND_THE_BEST_PART`)}
              <br /> {i18next.t(`YOUR_FIRST_SESSION_IS_ALWAYS_FREE_CONT`)}
              fit{' '}
            </h3>
            <div className='padding-top-2rem' />
            <div className='compare-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h2 className='header-price pink'>{content[0]}</h2>
                  ) : (
                    <p className='padding-price'>{content[0]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='traditional-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h3 className='header-price'>
                      {i18next.t(`TRADITIONAL`)}
                      <br />
                      {i18next.t(`COUNSELLING`)}
                    </h3>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`BASED_ON_PROVIDERS`)}
                      <br />
                      {i18next.t(`CHOSEN_SHEDULE`)}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[1]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='phyxable-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <div className='header-price'></div>
                  ) : index === 1 ? (
                    <p className='underline-border pink'>
                      {i18next.t(`65PRICE`)}
                    </p>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`CHOOSE_A_CARE_PROVIDER_THAT`)}
                      <br />
                      {i18next.t(`FITS_YOUR_SCHEDULE`)}{' '}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[2]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div style={{ backgroundColor: '#EFF2F6' }}>
            <div className='right-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract}
                alt='mockup'
              />
            </div>
            <div className='responsive-container'>
              <div className='left'>
                <h1 className='dark'>
                  {i18next.t(`PERSONALIZED`)}
                  <br />
                  {i18next.t(`VIDEO_BASED_EXERCISE`)}
                  <br /> {i18next.t(`SOLUTIONS_CREATED_BY`)}
                  <br />
                  {i18next.t(`YOUR_PHYSIO`)}
                </h1>
                <p className='regular'>
                  {i18next.t(
                    `FIX_YOUR_PAIN_AND_THEN_KEEP_FOLLOWING_THE_VIDEO_LEVELS_CONT`
                  )}
                </p>
              </div>
              <div className='right' style={{ padding: '0 4rem' }}>
                <img
                  className='max-100'
                  src={OnlinePhysioMockupVideo}
                  alt='mockup'
                />
              </div>
            </div>
            <div className='left-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract1}
                alt='mockup'
              />
            </div>
          </div>

          <div className='padding-top-4rem'>
            <h1 className='dark'>{i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}</h1>
            <div className='pain-container'>
              {props.teamInfo.map(dr => (
                <div className='pain-wrap-corporation' key={dr.id}>
                  <img src={dr.image} alt={dr.name} />
                  <h4 className='dark'>{dr.name}</h4>
                  <p className='regular'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='accordion-container'>
            <h1 className='dark left-align'>
              {i18next.t(`WE_KNOW_WHAT_YOURE_THINKING`)}
              <br />
              <span style={{ fontSize: '75%' }}>
                {i18next.t(`THIS_SOUNDS_TOO_GOOD_TO_BE_TRUE`)}
              </span>
            </h1>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};
const Desktop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-online-physio'>
          <div className='home-container'>
            <h1
              className='main-font '
              style={{ fontSize: 54 + `px`, marginBottom: 40 + `px` }}
            >
              {i18next.t(`ONLINE`)}
              <br />
              {i18next.t(`PHYSIOTHERAPY`)}
              <br />
              {i18next.t(`CONSULTATION`)}
            </h1>
            <h2 className='black' style={{ fontSize: 22 + `px` }}>
              {i18next.t(`WITH_THE_BEST_VETTED_HIGHLY_RATED`)}
            </h2>
            <div className='padding-top-1rem' />
            <Link to='/booking-select'>
              <button
                className='button'
                style={{
                  fontSize: 22 + `px`,
                  width: 310 + `px`,

                  height: 60 + `px`,
                }}
              >
                {i18next.t(`BOOK_FREE_CONSULTATION`)}
              </button>
            </Link>
          </div>
        </div>

        <div className='pink-container'>
          <h1 className='white'>
            {i18next.t(`WE_WANT_YOU`)}
            <br />
            {i18next.t(`TO_BE_PAIN_FREE`)}
          </h1>
          <h2 className='white'>
            {i18next.t(`AND_WE_WILL_MAKE_SURE_THAT_YOU_FEEL_BETTER`)}
          </h2>
          <div className='padding-top-1rem' />
        </div>

        <div className='Content'>
          <div>
            <h1 className='dark'>{i18next.t(`HOW_IT_WORKS`)}</h1>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP1`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`BOOK_APPOINTMENT`)}
                </h3>
                <p className='regular'>
                  {i18next.t(`PICK_THE_TIME_SLOT_THAT_FITS_YOUR_SCHEDULE_CONT`)}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/1_book_appointment_woman_921529404e.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/2_meet_bc7808b17d.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP2`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`MEET_YOUR_ONLINE_PRACTITIONER`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `YOU_WILL_RECEIVE_INSTRUCTIONS_AND_REMINDERS_ON_HOW_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP3`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`GET_YOUR_DIAGNOSIS_TREATMENT_ONLINE`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/3_diagnosis_f600cbb613.svg'
                  alt='book'
                />
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/4_access_treatment_69ccdafcc0.svg'
                  alt='book'
                />
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <h5 style={{ margin: 0 }} className='right-align'>
                  {i18next.t(`STEP4`)}
                </h5>
                <h3 style={{ margin: 0 }} className='dark right-align'>
                  {i18next.t(`ACCESS_YOUR_CUSTOMZIED_TREATMENT_PLAN`)}
                </h3>
                <p className='regular right-align'>
                  {i18next.t(
                    `THE_PHYXABLE_PLATFORM_PROVIDES_EASY_ACCESS_TO_THE_TREATMENT_CONT`
                  )}
                </p>
              </div>
            </div>
            <div className='responsive-container'>
              <div style={{ padding: '1rem 2rem' }} className='left'>
                <h5 style={{ margin: 0 }}>{i18next.t(`STEP5`)}</h5>
                <h3 style={{ margin: 0 }} className='dark'>
                  {i18next.t(`TRACK_YOUR_PROGRESS`)}
                </h3>
                <p className='regular'>
                  {i18next.t(
                    `YOUR_FIRST_CONSULTATIONS_INVOLVES_A_FULL_ASSESSMENT_CONT`
                  )}
                </p>
              </div>
              <div style={{ padding: '1rem 2rem' }} className='right'>
                <img
                  className='grid-items img-200'
                  src='https://phyxable-blogs.s3.amazonaws.com/5_track_1220a5c1c8.svg'
                  alt='book'
                />
              </div>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <h1 className='dark'>
              {i18next.t(`WHY_SHOULD_YOU_CHOOSE_PHYXABLE`)}
            </h1>
            <div className='white-container-desktop'>
              <ul className='pin-point-flex'>
                {props.pointInfo.map(data => (
                  <li key={data.point} className='grid-flex'>
                    <img
                      src={data.image}
                      alt='checkmark'
                      style={{ width: '45px' }}
                    />
                    <p className='padding-left-1rem'>{data.point}</p>
                  </li>
                ))}
              </ul>
              <Link to='/booking-select'>
                <button
                  className='button'
                  style={{
                    marginTop: 1 + `rem`,
                    marginBottom: 1 + `rem`,
                  }}
                >
                  {i18next.t(`BOOK_FREE_CONSULTATION`)}
                </button>
              </Link>
            </div>
          </div>

          <div className='padding-top-4rem'>
            <PatientStories />
          </div>

          <div className='price-table'>
            <h1>
              {i18next.t(`SIMPLE_PRICING_TO`)} <br />
              {i18next.t(`FIT_YOUR_NEEDS`)}
            </h1>
            <h2>
              {i18next.t(`AND_THE_BEST_PART`)}
              <br /> {i18next.t(`YOUR_FIRST_SESSION_IS_ALWAYS_FREE_CONT`)}{' '}
            </h2>
            <div className='padding-top-2rem' />
            <div className='compare-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h2 className='header-price pink'>{content[0]}</h2>
                  ) : (
                    <p className='padding-price'>{content[0]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='traditional-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <h2 className='header-price'>
                      {i18next.t(`TRADITIONAL`)}
                      <br />
                      {i18next.t(`COUNSELLING`)}
                    </h2>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`BASED_ON_PROVIDERS`)}
                      <br />
                      {i18next.t(`CHOSEN_SHEDULE`)}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[1]}</p>
                  )}
                </div>
              ))}
            </div>
            <div className='phyxable-price'>
              {props.priceTable.map((content, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <div className='header-price'></div>
                  ) : index === 1 ? (
                    <p className='underline-border pink'>
                      {i18next.t(`65PRICE`)}
                    </p>
                  ) : index === 6 ? (
                    <p className='padding-price'>
                      {i18next.t(`CHOOSE_A_CARE_PROVIDER_THAT`)}
                      <br />
                      {i18next.t(`FITS_YOUR_SCHEDULE`)}{' '}
                    </p>
                  ) : (
                    <p className='underline-border'>{content[2]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div style={{ backgroundColor: '#EFF2F6' }}>
            <div className='right-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract}
                alt='mockup'
              />
            </div>
            <div className='responsive-container'>
              <div className='left'>
                <h1 className='dark'>
                  {i18next.t(`PERSONALIZED`)}
                  <br />
                  {i18next.t(`VIDEO_BASED_EXERCISE`)}
                  <br /> {i18next.t(`SOLUTIONS_CREATED_BY`)}
                  <br />
                  {i18next.t(`YOUR_PHYSIO`)}
                </h1>
                <p className='regular ftSz4'>
                  {i18next.t(
                    `FIX_YOUR_PAIN_AND_THEN_KEEP_FOLLOWING_THE_VIDEO_LEVELS_CONT`
                  )}
                </p>
              </div>
              <div className='right' style={{ padding: '0 4rem' }}>
                <img
                  className='max-100'
                  src={OnlinePhysioMockupVideo}
                  alt='mockup'
                />
              </div>
            </div>
            <div className='left-align'>
              <img
                className='max-100'
                src={OnlinePhysioAbstract1}
                alt='mockup'
              />
            </div>
          </div>

          <div className='padding-top-4rem'>
            <h1 className='dark'>{i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}</h1>
            <div className='pain-container'>
              {props.teamInfo.map(dr => (
                <div className='pain-wrap-corporation' key={dr.id}>
                  <img src={dr.image} alt={dr.name} />
                  <h4 className='dark'>{dr.name}</h4>
                  <p className='regular'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div className='accordion-container'>
            <h1 className='dark left-align'>
              {i18next.t(`WE_KNOW_WHAT_YOURE_THINKING`)}
              <br />
              <span style={{ fontSize: '75%' }}>
                {i18next.t(`THIS_SOUNDS_TOO_GOOD_TO_BE_TRUE`)}
              </span>
            </h1>
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(OnlinePhysiotherapy);
