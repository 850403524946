const INITIAL_STATE = {};

const applySetCurrentViewPort = (state, action) => ({
  ...state,
  currentViewPort: action.data,
});

function currentViewPortReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CURRENT_VIEWPORT_SET": {
      return applySetCurrentViewPort(state, action);
    }

    default:
      return state;
  }
}
export default currentViewPortReducer;
