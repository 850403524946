import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactJWPlayer from 'react-jw-player';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import './Home.css';

import PatientStories from './PatientStories';
import PatientStoriesMobile from './PatientStoriesMobile';
import PatientStoriesTablet from './PatientStoriesTablet';

import useViewport from '../../helpers/useViewPort';

// French
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function Home(props) {
  const [playlist, setPlaylist] = useState(null);
  const { t, i18n } = useTranslation();
  // const [currentWidth, setCurrentWidth] = useState(0);

  // const { width } = useViewport();
  // const mobileBreakpoint = 769;
  // const tabletBreakpoint = 1025;
  // const laptopBreakpoint = 1440;

  // useEffect(() => {
  //   if (width !== currentWidth) {
  //     setCurrentWidth(width);
  //   }
  // }, [width]);

  // useEffect(() => {
  //   if (currentWidth < mobileBreakpoint) {
  //     if (props.currentViewPort !== "MOBILE") {
  //       props.onSetCurrentViewPort("MOBILE");
  //     }
  //   } else if (currentWidth < tabletBreakpoint) {
  //     if (props.currentViewPort !== "TABLET") {
  //       props.onSetCurrentViewPort("TABLET");
  //     }
  //   } else if (currentWidth < laptopBreakpoint) {
  //     if (props.currentViewPort !== "LAPTOP") {
  //       props.onSetCurrentViewPort("LAPTOP");
  //     }
  //   } else {
  //     if (props.currentViewPort !== "DESKTOP") {
  //       props.onSetCurrentViewPort("DESKTOP");
  //     }
  //   }
  // }, [currentWidth]);

  const getStartedPressed = () => {
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Clicked Get Stared Website',
      label: 'Get Started_Website',
    });
    window.open('https://app.phyxable.com/on-boarding/', '_self');
  };

  useEffect(() => {
    window.scroll(0, 0);
    document.title = 'Phyxable | Home';
    fetch(`https://cdn.jwplayer.com/v2/media/I8zWSvS9`)
      .then(res => res.json())
      .then(
        result => {
          setPlaylist(result.playlist);
        },
        error => {
          console.log(error);
        }
      );
  }, []);

  return (
    <>
      {props.currentViewPort === 'MOBILE' ? (
        <MobileHome playlist={playlist} />
      ) : props.currentViewPort === 'TABLET' ? (
        <TabletHome playlist={playlist} />
      ) : props.currentViewPort === 'LAPTOP' ? (
        <LaptopHome playlist={playlist} />
      ) : (
        <DesktopHome playlist={playlist} />
      )}
    </>
  );
}
const MobileHome = props => {
  const getStartedPressed = () => {
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Clicked Get Stared Website',
      label: 'Get Started_Website',
    });
    window.open('https://app.phyxable.com/on-boarding/', '_self');
  };

  return (
    <div>
      <div className='bg-mobile-mobile p6'>
        <div className='home-container'>
          <div className='margin-auto '>
            <h1 className='pink main-font cAlign interRegular fw-600'>
              {i18next.t(`WHERE_HEALING_BEGINS`)}
            </h1>
          </div>
          <h3 className='pink cAlign mtop-1'>
            {i18next.t(`A_PAIN_STRATEGY_THAT_WORKS`)}
          </h3>{' '}
        </div>{' '}
      </div>
      <div className='primaryBackground w-100per'>
        <div className=' w-80per mlAuto mrAuto'>
          <center>
            <p className=' white padding-top-1rem ftSz4 ltSpREG'>
              {i18next.t(
                `PHYXABLE_EMPOWERS_YOU_WITH_WORLD_CLASS_PRACTITIONERS`
              )}
            </p>

            <div className='landing-button mtop10'>
              <button
                className='button interRegular ltSp1 ftSz5 ftWghtREG'
                style={{ background: '#fff', color: '#ff5a66' }}
                onClick={getStartedPressed}
              >
                {i18next.t(`GET_STARTED`)}
              </button>

              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.phyxable.com"
              >
                <button
                  className="button interRegular ltSp1 ftSz5 ftWghtREG"
                  style={{ background: "#fff", color: "#ff5a66" }}
                >
                  { i18next.t(`SELF_HELP_SOLUTIONS`) }
                </button>
              </a> */}
            </div>

            <div className=' mtop6 '>
              <div className=''>
                <p
                  style={
                    {
                      // width: "50%",
                      // fontSize: 10.2143 + `px`,
                    }
                  }
                  className='white lAlign'
                >
                  {i18next.t(`OUR_PARTNERS`)}
                </p>
              </div>
              <div className='divider-home' />

              <div className='dFlex  jSpace mlAuto mrAuto w-100per flexWrap '>
                <div className='w-100per dFlex pTwo  jSpace flexAlCenter '>
                  <div className='  mlAuto mrAuto '>
                    <img
                      src={require('../../assets/partner-logo/ff_logo.svg')}
                      alt='ff_logo'
                    />
                  </div>
                  <div className='w-50per '>
                    <img
                      src={require('../../assets/partner-logo/loblaws_logo.svg')}
                      alt='loblaws_logo'
                      className=''
                    />
                  </div>
                </div>

                <div className='w-100per dFlex pTwo  jSpace flexAlCenter   '>
                  <div className='w-50per pTwo'>
                    <img
                      src={require('../../assets/partner-logo/shoppers_drug_mart_logo.svg')}
                      alt='shoppers_drug_mart_logo'
                      className=''
                    />
                  </div>
                  <div className='w-50per pTwo'>
                    <img
                      src={require('../../assets/partner-logo/league_logo.svg')}
                      alt='league_logo'
                      className=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>

      <div>
        <div className='w-90per mrAuto mlAuto mtop2'>
          <h1 className='dark cAlign interRegular ftSz9 '>
            {i18next.t(`TAKE_CONTROL_OF_YOUR_PAIN_CONT`)}
          </h1>
          <p className='regular interRegular lAlign ftSz3'>
            {i18next.t(`LET_US_SHOW_YOU_A_BETTER_CONT`)}
          </p>
        </div>
        <div className='padding-top-1rem'>
          <video
            style={{
              maxWidth: '100%',
              borderRadius: '10px',
              boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
            }}
            muted
            autoPlay
            loop
            controls
            src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
          />
        </div>
        <div>
          <div className='w-90per mrAuto mlAuto cAlign mtop6'>
            <h2 className='dark  interRegular ftSz5'>{i18next.t(`PAIN`)}</h2>
            <p className='regular ftSz3'>
              {i18next.t(`WE_GET_TO_THE_ROOT_CAUSE_OF_YOUR_PAIN_CONT`)}
            </p>
          </div>

          <div className='w-90per mrAuto mlAuto cAlign mtop6'>
            <h2 className='dark  interRegular ftSz5'>{i18next.t(`PREVENT`)}</h2>
            <p className='regular ftSz3'>
              {i18next.t(`WE_HELP_YOU_BUILD_BETTER_CONT`)}
            </p>
          </div>

          <div className='w-90per mrAuto mlAuto cAlign mtop6'>
            <h2 className='dark  interRegular ftSz5'>{i18next.t(`PERFORM`)}</h2>
            <p className='regular ftSz3'>
              {i18next.t(`ONCE_YOU_HAVE_BUILT_GOOD_FOUNDATIONS`)}
            </p>
          </div>
        </div>

        <div className='w-90per mrAuto mlAuto cAlign mtop6'>
          <div>
            <h1 className='dark interRegular'>
              {i18next.t(`TALK_TO_ONE_OF_OUR`)}
              <br />
              {i18next.t(`PRACTITIONERS`)}
            </h1>
            <p className='regular'>
              {i18next.t(
                `PHYXABLE_PRACTITIONERS_PROVIDE_A_SPECIFIC_DIAGNOSIS_FOR_YOU_CONT`
              )}
            </p>
            <div className='center' style={{ padding: '2rem 0' }}>
              <img
                className='max-100'
                src={require('../../assets/Practitioners/Practitioners_Videocalls.png')}
                alt='Landingpage_mockup1'
              />
            </div>
            <div className=' dFlex flexCol flexAlCenter'>
              <Link to='/search-practitioners'>
                <button className='button'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='hero-map mtop6'>
          <div className='hero-text w-80per'>
            <h1 className='dark cAlign interRegular ftSz13 ftWght400'>
              {i18next.t(`LETS_MATCH_YOU_WITH_THE_BEST`)}
              <br />
              {i18next.t(`PRATITIONER_IN_YOUR_AREA`)}
            </h1>
          </div>
        </div>

        <div className='w-90per mrAuto mlAuto mtop cAlign'>
          <h1 className='dark  interRegular ftSz9 '>
            {i18next.t(`OR_TRY_A_PREVENTION`)}
            <br />
            {i18next.t(`PROGRAM_TODAY`)}
          </h1>
          <p className='regular interRegular cAlign ftSz3'>
            {i18next.t(`CONTINUAL_REMOTE_MONITORING_TO_MAKE_SURE_CONT`)}
          </p>
        </div>
        <div className='w-80per mrAuto mlAuto mtop'>
          <div className='center'>
            <img
              className='max-100'
              src={require('../../assets/home/Posture_Program.svg')}
              alt='Posture_Program'
            />
          </div>
          <div className='w-80per mrAuto mlAuto mtop'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://app.phyxable.com'
            >
              <button className='button'>{i18next.t(`FREE_TO_START`)}</button>
            </a>
          </div>
        </div>

        <div>
          <PatientStoriesMobile />
        </div>

        <div className='padding-top-2rem'>
          <div className='our-value'>
            <h1 className='cAlign ftSz10'>{i18next.t(`OUR_VALUE`)}</h1>
            <ul className=' cAlign dFlex flexCol'>
              <div className=' dFlex flexRow jSpaceB w-100per mlAuto mrAuto '>
                <li className='p1 w-100per mlAuto mrAuto'>
                  <h1 className='ftSz10'>$2.8M</h1>
                  <p className='interRegular ftSz2'>{i18next.t(`SAVED`)}</p>
                </li>
                <li className='p1 w-100per mlAuto mrAuto'>
                  <h1 className='ftSz10'>12,402</h1>
                  <p className='interRegular ftSz2'>
                    {i18next.t(`WORK_DAYS_SAVED`)}
                  </p>
                </li>
              </div>
              <div className=' dFlex flexRow jSpaceB w-100per mlAuto mrAuto '>
                <li className='p1 w-100per mlAuto mrAuto'>
                  <h1 className='ftSz10'>5,640</h1>
                  <p className='interRegular ftSz2'>
                    {i18next.t(`HOURS_SAVED_FROM_TRAVELING`)}
                  </p>
                </li>
                <li className='p1 w-100per mlAuto mrAuto'>
                  <h1 className='ftSz10'>29,340</h1>
                  <p className='interRegular ftSz2'>
                    {i18next.t(`PEOPLE_HELPED_OUT_OF_PAIN`)}
                  </p>
                </li>
              </div>
            </ul>
          </div>
        </div>

        {/* <div className="padding-top-6rem" /> */}

        <div className='mrAuto mlAuto w-90per cAlign'>
          <h1 className='dark ftSz9 interRegular'>
            {i18next.t(`PHYXALBE_AT_WORK`)}
            <br />
            {i18next.t(`ITS_FREE_TO_START`)}
          </h1>
          <p className='regular interRegular ltSpace ftWght1'>
            {i18next.t(
              `GETTING_YOU_BACK_TO_WORK_HEALTHIER_AND_PERFORMING_AT_YOUR`
            )}
          </p>
          <div className='cAlign' style={{ padding: '2rem 0' }}>
            <img
              className='max-100'
              src={require('../../assets/home/Book_demo.svg')}
              alt='book_demo'
            />
          </div>
          <div className='cAlign mbottom15 mtop6'>
            <Link to='/corporations'>
              <button className='button'>{i18next.t(`BOOK_A_DEMO`)}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabletHome = props => {
  const getStartedPressed = () => {
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Clicked Get Stared Website',
      label: 'Get Started_Website',
    });
    window.open('https://app.phyxable.com/on-boarding/', '_self');
  };
  return (
    <div>
      <div className='bg-tablet'>
        <div className='home-container'>
          <h1 className='pink ftSz15'>{i18next.t(`WHERE_HEALING_BEGINS`)}</h1>
          <h3 className='pink ftSz1'>
            {i18next.t(`A_PAIN_STRATEGY_THAT_WORKS`)}
          </h3>
          <p className='white ftSz0'>
            {i18next.t(`PHYXABLE_EMPOWERS`)}
            <br />
            {i18next.t(`PRACTITIONERS_AND_TECHNOLOGY`)}
            <br />
            {i18next.t(`AND_PAIN`)}
            <br />
            {i18next.t(`THIS_IS_WHERE`)}
          </p>

          <div className='landing-button'>
            <button
              className='button'
              style={{
                width: 170 + `px`,
                height: 40 + `px`,
                fontSize: 14 + `px`,
                borderStyle: 'none',
              }}
              onClick={getStartedPressed}
            >
              {i18next.t(`GET_STARTED`)}
            </button>

            {/* <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://app.phyxable.com'
            >
              <button
                className='button'
                style={{
                  width: 170 + `px`,
                  height: 40 + `px`,
                  fontSize: 14 + `px`,
                  borderStyle: 'none',
                }}
              >
                {i18next.t(`SELF_HELP_SOLUTIONS`)}
              </button>
            </a> */}
          </div>

          <div className='  '>
            <div className='divider-home-tablet' />
            <p style={{ fontSize: '0.75rem' }} className='white'>
              {i18next.t(`OUR_PARTNERS`)}
            </p>
            <div className='grid-container'>
              <div className='grid-items'>
                <img
                  src={require('../../assets/partner-logo/ff_logo.svg')}
                  alt='ff_logo'
                  style={{ width: 100 + `px` }}
                />
              </div>
              <div className='grid-items'>
                <img
                  src={require('../../assets/partner-logo/loblaws_logo.svg')}
                  alt='loblaws_logo'
                  style={{ width: 80 + `px` }}
                />
              </div>
              <div className='grid-items'>
                <img
                  src={require('../../assets/partner-logo/shoppers_drug_mart_logo.svg')}
                  alt='shoppers_drug_mart_logo'
                  style={{ width: 80 + `px` }}
                />
              </div>
              <div className='grid-items'>
                <img
                  src={require('../../assets/partner-logo/league_logo.svg')}
                  alt='league_logo'
                  style={{ width: 60 + `px` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mrAuto mlAuto cAlign mtop6'>
        <div className=' w-80per  mlAuto mrAuto  p0 '>
          <h1 className='dark ftSz10 cAlign'>
            {i18next.t(`TAKE_CONTROL_OF_YOUR_PAIN_CONT`)}
          </h1>
          <p className='regular ftSz1 cAlign'>
            {i18next.t(`LET_US`)}
            <br /> {i18next.t(`APPROACH`)}
          </p>
        </div>
        <div className='padding-top-1rem w-80per  mrAuto mlAuto'>
          <video
            style={{
              borderRadius: '10px',
              boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
            }}
            height='auto'
            width='100%'
            muted
            autoPlay
            loop
            controls
            src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
          />
        </div>
        <div className=' dFlex jCenter w-80per mrAuto mlAuto ptop100 '>
          <div className='pain-wrap mrAuto mlAuto'>
            <img
              className='grid-items img-100-actual'
              src={require('../../assets/home/pain.svg')}
              alt='pain'
            />
            <h2 className='pink ftSz3'>{i18next.t(`PAIN`)}</h2>
            <p className='regular ftSz0 w-75per mlAuto mrAuto  '>
              {i18next.t(`WE_GET_TO_THE_ROOT_CAUSE_OF_YOUR_PAIN_CONT`)}
            </p>
          </div>
          <div className='pain-wrap mrAuto mlAuto'>
            <img
              className='grid-items padding-vector'
              style={{ width: 66.6667 + `px` }}
              src={require('../../assets/vector/vector-1.svg')}
              alt='vector-1'
            />
          </div>
          <div className='pain-wrap mrAuto mlAuto'>
            <img
              className='grid-items img-100-actual'
              src={require('../../assets/home/prevent.svg')}
              alt='prevent'
            />
            <h2 className='pink ftSz3'>{i18next.t(`PREVENT`)}</h2>
            <p className='regular ftSz0  w-75per mlAuto mrAuto '>
              {i18next.t(`WE_HELP_YOU_BUILD_BETTER_CONT`)}
            </p>
          </div>
          <div className='pain-wrap mrAuto mlAuto'>
            <img
              className='grid-items padding-vector'
              style={{ width: 66.6667 + `px` }}
              src={require('../../assets/vector/vector-2.svg')}
              alt='vector-2'
            />
          </div>
          <div className='pain-wrap mrAuto mlAuto'>
            <img
              className='grid-items img-100-actual'
              src={require('../../assets/home/perform.svg')}
              alt='perform'
            />
            <h2 className='pink ftSz3'>{i18next.t(`PERFORM`)}</h2>
            <p className='regular ftSz0  w-75per mlAuto mrAuto '>
              {i18next.t(`ONCE_YOU_HAVE_BUILT_GOOD_FOUNDATIONS`)}
            </p>
          </div>
        </div>

        <div className='w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign'>
          <div className=' w-50per'>
            <h1 className='dark lAlign ftSz10'>
              {i18next.t(`TALK_TO_ONE_OF_OUR`)}
              <br />
              {i18next.t(`PRACTITIONERS`)}
            </h1>
            <p className='regular lAlign ftSz0'>
              {i18next.t(
                `PHYXABLE_PRACTITIONERS_PROVIDE_A_SPECIFIC_DIAGNOSIS_FOR_YOU_CONT`
              )}
            </p>
            <div className='padding-top-1rem lAlign'>
              <Link to='/search-practitioners'>
                <button className='button interRegular ftWght1'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
          <div className='w-50per rAlign'>
            <img
              className='w-100per'
              src={require('../../assets/Practitioners/Practitioners_Videocalls.png')}
              alt='Landingpage_mockup1'
            />
          </div>
        </div>

        <div className='hero-map-tablet w-100per'>
          <div className='' style={{ paddingTop: 15 + `%` }}>
            <div className='w-80per cAlign mlAuto mrAuto'>
              <h1 className='dark ftSz10 '>
                {i18next.t(`LETS_MATCH_YOU_WITH_THE_BEST`)}
                <br />
                {i18next.t(`PRATITIONER_IN_YOUR_AREA`)}
              </h1>
            </div>
            <div className='padding-top-1rem cAlign'>
              <Link to='/search-practitioners'>
                <button className='button '>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='w-80per dFlex jCenter flexAlCenter mlAuto mrAuto ptop100 mbottom10'>
          <div className='w-50per mlAuto mrAuto lAlign'>
            <img
              className='w-90per'
              src={require('../../assets/home/Posture_Program.svg')}
              alt='Posture_Program'
            />
          </div>
          <div className='w-50per mlAuto mrAuto' style={{ textAlign: 'right' }}>
            <h1 className='dark ftSz10'>
              {i18next.t(`OR_TRY_A_PREVENTION`)}
              <br />
              {i18next.t(`PROGRAM_TODAY`)}
            </h1>
            <p className='regular  ftSz0'>
              {i18next.t(`CONTINUAL_REMOTE_MONITORING_TO_MAKE_SURE_CONT`)}
            </p>
            <div className='padding-top-1rem'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://app.phyxable.com'
              >
                <button className='button'>{i18next.t(`FREE_TO_START`)}</button>
              </a>
            </div>
          </div>
        </div>

        <div>
          <PatientStoriesTablet />
        </div>

        <div className='padding-top-2rem'>
          <div className='our-value-tablet'>
            <h1>{i18next.t(`OUR_VALUE`)}</h1>
            <ul className=' dFlex w-90per jSpace flexAlStart mlAuto mrAuto ftSz1'>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`5_640`)}</h1>
                <p className='mtop-05'>
                  {i18next.t(`HOURS_SAVED_FROM_TRAVELING`)}
                </p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`2_8M`)}</h1>
                <p className='mtop-05'>{i18next.t(`SAVED`)}</p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`12_402`)}</h1>
                <p className='mtop-05'>{i18next.t(`WORK_DAYS_SAVED`)}</p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`29_340`)}</h1>
                <p className='mtop-05'>
                  {i18next.t(`PEOPLE_HELPED_OUT_OF_PAIN`)}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className='padding-top-2rem' />
        <div className='w-80per dFlex jCenter flexAlCenter mlAuto mrAuto  mbottom10'>
          <div className='w-50per lAlign'>
            <h1 className='dark lAlign ftSz10 '>
              {i18next.t(`PHYXALBE_AT_WORK`)}
              <br />
              {i18next.t(`ITS_FREE_TO_START`)}
            </h1>{' '}
            <p className='regular lAlign ftSz1 '>
              {i18next.t(
                `GETTING_YOU_BACK_TO_WORK_HEALTHIER_AND_PERFORMING_AT_YOUR`
              )}
            </p>
            <div className='padding-top-1rem lAlign'>
              <Link to='/corporations'>
                <button className='button'>{i18next.t(`BOOK_A_DEMO`)}</button>
              </Link>
            </div>
          </div>
          <div className='w-50per rAlign'>
            <img
              className='w-90per'
              src={require('../../assets/home/Book_demo.svg')}
              alt='book_demo'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LaptopHome = props => {
  const getStartedPressed = () => {
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Clicked Get Stared Website',
      label: 'Get Started_Website',
    });
    window.open('https://app.phyxable.com/on-boarding/', '_self');
  };
  return (
    <div>
      <div className='bg-desktop'>
        <div className='home-container'>
          <h1 className='pink main-font'>
            {i18next.t(`WHERE_HEALING_BEGINS`)}
          </h1>
          <h3 className='pink'>{i18next.t(`A_PAIN_STRATEGY_THAT_WORKS`)}</h3>
          <p className='white'>
            {i18next.t(`PHYXABLE_EMPOWERS`)}
            <br />
            {i18next.t(`PRACTITIONERS_AND_TECHNOLOGY`)}
            <br />
            {i18next.t(`AND_PAIN`)}
            <br />
            {i18next.t(`THIS_IS_WHERE`)}
          </p>

          <div className='landing-button'>
            <button className='button' onClick={getStartedPressed}>
              {i18next.t(`GET_STARTED`)}
            </button>

            {/* <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://app.phyxable.com'
            >
              <button className='button'>
                {i18next.t(`SELF_HELP_SOLUTIONS`)}
              </button>
            </a> */}
          </div>
        </div>
        <div style={{ padding: '2rem 4rem 0 4rem' }}>
          <p style={{ fontSize: '0.75rem' }} className='white'>
            {i18next.t(`OUR_PARTNERS`)}
          </p>
          <div className='divider-home' />
          <div className='grid-container'>
            <div className='grid-items'>
              <img
                src={require('../../assets/partner-logo/ff_logo.svg')}
                alt='ff_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                src={require('../../assets/partner-logo/loblaws_logo.svg')}
                alt='loblaws_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                src={require('../../assets/partner-logo/shoppers_drug_mart_logo.svg')}
                alt='shoppers_drug_mart_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                src={require('../../assets/partner-logo/league_logo.svg')}
                alt='league_logo'
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="bg-mobile">
          <div className="home-container">
            <h1 className="pink main-font right-align">
              Where
              <br /> healing begins.
            </h1>
            <h3 className="white right-align">A pain strategy that works.</h3>

            <center>
              <p className="center white padding-top-1rem">
                Phyxable empowers you with world class practitioners and
                technology to treat your injury and pain, while building a
                better you. This is where healing begins.
              </p>

              <div className="landing-button">
                <button className="button">Book Free Consultation</button>
                <button className="button">Self Help Solutions</button>
              </div>

              <div className="home-menu">
                <div className="divider-home" />
                <p style={{ float: "left", width: "30%" }} className="white">
                  Our partners
                </p>
                <span
                  style={{ float: "left", width: "60%" }}
                  className="grid-container"
                >
                  <div className="grid-items">
                    <img
                      src={require("../../assets/partner-logo/ff_logo.svg")}
                      alt="ff_logo"
                    />
                  </div>
                  <div className="grid-items">
                    <img
                      src={require("../../assets/partner-logo/loblaws_logo.svg")}
                      alt="loblaws_logo"
                    />
                  </div>
                  <div className="grid-items">
                    <img
                      src={require("../../assets/partner-logo/shoppers_drug_mart_logo.svg")}
                      alt="shoppers_drug_mart_logo"
                    />
                  </div>
                  <div className="grid-items">
                    <img
                      src={require("../../assets/partner-logo/league_logo.svg")}
                      alt="league_logo"
                    />
                  </div>
                </span>
              </div>
            </center>
          </div>
        </div> */}

      <div className='Content'>
        <h1 className='dark'>{i18next.t(`TAKE_CONTROL_OF_YOUR_PAIN_CONT`)}</h1>
        <p className='regular'>
          {i18next.t(`LET_US`)}
          <br />
          {i18next.t(`APPROACH`)}
        </p>
        <div className='padding-top-1rem'>
          <video
            style={{
              borderRadius: '10px',
              boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
            }}
            width='650'
            height='auto'
            muted
            autoPlay
            loop
            controls
            src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
          />
        </div>
        <div className='pain-container'>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/pain.svg')}
              alt='pain'
            />
            <h2 className='pink'>{i18next.t(`PAIN`)}</h2>
            <p className='regular'>
              {i18next.t(`WE_GET_TO_THE_ROOT_CAUSE_OF_YOUR_PAIN_CONT`)}
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items padding-vector'
              src={require('../../assets/vector/vector-1.svg')}
              alt='vector-1'
            />
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/prevent.svg')}
              alt='prevent'
            />
            <h2 className='pink'>{i18next.t(`PREVENT`)}</h2>
            <p className='regular'>
              {i18next.t(`WE_HELP_YOU_BUILD_BETTER_CONT`)}
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items padding-vector'
              src={require('../../assets/vector/vector-2.svg')}
              alt='vector-2'
            />
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/perform.svg')}
              alt='perform'
            />
            <h2 className='pink'>{i18next.t(`PERFORM`)}</h2>
            <p className='regular'>
              {i18next.t(`ONCE_YOU_HAVE_BUILT_GOOD_FOUNDATIONS`)}
            </p>
          </div>
        </div>

        <div className='responsive-container'>
          <div className='left'>
            <h1 className='dark'>
              {i18next.t(`TALK_TO_ONE_OF_OUR`)}
              <br />
              {i18next.t(`PRACTITIONERS`)}
            </h1>
            <p className='regular'>
              {i18next.t(
                `PHYXABLE_PRACTITIONERS_PROVIDE_A_SPECIFIC_DIAGNOSIS_FOR_YOU_CONT`
              )}
            </p>
            <div className='padding-top-1rem'>
              <Link to='/search-practitioners'>
                <button className='button'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
          <div className='right' style={{ padding: '2rem 0' }}>
            <img
              className='max-100'
              src={require('../../assets/Practitioners/Practitioners_Videocalls.png')}
              alt='Landingpage_mockup1'
            />
          </div>
        </div>

        <div className='hero-map'>
          <div className='hero-text'>
            <h1 className='dark'>
              {i18next.t(`LETS_MATCH_YOU_WITH_THE_BEST`)}
              <br />
              {i18next.t(`PRATITIONER_IN_YOUR_AREA`)}
            </h1>
            <div className='padding-top-1rem'>
              <Link to='/search-practitioners'>
                <button className='button'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='responsive-container'>
          <div className='left'>
            <img
              className='max-100'
              src={require('../../assets/home/Posture_Program.svg')}
              alt='Posture_Program'
            />
          </div>
          <div className='right' style={{ textAlign: 'right' }}>
            <h1 className='dark'>
              {i18next.t(`OR_TRY_A_PREVENTION`)}
              <br />
              {i18next.t(`PROGRAM_TODAY`)}
            </h1>
            <p className='regular'>
              {i18next.t(`CONTINUAL_REMOTE_MONITORING_TO_MAKE_SURE_CONT`)}
            </p>
            <div className='padding-top-1rem'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://app.phyxable.com'
              >
                <button className='button'>{i18next.t(`FREE_TO_START`)}</button>
              </a>
            </div>
          </div>
        </div>

        <div>
          <PatientStories />
        </div>

        <div className='padding-top-2rem'>
          <div className='our-value'>
            <h1>{i18next.t(`OUR_VALUE`)}</h1>
            <ul className='nav-our-value'>
              <li>
                <h1>{i18next.t(`5_640`)}</h1>
                <p>{i18next.t(`HOURS_SAVED_FROM_TRAVELING`)}</p>
              </li>
              <li>
                <h1>{i18next.t(`2_8M`)}</h1>
                <p>{i18next.t(`SAVED`)}</p>
              </li>
              <li>
                <h1>{i18next.t(`12_402`)}</h1>
                <p>{i18next.t(`WORK_DAYS_SAVED`)}</p>
              </li>
              <li>
                <h1>{i18next.t(`29_340`)}</h1>
                <p>{i18next.t(`PEOPLE_HELPED_OUT_OF_PAIN`)}</p>
              </li>
            </ul>
          </div>
        </div>

        <div className='padding-top-6rem' />
        <div className='responsive-container'>
          <div className='left'>
            <h1 className='dark'>
              {i18next.t(`PHYXALBE_AT_WORK`)}
              <br />
              {i18next.t(`ITS_FREE_TO_START`)}
            </h1>
            <p className='regular'>
              {i18next.t(
                `GETTING_YOU_BACK_TO_WORK_HEALTHIER_AND_PERFORMING_AT_YOUR`
              )}
            </p>
            <div className='padding-top-1rem'>
              <Link to='/corporations'>
                <button className='button'>{i18next.t(`BOOK_A_DEMO`)}</button>
              </Link>
            </div>
          </div>
          <div className='right' style={{ padding: '2rem 0' }}>
            <img
              className='max-100'
              src={require('../../assets/home/Book_demo.svg')}
              alt='book_demo'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopHome = props => {
  const getStartedPressed = () => {
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Button_Clicks',
      action: 'Clicked Get Stared Website',
      label: 'Get Started_Website',
    });
    window.open('https://app.phyxable.com/on-boarding/', '_self');
  };
  return (
    <div>
      <div className='bg-desktop'>
        <div className='home-container-desktop'>
          <h1
            className='pink main-font '
            style={{ fontSize: 54 + `px`, marginBottom: 40 + `px` }}
          >
            {i18next.t(`WHERE_HEALING_BEGINS`)}
          </h1>
          <h2 className='pink' style={{ fontSize: 22 + `px` }}>
            {i18next.t(`A_PAIN_STRATEGY_THAT_WORKS`)}
          </h2>
          <p className='white' style={{ fontSize: 22 + `px` }}>
            {i18next.t(`PHYXABLE_EMPOWERS`)}
            <br />
            {i18next.t(`PRACTITIONERS_AND_TECHNOLOGY`)}
            <br />
            {i18next.t(`AND_PAIN`)}
            <br /> {i18next.t(`THIS_IS_WHERE`)}
          </p>

          <div className='landing-button' style={{ marginTop: 40 + `px` }}>
            <button
              className='button '
              style={{
                fontSize: 22 + `px`,
                width: 310 + `px`,
                height: 60 + `px`,
                marginBottom: 40 + `px`,
              }}
              onClick={getStartedPressed}
            >
              {i18next.t(`GET_STARTED`)}
            </button>

            {/* <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://app.phyxable.com'
              
            >
              <button
                className='button'
                style={{
                  fontSize: 22 + `px`,
                  width: 310 + `px`,
                  height: 60 + `px`,
                  marginBottom: 40 + `px`,
                }}
              >
                {' '}
                {i18next.t(`SELF_HELP_SOLUTIONS`)}
              </button>
            </a> */}
          </div>
        </div>
        <div className='home-menu'>
          <p style={{ fontSize: 20 + `px` }} className='white'>
            {i18next.t(`OUR_PARTNERS`)}
          </p>
          <div className='divider-home' />
          <div className='grid-container'>
            <div className='grid-items'>
              <img
                style={{ width: '180px', height: '60px' }}
                src={require('../../assets/partner-logo/ff_logo.svg')}
                alt='ff_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                style={{ width: '180px', height: '60px' }}
                src={require('../../assets/partner-logo/loblaws_logo.svg')}
                alt='loblaws_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                style={{ width: '180px', height: '60px' }}
                src={require('../../assets/partner-logo/shoppers_drug_mart_logo.svg')}
                alt='shoppers_drug_mart_logo'
              />
            </div>
            <div className='grid-items'>
              <img
                style={{ width: '180px', height: '60px' }}
                src={require('../../assets/partner-logo/league_logo.svg')}
                alt='league_logo'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='Content'>
        <h1 className='dark'>{i18next.t(`TAKE_CONTROL_OF_YOUR_PAIN_CONT`)}</h1>
        <p className='regular ftSz4'>
          {i18next.t(`LET_US`)}
          <br />
          {i18next.t(`APPROACH`)}
        </p>
        <div className='padding-top-1rem'>
          <video
            style={{
              borderRadius: '10px',
              boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
            }}
            width='650'
            height='auto'
            muted
            autoPlay
            loop
            controls
            src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
          />
        </div>
        <div className='pain-container'>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/pain.svg')}
              alt='pain'
            />
            <h2 className='pink'>{i18next.t(`PAIN`)}</h2>
            <p className='regular ftSz4'>
              {i18next.t(`WE_GET_TO_THE_ROOT_CAUSE_OF_YOUR_PAIN_CONT`)}
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items padding-vector'
              src={require('../../assets/vector/vector-1.svg')}
              alt='vector-1'
            />
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/prevent.svg')}
              alt='prevent'
            />
            <h2 className='pink'> {i18next.t(`PREVENT`)}</h2>
            <p className='regular ftSz4'>
              {i18next.t(`WE_HELP_YOU_BUILD_BETTER_CONT`)}
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items padding-vector'
              src={require('../../assets/vector/vector-2.svg')}
              alt='vector-2'
            />
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-200'
              src={require('../../assets/home/perform.svg')}
              alt='perform'
            />
            <h2 className='pink'>{i18next.t(`PERFORM`)}</h2>
            <p className='regular ftSz4'>
              {i18next.t(`ONCE_YOU_HAVE_BUILT_GOOD_FOUNDATIONS`)}
            </p>
          </div>
        </div>

        <div className='w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign'>
          <div className=' w-50per'>
            <h1 className='dark lAlign ftSz10'>
              {i18next.t(`TALK_TO_ONE_OF_OUR`)}
              <br />
              {i18next.t(`PRACTITIONERS`)}
            </h1>
            <p className='regular lAlign ftSz4'>
              {i18next.t(
                `PHYXABLE_PRACTITIONERS_PROVIDE_A_SPECIFIC_DIAGNOSIS_FOR_YOU_CONT`
              )}
            </p>
            <div className='padding-top-1rem lAlign'>
              <Link to='/search-practitioners'>
                <button className='button interRegular ftWght1'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
          <div className='w-50per rAlign'>
            <img
              className='w-100per'
              src={require('../../assets/Practitioners/Practitioners_Videocalls.png')}
              alt='Landingpage_mockup1'
            />
          </div>
        </div>

        <div className='hero-map'>
          <div className='hero-text'>
            <h1 className='dark'>
              {i18next.t(`LETS_MATCH_YOU_WITH_THE_BEST`)}
              <br />
              {i18next.t(`PRATITIONER_IN_YOUR_AREA`)}
            </h1>
            <div className='padding-top-1rem'>
              <Link to='/search-practitioners'>
                <button className='button'>
                  {i18next.t(`FIND_PRACTITIONER`)}
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='w-80per dFlex jCenter flexAlCenter mlAuto mrAuto ptop100 mbottom10'>
          <div className='w-50per mlAuto mrAuto lAlign'>
            <img
              className='w-70per'
              src={require('../../assets/home/Posture_Program.svg')}
              alt='Posture_Program'
            />
          </div>
          <div className='w-50per mlAuto mrAuto' style={{ textAlign: 'left' }}>
            <h1 className='dark ftSz10'>
              {i18next.t(`OR_TRY_A_PREVENTION`)}
              <br />
              {i18next.t(`PROGRAM_TODAY`)}
            </h1>
            <p className='regular  ftSz4'>
              {i18next.t(`CONTINUAL_REMOTE_MONITORING_TO_MAKE_SURE_CONT`)}
            </p>
            <div className='padding-top-1rem'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://app.phyxable.com'
              >
                <button className='button'>{i18next.t(`FREE_TO_START`)}</button>
              </a>
            </div>
          </div>
        </div>

        <div>
          <PatientStoriesTablet />
        </div>

        <div className='padding-top-2rem'>
          <div className='our-value-tablet'>
            <h1>{i18next.t(`OUR_VALUE`)}</h1>
            <ul className=' dFlex w-90per jSpace flexAlStart mlAuto mrAuto ftSz1'>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`5_640`)}</h1>
                <p className='mtop-05'>
                  {i18next.t(`HOURS_SAVED_FROM_TRAVELING`)}
                </p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`2_8M`)}</h1>
                <p className='mtop-05'>{i18next.t(`SAVED`)}</p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`12_402`)}</h1>
                <p className='mtop-05'>{i18next.t(`WORK_DAYS_SAVED`)}</p>
              </li>
              <li className='mrAuto mlAuto w-25per cAlign'>
                <h1 className=' ftSz11'>{i18next.t(`29_340`)}</h1>
                <p className='mtop-05'>
                  {i18next.t(`PEOPLE_HELPED_OUT_OF_PAIN`)}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className='padding-top-2rem' />
        <div className='w-80per dFlex jCenter flexAlCenter mlAuto mrAuto  mbottom10'>
          <div className='w-50per lAlign'>
            <h1 className='dark lAlign ftSz10 '>
              {i18next.t(`PHYXALBE_AT_WORK`)}
              <br />
              {i18next.t(`ITS_FREE_TO_START`)}
            </h1>{' '}
            <p className='regular lAlign ftSz4 '>
              {i18next.t(
                `GETTING_YOU_BACK_TO_WORK_HEALTHIER_AND_PERFORMING_AT_YOUR`
              )}
            </p>
            <div className='padding-top-1rem lAlign'>
              <Link to='/corporations'>
                <button className='button'>{i18next.t(`BOOK_A_DEMO`)}</button>
              </Link>
            </div>
          </div>
          <div className='w-50per rAlign'>
            <img
              className='w-90per'
              src={require('../../assets/home/Book_demo.svg')}
              alt='book_demo'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width,
});
const mapDispatchToProps = dispatch => ({
  onSetCurrentViewPort: data =>
    dispatch({ type: 'CURRENT_VIEWPORT_SET', data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
