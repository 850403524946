import React from "react";
import { Link } from "react-router-dom";

const Card = ({ blog }) => {
  return (
      <div className="grid__item">
      <Link
        className="no-decoration"
        to={{ 
          pathname: `/blog/${blog.title.replace(/\s/g, '')}`
        }}>
        <div className="card" style={{display:"block"}}>
        {blog.image &&
            <img
              className="card__img"
              src={blog.image.url}
              alt={blog.image.url}
            />
        }
        <div className="card__content left-align">
          <h3 className="card__text">
            {blog.title}
          </h3>
          {blog.categories && blog.categories.map((category, index) => 
            <span key={index}>
              <Link 
                className="category pink"
                to={{ 
                    pathname: `/blog/category/${category.category.replace(/\s/g, '')}`,
                    state: { category: category.category } 
                }}>
              {index !== 0 && ","} {category.category}
              </Link>
            </span>
          )}
        </div>
        </div>
      </Link>
      </div>
  );
};

export default Card;