import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import "./FAQ.css";

const faq_data = [
  {
    id: 1,
    title: "INSURANCE",
    overview: "WILL_MY_INSURANCE_COVER_MY_ONLINE_ASSESSMENT",
    description:
      "ABSOLUTEL_IF_YOU_BOOK_AN_ASSESSMENT_WITH_US",
  },
  {
    id: 2,
    title: "PHYSIO",
    overview: "HOW_LONG_BEFORE_I_FEEL_THE_DIFFERENCE_FROM_PHYXABLE_PROGRAM",
    description:
      "TREATING_PHYSICAL_PAIN_TAKES_TIME",
  },
  {
    id: 3,
    title: "PHYXABLE",
    overview: "WHAT_ACTUALLY_IS_PHYXABLE",
    description:
      "PHYXABLE_IS_GUIDED_VIDEO_BASED_PAIN_RELIEF_CONT",
  },
  {
    id: 4,
    title: "INSURANCE",
    overview: "DO_I_GET_PERSONAL_SUPPORT_IF_I_NEED_IT",
    description:
      "YES_IF_YOU_NEED_ADDITIONAL_SUPPORT_CONT",
  },
  {
    id: 5,
    title: "PHYSIO",
    overview: "CAN_I_CANCEL_MY_APPOINTMENT",
    description:
      "YES_YOU_MAY_CANCEL_YOUR_APPOINTMENT_ANYTIME_BEFORE_CONT",
  },
  {
    id: 6,
    title: "PHYXABLE",
    overview: "HOW_LIKELY_IS_IT_THAT",
    description:
      "SO_FAR_WEVE_HELPED_OVER_600_PEOPLE_CONT",
  },
  {
    id: 7,
    title: "INSURANCE",
    overview:
      "CAN_I_TALK_TO_A_PHYSIOTHERAPIST_BEFORE_CONT",
    description:
      "YES_YOU_CAN_AS_LONG_AS_YOU_HAVE_A_SMARTPHONE_CONT",
  },
  {
    id: 8,
    title: "PHYSIO",
    overview: "WILL_YOU_DO_ANYTHING_AT_THE_FIRST_SESSION_CONT",
    description:
      "THE_FIRST_SESSION_IS_TYPICALLY_CONT",
  },
  {
    id: 9,
    title: "PHYXABLE",
    overview: "Will I get any exercises to take home with me?",
    description:
      "YOU_WILL_BE_ASSIGNED_EXERICES_AND_STRECTCHES_CONT",
  },
  {
    id: 10,
    title: "PHYXABLE",
    overview: "HOW_MUCH_DOES_IT_COST_TO_USE_THE_APP",
    description:
      "WE_OFFER_MONTHLY_AND_ANNUAL_SUBSCRIPTIONS_TO_THE_APP_CONT",
  },
  {
    id: 11,
    title: "PHYXABLE",
    overview: "HOW_DO_I_GET_ON_THE_APP",
    description:
      'ONCE_YOU_ARE_ON_THE_PHYXABLE_WEBSITE_CONT',
  },
  {
    id: 12,
    title: "PHYXABLE",
    overview: "HOW_DO_I_GET_A_MEMBERSHIP",
    description:
      'ONCE_YOU_SIGN_UP_FOR_THE_APP_NAVIGATE_CONT',
  },
];

function FAQ(props) {
  const { t, i18n } = useTranslation();
  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | FAQ";
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);
  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile />
      ) : viewPort === "TABLET" ? (
        <Tablet />
      ) : viewPort === "LAPTOP" ? (
        <Laptop />
      ) : (
        <Desktop />
      )}
    </>
  );
}

const Mobile = () => {
  return (
    <div>
      <div className="bg-faq-mobile">
        <div className="home-container ">
          <h1 className="pink main-font padding-left-1rem ">
            { i18next.t(`FREQUENTLY`) }
            <br /> { i18next.t(`ASKED`) }
            <br /> { i18next.t(`QUESTIONS`) }
          </h1>
        </div>
      </div>

      <div className="Content">
        <div className="faq-main">
          {faq_data.map((faq) => (
            <div key={faq.id} className="">
              <p className="gray roboto ftSz3">{ i18next.t(faq.title) }</p>
              <p className="pink interRegular ftSz6">{ i18next.t(faq.overview) }</p>
              <p className="interRegular secColor2 ftSz2 ftWght1">{ i18next.t(faq.description) }</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const Tablet = () => {
  return (
    <div>
      <div className="bg-faq-tablet">
        <div className="home-container">
          <h1 className="pink main-font padding-left-1rem">
            { i18next.t(`FREQUENTLY`) }
            <br /> { i18next.t(`ASKED`) }
            <br /> { i18next.t(`QUESTIONS`) }
          </h1>
        </div>
      </div>

      <div className="">
        <div className="w-90per dFlex jCenter mlAuto mrAuto FlexWrap flexAlStart">
          {faq_data.map((faq) => (
            <div key={faq.id} className="w-3rdPer pTwo  cAlign">
              <p className="gray">{ i18next.t(faq.title) }</p>
              <p className="pink bold">{ i18next.t(faq.overview) }</p>
              <p className="regular">{ i18next.t(faq.description) }</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const Laptop = () => {
  return (
    <div>
      <div className="bg-faq">
        <div className="home-container">
          <h1 className="pink main-font padding-left-1rem">
            { i18next.t(`FREQUENTLY`) }
            <br /> { i18next.t(`ASKED`) }
            <br /> { i18next.t(`QUESTIONS`) }
          </h1>
        </div>
      </div>

      <div className="Content">
        <div className="faq-main">
          {faq_data.map((faq) => (
            <div key={faq.id} className="faq-wrap">
              <p className="gray">{ i18next.t(faq.title) }</p>
              <p className="pink bold">{ i18next.t(faq.overview) }</p>
              <p className="regular">{ i18next.t(faq.description) }</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const Desktop = () => {
  return (
    <div>
      <div className="bg-faq">
        <div className="home-container">
          <h1
            className="pink main-font padding-left-1rem"
            style={{ fontSize: 54 + `px`, marginBottom: 40 + `px` }}
          >
            { i18next.t(`FREQUENTLY`) }
            <br /> { i18next.t(`ASKED`) }
            <br /> { i18next.t(`QUESTIONS`) }
          </h1>
        </div>
      </div>

      <div className="Content">
        <div className="faq-main">
          {faq_data.map((faq) => (
            <div key={faq.id} className="faq-wrap">
              <p className="gray">{ i18next.t(faq.title) }</p>
              <p className="pink bold">{ i18next.t(faq.overview) }</p>
              <p className="regular">{ i18next.t(faq.description) }</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(FAQ);
