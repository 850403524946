const INITIAL_STATE = {};

const applySetWidth = (state, action) => ({
  ...state,
  width: action.data,
});

function widthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "WIDTH_SET": {
      return applySetWidth(state, action);
    }

    default:
      return state;
  }
}
export default widthReducer;
