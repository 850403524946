import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import './CorporateWellness.css';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import PDF from '../../assets/Corporations/casestudy.pdf';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import PatientStoriesMobile from '../Home/PatientStoriesMobile';
import PatientStoriesTablet from '../Home/PatientStoriesTablet';
import PatientStories from '../Home/PatientStories';
import DR_JIM from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_drjim.png';
import DR_STEPH from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_drsteph.png';
import DR_AFSHIN from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_afshin.png';
import DR_SCOTT from '../../assets/OnlinePhysiotherapy/onlinephysiotherapy_scott.png';
import { Insurers } from './Insurers';
import Form from './Form';

function CorporateWellness(props) {
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [viewPort, setViewPort] = useState('');
  const [form, setForm] = useState('bookDemo');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [corporation, setCorporation] = useState('');

  const teamInfo = [
    {
      id: 1,
      name: 'Dr. Jim',
      title: i18next.t(`CHIROPRACTOR`),
      image: DR_JIM,
    },
    {
      id: 2,
      name: 'Dr. Steph ND',
      title: i18next.t(`NATUROPATH`),
      image: DR_STEPH,
    },
    {
      id: 3,
      name: 'Afshin',
      title: i18next.t(`PHYSIOTHERAPIST`),
      image: DR_AFSHIN,
    },
    {
      id: 4,
      name: 'Scott',
      title: i18next.t(`PHYSIOTHERAPIST`),
      image: DR_SCOTT,
    },
  ];

  let location = useLocation();

  useEffect(() => {
    document.title = 'Phyxable | Corporations';
    ReactGA.initialize('UA-148659511-2', { debug: true });
    if (viewPort !== props.currentViewPort) {
      // alert(currentViewPort);
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);

  const canBeSubmitted = () => {
    return name.length > 0 && email.length > 0 && corporation.length > 0;
  };

  const sendMail = () => {
    const name = document.getElementById('form_name').value;
    const email = document.getElementById('form_email').value;
    const corporation = document.getElementById('corporation').value;
    const message = document.getElementById('message')
      ? document.getElementById('message').value
      : '';

    if (name !== '' && email !== '' && corporation !== '' && message !== '') {
      setLoading(true);
      axios({
        method: 'POST',
        url: 'https://practitioner.phyxable.com/sendFeedback',
        data: {
          name: name,
          email: email,
          corporation: corporation,
          message: message,
          type: 'APPLY TODAY',
        },
      }).then(response => {
        if (response.data.msg === 'success') {
          ReactGA.event({
            category:
              location.pathname === '/corporatewellnesstrial'
                ? 'trial sign up two month'
                : 'trial sign up one month’',
            action: 'trial signup',
            label: 'trial signup',
          });
          document.getElementById('form_name').value = '';
          document.getElementById('form_email').value = '';
          document.getElementById('corporation').value = '';
          document.getElementById('message').value = '';
          setLoading(false);
          alert(
            'Thanks for reaching out!  We will be in touch via email or phone call shortly”'
          );
        } else if (response.data.msg === 'fail') {
          alert('Message failed to send.');
        }
      });
    } else {
      alert('Fill out data');
    }
  };

  const isEnabled = canBeSubmitted();
  const text =
    location.pathname === '/corporatewellnesstrial'
      ? 'Sign up for the two month trial'
      : 'Sign up for the one month trial';

  return (
    <>
      {viewPort === 'MOBILE' ? (
        <Mobile
          text={text}
          teamInfo={teamInfo}
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : viewPort === 'TABLET' ? (
        <Tablet
          text={text}
          teamInfo={teamInfo}
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : viewPort === 'LAPTOP' ? (
        <Laptop
          text={text}
          teamInfo={teamInfo}
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : (
        <Desktop
          text={text}
          teamInfo={teamInfo}
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      )}
    </>
  );
}
const Mobile = props => {
  return (
    <div>
      <div>
        <div
          style={{ background: '#fff' }}
          className='bg-mobile-mobile cAlign '
        >
          <div className='home-container'>
            <img
              src={require('../../assets/logo/logo_top_padding.svg')}
              style={{ height: '150px' }}
              alt='logo'
            />
            <h1 className='priColor interRegular ftSz13'>
              {i18next.t(`GIVE_YOUR_EMPLOYEES`)}{' '}
            </h1>
            <h3>{props.text}</h3>
          </div>
        </div>
        <center className='padding-top-2rem'>
          <Form />
        </center>
      </div>

      <center>
        <h1 className='pink'>How it Works</h1>
        <div>
          <div>
            <img
              className='grid-items img-200'
              src={require('../../assets/corporate-wellness/S5_Webinar.svg')}
              alt='pain'
            />
            <p className='regular'>
              1 hour "wellness talk" and ergonomics session - led by our
              practitioner.
            </p>
          </div>
          <div>
            <img
              className='grid-items img-200'
              src={require('../../assets/corporate-wellness/Conference.svg')}
              alt='prevent'
            />
            <p className='regular'>
              Free discovery sessions for all team members
            </p>
          </div>
          <div>
            <img
              className='grid-items img-200'
              src={require('../../assets/corporate-wellness/S5_Solve Puzzle.svg')}
              alt='perform'
            />
            <p className='regular'>
              Self-guided or practitioner led pain and prevention programs
            </p>
          </div>
          <div>
            <img
              className='grid-items img-200'
              src={require('../../assets/corporate-wellness/S5_Emailing.svg')}
              alt='perform'
            />
            <p className='regular'>Daily reminders for new "Phyxs"</p>
          </div>
        </div>

        <h1 className='pink'>Features</h1>
        <div
          style={{ justifyContent: 'space-around', paddingBottom: 0 }}
          className='pain-container'
        >
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/Data.svg')}
              alt='pain'
            />
            <p className='regular'>
              Usage based notifications to keep employees engaged
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/S5_Phone.svg')}
              alt='prevent'
            />
            <p className='regular'>
              Direct unlimited in-app messaging with practitioners
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/S5_Checklist.svg')}
              alt='perform'
            />
            <p className='regular'>
              New monthly exercise programs (around pain, prevention,
              performance)
            </p>
          </div>
        </div>
        <div
          style={{ justifyContent: 'space-around', paddingTop: 0 }}
          className='pain-container'
        >
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/Upwards.svg')}
              alt='perform'
            />
            <p className='regular'>Support with disability management</p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/S5_Files.svg')}
              alt='perform'
            />
            <p className='regular'>
              Reporting for insurance and employers based on cohort data
            </p>
          </div>
          <div className='pain-wrap'>
            <img
              className='grid-items img-150'
              src={require('../../assets/corporate-wellness/S5_Marketing_Research.svg')}
              alt='perform'
            />
            <p className='regular'>
              Reporting for employees on their individual progress
            </p>
          </div>
        </div>
      </center>

      <Insurers />

      <PatientStoriesMobile />

      <center>
        <div className='padding-top-4rem'>
          <h1 className='dark'>{i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}</h1>
          <div className='pain-container'>
            {props.teamInfo.map(dr => (
              <div className='pain-wrap-corporation' key={dr.id}>
                <img src={dr.image} alt={dr.name} />
                <h4 className='secColor1 interRegular ftSz5'>{dr.name}</h4>
                <p className='regular interRegular ftSz3'>{dr.title}</p>
              </div>
            ))}
          </div>
        </div>
      </center>

      <div className='w-90per mrAuto mlAuto mtop15 mbottom10'>
        <div id='book' className='book-demo-mobile cAlign'>
          <h2 className='ProximaNova blackColor ftSz3'>APPLY TODAY</h2>
          <div className='w-90per mrAuto mlAuto'>
            <p className='ProximaNova ftSz1 '>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
          </div>
          <div style={{ display: 'inline-block' }}>
            <input
              id='form_name'
              className='input-book-demo'
              placeholder={i18next.t('NAME')}
              onChange={e => props.setName(e.target.value)}
            />
            <input
              id='form_email'
              className='input-book-demo'
              placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
              onChange={e => props.setEmail(e.target.value)}
            />
            <input
              id='corporation'
              className='input-book-demo'
              placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
              onChange={e => props.setCorporation(e.target.value)}
            />
            {props.form === 'bookDemo' && (
              <textarea
                id='message'
                data-enable-grammarly='false'
                style={{ height: '16rem' }}
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
              />
            )}
          </div>
          <div className='padding-top-1rem'>
            <button className='button' onClick={props.sendMail}>
              {props.isLoading ? 'Loading...' : 'SIGN ME UP'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const Tablet = props => {
  return (
    <div>
      <div>
        <div style={{ background: '#fff' }} className='bg-tablet-corporation'>
          <div className='home-container'>
            <img
              src={require('../../assets/logo/logo_top_padding.svg')}
              style={{ height: '150px' }}
              alt='logo'
            />
            <h1 className='ftSz15'>{i18next.t(`GIVE_YOUR_EMPLOYEES`)}</h1>
            <h3>{props.text}</h3>
          </div>
          <center className='padding-top-4rem'>
            <Form />
          </center>
        </div>

        <center>
          <h1 className='pink'>How it Works</h1>
          <div className='pain-container'>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Webinar.svg')}
                alt='pain'
              />
              <p className='regular'>
                1 hour "wellness talk" and ergonomics session - led by our
                practitioner.
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector1.svg')}
                alt='vector-1'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/Conference.svg')}
                alt='prevent'
              />
              <p className='regular'>
                Free discovery sessions for all team members
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector2.svg')}
                alt='vector-2'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Solve Puzzle.svg')}
                alt='perform'
              />
              <p className='regular'>
                Self-guided or practitioner led pain and prevention programs
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector3.svg')}
                alt='vector-2'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Emailing.svg')}
                alt='perform'
              />
              <p className='regular'>Daily reminders for new "Phyxs"</p>
            </div>
          </div>

          <h1 className='pink'>Features</h1>
          <div
            style={{ justifyContent: 'space-around', paddingBottom: 0 }}
            className='pain-container'
          >
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/Data.svg')}
                alt='pain'
              />
              <p className='regular'>
                Usage based notifications to keep employees engaged
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Phone.svg')}
                alt='prevent'
              />
              <p className='regular'>
                Direct unlimited in-app messaging with practitioners
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Checklist.svg')}
                alt='perform'
              />
              <p className='regular'>
                New monthly exercise programs (around pain, prevention,
                performance)
              </p>
            </div>
          </div>
          <div
            style={{ justifyContent: 'space-around', paddingTop: 0 }}
            className='pain-container'
          >
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/Upwards.svg')}
                alt='perform'
              />
              <p className='regular'>Support with disability management</p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Files.svg')}
                alt='perform'
              />
              <p className='regular'>
                Reporting for insurance and employers based on cohort data
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Marketing_Research.svg')}
                alt='perform'
              />
              <p className='regular'>
                Reporting for employees on their individual progress
              </p>
            </div>
          </div>
        </center>

        <Insurers />

        <PatientStoriesTablet />

        <center>
          <div className='padding-top-4rem '>
            <h1 className='dark ftSz12'>
              {i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}
            </h1>
            <div className='w-55per mlAuto mrAuto'>
              <p className='secColor2 ftSz1 '></p>
            </div>

            <div className='dFlex jSpace w-90per mlAuto mrAuto mtop7 '>
              {props.teamInfo.map(dr => (
                <div className='w-25per cAlign ' key={dr.id}>
                  <img src={dr.image} alt={dr.name} className='w-80per pad0' />
                  <h4 className='dark ftSz2'>{dr.name}</h4>
                  <p className='regular ftSz0'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>
        </center>

        <div
          id='book'
          className='book-demo-tablet w-90per mlAuto mrAuto mbottom10'
        >
          <div className=' w-90per mlAuto mrAuto'>
            <h2 className='pink cAlign'>APPLY TODAY</h2>
            <p className='gray cAlign'>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
            <div>
              <input
                id='form_name'
                className='input-book-demo-tablet'
                placeholder={i18next.t('NAME')}
                onChange={e => props.setName(e.target.value)}
              />
              <input
                id='form_email'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                onChange={e => props.setEmail(e.target.value)}
              />
              <input
                id='corporation'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                onChange={e => props.setCorporation(e.target.value)}
              />
              {props.form === 'bookDemo' && (
                <textarea
                  id='message'
                  data-enable-grammarly='false'
                  style={{ height: '16rem' }}
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                />
              )}
            </div>
            <div className='padding-top-1rem cAlign'>
              <button className='button' onClick={props.sendMail}>
                {props.isLoading ? 'Loading...' : 'SIGN ME UP'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Laptop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-corporate-wellness'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div className='corporation-first'>
              <img
                src={require('../../assets/logo/logo_top_padding.svg')}
                style={{ height: '150px' }}
                alt='logo'
              />
              <h1 className='main-font' style={{ marginBottom: '20px' }}>
                {i18next.t(`GIVE_YOUR_EMPLOYEES`)}
              </h1>
              <h3>{props.text}</h3>
            </div>
            <center className='corporation-second'>
              <Form />
            </center>
          </div>
        </div>

        <div className='Content'>
          <h1 className='pink'>How it Works</h1>
          <div className='pain-container'>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Webinar.svg')}
                alt='pain'
              />
              <p className='regular'>
                1 hour "wellness talk" and ergonomics session - led by our
                practitioner.
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector1.svg')}
                alt='vector-1'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/Conference.svg')}
                alt='prevent'
              />
              <p className='regular'>
                Free discovery sessions for all team members
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector2.svg')}
                alt='vector-2'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Solve Puzzle.svg')}
                alt='perform'
              />
              <p className='regular'>
                Self-guided or practitioner led pain and prevention programs
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items padding-vector'
                src={require('../../assets/corporate-wellness/vector3.svg')}
                alt='vector-2'
              />
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-200'
                src={require('../../assets/corporate-wellness/S5_Emailing.svg')}
                alt='perform'
              />
              <p className='regular'>Daily reminders for new "Phyxs"</p>
            </div>
          </div>

          <h1 className='pink'>Features</h1>
          <div
            style={{ justifyContent: 'space-around', paddingBottom: 0 }}
            className='pain-container'
          >
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/Data.svg')}
                alt='pain'
              />
              <p className='regular'>
                Usage based notifications to keep employees engaged
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Phone.svg')}
                alt='prevent'
              />
              <p className='regular'>
                Direct unlimited in-app messaging with practitioners
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Checklist.svg')}
                alt='perform'
              />
              <p className='regular'>
                New monthly exercise programs (around pain, prevention,
                performance)
              </p>
            </div>
          </div>
          <div
            style={{ justifyContent: 'space-around', paddingTop: 0 }}
            className='pain-container'
          >
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/Upwards.svg')}
                alt='perform'
              />
              <p className='regular'>Support with disability management</p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Files.svg')}
                alt='perform'
              />
              <p className='regular'>
                Reporting for insurance and employers based on cohort data
              </p>
            </div>
            <div className='pain-wrap'>
              <img
                className='grid-items img-150'
                src={require('../../assets/corporate-wellness/S5_Marketing_Research.svg')}
                alt='perform'
              />
              <p className='regular'>
                Reporting for employees on their individual progress
              </p>
            </div>
          </div>

          <Insurers />

          <PatientStories />

          <center>
            <div className='padding-top-4rem'>
              <h1 className='dark'>
                {i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}
              </h1>
              <div className='pain-container'>
                {props.teamInfo.map(dr => (
                  <div className='pain-wrap-corporation' key={dr.id}>
                    <img src={dr.image} alt={dr.name} />
                    <h4 className='dark'>{dr.name}</h4>
                    <p className='regular'>{dr.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </center>

          <div id='book' className='book-demo'>
            <h2 className='pink'>APPLY TODAY</h2>
            <p className='gray'>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
            <div style={{ display: 'inline-block' }}>
              <input
                id='form_name'
                className='input-book-demo'
                placeholder={i18next.t('NAME')}
                onChange={e => props.setName(e.target.value)}
              />
              <input
                id='form_email'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                onChange={e => props.setEmail(e.target.value)}
              />
              <input
                id='corporation'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                onChange={e => props.setCorporation(e.target.value)}
              />
              {props.form === 'bookDemo' && (
                <textarea
                  id='message'
                  data-enable-grammarly='false'
                  style={{ height: '16rem' }}
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                />
              )}
            </div>
            <div className='padding-top-1rem'>
              <button className='button' onClick={props.sendMail}>
                {props.isLoading ? 'Loading...' : 'SIGN ME UP'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Desktop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-corporate-wellness'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <div className='left-align'>
              <img
                src={require('../../assets/logo/logo_top_padding.svg')}
                style={{ height: '200px' }}
                alt='logo'
              />
              <h1
                className='main-font'
                style={{
                  fontSize: 64 + `px`,
                  marginBottom: 30 + `px`,
                  marginTop: 0,
                  lineHeight: 1.25,
                }}
              >
                GIVE YOUR
                <br /> EMPLOYEES A TASTE
                <br /> OF OPTIMUM
                <br /> PHYSICAL HEALTH
              </h1>
              <h1>{props.text}</h1>
            </div>
            <center style={{ marginTop: '150px', paddingLeft: '2rem' }}>
              <Form />
            </center>
          </div>
        </div>

        <center>
          <div className='Content'>
            <h1 className='pink'>How it Works</h1>
            <div className='pain-container'>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-200'
                  src={require('../../assets/corporate-wellness/S5_Webinar.svg')}
                  alt='pain'
                />
                <p className='regular'>
                  1 hour "wellness talk" and ergonomics session - led by our
                  practitioner.
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items padding-vector'
                  src={require('../../assets/corporate-wellness/vector1.svg')}
                  alt='vector-1'
                />
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-200'
                  src={require('../../assets/corporate-wellness/Conference.svg')}
                  alt='prevent'
                />
                <p className='regular'>
                  Free discovery sessions for all team members
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items padding-vector'
                  src={require('../../assets/corporate-wellness/vector2.svg')}
                  alt='vector-2'
                />
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-200'
                  src={require('../../assets/corporate-wellness/S5_Solve Puzzle.svg')}
                  alt='perform'
                />
                <p className='regular'>
                  Self-guided or practitioner led pain and prevention programs
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items padding-vector'
                  src={require('../../assets/corporate-wellness/vector3.svg')}
                  alt='vector-2'
                />
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-200'
                  src={require('../../assets/corporate-wellness/S5_Emailing.svg')}
                  alt='perform'
                />
                <p className='regular'>Daily reminders for new "Phyxs"</p>
              </div>
            </div>

            <h1 className='pink'>Features</h1>
            <div
              style={{ justifyContent: 'space-around', paddingBottom: 0 }}
              className='pain-container'
            >
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/Data.svg')}
                  alt='pain'
                />
                <p className='regular'>
                  Usage based notifications to keep employees engaged
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/S5_Phone.svg')}
                  alt='prevent'
                />
                <p className='regular'>
                  Direct unlimited in-app messaging with practitioners
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/S5_Checklist.svg')}
                  alt='perform'
                />
                <p className='regular'>
                  New monthly exercise programs (around pain, prevention,
                  performance)
                </p>
              </div>
            </div>
            <div
              style={{ justifyContent: 'space-around', paddingTop: 0 }}
              className='pain-container'
            >
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/Upwards.svg')}
                  alt='perform'
                />
                <p className='regular'>Support with disability management</p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/S5_Files.svg')}
                  alt='perform'
                />
                <p className='regular'>
                  Reporting for insurance and employers based on cohort data
                </p>
              </div>
              <div className='pain-wrap'>
                <img
                  className='grid-items img-150'
                  src={require('../../assets/corporate-wellness/S5_Marketing_Research.svg')}
                  alt='perform'
                />
                <p className='regular'>
                  Reporting for employees on their individual progress
                </p>
              </div>
            </div>
          </div>
        </center>

        <Insurers />

        <PatientStories />

        <center>
          <div className='padding-top-4rem'>
            <h1 className='dark'>{i18next.t(`MEET_OUR_AWARD_WINNING_TEAM`)}</h1>
            <div className='pain-container'>
              {props.teamInfo.map(dr => (
                <div className='pain-wrap-corporation' key={dr.id}>
                  <img src={dr.image} alt={dr.name} />
                  <h4 className='dark'>{dr.name}</h4>
                  <p className='regular'>{dr.title}</p>
                </div>
              ))}
            </div>
          </div>

          <div id='book' className='book-demo'>
            <h2 className='pink'>APPLY TODAY</h2>
            <p className='gray ftSz4'>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
            <div style={{ display: 'inline-block' }}>
              <input
                id='form_name'
                className='input-book-demo'
                placeholder={i18next.t('NAME')}
                onChange={e => props.setName(e.target.value)}
              />
              <input
                id='form_email'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                onChange={e => props.setEmail(e.target.value)}
              />
              <input
                id='corporation'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                onChange={e => props.setCorporation(e.target.value)}
              />
              {props.form === 'bookDemo' && (
                <textarea
                  id='message'
                  data-enable-grammarly='false'
                  style={{ height: '16rem' }}
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                />
              )}
            </div>
            <div className='padding-top-1rem'>
              <button className='button' onClick={props.sendMail}>
                {props.isLoading ? 'Loading...' : 'SIGN ME UP'}
              </button>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(CorporateWellness);
