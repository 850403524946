import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Footer.css";
import { Link, useLocation } from "react-router-dom";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const solutionFooter = [
  { id: 1, name: "FOR_CORPORATIONS", url: "/corporations" },
  { id: 2, name: "FOR_PRACTITIONERS", url: "/practitioners" },
  { id: 3, name: "HOW_IT_WORKS", url: "/how-it-works" },
  { id: 4, name: "FIND_A_PRACTITIONER_NEAR_YOU", url: "/search-practitioners" },
];

const legalFooter = [
  { id: 1, name: "FAQ", url: "/faq" },
  { id: 2, name: "TERMS_OF_USE", url: "/terms-of-use" },
  { id: 3, name: "PRIVACY_POLICY", url: "/privacy-policy" },
];

const contactFooter = [
  { id: 1, name: "ABOUT_US", url: "/about-us" },
  { id: 2, name: "BLOG", url: "/blog" },
  { id: 3, name: "GET_SUPPORT", url: "/support"}
];

function Footer(props) {

  const { t, i18n } = useTranslation();
  const [viewPort, setViewPort] = useState("");

  useEffect(() => {
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);

  let location = useLocation();
  location = location.pathname;

  return (
    <>
      {location === "/corporatewellnesstrial" || location === "/corporatewellnessmonthtrial"  ? 
        <CorporateWellnessFooter />
      :
        viewPort === "MOBILE" ? (
        <MobileFooter location={location} />
      ) : viewPort === "TABLET" ? (
        <TabletFooter location={location} />
      ) : viewPort === "LAPTOP" ? (
        <LaptopFooter location={location} />
      ) : (
        <DesktopFooter location={location} />
      )}
    </>
  );
}
const MobileFooter = (props) => {
  return (
    <footer>
      <div className="w-90per mrAuto mlAuto lAlign">
        <div className="">
          <Link to="/">
            <img
              src={require("../../assets/logo/logo.svg")}
              className="Logo"
              alt="logo"
            />
          </Link>
        </div>
        <div className="w-90per mrAuto mlAuto lAlign">
          <p className="interRegular ftSz3 ftWght1 ltSpREG">
            { i18next.t(`A_PAIN_AND_PREVENTION_STRATEGY`) }
          </p>
        </div>
        <div className="w-90per mrAuto mlAuto lAlign">
          <section>
            <div className="mtop2">
              <h2 className="ft-title">{ i18next.t(`SOLUTIONS`) }</h2>
              <ul className="interRegular ftSz3 ftWght1 mtop-04">
                {solutionFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="mtop2">
              <h2 className="ft-title">{ i18next.t(`LEGAL`) }</h2>
              <ul className="interRegular ftSz3 ftWght1 mtop-04">
                {legalFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="mtop2">
              <h2 className="ft-title">{ i18next.t(`CONTACT`) }</h2>
              <ul className="interRegular ftSz3 ftWght1 mtop-04">
                {contactFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
                <li>{ i18next.t(`CALL_18557499669`) } 1-855-749-9669</li>
                <li>{ i18next.t(`EMAIL_SUPPORT`) }support@phyxable.com</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <section className="ft-legal mtop2">
        <ul className="ft-legal-list">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/phyxable"
            >
              <img
                src={require("../../assets/social/Facebook.svg")}
                alt="facebook"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/phyxable"
            >
              <img
                src={require("../../assets/social/Instagram.svg")}
                alt="instagram"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/phyxable"
            >
              <img
                src={require("../../assets/social/Twitter.svg")}
                alt="twitter"
              />
            </a>
          </li>
        </ul>

        <div>
          <p className="cAlign">
            © {new Date().getFullYear()} { i18next.t(`PHYXABLE_INC`) }
          </p>
        </div>
      </section>
    </footer>
  );
};
const TabletFooter = (props) => {
  return (
    <footer style={{ width: 100 + `vw` }}>
      {" "}
      <div className="footer-padding w-90per mlAuto mrAuto">
        <Link to="/">
          <img src={require("../../assets/logo/logo.svg")} alt="logo" />
        </Link>
      </div>
      <div className="ft-container w-90per mlAuto mrAuto">
        <section className=" dFlex jSpace">
          <div className=" w-3rdPer pTwo ">
            <h2 className="priColor ftSz2">SOLUTIONS</h2>
            <ul className=" ftSz3">
              {solutionFooter.map((item) =>
                item.url === props.location ? (
                  <li key={item.id} className="mbt4">
                    <a className="pink " href={item.url}>
                      {i18next.t(item.name)}
                    </a>
                  </li>
                ) : (
                  <li key={item.id} className="mbt4">
                    <a href={item.url}>{i18next.t(item.name)}</a>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className=" w-3rdPer pTwo ">
            <h2 className="priColor ftSz2">LEGAL</h2>
            <ul className=" ftSz3">
              {legalFooter.map((item) =>
                item.url === props.location ? (
                  <li key={item.id} className="mbt4">
                    <a className="pink" href={item.url}>
                      {i18next.t(item.name)}
                    </a>
                  </li>
                ) : (
                  <li key={item.id} className="mbt4">
                    <a href={item.url}>{i18next.t(item.name)}</a>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className=" w-3rdPer  pTwo ">
            <h2 className="priColor ftSz2">CONTACT</h2>
            <ul className=" ftSz3">
              {contactFooter.map((item) =>
                item.url === props.location ? (
                  <li key={item.id} className="mbt4">
                    <a className="pink" href={item.url}>
                      {i18next.t(item.name)}
                    </a>
                  </li>
                ) : (
                  <li key={item.id} className="mbt4">
                    <a href={item.url}>{i18next.t(item.name)}</a>
                  </li>
                )
              )}
                <li>{ i18next.t(`CALL_18557499669`) } 1-855-749-9669</li>
                <li>{ i18next.t(`EMAIL_SUPPORT`) }support@phyxable.com</li>
            </ul>
          </div>
        </section>
      </div>
      <section className="ft-legal w-90per mlAuto mrAuto">
        <ul className="ft-legal-list">
          <li className="ftSz1">
            © {new Date().getFullYear()} Phyxable Inc. All rights reserved.
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/phyxable"
            >
              <img
                src={require("../../assets/social/Facebook.svg")}
                alt="facebook"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/phyxable"
            >
              <img
                src={require("../../assets/social/Instagram.svg")}
                alt="instagram"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/phyxable"
            >
              <img
                src={require("../../assets/social/Twitter.svg")}
                alt="twitter"
              />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
};
const LaptopFooter = (props) => {
  return (
    <footer>
      <div className="ft-container">
        <div className="footer-padding">
          <Link to="/">
            <img
              src={require("../../assets/logo/logo.svg")}
              className="Logo"
              alt="logo"
            />
          </Link>
        </div>
        <div>
          <section className="ft-main">
            <div className="ft-main-item">
              <h2 className="ft-title">SOLUTIONS</h2>
              <ul>
                {solutionFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="ft-main-item">
              <h2 className="ft-title">LEGAL</h2>
              <ul>
                {legalFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="ft-main-item">
              <h2 className="ft-title">CONTACT</h2>
              <ul>
                {contactFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
                <li>Call: 1-855-749-9669</li>
                <li>Email: support@phyxable.com</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <section className="ft-legal">
        <ul className="ft-legal-list">
          <li>
            © {new Date().getFullYear()} Phyxable Inc. All rights reserved.
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/phyxable"
            >
              <img
                src={require("../../assets/social/Facebook.svg")}
                alt="facebook"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/phyxable"
            >
              <img
                src={require("../../assets/social/Instagram.svg")}
                alt="instagram"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/phyxable"
            >
              <img
                src={require("../../assets/social/Twitter.svg")}
                alt="twitter"
              />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
};
const DesktopFooter = (props) => {
  return (
    <footer>
      <div className="ft-container">
        <div className="footer-padding">
          <Link to="/">
            <img
              src={require("../../assets/logo/logo.svg")}
              className="Logo"
              alt="logo"
            />
          </Link>
        </div>
        <div>
          <section className="ft-main">
            <div className="ft-main-item">
              <h2 className="ft-title">SOLUTIONS</h2>
              <ul>
                {solutionFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="ft-main-item">
              <h2 className="ft-title">LEGAL</h2>
              <ul>
                {legalFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="ft-main-item">
              <h2 className="ft-title">CONTACT</h2>
              <ul>
                {contactFooter.map((item) =>
                  item.url === props.location ? (
                    <li key={item.id}>
                      <a className="pink" href={item.url}>
                        {i18next.t(item.name)}
                      </a>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <a href={item.url}>{i18next.t(item.name)}</a>
                    </li>
                  )
                )}
                <li>Call: 1-855-749-9669</li>
                <li>Email: support@phyxable.com</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <section className="ft-legal">
        <ul className="ft-legal-list">
          <li>
            © {new Date().getFullYear()} Phyxable Inc. All rights reserved.
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/phyxable"
            >
              <img
                src={require("../../assets/social/Facebook.svg")}
                alt="facebook"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/phyxable"
            >
              <img
                src={require("../../assets/social/Instagram.svg")}
                alt="instagram"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.twitter.com/phyxable"
            >
              <img
                src={require("../../assets/social/Twitter.svg")}
                alt="twitter"
              />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
};

const CorporateWellnessFooter = () => {
  return (
    <footer>
      <div style={{ padding: "2rem 0", marginTop: "100px" }} className="center">
          <Link to="/">
            <img
              src={require("../../assets/logo/logo.svg")}
              className="Logo"
              alt="logo"
            />
          </Link>
        </div>
    </footer>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(Footer);
