/**
 * Initialize firebase and it configs
 */
import firebase from "firebase/app";
import "firebase/auth";
require("firebase/functions");

export const firebaseConfig = {

  apiKey: "AIzaSyDZZ-kPUtTzhDIIC7eKcLuy0hOf-qpcIiI",
  authDomain: "phyxabletest.firebaseapp.com",
  databaseURL: "https://phyxabletest.firebaseio.com",
  projectId: "phyxabletest",
  storageBucket: "phyxabletest.appspot.com",
  messagingSenderId: "257817324866",
  appId: "1:257817324866:web:0bce82121a81eecd"

  // apiKey: "AIzaSyBAEyGaOXkMh-DyEmuaJJ_FBR688DnLNKY",
  // authDomain: "phyxabledevelopment.firebaseapp.com",
  // databaseURL: "https://phyxabledevelopment.firebaseio.com",
  // projectId: "phyxabledevelopment",
  // storageBucket: "phyxabledevelopment.appspot.com",
  // messagingSenderId: "556980468782",
  // appId: "1:556980468782:web:67b377c4b489f3e17f73bf"
};

export const doCreateUserWithEmailAndPassword = (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

export const phyxable = firebase.initializeApp(firebaseConfig, "phyxable");

export const dataBase = phyxable.firestore();
export const databaseRealtime = phyxable.database();
export const mediaStore = phyxable.storage;
export const authentication = phyxable.auth();
export const dx = phyxable;


export const fireMethods = {
  dataBase: phyxable.firestore(),
  analytics: phyxable.analytics(),
  databaseRealtime: phyxable.database(),
  mediaStore: phyxable.storage(),
  authentication: phyxable.auth(),
  createNewGoogleSheet: phyxable
    .functions()
    .httpsCallable("createNewGoogleSheet"),
  sendResetLink: phyxable.functions().httpsCallable("sendPasswordResetEmail"),
  doResetPassword: phyxable.functions().httpsCallable("resetUserPassword"),
  getDeliveryfee: phyxable.functions().httpsCallable("getDeliveryFee2"),
};
