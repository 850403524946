import React, { Component } from 'react';
import axios from 'axios';
import { MapComponent } from './MapComponent';

class Map extends Component {
  constructor(props){
    super(props)
    this.state = {
      currentLatLng: {
        lat: 0,
        lng: 0
      },
      isMarkerShown: false,
      wait: true,
      id: null,
      isOpen: false,
      latlongarray: [],
    }
  }

  async componentDidMount() {
    try {
      const { practitioner,currentLatLng } = this.props;
      this.setState({ currentLatLng: currentLatLng });
      let clinicDistance = [];

      function calculateDistance (lat1, lon1, lat2, lon2) {
        console.log(lat1, lon1, lat2, lon2);
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        dist = dist * 1.609344;
        // if(dist === 1) dist = parseFloat(dist.toFixed(1))+" km";
        // else if(dist > 1) dist = parseInt(dist)+" km";
        // else dist = parseInt(dist * 1000)+" m";
        return dist;
      }
      
      for(const i in practitioner) {
        let temp_latlong;
          if(practitioner[i].userProfile && practitioner[i].userProfile.clinicAddress){
        await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${practitioner[i].userProfile.clinicAddress.replace(/\s/g, '+')}&key=AIzaSyBu0OOuXfuaslnh48ZJIbR_Zf6sjoWR8ow`)
        .then((response) => temp_latlong = response.data.results[0].geometry.location )
        .then(() => clinicDistance.push({...practitioner[i], distance: calculateDistance(this.state.currentLatLng.lat,this.state.currentLatLng.lng,temp_latlong.lat,temp_latlong.lng)}))       
        this.setState({ latlongarray: [ ...this.state.latlongarray, temp_latlong ] });
      } else {
        clinicDistance.push({...practitioner[i], distance: null })
      }
      }
      
      let x = [];
      let y = [];
      for(const k in clinicDistance){
        if(clinicDistance[k].distance) x.push(clinicDistance[k]);
        else y.push(clinicDistance[k]);
      }
      console.log([ ...x.sort((a,b) => a.distance - b.distance), ...y]);
      this.props.arrangeOrder([ ...x.sort((a,b) => a.distance - b.distance), ...y]);
    } catch (error) {
      console.log(error);
    }  
  }

  onOpenClick = item => {
    this.setState({ isOpen: true, id: item });
  }

  render() {
    return (
        this.state.currentLatLng.lat !== 0 ? 
        <MapComponent
        onOpenClick={this.onOpenClick}
        isOpen={this.state.isOpen}
        id={this.state.id}
        currentLocation={this.state.currentLatLng}
        latlongarray={this.state.latlongarray}
      /> :
      <div>Loading...</div>
    )
  }
}

export default Map;