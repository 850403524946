import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { useLocation } from "react-router-dom";
import axios from 'axios';

const Form = () => {

  const[loading, setLoading] = useState(false);
  const[msg, setMsg] = useState("");
  
  let location = useLocation();

  const sendForm = () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const company = document.getElementById("company").value;
    const mobile = document.getElementById("mobile") ? document.getElementById("mobile").value : "";

      if (name !== "" && email !== "" && company !== "") {
        setLoading(true);
        axios({
          method: "POST",
          url: "https://practitioner.phyxable.com/sendTrialFeedback",
          data: {
            name: name,
            email: email,
            company: company,
            mobile: mobile,
          },
        }).then((response) => {
          if (response.data.msg === "success") {
            ReactGA.event({
              category: location.pathname === "/corporatewellnesstrial" ? "trial sign up two month" : "trial sign up one month’",
              action: 'trial signup',
              label: 'trial signup'
            });
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("company").value = "";
            document.getElementById("mobile").value = "";
            setLoading(false);
            setMsg("Thanks for your request. We'll be in touch with you shortly");
          } else if (response.data.msg === "fail") {
            setMsg("Message failed to send.");
          }
        });
      } else {
        setMsg("Fill out data");
      }
    
  };

  return(
    <div>
      <div id="book" style={{ background: "#EFF2F7", padding: "8px", width: "370px" }} className="book-demo">
            <p style={{ fontSize: "1.25rem", fontWeight: "bold" }}>Apply today to reserve {location.pathname === "/corporatewellnesstrial" ? "a two" : "one"} month long trial for your whole organization - Regardless of your team's size!</p>
            <div style={{ textAlign: "left", padding: "16px" }}>
              <Label style={{ fontWeight: "bold" }}>Name *</Label>
              <input id="name" className="input__signup"  />
              <Label style={{ fontWeight: "bold" }}>Company *</Label>
              <input
                  id="email"
                  className="input__signup"
                  // placeholder= { i18next.t("INPUT_BOOK_DEMO_EMAIL") }
                  // onChange={(e) => props.setEmail(e.target.value)}
                />
                <Label style={{ fontWeight: "bold" }}>Work Email *</Label>
                <input
                  id="company"
                  className="input__signup"
                  // placeholder= { i18next.t("INPUT_BOOK_DEMO_CORPORATION") }
                  // onChange={(e) => props.setCorporation(e.target.value)}
                />
                <Label style={{ fontWeight: "bold" }}>Phone Number</Label>
                <input
                  id="mobile"
                  className="input__signup"
                  // placeholder= { i18next.t("INPUT_BOOK_DEMO_CORPORATION") }
                  // onChange={(e) => props.setCorporation(e.target.value)}
                />
              <div className="padding-top-1rem">
              <button onClick={sendForm} className="sign_me_up_button" >
                {loading ? "Loading..." : "SIGN ME UP"}
              </button>
            </div>
            <p style={{ fontWeight: "bold" }}>{msg}</p>
            </div>
          </div>
    </div>
  )
}

export default Form;