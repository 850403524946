import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { compose, withProps } from 'recompose';

export const MapComponent = compose(
    withProps({
      googleMapURL:`https://maps.googleapis.com/maps/api/js?3.exp&libraries=geometry,drawing,places&key=AIzaSyBu0OOuXfuaslnh48ZJIbR_Zf6sjoWR8ow`,
      loadingElement: <div style={{ height: "100%" }} />,
      containerElement: <div style={{ height: "100%" }} />,
      mapElement: <div style={{ height: "100vh", border: "1px solid #FF5A66", borderRadius: "20px" }} />,
    }),
    withScriptjs,
    withGoogleMap
  )((props) =>
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: props.currentLocation.lat, lng: props.currentLocation.lng }}
    >
      <Marker 
        icon={require('../../assets/Practitioners/navigation.svg')}
        position={{ lat: props.currentLocation.lat, lng: props.currentLocation.lng }} />
      {props.latlongarray && props.latlongarray.map(item => 
      <div key={item.lat+item.lng}>
       <Marker
          icon={require('../../assets/Practitioners/marker.svg')}
          position={{ lat: item.lat, lng: item.lng }}
          //onClick={() => { props.onOpenClick(item.id) }}
        >
          {props.isOpen && item.id === props.id && 
          <InfoWindow>
            <div>
              {item.msg}
            </div>
          </InfoWindow>}
        </Marker></div>)}
    </GoogleMap>
  );