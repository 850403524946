import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Image from './assets/Image.jpg';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Empty from './assets/empty.png';
import Modal from 'react-modal';
import { IoCloseOutline } from 'react-icons/io5';

const CaseStudy = () => {
  const BASE_URL =
    window.location.hostname == 'localhost' ? 'http://localhost:3000' : '';

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');

  const imagePressed = () => {
    setShowModal(true);
  };

  const sendMail = () => {
    axios({
      method: 'POST',
      url: `${BASE_URL}/case-study-mail`,
      data: {
        email: email.trim(),
        name: name.trim(),
        companyName: companyName.trim(),
      },
    })
      .then(() => {
        console.log('Case study email sent');
      })
      .catch(err => {
        console.error('Sending Email ERR: ', err);
      });
  };

  const validate = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email !== '' || name !== '' || companyName !== '') {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    setShowModal(false);
    if (validate()) {
      sendMail();
      history.push('/case-details');
    } else {
      alert(
        'All fields are mandatory and please make sure the email you entered is correct'
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.container}>
      {/* Heading */}
      <div className={styles.heading}>
        <div className={styles.headText}>Case Studies</div>
        <div className={styles.headSubText}>
          <span>
            We've put together some of our most inspiring cases of recovery.
            Patients who came to us after suffering an injury, and experienced a
            positive turnaround in their conditions after following our
            customized programs.
          </span>
        </div>
      </div>
      {/* Buttons */}
      <div className={styles.buttonContainer}>
        <button className={styles.navigationButton}>View All</button>
      </div>
      {/* Image */}
      <div className={styles.image}>
        <div>
          <img src={Image} onClick={imagePressed} width='100%' height='200px' />
        </div>
        <div>
          <img src={Empty} onClick={imagePressed} width='100%' height='200px' />
        </div>
        <div>
          <img src={Empty} onClick={imagePressed} width='100%' height='200px' />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
          },
        }}
      >
        <div className={styles.modalContainer}>
          <div className={styles.close}>
            <IoCloseOutline size={25} onClick={closeModal} />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.modalHeading}>VIEW CASE STUDY</div>
            <div className={styles.modalSubHeading}>
              Please provide us with the following information to access the
              Case Study.
            </div>
            {/* Form */}
            <div>
              <input
                type='text'
                placeholder='Enter name'
                className={styles.modalInput}
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <input
                type='text'
                placeholder='Enter email-address'
                className={styles.modalInput}
                value={email}
                onChange={e => setEmail(e.target.value.trim())}
              />
              <input
                type='text'
                placeholder='Enter company name'
                className={styles.modalInput}
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
              <div className={styles.modalButtonContainer}>
                <button className={styles.modalButton} onClick={handleSubmit}>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CaseStudy;
