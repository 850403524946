import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function BookingQuebec() {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scroll(0, 0);
        document.title = "Phyxable | Booking";
        ReactGA.initialize('UA-148659511-2', { debug: true });
        ReactGA.event({
            category: 'Visitors on LP Quebec',
            action: '/booking',
            label: 'Booking'
        });
    }, []);
    
    const handleReactGAClick = () => {
        ReactGA.event({
          category: 'Clicks on CTA Quebec',
          action: '/booking',
          label: 'Booking'
        });
    }

    return(
        <div className="Content padding-2rem">
            <div className="padding-top-4rem">
                <h1 className="dark">
                Soulagez votre douleur<br/>
                N'importe quand n'importe où
                </h1>
                <p className="gray">Consultation vidéo individuelle en ligne<br/> 
                avec les meilleurs physiothérapeutes du Québec</p>

                <div className="padding-top-1rem" />
                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1"
                >
                    <button style={{ width: "350px" }} className="button" onClick={handleReactGAClick}>
                    Réservez la première consultation gratuite
                    </button>
                </a>
            
                <div className="padding-top-2rem" />

                <img 
                    className="max-100"
                    src={require("../../assets/booking/phyxable+online+physiotherapy+consultation.png")}
                    alt="booking"
                />

                <div className="padding-top-2rem" />

                <h1 className="dark">Comment ça fonctionne?</h1>

                <div className="left-align gray">
                    <strong>1. Prendre rendez-vous en physiothérapie</strong>
                        <p className="padding-left-1rem">Choisissez la plage horaire qui vous convient.</p>
                    <strong>2. Rencontrez votre thérapeute en ligne</strong>
                        <p className="padding-left-1rem">Rejoignez la consultation de physiothérapie vidéo en ligne par téléphone / tablette / ordinateur de n'importe où.</p>
                    <strong>3. Obtenez votre diagnostic et votre traitement en ligne</strong>
                        <p className="padding-left-1rem">Votre physiothérapeute Phyxable fera l'évaluation de votre condition et vous prescrira des exercices vidéo personnalisés. Phyxable accompagnera jusqu'à 100% de récupération.</p>
                </div>

                <div className="padding-top-2rem" />

                <h1 className="dark">Pourquoi Phyxable?</h1>

                <div className="responsive-container">
                    <div style={{ padding: "4rem 0" }} className="right">
                    <img
                        className="max-100"
                        src={require("../../assets/booking/Why+Phyxable.png")}
                        alt="Landingpage_mockup1"
                    />
                    </div>
                    <div className="left gray">
                        <p>1. La meilleure physiothérapie par téléréadaptation au Québec.</p>
                        <p>2. Couvert par les assurances. Nous nous occupons de tous les documents d'assurance et de facturation.</p>
                        <p>3. Efficacité prouvée à 92%.</p>
                        <p>4. Restez en sécurité à la maison. Sauvez du temps de transport.</p>
                    </div>
                </div>

                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1"
                >
                    <button style={{ width: "350px" }} className="button" onClick={handleReactGAClick}>
                    Réservez la première consultation gratuite
                    </button>
                </a>


                <div className="padding-top-2rem" />

                <h1 className="dark">Rencontrez notre équipe médicale réputée</h1>

                <div className="pain-container gray">
                    <div className="pain-wrap-corporation">
                        <img
                        style={{ width: "250px" }} src={require("../../assets/booking/Maude.png")} alt="Maude" />
                        <h4 className="gray">MAUDE JOBIN MARCHAND</h4>
                        <p>PHYSIOTHÉRAPEUTE</p>
                    </div>
                    <div className="pain-wrap-corporation">
                        <img
                        style={{ width: "250px" }} src={require("../../assets/booking/jimmy.png")} alt="jimmy" />
                        <h4 className="gray">Dr. JAMES FENG</h4>
                        <p>CHIROPRACTICIEN</p>
                    </div>
                </div>

                <div className="padding-top-2rem" />

                <h1 className="dark">Comment nous avons aidé</h1>

                <div className="responsive-container">
                    <div style={{ textAlign: "center" }} className="right">
                        <img
                            className="max-100"
                            src={require("../../assets/booking/jade.png")}
                            alt="jade"
                        />
                        <p>“Ma douleur au bas du dos a considérablement diminué au cours des 2 dernières consultations vidéo. C'est incroyablement et rafraîchissant de ressentir peu ou pas de douleur en étant assis à mon bureau.”</p>
                        <h4> - JADE C.</h4>
                    </div>
                    <div style={{  textAlign: "center" }}  className="left">
                        <img
                            className="max-100"
                            src={require("../../assets/booking/justin.png")}
                            alt="justin"
                        />
                        <p>“J'ai souffert de douleurs au bas du dos toute ma vie. D'avoir un moyen facile de le gérer à la maison a changé la donne. En plus d'aider mon dos, Phyxable a également contribué à améliorer ma santé physique globale.”</p>
                        <h4> - JUSTIN F.</h4>
                    </div>
                </div>

                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1"
                >
                    <button style={{ width: "350px" }} className="button" onClick={handleReactGAClick}>
                    Réservez la première consultation gratuite
                    </button>
                </a>


                <div className="padding-top-2rem" />

                <h1 className="dark">Tarification</h1>

                <div className="responsive-container">
                    <div className="right">
                    <img
                        className="max-100"
                        src={require("../../assets/booking/consult.png")}
                        alt="consult"
                    />
                    </div>
                    <div className="left gray" style={{ padding: "0", width: "30vw"  }}>
                        <h4>PREMIÈRE CONSULTATION GRATUITE</h4>
                        <p>Votre première consultation de physiothérapie avec Phyxable est entièrement gratuite. Aucune carte de crédit nécessaire. Faites votre diagnostic maintenant!</p>
                    </div>
                </div>

                <div className="responsive-container">
                    <div className="right">
                    <img
                        className="max-100"
                        src={require("../../assets/booking/insurance.png")}
                        alt="insurance"
                    />
                    </div>
                    <div className="left gray" style={{ padding: "0", width: "30vw" }}>
                        <h4>COUVERT PAR L'ASSURANCE</h4>
                        <p>Nous vous facturons directement. Vous pouvez obtenir un remboursement de votre assureur. Un reçu d'assurance vous sera envoyé.</p>
                    </div>
                </div>

                <div className="responsive-container">
                    <div className="right">
                    <img
                        className="max-100"
                        src={require("../../assets/booking/pay.png")}
                        alt="pay"
                    />
                    </div>
                    <div className="left gray" style={{ padding: "0", width: "30vw"  }}>
                        <h4>PAYEZ PAR SESSION</h4>
                        <p>Si vous n'avez pas d'assurance-travail, vous pouvez payer par session en utilisant une carte de crédit ou une carte de débit.</p>
                    </div>
                </div>

                <a 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1"
                >
                    <button style={{ width: "350px" }} className="button" onClick={handleReactGAClick}>
                    Réservez la première consultation gratuite
                    </button>
                </a>

                <p className="gray">Des questions? N'hésitez pas à nous appeler au +1 855-749-9669</p>
        </div>
    </div>
    )
};