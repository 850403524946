import { combineReducers } from "redux";

// import sampleReducer from "./sampleReducer";
import viewportsReducer from "./viewportsReducer";
import widthReducer from "./widthReducer";
import currentViewPortReducer from "./currentViewPort";
import currentDayReducer from "./currentDay";
import weekOfReducer from "./weekOf";

export const rootReducer = combineReducers({
  //   sampleReducer: sampleReducer,
  viewportsReducer: viewportsReducer,
  widthReducer: widthReducer,
  currentViewPortReducer: currentViewPortReducer,
  currentDayReducer: currentDayReducer,
  weekOfReducer: weekOfReducer,
});
