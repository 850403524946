import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './index.css';
import i18next from 'i18next';

const options = [
  'English',
  'Français',
];

export default function DropdownMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <div
        style={{
          fontSize: props.currentViewPort === "TABLET" ? 12 + `px` : null,
          color: "#FF5A66",
          border: "1px solid #FF5A66",
          borderRadius: "20px",
          padding: "8px 24px",
          cursor: "pointer",
        }}
        key="Lang"
        onClick={handleClick}
      >
        {localStorage.lang === "fr" ? "Français" : "English"} 
        <img style={{ paddingLeft: "8px" }} src={require("../../assets/vector/down.svg")} />
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem 
          className="more__actions"
          key={option} 
          onClick={() => {
              handleClose();
              if(option === 'English') {
                localStorage.setItem("lang", "en");
                i18next.changeLanguage("en");
              } 
              else {
                localStorage.setItem("lang", "fr");
                i18next.changeLanguage("fr");
              }
            }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
    )
}