import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import './thankyou.scss';
import moment from 'moment';
import ReactGA from 'react-ga';
import thankYouSVG from '../components/thankYou.svg';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const ThankYou = ({ selectedDateValue, time, currentViewPort }) => {
  const { t, i18n } = useTranslation();
  const [viewPort, setViewPort] = useState('');

  useEffect(() => {
    // console.log("Visitors on Thank You For Booking page");
    ReactGA.event({
      category: 'Visitors on Thank You For Booking page',
      action: '/booking',
      label: 'Booking',
    });

    ReactGA.event({
      category: 'Sign_up',
      action: 'Website_Booking_Sign_Up',
      label: 'Final Sign up',
    });
  }, [currentViewPort]);

  useEffect(() => {
    if (viewPort !== currentViewPort) {
      setViewPort(currentViewPort);
    }
  }, [currentViewPort]);

  let startTime = localStorage.getItem('clicked_time');


  return (
    <>
      {viewPort === 'MOBILE' ? (
        <Mobile />
      ) : viewPort === 'TABLET' ? (
        <NonMobile />
      ) : viewPort === 'LAPTOP' ? (
        <NonMobile />
      ) : (
        <NonMobile />
      )}
    </>
  );
};

const NonMobile = () => {
  return (
    <Grid>
      <Paper elevation={10} style={styleWeb} >

        <div className='child-container'>
          <h1 className='title'>{i18next.t(`THANK_YOU_FOR_BOOKING`)}</h1>

          <div className='sub-child'>
            <h1 className='f1'>{i18next.t(`FREE_CONSULT_AT`)}</h1>
          </div>

          <div className='time-stamp'>
            <h4>
              {moment(localStorage.getItem('clicked_date')).format(
                'dddd,  MMMM DD YYYY '
              )}
            </h4>
            <p>
              {' '}
              {localStorage.getItem('clicked_time')} -{' '}
              {localStorage.getItem('clicked_time_2')}
            </p>
          </div>

          <p className='tiny-info'>{i18next.t(`SIGNING_IN_WILL_TAKE_YOU`)}</p>

          <a href='https://app.phyxable.com/signin'>
            {' '}
            <button className='action-button '  style={{ background: '#ff5a66' , borderRadius:'1rem', width:'30%', color:'white'}}>{i18next.t(`SIGN_IN`)}</button>
          </a>
        </div>
      </Paper>
    </Grid>
  );
};

const Mobile = () => {
  return (
    <div className='parent'>
      <div className='child-container'>
        <h1 className='title'>{i18next.t(`THANK_YOU_FOR_BOOKING`)}</h1>

        <div className='sub-child mtop-5'>
          <h1 className='f1'>{i18next.t(`YOUR_FREE_CONSULTATION_IS`)}</h1>
        </div>

        <div className='time-stamp w-90per mrAuto mlAuto cAlign ftSz5 mtop-5'>
          <h4>
            {moment(localStorage.getItem('clicked_date')).format(
              'dddd,  MMMM DD YYYY '
            )}{' '}
            {localStorage.getItem('clicked_time')} -{' '}
            {localStorage.getItem('clicked_time_2')}
          </h4>
          <p></p>
        </div>
        <div>
          <img src={thankYouSVG} alt='' />
        </div>

        <p className='tiny-info ProximaNova'>
          {i18next.t(`SIGNING_IN_WILL_TAKE_YOU`)}
        </p>

        <a
          href='https://app.phyxable.com/signin'
          className='signUpMobileThankyou'
        >
          {' '}
          <button className='action-button' style={{ background: '#ff5a66'}}>
            Sign in
          </button>
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(ThankYou);
const styleWeb = {
  padding: 20,
  height: '90%',
  width: '50%',
  fontSize: '1rem',
  margin: '5% auto',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'ProximaNova'

}

const styleMobile = {
  padding: 20,
  height: '40%',
  width: '80%',
  fontSize: '1rem',
  margin: '5% auto',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column'

}