import React, { useEffect, useState } from 'react';
import {markdown} from 'markdown';
import showdown from 'showdown';
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { Link, useLocation } from 'react-router-dom';
import VASLoading from "../../assets/booking/Vas-Loading_1.gif";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Blog(props) {

  const { t, i18n } = useTranslation();

  const [blog, setBlog] = useState(null);
  const [html, setHTML] = useState("");
  let showDownMarkDown = new showdown.Converter();
    let location = useLocation();
  location = location.pathname;
  

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Blog";
    

       axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs`)
       
      .then(response => {
        for (const i in response.data) {
          if(`/blog/${response.data[i].title.replace(/\s/g, '')}` === location)
            setBlog(response.data[i]);
            // console.log("converter",converter);
        }

        console.log("blog",blog);
      
      })
      .catch(error => console.error(error));
  }, []);

  const contentBlog = blog => {

    console.log("------------------CONTENT BLOG----------------")
    console.log(blog.content)

    try {
      
      return(

      <div style={{marginTop:"150px", alignItems:"center"}}>

        <div>{ReactHtmlParser(showDownMarkDown.makeHtml(blog.content))}</div>

    


      </div>

    
      )
    } catch (error) {
      console.log(error);
    }
  }

    return blog!== null ? (
      <div className="Content">
        <div>
          <div>
            <p style={{ textAlign: "left", cursor: "pointer", padding: "4rem 0 0 4rem" }} 
              onClick={() => props.history.goBack()}>&lt; 
              <span style={{ textDecoration: "underline" }}> Back</span></p>
          {blog.image &&
              <div
                id="banner"
                data-src={blog.image.url}
                data-srcset={blog.image.url}
                data-uk-img
              >
              <h1>{blog.title}</h1>
              {blog.categories && blog.categories.map((category, index) => 
                <span key={index}>
                  <Link 
                    className="category pink"
                    to={{ 
                        pathname: `/blog/category/${category.category.replace(/\s/g, '')}`,
                        state: { category: category.category } 
                    }}>
                  {index !== 0 && ","} {category.category}
                  </Link>
                </span>
              )}
              <div className="padding-top-2rem"  />
              {blog.image &&
              <img
                src={blog.image.url}
                alt={blog.image.url}
                style={{ maxWidth: "100%" ,height: "auto", width: "1000px" }}
              />}
              <div className="uk-section">
                <div className="uk-container uk-container-small">
                  { contentBlog(blog) }
                </div>
              </div>
            </div>
          }
          </div>
        </div>
      </div>
    ) : 
    (
      <div className="center" >
        <img src={VASLoading} />
      </div>
    )
}