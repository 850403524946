import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown";
import './LandingPage.scss';
import { useLocation } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { markdown } from 'markdown';
import axios from 'axios';
import ReactGA from 'react-ga';
import VASLoading from "../../assets/booking/Vas-Loading_1.gif";

export default function LandingPageContent() {
  const [page, setPage] = useState(null);

  let location = useLocation();
  location = location.pathname;
  console.log(location);

  const reactGA = document.getElementsByClassName("react-ga");
  const reactGA1 = document.getElementsByClassName("react-ga-1");
  const reactGA2 = document.getElementsByClassName("react-ga-2");
  const reactGA3 = document.getElementsByClassName("react-ga-3");
  const reactGA4 = document.getElementsByClassName("react-ga-4");
  const reactGA5 = document.getElementsByClassName("react-ga-5");

  function handleReactGA(id, num) {
    try {
      for (var i = 0; i < id.length; i++) {
        id[i].onclick = function () {
          if (num === 0) {
            console.log(page.location);
            ReactGA.event({
              category: `Clicks on CTA ${page.location}`,
              action: '/booking',
              label: 'Booking'
            });
          }
          else {
            //       console.log(`Clicks on CTA-${num} ${page.location}`); 
            ReactGA.event({
              category: `Clicks on CTA-${num} ${page.location}`,
              action: '/booking',
              label: 'Booking'
            });
          }

        }
      };
    } catch (e) {
      console.log(e);
    }
  }

  if (reactGA) {
    handleReactGA(reactGA, 0);
  }

  if (reactGA1) {
    handleReactGA(reactGA1, 1);
  }

  if (reactGA2) {
    handleReactGA(reactGA2, 2);
  }

  if (reactGA3) {
    handleReactGA(reactGA3, 3);
  }

  if (reactGA4) {
    handleReactGA(reactGA4, 4);
  }

  if (reactGA5) {
    handleReactGA(reactGA5, 5);
  }

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Landing Page";
    ReactGA.initialize('UA-148659511-2', { debug: true });
    let temp;
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/landing-pages`)
      .then(response => temp = response.data)
      .then(() => {
        for (const i in temp) {
          if (`/free-consultation/${temp[i].url}` === location) {
            setPage(temp[i]);
            // console.log(temp[i]);
            ReactGA.event({
              category: `Visitors on LP ${temp[i].location}`,
              action: '/booking',
              label: 'Booking'
            });
          }
        }
      })
      .catch(error => console.error(error));
  }, []);

  const contentBlog = page => {
    console.log("Page:", (page.content));
    try {
      return (
        
          <div>{ReactHtmlParser(page.content)}</div>
       

        // <div style={{paddingTop:'150px'}} dangerouslySetInnerHTML={{__html:(page.content)}}></div>
      )
    } catch (error) {
      console.log("Error:", error);
    }
  }

  return page !== null ? (
    <div className="lp-section">
     
        {contentBlog(page)}
     
    </div>

  )
    :
    (
      <div className="center" >
        <img src={VASLoading} />
      </div>
    )
}