import React, { Component } from 'react';
import './Blog.scss';
import Card from './Card';
import axios from 'axios';

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

export default class Category extends Component {
  state = {
    blogs: [],
    error: null,
  }

  componentDidMount = async () => {
    window.scroll(0,0);
    if(this.props.location.state){
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs`)
      .then(response => {
        for (const i in response.data) {
          for(const j in response.data[i].categories){
            if(response.data[i].categories[j].category === this.props.location.state.category)
              this.setState({ blogs: [ ...this.state.blogs, response.data[i] ] });
          }
        }
      })
      .catch(error => console.error(error));
    }
  }

  render(){
    const { error, blogs } = this.state;
    console.log(blogs);
    
    if (error) {
       return <div className='Content'>An error occured: {error.message}</div>
    }

    return(
    <div>
        <div className="Content">
            <div className="grid">
                {blogs ? blogs.map(blog => 
                    <Card blog={blog} key={`blog__${blog.id}`} />
                ) : 
                <p className="dark">Loading...</p> }
            </div>
        </div>
    </div>
    )
  }
}

Category = withTranslation()(Category);