import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import { Link } from 'react-router-dom';
import './bookingSelectStyles.css';
import canada from '../../assets/booking/Canada_Flag.svg'
import Emoji from '../../assets/booking/Emoji.svg'
import provinces from '../../helpers/provinces';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Provinces() {
   
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scroll(0, 0);
        document.title = "Phyxable | Provinces";
        ReactGA.initialize('UA-148659511-2', { debug: true });
    }, []);

    const handleReactGAClick = Location => {
        ReactGA.event({
        category: `Clicked on ${Location} button from /provinces`,
        action: '/booking-select',
        label: 'Booking'
        });
    }

    return(
        <>
        <div className="Content">
            <div className="padding-top-4rem">
                <h1 className="dark">{ i18next.t(`SELECT_YOUR_PROVINCE`) }</h1>
                <center>
            <div className="provinces-width">

            {provinces.map( province => 
            <span key= {province.id}>
            {/* {province.province === "Quebec - Francais" ?
            <a target ="_blank" href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1">
                                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                                    <button onClick={() => handleReactGAClick("Quebec")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Quebec - Francais</button>
                                </div>
                            </a>
            : */}
            {/* // pathname: `/free-consultation/${landingpage.url}` */}
                            <Link 
                            onClick ={() =>localStorage.setItem("province", province.province)}
                            to={{
                                pathname : "/appointment-booking",
                                state: province
                            }}>
                            <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                                <button onClick={() => handleReactGAClick(province.province)} className="booking-select-button padding-left-1rem margin-bottom-1rem">  {province.province}</button>
                            </div>
                            </Link>
            </span>
            )}

                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("USA")} style={{cursor: "default"}} className="Yukon-booking-button-select padding-left-1rem margin-bottom-1rem"></button>
                </div> 

                {/* <Link to="/appointment-booking">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Alberta")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Alberta</button>
                    </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("BC")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`BRITISH_COLUMBIA`) }</button>
                </div>
                </Link>
                <Link to="/appointment-booking">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Manitoba")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Manitoba</button>
                    </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("New Brunswick")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`NEW_BRUNSWICK`) }</button>
                </div>
                </Link>
                <Link to="/appointment-booking">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("New Foundland")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`NEWFOUNDLAND_LABRADOR`) }</button>
                    </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Northwest Territorries")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`NORTHWEST_TERRITORIES`) }</button>
                </div>
                </Link>
                <Link to="/appointment-booking">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Nova Scotia")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`NOVA_SCOTIA`) }</button>
                    </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Nunavat")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Nunavat</button>
                </div>
                </Link>
                <Link to="/appointment-booking">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Ontario")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Ontario</button>
                    </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Prince Edward Island")} className="booking-select-button padding-left-1rem margin-bottom-1rem">{ i18next.t(`PRINCE_EDWARD_ISLAND`) }</button>
                </div>
                </Link>
                <a target ="_blank" href="https://calendly.com/phyxable/first-free-online-physiotherapy-video-consultat-quebec/?utm_campaign=quebec&utm_medium=cpc&utm_source=google&utm_term=button1">
                    <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Quebec")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Quebec - Francais</button>
                    </div>
                </a>
                
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Saskatchewan")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Saskatchewan</button>
                </div>
                </Link>
                <Link to="/appointment-booking">
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Yukon")} className="booking-select-button padding-left-1rem margin-bottom-1rem">  Yukon</button>
                </div>
                </Link>
                <div className="padding-top-1rem margin-bottom-1rem" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("USA")} style={{cursor: "default"}} className="Yukon-booking-button-select padding-left-1rem margin-bottom-1rem"></button>
                </div> */}
            </div>
            </center>
        </div>
        </div>
        </>
    )
}