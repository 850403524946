import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function ControlledAccordions() {

  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion style={{ boxShadow: "0 2px 8px 0 rgba(0,0,0,0.18)" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <h4 className="accordian-details">{ i18next.t(`HOW_IS_THE_PRICE_SO_LOW`) }</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p className="left-align accordian-details">
          { i18next.t(`BY_HAVING_THE_SESSIONS_VIA_VIDEO_CONT`) }
          </p>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion style={{ boxShadow: "0 2px 8px 0 rgba(0,0,0,0.18)" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <h4 className="accordian-details">{ i18next.t(`HOW_IS_THE_PRICE_SO_LOW`) }</h4>
        </AccordionSummary>
        <AccordionDetails>
          <p className="left-align accordian-details">
          { i18next.t(`BY_HAVING_THE_SESSIONS_VIA_VIDEO_CONT`) }
          </p>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}