import moment from 'moment-timezone';

const provinces = [
  { id: "1", province: "Alberta", data: { tzMomentName: 'US/Mountain' } },
  { id: "2", province: "British Columbia", data: { tzMomentName: 'US/Pacific' } },
  { id: "3", province: "Manitoba", data: { tzMomentName: 'US/Central' } },
  { id: "4", province: "New Brunswick", data: { tzMomentName: 'Canada/Atlantic' } },
  { id: "5", province: "Newfoundland and Labrador", data: { tzMomentName: 'Canada/Newfoundland' } },
  { id: "6", province: "Northwest Territories", data: { tzMomentName: 'America/Yellowknife' } },
  { id: "7", province: "Nova Scotia", data: { tzMomentName: 'Canada/Atlantic' } },
  { id: "8", province: "Nunavut", data: { tzMomentName: 'America/Iqaluit' } },
  { id: "9", province: "Ontario", data: { tzMomentName: 'Canada/Eastern' } },
  { id: "10", province: "Prince Edward Island", data: { tzMomentName: 'Canada/Atlantic' } },
  { id: "11", province: "Quebec - Francais", data: { tzMomentName: 'Canada/Eastern' } },
  { id: "12", province: "Saskatchewan", data: {  tzMomentName: 'Canada/Saskatchewan' } },
  { id: "13", province: "Yukon", data: { tzMomentName: 'Canada/Yukon' } },
];

// localStorage.getItem("province") === "Alberta" ? "MT" :
// localStorage.getItem("province") === "British Columbia" ? "PST" :
// localStorage.getItem("province") === "Manitoba" ? "CT" :
// localStorage.getItem("province") === "New Brunswick" ? "AST" :
// localStorage.getItem("province") === "Newfoundland and Labrador" ? "NDT" :
// localStorage.getItem("province") === "Nova Scotia" ? "AST" :
// localStorage.getItem("province") === "Northwest Territories" ? "MT" :
// localStorage.getItem("province") === "Nunavat" ? "MT" :
// localStorage.getItem("province") === "Ontario" ? "EST" :
// localStorage.getItem("province") === "Prince Edward Island" ? "AST" : 
// localStorage.getItem("province") === "Saskatchewan" ? "CT" :
// localStorage.getItem("province") === "Yukon" ? "PST" : 
// ''

const provincesByName = Object.fromEntries(provinces.map(p => ([p.province, p])));


export function getProvince(provinceName) {
  return provincesByName[provinceName];
}

export function getProvinceTimezoneName(provinceName) {
  const province = getProvince(provinceName);
  if (!province) {
    return null;
  }
  return moment.tz(province.data.tzMomentName).zoneAbbr();
}

export function convertToProvinceTime(time, targetProvince) {
  const province = getProvince(targetProvince);
  time = moment(time);
  if (!province) {
    return time;
  }
  return moment.tz(time, province.data.tzMomentName);
}

export function getProvinceUtcOffset(provinceName) {
  const province = getProvince(provinceName);
  if (!province) {
    return null;
  }
  return moment.tz(province.data.tzMomentName).utcOffset();
}

window.moment = moment;
window.convertToProvinceTime = convertToProvinceTime;

export default provinces;