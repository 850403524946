// reverse indicates that we are converting from EST
const convertTimeToEST = (date, timezone, reverse = 1) => {

	date = new Date(date);

	switch(timezone) {
      case "EST": // ON
        break;
      case "NDT": // NL
        date.setHours(date.getHours() - 1 * reverse);
        date.setMinutes(date.getMinutes() - 30 * reverse);
        break;
      case "ADT": // Nova Scotia
        date.setHours(date.getHours() - 1 * reverse);
        break;
      case "CDT": // Manitoba
        date.setHours(date.getHours() - 2 * reverse);
        break;
      case "CST": // Saskatchewan and Alberta
        date.setHours(date.getHours() + 1 * reverse);
        break;
      case "PDT": // BC
        date.setHours(date.getHours() + 3 * reverse);
        break;
    }

    return new Date(date);
}

export { convertTimeToEST };