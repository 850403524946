import React, { Component } from "react";
import { connect } from "react-redux";
import Map from "./Map";
import "./SearchPractitioners.css";
import { Link } from "react-router-dom";
import axios from "axios";
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
const uri = process.env.REACT_APP_JANE_APP_URL;

const handleBookPractitioner = () => {
  window.open(uri, '_blank', 'noopener');
};

class SearchPractitioners extends Component {
  constructor(props) {
    super(props);
    this.searchArea = this.searchArea.bind(this);
  }
  state = {
    practitioner: null,
    currentLatLng: {
      lat: 0,
      lng: 0,
    },
    latlongarray: [],
    loading: false,
    viewPort: "",
  };

  async componentDidMount() {
    window.scroll(0, 0);
    document.title = "Phyxable | Find Practitioners";
    if (this.state.viewPort !== this.props.currentViewPort) {
      this.setState({ viewPort: this.props.currentViewPort });
    }
    await this.getGeoLocation();
    const res = await axios('https://practitioner.phyxable.com/userData/practitioners');
    // filter the practitioners who are practitioners whose userType is PRACTITIONER and showInPracList is true or null or undefined
    let pracs = res.data.filter(prac => prac.userProfile.accountType === "PRACTITIONER");
    console.log(pracs);
    pracs = pracs.filter(prac => {
      console.log(prac.showInPracList);
      if (prac.showInPracList === null || prac.showInPracList === undefined) return true;
      return prac.showInPracList === true;
    });
    // const pracs = res.data.filter(prac => prac.userProfile.accountType === "PRACTITIONER" && (prac.showInPracList === true || prac.showInPracList === null));
    this.setState({ practitioner: pracs });
  }

  arrangeOrder = (practitioner) => {
    const specialty = document.getElementById("specialty").value.toLowerCase();
    let practitioner_list = [];
    if (specialty !== "") {
      for (const i in practitioner) {
        if (practitioner[i].userProfile && practitioner[i].userProfile.type) {
          if (
            practitioner[i].userProfile.type.toLowerCase().includes(specialty)
          )
            practitioner_list.push(practitioner[i]);
        }
      }
      this.setState({ practitioner: practitioner_list, loading: false });
    } else {
      this.setState({ practitioner: practitioner, loading: false });
    }
  };

  async searchArea() {
    if (document.getElementById("address").value !== "") {
      try {
        const { practitioner } = this.state;

        this.setState({ loading: true });

        const address = document
          .getElementById("address")
          .value.replace(/\s/g, "+");
        await axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GKEY}`
          )
          .then((response) =>
            this.setState({
              currentLatLng: response.data.results[0].geometry.location,
            })
          );

        let clinicDistance = [];

        function calculateDistance(lat1, lon1, lat2, lon2) {
          console.log(lat1, lon1, lat2, lon2);
          var radlat1 = (Math.PI * lat1) / 180;
          var radlat2 = (Math.PI * lat2) / 180;
          var theta = lon1 - lon2;
          var radtheta = (Math.PI * theta) / 180;
          var dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
          dist = Math.acos(dist);
          dist = (dist * 180) / Math.PI;
          dist = dist * 60 * 1.1515;
          dist = dist * 1.609344;
          // if(dist === 1) dist = parseFloat(dist.toFixed(1))+" km";
          // else if(dist > 1) dist = parseInt(dist)+" km";
          // else dist = parseInt(dist * 1000)+" m";
          return dist;
        }

        for (const i in practitioner) {
          let temp_latlong;
          if (
            practitioner[i].userProfile &&
            practitioner[i].userProfile.clinicAddress
          ) {
            await axios
              .get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${practitioner[
                  i
                ].userProfile.clinicAddress.replace(/\s/g, "+")}&key=AIzaSyBu0OOuXfuaslnh48ZJIbR_Zf6sjoWR8ow`
              )
              .then(
                (response) =>
                  (temp_latlong = response.data.results[0].geometry.location)
              )
              .then(() =>
                clinicDistance.push({
                  ...practitioner[i],
                  distance: calculateDistance(
                    this.state.currentLatLng.lat,
                    this.state.currentLatLng.lng,
                    temp_latlong.lat,
                    temp_latlong.lng
                  ),
                })
              );
            this.setState({
              latlongarray: [...this.state.latlongarray, temp_latlong],
            });
          } else {
            clinicDistance.push({ ...practitioner[i], distance: null });
          }
        }

        let x = [];
        let y = [];
        for (const k in clinicDistance) {
          if (clinicDistance[k].distance) x.push(clinicDistance[k]);
          else y.push(clinicDistance[k]);
        }
        console.log([...x.sort((a, b) => a.distance - b.distance), ...y]);
        this.arrangeOrder([...x.sort((a, b) => a.distance - b.distance), ...y]);
      } catch (error) {
        this.setState({ loading: false });
        console.log(error);
      }
    }
  }

  getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentLatLng: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          wait: false,
        });
      });
    } else {
      console.log("Error");
    }
  };

  getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentLatLng: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          wait: false,
        });
      });
    } else {
      console.log("Error");
    }
  };

  render() {
    return (
      <>
        {this.props.currentViewPort === "MOBILE" ? (
          <Mobile
            state={this.state}
            searchArea={this.searchArea}
            arrangeOrder={this.arrangeOrder}
          />
        ) : this.props.viewPort === "TABLET" ? (
          <Tablet
            state={this.state}
            searchArea={this.searchArea}
            arrangeOrder={this.arrangeOrder}
          />
        ) : this.props.viewPort === "LAPTOP" ? (
          <Laptop
            state={this.state}
            searchArea={this.searchArea}
            arrangeOrder={this.arrangeOrder}
          />
        ) : (
          <Desktop
            state={this.state}
            searchArea={this.searchArea}
            arrangeOrder={this.arrangeOrder}
          />
        )}
      </>
    );
  }
}

const Mobile = (props) => {
  return (
    <div className="dark Content left-align">
      <div className="w-90per mrAuto mlAuto mtop15">
        <h1>{i18next.t(`SEARCH_PRACTITIONERS`)}</h1>
        <div className="">
          <label>{i18next.t(`NEAR`)}</label>
          <div className="input-search-mobile">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="search"
              />
            </span>
            <input
              id="address"
              className="input-field"
              placeholder={i18next.t("ENTER_ADDRESS_OR_POSTAL_CODE")}
            />
          </div>
        </div>
        <div className="">
          <label>{i18next.t(`SEARCH_FOR_A_PROVIDER_OR_SPECIALTY`)}</label>
          <div className="input-search-mobile">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="seacrh"
              />
            </span>
            <input
              id="specialty"
              className="input-field"
              placeholder={i18next.t("ENTER_PROVIDER_NAME")}
            />
          </div>
        </div>
        <div className=" cAlign mtop10 mbottom10">
          <button
            className="button"
            onClick={() => {
              props.searchArea();
            }}
          >
            {props.state.loading ? "Loading..." : "Search"}
          </button>
        </div>
      </div>
      <div className="cAlign">
        <p className="ftSz7 interRegular ltSp8 ">{i18next.t(`SEARCH_RESULTS`)}</p>
      </div>

      <div className="">
        {props.state.practitioner && props.state.currentLatLng.lat !== 0 && (
          <>
            <div className="lAlign w-90per mrAuto mlAuto">
              <p className="ftSz11 interRegular ltSp8 ">{i18next.t(`MAP`)}</p>
            </div>
            <Map
              practitioner={props.state.practitioner}
              currentLatLng={props.state.currentLatLng}
              arrangeOrder={props.arrangeOrder}
            />
          </>
        )}
      </div>
      <div className="prac-map-container">
        <div className="w-90per mlAuto mrAuto">
          <div className="">
            <h3 className="ftSz11 interRegular ltSp8 lAlign">
              {i18next.t(`PRACTIONERS_NEAR_YOU`)}
            </h3>
          </div>
          {props.state.practitioner && !props.state.loading ? (
            props.state.practitioner.map((item, index) => {
              return (
                <ul key={index} className="avatar-container-mobile">
                  <div className="dFlex">
                    <li>
                      {item.userProfile.avatarURL ? (
                        <img
                          className="avatar-practitioner"
                          src={item.userProfile.avatarURL}
                          alt=""
                        />
                      ) : (
                        <div className="avatar-practitioner" />
                      )}
                    </li>
                    <li className="info-container">
                      <div className="dFlex jCenter">
                        <h3 className="mright1">{item.userProfile.name}</h3>
                        <p>
                          {item.distance && item.distance !== null ? (
                            <img
                              style={{ paddingRight: "0.25rem" }}
                              src={require("../../assets/Practitioners/Shape.svg")}
                              alt="shape"
                            />
                          ) : null}
                          {item.distance && item.distance !== null
                            ? item.distance === 1
                              ? `${parseFloat(item.distance.toFixed(1))} km`
                              : item.distance > 1
                                ? `${parseInt(item.distance)} km`
                                : `${parseInt(item.distance * 1000)} m`
                            : null}
                        </p>
                      </div>
                      <p>{item.userProfile.clinic}</p>
                      <p>{item.userProfile.clinicAddress}</p>
                    </li>
                  </div>
                  <li className="w-90per mlAuto mrAuto cAlign">
                    <button className="book-button"
                      onClick={() => {
                        handleBookPractitioner();
                      }}
                    >{i18next.t(`BOOK_PRACTITIONER`)}</button>
                    <Link
                      to={{
                        pathname: `/profile/${item.userProfile.name.replace(
                          /\s/g,
                          ""
                        )}`,
                        state: { profile: item },
                      }}
                    >
                      <button className="profile-button">{i18next.t(`PROFILE`)}</button>
                    </Link>
                  </li>
                </ul>
              );
            })
          ) : (
            <p>{i18next.t(`LOADING`)}</p>
          )}
        </div>
      </div>
    </div>
  );
};
const Tablet = (props) => {
  return (
    <div className="dark Content left-align">
      <div className="search-container">
        <h1>{i18next.t("SEARCH_PRACTITIONERS")}</h1>
        <div className="w3-third">
          <label>{i18next.t(`NEAR`)}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="search"
              />
            </span>
            <input
              id="address"
              className="input-field"
              placeholder={i18next.t("ENTER_ADDRESS_OR_POSTAL_CODE")}
            />
          </div>
        </div>
        <div className="w3-third">
          <label>{i18next.t("SEARCH_FOR_A_PROVIDER_OR_SPECIALTY")}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="seacrh"
              />
            </span>
            <input
              id="specialty"
              className="input-field"
              placeholder={i18next.t("ENTER_PROVIDER_NAME")}
            />
          </div>
        </div>
        <div className="w3-third">
          <button
            className="button"
            onClick={() => {
              props.searchArea();
            }}
          >
            {props.state.loading ? "Loading..." : "Search"}
          </button>
        </div>
      </div>
      <div className="prac-map-container">
        <div className="prac-details">
          <h3>{i18next.t(`PRACTIONERS_NEAR_YOU`)}</h3>
          {props.state.practitioner && !props.state.loading ? (
            props.state.practitioner.map((item, index) => {
              return (
                <ul key={index} className="avatar-container">
                  <li>
                    {item.userProfile.avatarURL ? (
                      <img
                        className="avatar-practitioner"
                        src={item.userProfile.avatarURL}
                        alt=""
                      />
                    ) : (
                      <div className="avatar-practitioner" />
                    )}
                  </li>
                  <li className="info-container">
                    <h3>{item.userProfile.name}</h3>
                    <p>
                      {item.distance && item.distance !== null ? (
                        <img
                          style={{ paddingRight: "0.25rem" }}
                          src={require("../../assets/Practitioners/Shape.svg")}
                          alt="shape"
                        />
                      ) : null}
                      {item.distance && item.distance !== null
                        ? item.distance === 1
                          ? `${parseFloat(item.distance.toFixed(1))} km`
                          : item.distance > 1
                            ? `${parseInt(item.distance)} km`
                            : `${parseInt(item.distance * 1000)} m`
                        : null}
                    </p>
                    <p>{item.userProfile.clinic}</p>
                    <p>{item.userProfile.clinicAddress}</p>
                  </li>
                  <li className=" w-100per mlAuto mrAuto rAlign">

                    <button className="book-button" onClick={() => {
                      handleBookPractitioner();
                    }}>{i18next.t("BOOK_PRACTITIONER")}</button>
                    <Link
                      to={{
                        pathname: `/profile/${item.userProfile.name.replace(
                          /\s/g,
                          ""
                        )}`,
                        state: { profile: item },
                      }}
                    >
                      <button className="profile-button">{i18next.t("PROFILE")}</button>
                    </Link>
                  </li>
                </ul>
              );
            })
          ) : (
            <p>{i18next.t(`LOADING`)}</p>
          )}
        </div>
        <div className="map-container">
          {props.state.practitioner && props.state.currentLatLng.lat !== 0 && (
            <Map
              practitioner={props.state.practitioner}
              currentLatLng={props.state.currentLatLng}
              arrangeOrder={props.arrangeOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const Laptop = (props) => {
  return (
    <div className="dark Content left-align">
      <div className="search-container">
        <h1>{i18next.t("SEARCH_PRACTITIONERS")}</h1>
        <div className="w3-third">
          <label>{i18next.t("NEAR")}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="search"
              />
            </span>
            <input
              id="address"
              className="input-field"
              placeholder={i18next.t("ENTER_ADDRESS_OR_POSTAL_CODE")}
            />
          </div>
        </div>
        <div className="w3-third">
          <label>{i18next.t("SEARCH_FOR_A_PROVIDER_OR_SPECIALTY")}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="seacrh"
              />
            </span>
            <input
              id="specialty"
              className="input-field"
              placeholder={i18next.t("ENTER_PROVIDER_NAME")}
            />
          </div>
        </div>
        <div className="w3-third">
          <button
            className="button"
            onClick={() => {
              props.searchArea();
            }}
          >
            {props.state.loading ? "Loading..." : "Search"}
          </button>
        </div>
      </div>
      <div className="prac-map-container">
        <div className="prac-details">
          <h3>{i18next.t("PRACTIONERS_NEAR_YOU")}</h3>
          {props.state.practitioner && !props.state.loading ? (
            props.state.practitioner.map((item, index) => {
              return (
                <ul key={index} className="avatar-container">
                  <li>
                    {item.userProfile.avatarURL ? (
                      <img
                        className="avatar-practitioner"
                        src={item.userProfile.avatarURL}
                        alt=""
                      />
                    ) : (
                      <div className="avatar-practitioner" />
                    )}
                  </li>
                  <li className="info-container">
                    <h3>{item.userProfile.name}</h3>
                    <p>
                      {item.distance && item.distance !== null ? (
                        <img
                          style={{ paddingRight: "0.25rem" }}
                          src={require("../../assets/Practitioners/Shape.svg")}
                          alt="shape"
                        />
                      ) : null}
                      {item.distance && item.distance !== null
                        ? item.distance === 1
                          ? `${parseFloat(item.distance.toFixed(1))} km`
                          : item.distance > 1
                            ? `${parseInt(item.distance)} km`
                            : `${parseInt(item.distance * 1000)} m`
                        : null}
                    </p>
                    <p>{item.userProfile.clinic}</p>
                    <p>{item.userProfile.clinicAddress}</p>
                  </li>
                  <li className="button-container">
                    <button className="book-button" onClick={() => {
                      handleBookPractitioner();
                    }}>{i18next.t("BOOK_PRACTITIONER")}</button>
                    <Link
                      to={{
                        pathname: `/profile/${item.userProfile.name.replace(
                          /\s/g,
                          ""
                        )}`,
                        state: { profile: item },
                      }}
                    >
                      <button className="profile-button">{i18next.t("PROFILE")}</button>
                    </Link>
                  </li>
                </ul>
              );
            })
          ) : (
            <p>{i18next.t(`LOADING`)}</p>
          )}
        </div>
        <div className="map-container">
          {props.state.practitioner && props.state.currentLatLng.lat !== 0 && (
            <Map
              practitioner={props.state.practitioner}
              currentLatLng={props.state.currentLatLng}
              arrangeOrder={props.arrangeOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};
const Desktop = (props) => {
  return (
    <div className="dark Content left-align">
      <div className="search-container">
        <h1>{i18next.t("SEARCH_PRACTITIONERS")}</h1>
        <div className="w3-third">
          <label>{i18next.t("NEAR")}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="search"
              />
            </span>
            <input
              id="address"
              className="input-field"
              placeholder={i18next.t("ENTER_ADDRESS_OR_POSTAL_CODE")}
            />
          </div>
        </div>
        <div className="w3-third">
          <label>{i18next.t("SEARCH_FOR_A_PROVIDER_OR_SPECIALTY")}</label>
          <div className="input-search">
            <span>
              <img
                src={require("../../assets/Practitioners/search.svg")}
                alt="seacrh"
              />
            </span>
            <input
              id="specialty"
              className="input-field"
              placeholder={i18next.t("ENTER_PROVIDER_NAME")}
            />
          </div>
        </div>
        <div className="w3-third">
          <button
            className="button"
            onClick={() => {
              props.searchArea();
            }}
          >
            {props.state.loading ? "Loading..." : "Search"}
          </button>
        </div>
      </div>
      <div className="prac-map-container">
        <div className="prac-details">
          <h3>{i18next.t("PRACTIONERS_NEAR_YOU")}</h3>
          {props.state.practitioner && !props.state.loading ? (
            props.state.practitioner.map((item, index) => {
              return (
                <ul key={index} className="avatar-container">
                  <li>
                    {item.userProfile.avatarURL ? (
                      <img
                        className="avatar-practitioner"
                        src={item.userProfile.avatarURL}
                        alt=""
                      />
                    ) : (
                      <div className="avatar-practitioner" />
                    )}
                  </li>
                  <li className="info-container">
                    <h3>{item.userProfile.name}</h3>
                    <p>
                      {item.distance && item.distance !== null ? (
                        <img
                          style={{ paddingRight: "0.25rem" }}
                          src={require("../../assets/Practitioners/Shape.svg")}
                          alt="shape"
                        />
                      ) : null}
                      {item.distance && item.distance !== null
                        ? item.distance === 1
                          ? `${parseFloat(item.distance.toFixed(1))} km`
                          : item.distance > 1
                            ? `${parseInt(item.distance)} km`
                            : `${parseInt(item.distance * 1000)} m`
                        : null}
                    </p>
                    <p>{item.userProfile.clinic}</p>
                    <p>{item.userProfile.clinicAddress}</p>
                  </li>
                  <li className="button-container">
                    <button className="book-button" onClick={() => {
                      handleBookPractitioner();
                    }}>{i18next.t("BOOK_PRACTITIONER")}</button>
                    <Link
                      to={{
                        pathname: `/profile/${item.userProfile.name.replace(
                          /\s/g,
                          ""
                        )}`,
                        state: { profile: item },
                      }}
                    >
                      <button className="profile-button">{i18next.t("PROFILE")}</button>
                    </Link>
                  </li>
                </ul>
              );
            })
          ) : (
            <p>{i18next.t(`LOADING`)}</p>
          )}
        </div>
        <div className="map-container">
          {props.state.practitioner && props.state.currentLatLng.lat !== 0 && (
            <Map
              practitioner={props.state.practitioner}
              currentLatLng={props.state.currentLatLng}
              arrangeOrder={props.arrangeOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

SearchPractitioners = withTranslation()(SearchPractitioners);
export default connect(mapStateToProps, null)(SearchPractitioners);
