import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import { Link } from 'react-router-dom';
import './bookingSelectStyles.css';
import canada from '../../assets/booking/Canada_Flag.svg'
import USA from '../../assets/booking/Emoji.svg'

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function BookingSelect() {
    
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scroll(0, 0);
        document.title = "Phyxable | Booking";
        ReactGA.initialize('UA-148659511-2', { debug: true });
    }, []);

    const handleReactGAClick = Location => {
        ReactGA.event({
        category: `Clicked on ${Location} button from /booking-select`,
        action: '/booking-select',
        label: 'Booking'
        });
    }

    return(
        <div className="Content padding-2rem" style ={{paddingBottom: "300px"}}>
            <div className="padding-top-4rem">
                <h1 className="dark">{ i18next.t(`SELECT_YOUR_LOCATION`) }</h1>

                <Link to="/provinces">
                    <div className="" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("Ontario")} className="countries-margin-bottom booking-select-button"><span className="countryName"> <img style={{paddingLeft: "16px"}}src={canada} />   Canada</span></button>
                    </div>
                </Link>

                <Link to="/appointment-booking">
                <div className="countires-padding" style={{display: "inline"}}>
                        <button onClick={() => handleReactGAClick("USA"), localStorage.setItem("province", "Ontario")} className="booking-select-button"><span className="countryName"><img  style={{paddingLeft: "16px"}}src={USA} /> { i18next.t(`USA`) } </span></button>
                </div>
                </Link>
            </div>
        </div>
    )
}