import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { Link } from 'react-router-dom';

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

export default class LandingPage extends Component {
  state = {
    page: null,
  };

  async componentDidMount() {
    window.scroll(0, 0);
    document.title = 'Phyxable | Landing Page';
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/landing-pages`)
      .then(response => this.setState({ page: response.data }))
      .catch(error => console.error(error));
  }

  contentBlog = blog => {
    try {
      return <ReactMarkdown escapeHtml={false} source={blog.content} />;
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { page } = this.state;

    return (
      page !== null && (
        <div className='Content'>
          <div className='padding-top-4rem' />
          {page &&
            page.map(landingpage => (
              <Link
                key={landingpage.id}
                className='no-decoration'
                to={{
                  pathname: `/free-consultation/${landingpage.url}`,
                }}
              >
                {landingpage.url}
                <br />
              </Link>
            ))}
        </div>
      )
    );
  }
}

LandingPage = withTranslation()(LandingPage);
