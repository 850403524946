import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./AboutUs.css";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function AboutUs(props) {

  const { t, i18n } = useTranslation();

  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | About Us";
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);

  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile />
      ) : viewPort === "TABLET" ? (
        <Tablet />
      ) : viewPort === "LAPTOP" ? (
        <Laptop />
      ) : (
        <Desktop />
      )}
    </>
  );
}

const Mobile = (props) => {
  return (
    <div>
      <div>
        <div className="bg-mobile-mobile ">
          <div className="home-container ">
            <h1 className="priColor interRegular cAlign ftSz13">
              { i18next.t(`ON_DEMAND_SOLUTIONS_FOR_SIMPLE_PAIN_RELIEF`) }
            </h1>

            <div className="cAlign ">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.phyxable.com"
              >
                <button className="button mbottom14">
                  { i18next.t(`SELF_HELP_SOLUTIONS`) } 
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="hero-about-us">
          <div className="posAbs topLeft50per tranlateReg w-90per mrAuto mlAuto cAlign interRegular">
            <h1 className="ftSz9  ltSp8 secColor1">
              { i18next.t(`WHATS_IN_OUR_ON_DEMAND_SOLUTIONS`) }
            </h1>
            <h3 className="padding-top-1rem ftSz5">
              { i18next.t(`CLINICALLY_VALIDATED_SOLUTIONS`) }
            </h3>
            <p className="ftSz3">
              { i18next.t(`PHYXABLE_CONTENT_IS_DESIGNED`) }
              <br />
              { i18next.t(`BY_WORLD_CLASS_PRACTITIONERS`) }
            </p>
            <h3 className="padding-top-1rem ftSz5">{ i18next.t(`10_MINUTES_PER_SESSION`) }</h3>
            <p className="ftSz3">
              { i18next.t(`EVERYONE_HAS_BUSY_SCHEDULES`) }
              <br />
              { i18next.t(`GET_STARTED_WITH_JUST_10_MINUTES_A_DAY`) }
            </p>
            <h3 className="padding-top-1rem ftSz5">{ i18next.t(`BACKED_BY_RESEARCH`) }</h3>
            <p className="ftSz3">
              { i18next.t(`PROGRAMS_ARE_DEVELOPED_UNDER_ADVANCED_PAIN_RELIEF_RESEARCH`) }
              <br />
              { i18next.t(`WITH_ADDED_GUIDANCE`) }
            </p>
          </div>
        </div>

        <div style={{ paddingTop: "0" }} className="Content">
          <div className="cAlign">
            <div className="w-90per mrAuto mlAuto interRegular">
              <h1 className=" ftSz9 secColor1">
                { i18next.t(`HOW_IS_IT_BETTER_THAN_TRADITIONAL_PHYSIOTHERAPY?`) }
              </h1>
              <div>
                <div className="padding-top-1rem secColor1 ">
                  <h4 className="  interRegular ftSz5">{ i18next.t(`AT_YOUR_CONVENIENCE`) }</h4>
                  <p className="interRegular ftSz3 ftWght1 ">
                    { i18next.t(`SAVE_YOUR_COMMUTE_TIME_SPEND_IT_ON_MEANINFUL_THINGS`) }
                  </p>
                </div>

                <div className="padding-top-1rem secColor1 ">
                  <h4 className="  interRegular ftSz5">{ i18next.t(`COVERED_BY_INSURANCE`) }</h4>
                  <p className="interRegular ftSz3 ftWght1 ">
                    { i18next.t(`WE_BILL_YOUR_WORK_INSURANCE_DIRECTLY_NO_UPFRONT_COSTS`) }
                  </p>
                </div>

                <div className="padding-top-1rem secColor1 ">
                  <h4 className="  interRegular ftSz5">{ i18next.t(`LITERALLY_ANYWHERE`) }</h4>
                  <p className="interRegular ftSz3 ftWght1 ">
                    { i18next.t(`AT_HOME_OR_TRAVELLING_TAKE_REHAB_TAKE_REHAB_INTO_YOUR_OWN_HANDS`) }
                  </p>
                </div>
              </div>
            </div>
            <div className="w-90per mrAuto mlAuto ">
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_betterphysiotherapy.svg")}
                alt="Aboutus_betterphysiotherapy"
              />
            </div>
          </div>

          <div className="cAlign">
            <div className="secColor1 w-90per mrAuto mlAuto">
              <div className="w-85per ">
                <h1 className=" ftSz11 interRegular ">
                  { i18next.t(`PHYXABLE_MAKES_EMPLOYEE_WELLNESS_A_BREEZE`) }
                </h1>
              </div>
              <p className="ftSz4  interRegular">
                { i18next.t(`PHYXABLE_PRACTIONER_PROVIDES_A_SPECIFIC_CONT`) }
              </p>
            </div>
            <div className="w-90per mrAuto mlAuto">
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_wellness.svg")}
                alt="Aboutus_wellness"
              />
            </div>
          </div>

          <div>
            <div className="w-90per mrAuto mlAuto">
              <h1 className="secColor1 interRegular ftSz11 ">
                { i18next.t(`TALK_TO_A_PRACTITIONER`) }
                <span className="pink">
                  { i18next.t(`CANADA_UK_OR_US`) }
                </span>
              </h1>
            </div>

            <div className="w-90per mtop7 mrAuto mlAuto ">
              <p className="secColor1 interRegular ftSz4 cAlign ftWght1 ltSpREG">
                { i18next.t(`OUR_SECURE_VIDEO_PLATFORM_CONNECTS_YOU_CONT`) }
              </p>
            </div>
            <Link to="/booking-select">
              <button className="button mbottom-1">
                { i18next.t(`BOOK_FREE_CONSULTATION`) }
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const Tablet = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-about-tablet pbottom80">
          <div className="home-container-tablet">
            <h1 className="white ftSz15">
              { i18next.t(`ON_DEMAND`) }
              <br />
              { i18next.t(`SOLUTIONS_FOR`) }
              <br /> { i18next.t(`SIMPLE_PAIN_RELIEF`) }
            </h1>
            <p className="white  ftSz1 pbottom2">
              { i18next.t(`DAILY_SESSIONS_FROM_YOUR_SMARTPHONE_COMPUTER_OR_TV`) }
            </p>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.phyxable.com"
            >
              <button className="button" style={{ borderStyle: "none" }}>
                { i18next.t(`SELF_HELP_SOLUTIONS`) }
              </button>
            </a>
          </div>
        </div>

        <div>
          <div className="w-80per dFlex  jStart mlAuto mrAuto lAlign mtop10">
            <h1 className="dark lAlign ftSz10">
              { i18next.t(`HOW_IS_IT_BETTER_THAN`) }
              <br />
              { i18next.t(`TRADITIONAL_PHYSIOTHERAPY`) }
            </h1>{" "}
          </div>
          <div className="w-80per dFlex jCenter mlAuto mrAuto  mbottom10  flexAlStart">
            <div className=" dark w-50per lAlign ftSz1  ftWght1">
              <h4 className=" padding-top-1rem ftSz5">{ i18next.t(`AT_YOUR_CONVENIENCE`) }</h4>
              <p className="">
                { i18next.t(`SAVE_YOUR_COMMUTE_TIME_SPEND_IT_ON_MEANINFUL_THINGS`) }
              </p>
              <h4 className=" padding-top-1rem ftSz5">{ i18next.t(`COVERED_BY_INSURANCE`) }</h4>
              <p className="">
                { i18next.t(`WE_BILL_YOUR_WORK_INSURANCE_DIRECTLY_NO_UPFRONT_COSTS`) }
              </p>
              <h4 className=" padding-top-1rem ftSz5">{ i18next.t(`LITERALLY_ANYWHERE`) }</h4>
              <p className="">
                { i18next.t(`AT_HOME_OR_TRAVELLING_TAKE_REHAB_TAKE_REHAB_INTO_YOUR_OWN_HANDS`) }
              </p>
            </div>
            <div className="w-50per rAlign">
              <img
                className="w-100per"
                src={require("../../assets/aboutus/Aboutus_betterphysiotherapy.svg")}
                alt="Aboutus_betterphysiotherapy"
              />
            </div>
          </div>
          <div className="hero-about-us-tablet">
            <div className=" cAlign">
              <h1 className="ftSz10">
                { i18next.t(`WHATS_IN_OUR`) }<br />
                { i18next.t(`ON_DEMAND_SOLUTIONS`) }
              </h1>
              <h3 className="padding-top-1rem ftSz5 interRegular">
                { i18next.t(`CLINICALLY_VALIDATED_SOLUTIONS`) }
              </h3>
              <p className="regular ftSz1 ">
                { i18next.t(`PHYXABLE_CONTENT_IS_DESIGNED`) }
                <br />
                { i18next.t(`BY_WORLD_CLASS_PRACTITIONERS`) }
              </p>
              <h3 className="padding-top-1rem ftSz5 interRegular">
                { i18next.t(`10_MINUTES_PER_SESSION`) }
              </h3>
              <p className="regular ftSz1 ">
                { i18next.t(`EVERYONE_HAS_BUSY_SCHEDULES`) }
                <br />
                { i18next.t(`GET_STARTED_WITH_JUST_10_MINUTES_A_DAY`) }
              </p>
              <h3 className="padding-top-1rem ftSz5 interRegular">
                { i18next.t(`BACKED_BY_RESEARCH`) }
              </h3>
              <p className="regular ftSz1 ">
                { i18next.t(`PROGRAMS_ARE_DEVELOPED_UNDER_ADVANCED_PAIN_RELIEF_RESEARCH`) }
                <br />
                { i18next.t(`WITH_ADDED_GUIDANCE`) }
              </p>
            </div>
          </div>
          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign">
            <div className=" w-50per">
              <h1 className="dark lAlign ftSz10">
                { i18next.t(`PHYXABLE_MAKES`) }
                <br />
                { i18next.t(`EMPLOYEE_WELLNESS`) }
                <br /> { i18next.t(`A_BREEZE`) }
              </h1>
              <p className="regular lAlign ftSz0  ftWght1">
                { i18next.t(`PHYXABLE_PRACTIONER_PROVIDES_A_SPECIFIC_CONT`) }
              </p>
            </div>
            <div className="w-50per rAlign">
              <img
                className="w-100per"
                src={require("../../assets/aboutus/Aboutus_wellness.svg")}
                alt="Aboutus_wellness"
              />
            </div>
          </div>

          <div className="cAlign pbottom80 interRegular">
            <h1 className="dark">{ i18next.t(`TALK_TO_A_PRACTITIONER`) }</h1>
            <h2 className="pink">{ i18next.t(`CANADA_UK_OR_US`) }</h2>
            <p className="dark">
              { i18next.t(`OUR_SECURE_VIDEO_PLATFORM`) }
              <br />
              { i18next.t(`LOCAL_PRACTITIONERS`) }
            </p>
            <Link to="/booking-select">
              <button className="button">{ i18next.t(`BOOK_FREE_CONSULTATION`) }</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const Laptop = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-about">
          <div className="home-container">
            <h1 className="white main-font">
              { i18next.t(`ON_DEMAND`) }
              <br />
              { i18next.t(`SOLUTIONS_FOR`) }
              <br /> { i18next.t(`SIMPLE_PAIN`) }
            </h1>
            <p className="white sub-title">
              { i18next.t(`DAILY_SESSIONS_FROM_YOUR_SMARTPHONE_COMPUTER_OR_TV`) }
            </p>
            <div className="divider-about" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.phyxable.com"
            >
              <button className="button">{ i18next.t(`SELF_HELP_SOLUTIONS`) }</button>
            </a>
          </div>
        </div>

        <div style={{ paddingTop: "0" }} className="Content">
          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">
                { i18next.t(`HOW_IS_IT_BETTER_THAN`) }
                <br />
                { i18next.t(`TRADITIONAL_PHYSIOTHERAPY`) }
              </h1>
              <h4 className="dark padding-top-1rem">{ i18next.t(`AT_YOUR_CONVENIENCE`) }</h4>
              <p className="regular">
                { i18next.t(`SAVE_YOUR_COMMUTE_TIME_SPEND_IT_ON_MEANINFUL_THINGS`) }
              </p>
              <h4 className="dark padding-top-1rem">{ i18next.t(`COVERED_BY_INSURANCE`) }</h4>
              <p className="regular">
                { i18next.t(`WE_BILL_YOUR_WORK_INSURANCE_DIRECTLY_NO_UPFRONT_COSTS`) }
              </p>
              <h4 className="dark padding-top-1rem">{ i18next.t(`LITERALLY_ANYWHERE`) }</h4>
              <p className="regular">
                { i18next.t(`AT_HOME_OR_TRAVELLING_TAKE_REHAB_TAKE_REHAB_INTO_YOUR_OWN_HANDS`) }
              </p>
            </div>
            <div className="right" style={{ paddingTop: "0" }}>
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_betterphysiotherapy.svg")}
                alt="Aboutus_betterphysiotherapy"
              />
            </div>
          </div>

          <div className="hero-about-us">
            <div className="hero-about-text">
              <h1>
                { i18next.t(`WHATS_IN_OUR`) }<br />
                { i18next.t(`ON_DEMAND_SOLUTIONS`) }
              </h1>
              <h3 className="padding-top-1rem">
                { i18next.t(`CLINICALLY_VALIDATED_SOLUTIONS`) }
              </h3>
              <p className="regular">
                { i18next.t(`PHYXABLE_CONTENT_IS_DESIGNED`) }
                <br />
                { i18next.t(`BY_WORLD_CLASS_PRACTITIONERS`) }
              </p>
              <h3 className="padding-top-1rem">{ i18next.t(`10_MINUTES_PER_SESSION`) }</h3>
              <p className="regular">
                { i18next.t(`EVERYONE_HAS_BUSY_SCHEDULES`) }
                <br />
                { i18next.t(`GET_STARTED_WITH_JUST_10_MINUTES_A_DAY`) }
              </p>
              <h3 className="padding-top-1rem">{ i18next.t(`BACKED_BY_RESEARCH`) }</h3>
              <p className="regular">
                { i18next.t(`PROGRAMS_ARE_DEVELOPED_UNDER_ADVANCED_PAIN_RELIEF_RESEARCH`) }
                <br />
                { i18next.t(`WITH_ADDED_GUIDANCE`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">
                { i18next.t(`PHYXABLE_MAKES`) }
                <br />
                { i18next.t(`EMPLOYEE_WELLNESS`) }
                <br /> { i18next.t(`A_BREEZE`) }
              </h1>
              <p className="regular">
                { i18next.t(`PHYXABLE_PRACTIONER_PROVIDES_A_SPECIFIC_CONT`) }
              </p>
            </div>
            <div className="right" style={{ padding: "2rem 0" }}>
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_wellness.svg")}
                alt="Aboutus_wellness"
              />
            </div>
          </div>

          <div>
            <h1 className="dark">{ i18next.t(`TALK_TO_A_PRACTITIONER`) }</h1>
            <h2 className="pink">{ i18next.t(`CANADA_UK_OR_US`) }</h2>
            <p className="dark">
              { i18next.t(`OUR_SECURE_VIDEO_PLATFORM`) }
              <br />
              { i18next.t(`LOCAL_PRACTITIONERS`) }
            </p>
            <Link to="/booking-select">
              <button className="button">{ i18next.t(`BOOK_FREE_CONSULTATION`) }</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const Desktop = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-about">
          <div className="home-container">
            <h1
              className="white main-font"
              style={{ fontSize: 54 + `px`, marginBottom: 40 + `px` }}
            >
              { i18next.t(`ON_DEMAND`) }
              <br />
              { i18next.t(`SOLUTIONS_FOR`) }
              <br /> { i18next.t(`SIMPLE_PAIN`) }
            </h1>
            <p className="white sub-title" style={{ fontSize: 22 + `px` }}>
              { i18next.t(`DAILY_SESSIONS_FROM_YOUR_SMARTPHONE_COMPUTER_OR_TV`) }
            </p>
            <div className="divider-about" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.phyxable.com"
            >
              <button className="button">{ i18next.t(`SELF_HELP_SOLUTIONS`) } </button>
            </a>
          </div>
        </div>

        <div style={{ paddingTop: "0" }} className="Content">
          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">
                { i18next.t(`HOW_IS_IT_BETTER_THAN`) }
                <br />
                { i18next.t(`TRADITIONAL_PHYSIOTHERAPY`) }
              </h1>
              <h4 className="dark padding-top-1rem">{ i18next.t(`AT_YOUR_CONVENIENCE`) }</h4>
              <p className="regular ftSz4">
                { i18next.t(`SAVE_YOUR_COMMUTE_TIME_SPEND_IT_ON_MEANINFUL_THINGS`) }
              </p>
              <h4 className="dark padding-top-1rem">{ i18next.t(`COVERED_BY_INSURANCE`) }</h4>
              <p className="regular ftSz4">
                { i18next.t(`WE_BILL_YOUR_WORK_INSURANCE_DIRECTLY_NO_UPFRONT_COSTS`) }
              </p>
              <h4 className="dark padding-top-1rem">{ i18next.t(`LITERALLY_ANYWHERE`) }</h4>
              <p className="regular ftSz4">
                { i18next.t(`AT_HOME_OR_TRAVELLING_TAKE_REHAB_TAKE_REHAB_INTO_YOUR_OWN_HANDS`) }.
              </p>
            </div>
            <div className="right" style={{ paddingTop: "0" }}>
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_betterphysiotherapy.svg")}
                alt="Aboutus_betterphysiotherapy"
              />
            </div>
          </div>

          <div className="hero-about-us">
            <div className="hero-about-text">
              <h1>
                { i18next.t(`WHATS_IN_OUR`) }<br />
                { i18next.t(`ON_DEMAND_SOLUTIONS`) }
              </h1>
              <h3 className="padding-top-1rem">
                { i18next.t(`CLINICALLY_VALIDATED_SOLUTIONS`) }
              </h3>
              <p className="regular ftSz4">
                { i18next.t(`PHYXABLE_CONTENT_IS_DESIGNED`) }
                <br />
                { i18next.t(`BY_WORLD_CLASS_PRACTITIONERS`) }
              </p>
              <h3 className="padding-top-1rem">{ i18next.t(`10_MINUTES_PER_SESSION`) }</h3>
              <p className="regular ftSz4">
                { i18next.t(`EVERYONE_HAS_BUSY_SCHEDULES`) }
                <br />
                { i18next.t(`GET_STARTED_WITH_JUST_10_MINUTES_A_DAY`) }
              </p>
              <h3 className="padding-top-1rem">{ i18next.t(`BACKED_BY_RESEARCH`) }</h3>
              <p className="regular ftSz4">
                { i18next.t(`PROGRAMS_ARE_DEVELOPED_UNDER_ADVANCED_PAIN_RELIEF_RESEARCH`) }
                <br />
                { i18next.t(`WITH_ADDED_GUIDANCE`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">
                { i18next.t(`PHYXABLE_MAKES`) }
                <br />
                { i18next.t(`EMPLOYEE_WELLNESS`) }
                <br /> { i18next.t(`A_BREEZE`) }
              </h1>
              <p className="regular ftSz4" style={{ paddingRight: 8 + `px` }}>
                { i18next.t(`PHYXABLE_PRACTIONER_PROVIDES_A_SPECIFIC_CONT`) }
              </p>
            </div>
            <div className="right" style={{ padding: "2rem 0" }}>
              <img
                className="max-100"
                src={require("../../assets/aboutus/Aboutus_wellness.svg")}
                alt="Aboutus_wellness"
              />
            </div>
          </div>

          <div>
            <h1 className="dark">{ i18next.t(`TALK_TO_A_PRACTITIONER`) }</h1>
            <h2 className="pink">{ i18next.t(`CANADA_UK_OR_US`) }</h2>
            <p className="dark ftSz4">
              { i18next.t(`OUR_SECURE_VIDEO_PLATFORM`) }
              <br />
              { i18next.t(`LOCAL_PRACTITIONERS`) }
            </p>
            <Link to="/booking-select">
              <button className="button">{ i18next.t(`BOOK_FREE_CONSULTATION`) }</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(AboutUs);
