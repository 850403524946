import React, { useEffect, useState, memo } from "react";
// import { Select } from 'antd';
// import 'antd/dist/antd.css';
import "./justonestep.scss";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import { useHistory, useParams, Redirect, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ThankYou from './ThankYou';
import { CheckBox, Height } from "@material-ui/icons";
import Checkbox from '@material-ui/core/Checkbox';
import { Grid, Paper } from '@material-ui/core'
import checkIcon from "../../../../../assets/booking/CheckBox.svg"
import { doCreateUserWithEmailAndPassword } from '../../../BookingComponents/base/helpers/FirebaseInit';
import firebase from "@firebase/app";

import dayjs from 'dayjs';
import { convertTimeToEST } from './../../../../../services/index';
import { getProvinceTimezoneName, getProvinceUtcOffset } from "../../../../../helpers/provinces";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const JustOneStep =
  (props) => {


    const {
      step,
      handleClose,
      startTime,
      endTime,
      today,
      todayYear,
      dayname,
      userDetails,
      note,
      handleNoShow,
      handleArrived,
      handleInputChange,
      // handleInputChange1,
      error = {},
      enableSignupButton,
      handleCreateUser,
      handleGoogle,
      handleRemoveAppointment,
      loading,
      currentViewPort,
      companyCodes,
      userSignupDetails,
      dateSelectedValue,
      fullTime
    } = props;
    //const [] = useState(null);
    const [agreed, setAgreed] = useState(false);
    const [companySelected, setCompanySelected] = useState(localStorage.getItem("CompanyName"))
    const [viewPort, setViewPort] = useState("");
    const { t, i18n } = useTranslation();
    const [errors, setFormError] = useState('');
    const [loadingThankyou, setLoadingThankyou] = useState(false)


    useEffect(() => {
      // console.log("Visitors on Just One Last Step page");
      ReactGA.event({
        category: "Visitors on Just One Last Step page",
        action: "/booking",
        label: "Booking",
      });
    }, []);


    const styleWeb = {
      padding: 20,
      height: '40%',
      width: '35%',
      fontSize: '1rem',
      margin: '5% auto',
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'ProximaNova'

    }

    const styleMobile = {
      padding: 20,
      height: '40%',
      width: '80%',
      fontSize: '1rem',
      margin: '5% auto',
      justifyContent: 'center',
      alignContent: 'center',
      display: 'flex',
      flexDirection: 'column'

    }


    let history = useHistory();
    const createMeeting = (
      startDate,
      endDate,
      userSignupDetails,
      patientName,
      appointmentType,
      id
    ) => {
      const province = localStorage.getItem('province');
      let dDate = new Date();
      firebase
        .firestore()
        .collection('appointments')
        .add({
          // TODO: remove eid, since it's not ensured to be unqiue; replace with doc.id
          eid: Date.now(),

          // TODO: remove startDate endDate (or update, if changed in API)
          startDate: startDate,
          endDate: endDate,
          
          createdAt: moment().utc().format('YYYY-MM-DD HH:mm'),
          // TODO: remove utcOffset + tz; look it up by province instead
          utcOffset: getProvinceUtcOffset(province),
          tz: getProvinceTimezoneName(province),
          province,

          description: '30 Minutes Free',
          selecteduser: userSignupDetails.email,
          meetingId: id,
          type: appointmentType,
          email: userSignupDetails.email,
          phoneNumber: userSignupDetails.phone,
          currentPhyx: 'posture',
          patientName: patientName,
          campaign: 'canada',
        })
        .then(function (docRef) {
          // console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          console.error('Error adding document: ', error);
        });
    };



    // const [style, setstyle] = useState(styleWeb)

    const BASE_URL =
      process.env.NODE_ENV == 'development'
        ? 'https://phyxable.com'
        : 'https://phyxable.com';

    const handleSubmitForm = (userSignupDetails) => {

      let UIdate = localStorage.getItem('UIdate');
      let startTime1 = localStorage.getItem('startTime1');
      let selectedDateValue = localStorage.getItem('selectedDateValue');
      let patientDetails = JSON.parse(localStorage.getItem('patientDetails'));

      let oldUiTime = UIdate;
      let startDate = startTime1;
      let eendDate = moment(startDate).add(30, 'minutes');
      //console.log('checkkkkkkkkkk', startDate, endDate)
      let endDate = eendDate._i;

      let x11 = moment(startDate).format('HH:mm');
      let x12 = selectedDateValue;
      let x16 = dayjs(x12).add(30, 'minutes').format('YYYY-MM-DD HH:mm');
      // console.log('fxxxxxx', x12, x16)

      axios({
        method: 'POST',
        url: `${BASE_URL}/makeBooking`,
        data: {
          startDate: moment(x12, 'YYYY-MM-DD HH:mm:ss Z').utc().format(),
          endDate: moment(x16, 'YYYY-MM-DD HH:mm:ss Z').utc().format(),
          note: 'Reason For Booking:' + patientDetails.reason,
          patientName: userSignupDetails.name,
          email: userSignupDetails.email,
          phone: userSignupDetails.phone,
          patient_arrived: false,
          did_not_arrive: false,
        },
      })
        .then(response => {
          console.log('====**====', response)

          if (response.data.error === false) {
            console.log('====**====', response)
            sendEmail(x12, x16, userSignupDetails, patientDetails);
            createMeeting(
              x12,
              x16,
              userSignupDetails,
              userSignupDetails.name,
              'Free',
              response.data.data.id
            );
            setLoadingThankyou(false)
            history.push('/appointment-booking/thank-you');
          }
        })
        .catch(err => {
          console.error('ERR: ', err);
        });
    };
    // const BookingClickedStatusFunction = useCallback(() => {
    //   setBookingClickedStatus(!BookingClickedStatus);
    // }, []);

    const sendEmail = (x12, x16, userSignupDetails, patientDetails) => {
      //Makes request to backend to send receipt
      console.log('Sending mail...');
      console.log(userSignupDetails)
      const province = localStorage.getItem('province');
      const startDate = convertToProvinceTime(x12, province).format(
        'YYYY-MM-DD hh:mm A'
      );
      const endDate = convertToProvinceTime(x16, province).format(
        'YYYY-MM-DD hh:mm A'
      );
      const tz = getProvinceTimezoneName(province);
      axios({
        method: 'POST',
        url: `${BASE_URL}/sendReceipt`,
        data: {
          startDate,
          endDate,
          tz,
          name: userSignupDetails.name,
          email: userSignupDetails.email,
          title: 'Free',
          description: '30 minutes free',
        },
      })
        .then(() => {
          console.log('Email Sent___');

        })
        .catch(err => {
          console.error('Sending Email ERR: ', err);
        });
    };


    const createNewUser = (userSignupDetails, isGoogle) => {
      // console.log(userSignupDetails.companyCode);

      let UIDate = localStorage.getItem('clicked_date'); // consider changing to UIdate if there are problem
      let time = localStorage.getItem('clicked_time'); // startTime1

      // TODO: only use one way to store date/time objects. Either Date or moment; prefer moment
      const dateTimeOfAppointment = new Date(UIDate);
      dateTimeOfAppointment.setHours(time.split(':')[0]);
      dateTimeOfAppointment.setMinutes(time.split(':')[1]);

      // TODO: timezone is wrong
      const province = localStorage.getItem('province');
      const timezone =
        province === 'British Columbia'
          ? 'PDT'
          : province === 'Saskatchewan' || province === 'Albert'
            ? 'CST'
            : province === 'Maintoba'
              ? 'CDT'
              : province === 'Nova Scotia'
                ? 'ADT'
                : province === 'Newfoundland and Labrador'
                  ? 'NDT'
                  : 'EST'; // manually enter

      // TODO: don't use string manipulation for date manipulation. Use moment ctor and/or add/subtract instead
      const dateTimeOfAppointmentArr = dateTimeOfAppointment
        .toString()
        .split(' ');
      const stringOfDateOfAppointment =
        dateTimeOfAppointmentArr[1] +
        ' ' +
        dateTimeOfAppointmentArr[2] +
        ' ' +
        dateTimeOfAppointmentArr[3] +
        ' ' +
        dateTimeOfAppointmentArr[4] +
        ' ' +
        timezone;

      // const dateTimeToSend = new Date(dateTimeOfAppointment);
      // dateTimeToSend.setHours(dateTimeToSend.getHours() - 1); // convert timezones on server side instead
      const dateTimeToSend = convertTimeToEST(
        dateTimeOfAppointment.setHours(dateTimeOfAppointment.getHours() - 1),
        timezone
      ); // reminder sent one hour before appointment
      console.log(stringOfDateOfAppointment, dateTimeToSend);

      // const tempTestDate = new Date();
      // tempTestDate.setMinutes(tempTestDate.getMinutes() + 1);

      let patientId = null;
      setLoadingThankyou(true)

      doCreateUserWithEmailAndPassword(
        userSignupDetails.email,
        userSignupDetails.password
      )
        .then(authUser => {
          axios({
            method: 'POST',
            url: 'https://app.phyxable.com/registerUser',
            crossDomain: true,
            data: {
              userId: authUser.user.uid,
              userData: {
                // TODO: joinDate is not normalized; joinDate is usually called "createdAt"
                joinDate: moment().format('YYYY/MM/DD'),
                userProfile: {
                  subscription: {
                    paid: companyCodes.includes(
                      localStorage.getItem('companyCode')
                    )
                      ? true
                      : false,
                  },
                  progress: {},
                  score: {
                    streak: 0,
                    longestStreak: 0,
                    totalTime: 0,
                    feelingGood: 0,
                  },
                  name: userSignupDetails.name,
                  lastname: '',
                  email: userSignupDetails.email.toLowerCase(),
                  currentPhyx: 'posture',
                  accountType: 'USER',
                  signedIn: false,
                  toolTip: false,
                  companyName: localStorage.getItem('CompanyName'),
                  phone: userSignupDetails.phone,
                  timezone: timezone,
                  bookingTime: stringOfDateOfAppointment,
                },
                SMS: [
                  {
                    type: 'bookingReminder',
                    textSent: false,
                    // TODO: timeCreated is not normalized; usually called `createdAt`
                    timeCreated: new Date(),
                    timeToBeSent: dateTimeToSend,
                    formattedTimeToSend: stringOfDateOfAppointment,
                  },
                ],
                agreedToTerms: true,
              },
            },
          }).then(response => {
            if (response.data.msg === 'success') {
              // console.log(response)
              //           setcreateLoading(false);
              //localStorage.setItem('show', false);
              handleSubmitForm(userSignupDetails);
              history.push('/appointment-booking/thank-you');
            }
          });
          // console.log(response)
          patientId = authUser.user.uid;
          console.log(patientId);

          // return this.props.firebase.user(authUser.user.uid);
        })
        .catch(error => {
          setLoadingThankyou(false)
          alert(error);
          //setcreateLoading(false);
          //setError(true);
          // closeSignUp();
        });

      // console.clear();
      // console.log(dateTimeToSend);
      // console.log(stringOfDateOfAppointment);
    };


    useEffect(() => {
      if (viewPort !== currentViewPort) {
        setViewPort(currentViewPort);
        // if (viewPort === "MOBILE") {
        //   setstyle(styleMobile)
        // } else {
        //   setstyle(styleWeb)
        // }
      }
      localStorage.setItem("companyCode", "");
    }, [currentViewPort]);

    const handleSignInClick = () => {
      // console.log("Clicked on Sign in button");
      ReactGA.event({
        category: "Clicked on Sign in button",
        action: "/booking",
        label: "Booking",
      });
    };

    const array = ["Conexus", "Dupuis", "BMO", "Aviva", "BPHA", "HUMI", "League", "Myplanet", "Onova","Wave HQ","Validere","Cohere","Guusto","HILO"];

    const handleSignUpInputChange1 =
      e => {

        // const value = e.target.value;
        // console.log(e.target.);
        localStorage.setItem('companyCode', e.target.value);
        // setuserSignupDetails({
        //   ...userSignupDetails,
        //   [e.target.name]: value,
        // });
      }

    const handleSignUp = (event) => {
      event.preventDefault();

      setFormError({});
      !companyCodes.includes(localStorage.getItem('companyCode')) &&
        setFormError(errors => ({
          ...errors,
          companyCode: 'The code you entered is not valid.',
        }));

      (event.target['phoneNumber'].value.length === 0) &&
        setFormError(errors => ({
          ...errors,
          phone: 'Please provide your phone',
        }));

      (event.target['Name'].value.length === 0) &&
        setFormError(errors => ({
          ...errors,
          name: 'Please provide your full name',
        }));

      (event.target['email'].value.length === 0) &&
        setFormError(errors => ({
          ...errors,
          email: 'Please provide your email',
        }));

      (event.target['password'].value.length === 0) &&
        setFormError(errors => ({
          ...errors,
          password: 'Please provide your password',
        }));

      (event.target['confirmPassword'].value.length === 0) &&
        setFormError(errors => ({
          ...errors,
          confirmpassword: 'Please provide your password confirmation',
        }));

      if (

        event.target['phoneNumber'].value.length > 0 &&

        event.target['Name'].value.length > 0 &&

        event.target['email'].value.length > 0 &&

        event.target['password'].value.length > 0 &&

        event.target['confirmPassword'].value.length > 0
      ) {
        if (event.target['password'].value.length < 6) {
          setFormError(errors => ({
            ...errors,
            password: 'Password must be more than 6 characters',
          }));
        } else if (
          event.target['password'].value !== event.target['confirmPassword'].value
        ) {
          setFormError(errors => ({
            ...errors,
            password: 'Password and confirm password do not match',
          }));
        } else {
          let newUserObject = {
            name: event.target['Name'].value,
            password: event.target['password'].value,
            email: event.target['email'].value,
            phone: event.target['phoneNumber'].value,
            companyCode: localStorage.getItem('CompanyName')
              ? localStorage.getItem('CompanyName')
              : 'No',

          };
          console.log(newUserObject)

          //  if (localStorage.getItem('CompanyName') === 'No' &&
          //    !companyCodes.includes(localStorage.getItem('companyCode'))) {
          createNewUser(newUserObject);

          //  }
          let UIdate = localStorage.getItem('UIdate');
          let startTime1 = localStorage.getItem('startTime1');
          let selectedDateValue = localStorage.getItem('selectedDateValue');
          let patientDetails = JSON.parse(localStorage.getItem('patientDetails'));

          let oldUiTime = UIdate;
          let startDate = startTime1;
          let eendDate = moment(startDate).add(30, 'minutes');
          //console.log('checkkkkkkkkkk', startDate, endDate)
          let endDate = eendDate._i;

          let x11 = moment(startDate).format('HH:mm');
          let x12 = selectedDateValue;
          let x16 = dayjs(x12).add(30, 'minutes').format('YYYY-MM-DD HH:mm');
          // console.log('fxxxxxx', x12, x16)

        }
      }
    }




    // const style = viewPort === "MOBILE" ? styleMobile : styleWeb ;

    return (
      <>
        <div className="sub-on">
          <div style={{ textAlign: "left", paddingLeft: "60px" }}>
            <button className="" onClick={handleClose}>
              <span className="text-sm"> <strong> &lt; Back</strong></span>
            </button>
          </div>
        </div>
        <div>
          {
            step === 'signup' ?
              viewPort === "MOBILE" ?
                <Grid>
                  <Paper elevation={10} style={styleMobile}>
                    <h3 className="ProximaNova cAlign secColor3 mtop-4"
                      style={{ color: "#07122a", fontSize: "1rem" }}>{i18next.t(`JUST_ONE_LAST_STEP`)}</h3>
                    <p
                      className="ProximaNova cAlign secColor3 mtop-1"
                      style={{ color: "#07122a", fontSize: "1rem" }}
                    >
                      {i18next.t(`MAKE_AN_ACCOUNT_TO_CONFIRM`)}
                    </p>
                    <p className="ProximaNova cAlign secColor3 mtop-1"
                      style={{ color: "#07122a", fontSize: "1rem" }} >
                      {i18next.t(`HAVE_AN_ACCOUNT_ALREADY`)}&nbsp;
                      <a href="https://app.phyxable.com">
                        <button onClick={handleSignInClick}><strong style={{ color: "#18a0fb" }}>{i18next.t(`SIGN_IN`)}</strong></button>
                      </a>
                    </p>
                    <form onSubmit={handleSignUp}>

                      {
                        array.includes(localStorage.getItem("CompanyName")) ?
                          <div className="form-group">
                            <input type="text" className={`${errors.companyCode ? "border border-red-500 rounded" : "border border-black-500 rounded "}`} placeholder="Company Code" onChange={handleSignUpInputChange1} />
                            <ReactTooltip type="info" effect="solid" />
                            <a data-html={true} data-arrow-color="#18214D" textColor="#ffffff" data-tip="Enter the code that was shared <br /> with your company through email,<br /> social media or other channels to <br />
                     receive full access to our platform." href="#" className=" question-mark" >?</a>
                            <span className="text-red-500">{errors.companyCode}</span>
                            <div className="form-group">
                              <span className="text-green-500" >
                                {localStorage.getItem("companyCode") === "CONEX6M" ||
                                  localStorage.getItem("companyCode") === "BMO3M" ||
                                  localStorage.getItem("companyCode") === "HUMI100" ||
                                  localStorage.getItem("companyCode") === "AVIVA6M" ||
                                  localStorage.getItem("companyCode") === "BPA6M" ||
                                  localStorage.getItem("companyCode") === "LEAGUE30JUN" ||
                                  localStorage.getItem("companyCode") === "BMO+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "WAVE+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "VALIDERE+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "COHERE+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "GUUSTO+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "HILO+PHYXABLE" ||
                                  localStorage.getItem("companyCode") === "ONOVA31AUG" ?
                                  localStorage.getItem("companyCode") === "CONEX6M" ? 'You have unlocked full access for 6 months' :
                                    localStorage.getItem("companyCode") === "BMO3M" ? 'You have unlocked full access for 3 months' :
                                      localStorage.getItem("companyCode") === "HUMI100" ? 'You have unlocked full access for 1 month' :
                                        localStorage.getItem("companyCode") === "AVIVA6M" ? 'You have unlocked full access for 6 months' :
                                          localStorage.getItem("companyCode") === "BPA6M" ? 'You have unlocked full access for 6 months' :
                                            localStorage.getItem("companyCode") === "LEAGUE30JUN" ? 'You have unlocked full access for 1 month' :
                                              localStorage.getItem("companyCode") === "ONOVA31AUG" ? 'You have unlocked full access for 2 months' :
                                                localStorage.getItem("companyCode") === "BMO+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                localStorage.getItem("companyCode") === "WAVE+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                localStorage.getItem("companyCode") === "VALIDERE+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                localStorage.getItem("companyCode") === "COHERE+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                localStorage.getItem("companyCode") === "GUUSTO+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                localStorage.getItem("companyCode") === "HILO+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                  '' : ''
                                }
                              </span>
                            </div>

                          </div>
                          : null
                      }

                      <div className="form-group">
                        <input
                          type="text"
                          name="phoneNumber"
                          className={`${errors.phone ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Phone Number" />
                      </div>
                      <div className="form-group"> <span className="text-red-500">{errors.phone}
                      </span>
                      </div>



                      <div className="form-group">
                        <input type="text"
                          name="Name"
                          className={`${errors.name ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Name"
                        />


                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.name}
                        </span>
                      </div>

                      <div className="form-group">

                        <input type="text"
                          name="email"
                          className={`${errors.email ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Enter email" />

                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.email}</span>
                      </div>

                      <div className="form-group">
                        <input type="password"
                          name="password"
                          className={`${errors.password ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Enter password" />
                      </div>
                      <div className="form-f">
                        <span className="text-red-500">{errors.password}
                        </span>
                      </div>

                      <div className="form-group">
                        <input type="password"
                          className={`${errors.confirmpassword ? "border border-red-500 rounded" : "form-control"}`}
                          name="confirmPassword"
                          placeholder="Confirm password" />
                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.confirmpassword}
                        </span>
                      </div>
                      <div>
                        <Checkbox
                          onChange={(event) => {
                            let p = {
                              isTrue: event.target.checked,
                            };
                            // alert(event.target.checked);
                            setAgreed(event.target.checked);
                          }}
                          // backgroundColor="#065E9D"
                          color="primary"
                          // borderColor="#065E9D"
                          size='small'
                        /><span style={{ fontSize: "12px", fontWeight: 600 }}>
                          {i18next.t(`I_HAVE_READ_AND_AGREED`)}
                          <a style={{ color: "#18a0fb", backgroundColor: "transparent", textDecoration: "underline" }} target="_blank" href='https://phyxable.com/privacy-policy'>
                            {i18next.t(`PRIVACY_POLICY`)}</a>
                        </span>

                      </div>

                      <div className="form-group">
                        {
                          loadingThankyou
                            ?
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                            </Box>

                            :

                            !agreed ?
                              <button type="submit"
                                disabled
                                style={{ backgroundColor: !agreed ? '#e4e7ed' : '#ff5a66', color: !agreed ? 'black' : 'white', marginTop: '1rem', width: '40%', borderRadius: '1rem' }}>
                                Sign Up
                              </button>
                              :
                              <button type="submit"

                                style={{ backgroundColor: !agreed ? '#e4e7ed' : '#ff5a66', color: !agreed ? 'black' : 'white', marginTop: '1rem', width: '40%', borderRadius: '1rem' }}>
                                Sign Up
                              </button>

                        }
                      </div>

                    </form>
                  </Paper>
                </Grid>

                :


                <Grid>
                  <Paper elevation={10} style={styleWeb}>
                    <h3 className="ProximaNova cAlign secColor3 mtop-4"
                      style={{ color: "#07122a", fontSize: "1rem" }}>{i18next.t(`JUST_ONE_LAST_STEP`)}</h3>
                    <p
                      className="ProximaNova cAlign secColor3 mtop-1"
                      style={{ color: "#07122a", fontSize: "1rem" }}
                    >
                      {i18next.t(`MAKE_AN_ACCOUNT_TO_CONFIRM`)}
                    </p>
                    <p className="ProximaNova cAlign secColor3 mtop-1"
                      style={{ color: "#07122a", fontSize: "1rem" }} >
                      {i18next.t(`HAVE_AN_ACCOUNT_ALREADY`)}&nbsp;
                      <a href="https://app.phyxable.com">
                        <button onClick={handleSignInClick}><strong style={{ color: "#18a0fb" }}>{i18next.t(`SIGN_IN`)}</strong></button>
                      </a>
                    </p>
                    <form onSubmit={handleSignUp}>

                      {
                        array.includes(localStorage.getItem("CompanyName")) ?
                          <div className="form-group">
                            <input type="text" className={`${errors.companyCode ? "border border-red-500 rounded" : "border border-black-500 rounded "}`} placeholder="Company Code" onChange={handleSignUpInputChange1} />
                            <ReactTooltip type="info" effect="solid" />
                            <a data-html={true} data-arrow-color="#18214D" textColor="#ffffff" data-tip="Enter the code that was shared <br /> with your company through email,<br /> social media or other channels to <br />
                         receive full access to our platform." href="#" className=" question-mark" >?</a>
                            <span className="text-red-500">{errors.companyCode}</span>
                            <span className="text-green-500" >
                              {localStorage.getItem("companyCode") === "CONEX6M" ||
                                localStorage.getItem("companyCode") === "BMO3M" ||
                                localStorage.getItem("companyCode") === "HUMI100" ||
                                localStorage.getItem("companyCode") === "AVIVA6M" ||
                                localStorage.getItem("companyCode") === "BPA6M" ||
                                localStorage.getItem("companyCode") === "LEAGUE30JUN" ||
                                localStorage.getItem("companyCode") === "BMO+PHYXABLE" ||
                                localStorage.getItem("companyCode") === "ONOVA31AUG" ?
                                localStorage.getItem("companyCode") === "CONEX6M" ? 'You have unlocked full access for 6 months' :
                                  localStorage.getItem("companyCode") === "BMO3M" ? 'You have unlocked full access for 3 months' :
                                    localStorage.getItem("companyCode") === "HUMI100" ? 'You have unlocked full access for 1 month' :
                                      localStorage.getItem("companyCode") === "AVIVA6M" ? 'You have unlocked full access for 6 months' :
                                        localStorage.getItem("companyCode") === "BPA6M" ? 'You have unlocked full access for 6 months' :
                                          localStorage.getItem("companyCode") === "LEAGUE30JUN" ? 'You have unlocked full access for 1 month' :
                                            localStorage.getItem("companyCode") === "ONOVA31AUG" ? 'You have unlocked full access for 2 months' :
                                              localStorage.getItem("companyCode") === "BMO+PHYXABLE" ? 'You have unlocked full access for 1 month' :
                                                '' : ''
                              }
                            </span>

                          </div>
                          : null
                      }

                      <div className="form-group">
                        <input
                          type="text"
                          name="phoneNumber"
                          className={`${errors.phone ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Phone Number" />
                      </div>
                      <div className="form-group"> <span className="text-red-500">{errors.phone}
                      </span>
                      </div>



                      <div className="form-group">
                        <input type="text"
                          name="Name"
                          className={`${errors.name ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Name"
                        />


                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.name}
                        </span>
                      </div>

                      <div className="form-group">

                        <input type="text"
                          name="email"
                          className={`${errors.email ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Enter email" />

                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.email}</span>
                      </div>

                      <div className="form-group">
                        <input type="password"
                          name="password"
                          className={`${errors.password ? "border border-red-500 rounded" : "form-control"}`}
                          placeholder="Enter password" />
                      </div>
                      <div className="form-f">
                        <span className="text-red-500">{errors.password}
                        </span>
                      </div>

                      <div className="form-group">
                        <input type="password"
                          className={`${errors.confirmpassword ? "border border-red-500 rounded" : "form-control"}`}
                          name="confirmPassword"
                          placeholder="Confirm password" />
                      </div>
                      <div className="form-group">
                        <span className="text-red-500">{errors.confirmpassword}
                        </span>
                      </div>
                      <div>
                        <Checkbox
                          onChange={(event) => {
                            let p = {
                              isTrue: event.target.checked,
                            };
                            // alert(event.target.checked);
                            setAgreed(event.target.checked);
                          }}
                          // backgroundColor="#065E9D"
                          color="primary"
                          // borderColor="#065E9D"
                          size='small'
                        />
                        <span style={{ fontSize: "12px", fontWeight: 600 }}>{i18next.t(`I_HAVE_READ_AND_AGREED`)}
                          <a style={{ color: "#18a0fb", backgroundColor: "transparent", textDecoration: "underline" }} target="_blank" href='https://phyxable.com/privacy-policy'>{i18next.t(`PRIVACY_POLICY`)}</a>
                        </span>

                      </div>
                      <div className="form-group">
                        {
                          loadingThankyou
                            ?
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                            </Box>

                            :
                            !agreed ?
                              <button type="submit"
                                disabled
                                style={{ backgroundColor: !agreed ? '#e4e7ed' : '#ff5a66', color: !agreed ? 'black' : 'white', marginTop: '1rem', width: '40%', borderRadius: '1rem' }}>
                                Sign Up
                              </button>
                              :
                              <button type="submit"

                                style={{ backgroundColor: !agreed ? '#e4e7ed' : '#ff5a66', color: !agreed ? 'black' : 'white', marginTop: '1rem', width: '40%', borderRadius: '1rem' }}>
                                Sign Up
                              </button>

                        }
                      </div>

                    </form>
                  </Paper>
                </Grid>
              :
              <Grid>
                <div className="form-group">
                  <ThankYou selectedDateValue={dateSelectedValue} time={fullTime} />
                </div>
              </Grid>

          }
        </div>
      </>


    );
  }

// const Mobile = memo(
//   ({
//     handleClose,
//     handleInputChange,
//     handleInputChange1,
//     handleSignInClick,
//     handleCreateUser,
//     enableSignupButton,
//     loading,
//     errors,
//     companyCodes,
//     userSignupDetails
//   }) => {
//     const array = ["Conexus", "Dupuis", "BMO", "Aviva", "BPHA", "HUMI", "League", "Myplanet", "Onova"];
//     const [agreed, setAgreed] = useState(false);
//     return (
//       <div
//         className="just-container boxShadowBooking"
//         style={{
//           width: 100 + `%`,
//           height: 100 + `vh`,
//           // overflow: "auto",
//           // padding: 0,
//         }}
//       >
//         <div className="p1 ">
//           <p className="book" />
//           <button style={{ fontSize: 30 }} onClick={handleClose}>
//             &times;
//           </button>
//         </div>
//         <div
//           style={{ fontFamily: "Proxima Nova" }}
//           className="mtop-25  w-70per mrAuto mlAuto"
//         >
//           <b>
//             <p
//               className="ProximaNova ftSz7 cAlign secColor3"
//               style={{ fontWeight: "600", color: "#07122a" }}
//             >
//               {i18next.t(`JUST_ONE_LAST_STEP`)}
//             </p>
//           </b>
//           <p
//             className="ProximaNova ftSz5 cAlign secColor3 mtop-1"
//             style={{ color: "#07122a" }}
//           >
//             {i18next.t(`MAKE_AN`)}<br /> {i18next.t(`YOUR_FREE_CONSULTATION`)}
//           </p>
//         </div>
//         <div
//           className="child-1 w-100per mrAuto mlAuto"
//           style={{ fontFamily: "Proxima Nova" }}
//         >
//           <div className="child-1-1 w-full">
//             <div className="input-holder mb-0 w-full">
//               {array.includes(localStorage.getItem("CompanyName")) &&
//                 <div className="flex flex-col items-start relative mb-4">
//                   <a data-html={true} data-arrow-color="#18214D" textColor="#ffffff" data-tip="Enter the code that was shared <br /> with your company through email,<br /> social media or other channels to <br />
// receive full access to our platform." href="#" className="tip-question-mark">?</a>
//                   <ReactTooltip className="tooltip-background" place="right" type="info" effect="solid" />
//                   <input
//                     className={`${errors.companyCode ? "border border-red-500 rounded" : "border-0"}`}
//                     style={{
//                       border:
//                         localStorage.getItem("companyCode") === "CONEX6M"
//                           || localStorage.getItem("companyCode") === "BMO3M"
//                           || localStorage.getItem("companyCode") === "HUMI100"
//                           || localStorage.getItem("companyCode") === "AVIVA6M"
//                           || localStorage.getItem("companyCode") === "BPA6M"
//                           || localStorage.getItem("companyCode") === "LEAGUE30JUN"
//                           || localStorage.getItem("companyCode") === "ONOVA31AUG" ?
//                           "1px solid #74D173" : null
//                     }} // {{ border: cond ? “1px solid green” : null }}
//                     placeholder="Company Code"
//                     name="companyCode"
//                     onChange={handleInputChange1}
//                     required
//                   />
//                   <span className="text-red-500">{errors.companyCode}</span>
//                   <span className="text-green-500">
//                     {localStorage.getItem("companyCode") === "CONEX6M" ||
//                       localStorage.getItem("companyCode") === "BMO3M" ||
//                       localStorage.getItem("companyCode") === "HUMI100" ||
//                       localStorage.getItem("companyCode") === "AVIVA6M" ||
//                       localStorage.getItem("companyCode") === "BPA6M" ||
//                       localStorage.getItem("companyCode") === "LEAGUE30JUN" ||
//                       localStorage.getItem("companyCode") === "ONOVA31AUG" ?
//                       localStorage.getItem("companyCode") === "CONEX6M" ? 'You have unlocked full access for 6 months' :
//                         localStorage.getItem("companyCode") === "BMO3M" ? 'You have unlocked full access for 3 months' :
//                           localStorage.getItem("companyCode") === "HUMI100" ? 'You have unlocked full access for 1 month' :
//                             localStorage.getItem("companyCode") === "AVIVA6M" ? 'You have unlocked full access for 6 months' :
//                               localStorage.getItem("companyCode") === "BPA6M" ? 'You have unlocked full access for 6 months' :
//                                 localStorage.getItem("companyCode") === "LEAGUE30JUN" ? 'You have unlocked full access for 1 month' :
//                                   localStorage.getItem("companyCode") === "ONOVA31AUG" ? 'You have unlocked full access for 2 months' :
//                                     '' : ''
//                     } </span>
//                 </div>
//               }
//               <div className="flex flex-col items-start  mb-4">
//                 <input
//                   className={`${errors.phone ? "border border-red-500 rounded" : "border-0"}`}
//                   placeholder="Phone Number"
//                   name="phone"
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <span className="text-red-500">{errors.phone}</span>
//               </div>

//               <div className="flex flex-col items-start  mb-4">
//                 <input
//                   className={`${errors.name ? "border border-red-500 rounded" : "border-0"}`}
//                   placeholder="Name"
//                   name="name"
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <span className="text-red-500">{errors.name}</span>
//               </div>

//               <div className="flex flex-col items-start  mb-4">
//                 <input
//                   className={`${errors.email ? "border border-red-500 rounded" : "border-0"}`}
//                   placeholder="Email Address"
//                   name="email"
//                   onChange={handleInputChange}
//                   required
//                 />
//                 <span className="text-red-500">{errors.email}</span>
//               </div>

//               <div className="flex flex-col items-start  mb-4">
//                 <input
//                   className={`${errors.password ? "border border-red-500 rounded" : "border-0"}`}
//                   placeholder="Password"
//                   name="password"
//                   type="password"
//                   onChange={handleInputChange}
//                 />
//                 <span className="text-red-500">{errors.password}</span>
//               </div>

//               <div className="flex flex-col items-start  mb-4">
//                 <input
//                   className={`${errors.confirmpassword ? "border border-red-500 rounded" : "border-0"}`}
//                   placeholder="Confirm Password"
//                   name="confirmpassword"
//                   type="password"
//                   onChange={handleInputChange}
//                 />
//                 <span className="text-red-500">{errors.confirmpassword}</span>
//               </div>
//             </div>

//             <p>
//               {i18next.t(`HAVE_AN_ACCOUNT_ALREADY`)}Have an account?&nbsp;
//               <a href="https://app.phyxable.com">
//                 <button onClick={handleSignInClick}>Sign in</button>
//               </a>
//             </p>
//             <div>
//               <Checkbox
//                 onChange={(event) => {
//                   let p = {
//                     isTrue: event.target.checked,
//                   };
//                   // alert(event.target.checked);
//                   setAgreed(event.target.checked);
//                 }}
//                 // backgroundColor="#065E9D"
//                 color="primary"
//                 // borderColor="#065E9D"
//                 size='small'
//               /><span style={{ fontSize: "12px", fontWeight: 600 }}>{i18next.t(`I_HAVE_READ_AND_AGREED`)} I have read and agreed to Phyxable's <a style={{ color: "#18a0fb", backgroundColor: "transparent", textDecoration: "underline" }} target="_blank" href='https://phyxable.com/privacy-policy'>{i18next.t(`I_HAVE_READ_AND_AGREED`)}Privacy Policy</a></span>

//             </div>
//             <div className="action-buttons">
//               <button
//                 onClick={handleCreateUser}
//                 disabled={!agreed}
//                 style={{ backgroundColor: !agreed ? "#e4e7ed" : "#18214D" }}
//               >
//                 {loading ? "Please wait..." : "Sign up"}
//               </button>
//               {/* <p> -OR- </p>
//           <button onClick={handleGoogle} className="google">
//             <img src={require('./google-icon.png')} />
//             Sign up with <b style={{ backgroundColor: 'transparent' }}>Google</b>
//           </button> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// );

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(JustOneStep);
