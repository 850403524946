import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function NotFound() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Not Found";
  }, []);

    return (
      <div className='Content'>
      <div className='padding-top-2rem'>
      
        <h2>{ i18next.t(`WE_OFTEN_FIND_OURSELVES`) }<br/> 
        { i18next.t(`WANDERING_AIMLESSLY_IN_THE`) }<br/> 
        { i18next.t(`JOURNEY_TO_PAIN_FREE_LIVING`) }</h2>

        <div className='padding-top-2rem' />
        
        <Link to="/">
          { i18next.t(`GET_BACK_ON_TRACK`) }Get back on track &gt;&gt;
        </Link>
      </div>
      </div>
    );
}