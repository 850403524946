import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import LifeworksUI from './LifeWorks';
import SmoothScrolling from '../Corporations/SmoothScrolling';
import { Insurers } from '../CorporateWellness/Insurers';
import PatientStories from '../Home/PatientStories';
import PatientStoriesMobile from '../Home/PatientStoriesMobile';

const Lifeworks = () => {
  const [isDesktop, setIsDesktop] = useState(null);

  useEffect(() => {
    if (window.innerWidth < 769) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, []);

  const scrollToPayment = () => {
    SmoothScrolling.scrollTo('stripeContainer');
  };

  return (
    <div style={{ marginBottom: '40px' }}>
      <LifeworksUI scrollToPayment={scrollToPayment} />
      <Insurers />
      {isDesktop === true ? <PatientStories /> : <PatientStoriesMobile />}
      <div className={styles.stripeButtonContainer}>
        <button className='button' onClick={scrollToPayment}>
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default Lifeworks;
