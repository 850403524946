import React, { Component } from "react";
import IMG_1 from "../../assets/home/patient-1.jpg";
import IMG_2 from "../../assets/home/patient-2.jpg";

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const arr = [
  {
    id: 1,
    image: IMG_1,
    patient: "Justin F.",
    about: "ABOUTPATIENT1",
    story: "STORY1",
  },
  {
    id: 2,
    image: IMG_2,
    patient: "Jade C.",
    about: "ABOUTPATIENT2",
    story: "STORY2",
  },
];

class PatientStoriesTablet extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    index: 0,
    selected: arr[0],
  };

  nextImage = () =>
    this.setState((prevState) => {
      const newIndex =
        prevState.index < arr.length - 1 ? prevState.index + 1 : 0;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });

  previousImage = () =>
    this.setState((prevState) => {
      const newIndex =
        prevState.index === 0 ? arr.length - 1 : prevState.index - 1;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });

  render = () => (
    <div className="dFlex jCenter flexAlCenter w-80per  mrAuto mlAuto">
      <div className="mrAuto mlAuto w-50per">
        <h1 className="blackColor lAlign ftSz10">{ i18next.t(`PATIENT_STORIES`) }</h1>

        <div className="">
          <h4 className=" dark interRegular ftSz1 w-90per lAlign">
            {this.state.selected.patient}
          </h4>
          <p className="  regular interRegular ftSz-1 mtop-1 lAlign">
            { i18next.t(this.state.selected.about) }
          </p>
        </div>
        <div className="lAlign mtop10">
          <h2 className="regular interRegular ftSz5 ">
            { i18next.t(this.state.selected.story) }
          </h2>
        </div>
        <div className="padding-top-1rem  lAlign">
          <button className="next-button" onClick={this.previousImage}>
            &lt;
          </button>
          <button className="next-button" onClick={this.nextImage}>
            &gt;
          </button>
        </div>
      </div>
      <div className=" mrAuto mlAuto w-50per rAlign">
        <img
          className="patient-img-tablet "
          src={this.state.selected.image}
          alt="patient"
        />
      </div>
    </div>
  );
}

PatientStoriesTablet = withTranslation()(PatientStoriesTablet);
export default PatientStoriesTablet;
