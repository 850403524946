const INITIAL_STATE = {
  mobileView: false,
  tabletView: false,
  laptopView: false,
  desktopView: false,
};

const applySetMobileView = (state, action) => ({
  ...state,
  mobileView: action.data,
});

const applySetTabletView = (state, action) => ({
  ...state,
  tabletView: action.data,
});

const applySetLaptopView = (state, action) => ({
  ...state,
  laptopView: action.data,
});

const applySetDesktopView = (state, action) => ({
  ...state,
  desktopView: action.data,
});
function viewportsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "MOBILE_VIEW_SET": {
      return applySetMobileView(state, action);
    }
    case "TABLET_VIEW_SET": {
      return applySetTabletView(state, action);
    }
    case "LAPTOP_VIEW_SET": {
      return applySetLaptopView(state, action);
    }
    case "DESKTOP_VIEW_SET": {
      return applySetDesktopView(state, action);
    }
    default:
      return state;
  }
}
export default viewportsReducer;
