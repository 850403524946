import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function TermsOfUse(props) {

  const { t, i18n } = useTranslation();

  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Terms Of Use";
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);
  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile />
      ) : viewPort === "TABLET" ? (
        <Tablet />
      ) : viewPort === "LAPTOP" ? (
        <Laptop />
      ) : (
        <Desktop />
      )}
    </>
  );
}

const Mobile = (props) => {
  return (
    <div className="interRegular">
      <div className="ptop100">
        <div className="w-85per mlAuto mrAuto blackColor">
          <h1 className=" lAlign ftSz13">
            { i18next.t(`TERMS_TITLE`) }<br /> { i18next.t(`OF_USE_TITLE`) }
          </h1>
        </div>

        <div className="w-85per mlAuto mrAuto blackColor">
          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
            { i18next.t(`TERMS1`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`TERMS2`) }
          </h2>
          <p>
            { i18next.t(`TERMS3`) }
          </p>
          <p>
            { i18next.t(`TERMS4`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`TERMS5`) }</h2>
          <p>
            { i18next.t(`TERMS6`) }
          </p>

          <p>
            { i18next.t(`TERMS7`) }
          </p>

          <p>
            { i18next.t(`TERMS8`) }
          </p>

          <p>
            { i18next.t(`TERMS9`) }
          </p>

          <p>
            { i18next.t(`TERMS10`) }
          </p>

          <p>
            { i18next.t(`TERMS11`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`TERMS12`) }</h2>
          <p>
            { i18next.t(`TERMS13`) }
          </p>

          <p>
            { i18next.t(`TERMS14`) }
          </p>

          <p>
            { i18next.t(`TERMS15`) }
          </p>

          <p>
            { i18next.t(`TERMS16`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Tablet = (props) => {
  return (
    <div className="interRegular">
      <div className="ptop100">
        <div className="w-85per mlAuto mrAuto blackColor">
          <h1 className=" lAlign ftSz13">{ i18next.t(`TERMS_TITLE`) }{ i18next.t(`OF_USE_TITLE`) }</h1>
        </div>

        <div className="w-75per mlAuto mrAuto  secColor1">
          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
          { i18next.t(`TERMS1`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`TERMS2`) }
          </h2>
          <p>
           { i18next.t(`TERMS3`) }
          </p>
          <p>
         { i18next.t(`TERMS4`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`TERMS5`) }</h2>
          <p>
            { i18next.t(`TERMS6`) }
          </p>

          <p>
         { i18next.t(`TERMS7`) }
          </p>

          <p>
            { i18next.t(`TERMS8`) }
          </p>

          <p>
          { i18next.t(`TERMS9`) }
          </p>

          <p>
           { i18next.t(`TERMS10`) }
          </p>

          <p>
      { i18next.t(`TERMS11`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`TERMS12`) }</h2>
          <p>
       { i18next.t(`TERMS13`) }
          </p>

          <p>
           { i18next.t(`TERMS14`) }
          </p>

          <p>
        { i18next.t(`TERMS15`) }
          </p>

          <p>
        { i18next.t(`TERMS16`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Laptop = (props) => {
  return (
    <div>
      <div className="Content">
        <div className="padding-privacy">
          <h1 className="terms">{ i18next.t(`TERMS_TITLE`) }{ i18next.t(`OF_USE_TITLE`) }</h1>
        </div>

        <div className="privacy-background dark">
          <h2 className="padding-top-2rem">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
     { i18next.t(`TERMS1`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS2`) }</h2>
          <p>
       { i18next.t(`TERMS3`) }
          </p>
          <p>
          { i18next.t(`TERMS4`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS5`) }</h2>
          <p>
         { i18next.t(`TERMS6`) }
          </p>

          <p>
          { i18next.t(`TERMS7`) }
          </p>

          <p>
   { i18next.t(`TERMS8`) }
          </p>

          <p>
          { i18next.t(`TERMS9`) }
          </p>

          <p>
         { i18next.t(`TERMS10`) }
          </p>

          <p>
          { i18next.t(`TERMS11`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS12`) }</h2>
          <p>
       { i18next.t(`TERMS13`) }
          </p>

          <p>
       { i18next.t(`TERMS14`) }
          </p>

          <p>
        { i18next.t(`TERMS15`) }
          </p>

          <p>
         { i18next.t(`TERMS16`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Desktop = (props) => {
  return (
    <div>
      <div className="Content">
        <div className="padding-privacy">
          <h1 className="terms">{ i18next.t(`TERMS_TITLE`) } { i18next.t(`OF_USE_TITLE`) }</h1>
        </div>

        <div className="privacy-background dark">
          <h2 className="padding-top-2rem">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
{ i18next.t(`TERMS1`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS2`) }</h2>
          <p>
{ i18next.t(`TERMS3`) }
          </p>
          <p>
           { i18next.t(`TERMS4`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS5`) }</h2>
          <p>
        { i18next.t(`TERMS6`) }
          </p>

          <p>
        { i18next.t(`TERMS7`) }
          </p>

          <p>
        { i18next.t(`TERMS8`) }
          </p>

          <p>
          { i18next.t(`TERMS9`) }
          </p>

          <p>
    { i18next.t(`TERMS10`) }
          </p>

          <p>
    { i18next.t(`TERMS11`) }.
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`TERMS12`) }</h2>
          <p>
           { i18next.t(`TERMS13`) }
          </p>

          <p>
            { i18next.t(`TERMS14`) }
          </p>

          <p>
        { i18next.t(`TERMS15`) }
          </p>

          <p>
        { i18next.t(`TERMS16`) }
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(TermsOfUse);
