import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import "./Practitioners.css";
import axios from "axios";
import SmoothScrolling from "../Corporations/SmoothScrolling";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function Practitioners(props) {

  const { t, i18n } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Practitioners";
    ReactGA.initialize('UA-148659511-2', { debug: true });
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);

  const sendMail = () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const corporation = document.getElementById("corporation").value;
    const message = document.getElementById("message").value;

    if (name !== "" && email !== "" && corporation !== "" && message !== "") {
      setLoading(true);
      axios({
        method: "POST",
        url: "https://practitioner.phyxable.com/sendFeedback",
        data: {
          name: name,
          email: email,
          corporation: corporation,
          message: message,
          type: "PRACTITIONER DEMO",
        },
      }).then((response) => {
        if (response.data.msg === "success") {
          ReactGA.event({
            category: 'Practitioner Demos',
            action: '/booking',
            label: 'Booking'
          });
          document.getElementById("name").value = "";
          document.getElementById("email").value = "";
          document.getElementById("corporation").value = "";
          document.getElementById("message").value = "";
          setLoading(false);
          alert(
            "Thanks for reaching out!  We will be in touch via email or phone call shortly”"
          );
        } else if (response.data.msg === "fail") {
          alert("Message failed to send.");
        }
      });
    } else {
      alert("Fill out data");
    }
  };

  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile sendMail={sendMail} isLoading={isLoading} />
      ) : viewPort === "TABLET" ? (
        <Tablet sendMail={sendMail} isLoading={isLoading} />
      ) : viewPort === "LAPTOP" ? (
        <Laptop sendMail={sendMail} isLoading={isLoading} />
      ) : (
        <Desktop sendMail={sendMail} isLoading={isLoading} />
      )}
    </>
  );
}
const Mobile = (props) => {
  return (
    <div>
      <div className="bg-desktop-practitioner-mobile">
        <div className="home-container">
          <h1 className="white interRegular ftSz13 ">
            { i18next.t(`ALL_THE_TOOLS_NEEDED_TO`) }
            <br />
            { i18next.t(`HELP_YOUR_PATIENTS`) }
          </h1>
        </div>
        <div className="primaryBackground p6 cAlign">
          <p className="white ftSz4 ProximaNova ltSpREG">
            { i18next.t(`TAKE_CONTROL_OF_YOUR_PATIENT_JOURNEY_FROM_INITIAL_BOOKING_CONT`) }
          </p>
          <div className="padding-top-1rem"></div>
          <button
            onClick={() => SmoothScrolling.scrollTo("book")}
            className="button"
          >
            { i18next.t(`STREAMLINE_YOUR_PATIENT_JOURNEY`) }
          </button>
        </div>
      </div>

      <div className="">
        <div className="">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11 ">
                { i18next.t(`APPOINTMENT_MANAGEMENT`) }
              </h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`YOUR_PATIENTS_CAN_BOOK_AN_APPOINTMENT_WITH_YOU_CONT`) }
            </p>
          </div>
          <div style={{ padding: "2rem 0" }} className="mrAuto mlAuto w-90per ">
            <img
              className="max-100 "
              src={require("../../assets/Practitioners/Practitioners_appointment.svg")}
              alt="Practitioners_appointment"
            />
          </div>
        </div>

        <div className="">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11">{ i18next.t(`VIDEO_CALLS`) }</h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`YOU_CAN_USE_THE_PHYXABLE_VIDEO_CALLS_MODULE_CONT`) }
            </p>
          </div>
          <div
            style={{ padding: "2rem 0" }}
            className="mtop-15 mrAuto mlAuto w-90per"
          >
            <img
              className="max-100"
              src={require("../../assets/Practitioners/Practitioners_Videocalls.png")}
              alt="Landingpage_mockup1"
            />
          </div>
        </div>

        <div className="mtop-15 ">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11">
                { i18next.t(`TRACK_PATIENT_PROGRESSION`) }
              </h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`YOU_CAN_VIEW_AND_TRACK_YOUR_PATIENT_DATA_CONT`) }
            </p>
          </div>
          <div style={{ padding: "2rem 0" }} className="mrAuto mlAuto w-90per">
            <img
              className="max-100"
              src={require("../../assets/Practitioners/Practitioners_safari.svg")}
              alt="Practitioners_safari"
            />
          </div>
        </div>

        <div className="">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11">
                { i18next.t(`BUILD_YOUR_OWN_VIDEO_SOLUTIONS`) }
              </h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`PRACTIONERS_CAN_BUILD_THEIR_OWN_SOLUTIONS_CONT`) }
            </p>
          </div>
          <div className="mrAuto mlAuto w-90per">
            <img
              className="max-100"
              src={require("../../assets/Practitioners/Practitioners_videosolutions.svg")}
              alt="Practitioners_videosolutions"
            />
          </div>
        </div>

        <div className="">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11">
                { i18next.t(`OUTCOME_MEASURES`) }
              </h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`PHYXABLE_HAS_INTEGRATED_DIFFERENT_OUTCOME_MEASURES_CONT`) }
            </p>
          </div>
          <div className="mrAuto mlAuto w-90per">
            <img
              className="max-100"
              src={require("../../assets/Practitioners/Practitioners_outcomes.svg")}
              alt="Landingpage_mockup1"
            />
          </div>
        </div>

        <div className="">
          <div className="mtop15 mrAuto mlAuto w-90per">
            <div className="w-85per">
              <h1 className="secColor1 interRegular ftSz11">
                { i18next.t(`CREATE_A_PROFILE`) }
              </h1>
            </div>
            <p className="interRegular ftSz4 ftWght1 secColor1">
              { i18next.t(`BUILD_YOUR_PRACTITIONER_PROFILE_AND_SHARE_IT_CONT`) }
            </p>
          </div>
          <div style={{ padding: "2rem 0" }} className="mrAuto mlAuto w-90per">
            <img
              className="max-100"
              src={require("../../assets/Practitioners/Practitioners_profile.svg")}
              alt="Landingpage_mockup1"
            />
          </div>
        </div>

        <div className="w-90per mrAuto mlAuto mtop15 mbottom10">
          <div id="book" className="book-demo-mobile cAlign">
            <h2 className="ProximaNova blackColor ftSz3">{ i18next.t(`BOOK_A_DEMO`) }</h2>
            <div className="w-90per mrAuto mlAuto">
              <p className="ProximaNova ftSz1 ">
                { i18next.t(`OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT`) }
              </p>
            </div>
            <div style={{ display: "inline-block" }}>
              <input id="name" className="input-book-demo" placeholder="NAME" />
              <input
                id="email"
                className="input-book-demo"
                placeholder="EMAIL ADDRESS"
              />
              <input
                id="corporation"
                className="input-book-demo"
                placeholder="CORPORATION NAME"
              />
              <textarea
                id="message"
                data-enable-grammarly="false"
                style={{ height: "16rem" }}
                className="input-book-demo"
                placeholder="HOW CAN WE HELP?"
              />
            </div>
            <div className="padding-top-1rem">
              <button className="button" onClick={props.sendMail}>
                {props.isLoading ? "Loading..." : "Book the demo"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Tablet = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-practitioner-mobile pbottom80">
          <div className="home-container-tablet ">
            <h1 className="white main-font">
              { i18next.t(`ALL_THE_TOOLS_NEEDED`) }
              <br />
              { i18next.t(`TO_HELP_YOUR_PATIENTS`) }
            </h1>
            <p className="white">
              { i18next.t(`TAKE_CONTROL`) }
              <br />
              { i18next.t(`CREATING_CUSTOMIZED_SOLUTIONS`) }
              <br />
              { i18next.t(`NOTIFICATIONS`) }
            </p>
            <div className="padding-top-1rem" />
            <button
              onClick={() => SmoothScrolling.scrollTo("book")}
              className="button"
            >
              { i18next.t(`STREAMLINE_YOUR_PATIENT_JOURNEY`) }
            </button>
          </div>
        </div>

        <div className="">
          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className="w-50per lAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_appointment.svg")}
                alt="Practitioners_appointment"
              />
            </div>{" "}
            <div className=" w-50per">
              <h1 className="dark rAlign ftSz10">
                {" "}
                { i18next.t(`APPOINTMENT`) }
                <br />{ i18next.t(`MANAGEMENT`) }
              </h1>
              <p className="regular rAlign ftSz0 interRegular ftWght1">
                { i18next.t(`YOUR_PATIENTS_CAN_BOOK_AN_APPOINTMENT`) }
                <br />
                { i18next.t(`WITH_YOU_AS_PER_YOUR_AVAILABILITY_YOUR`) }<br />
                { i18next.t(`AVAILABILITY_SYNCS_WITH_YOUR_CALENDAR`) }
              </p>
            </div>
          </div>
          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className=" w-50per">
              <h1 className="dark lAlign ftSz10">{ i18next.t(`VIDEO_CALLS`) }</h1>
              <p className="regular lAlign ftSz0 interRegular ftWght1">
                { i18next.t(`YOU_CAN_USE_THE_PHYXABLE_CALLS`) }
                <br />
                { i18next.t(`MODULE_TO_DO_VIDEO_CALLS_WITH_YOUR`) }
                <br />
                { i18next.t(`PATIENTS_HIPAA_PIPEDA_CERTIFIED`) }
                <br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div className="w-50per rAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_Videocalls.png")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className="w-50per lAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_appointment.svg")}
                alt="Practitioners_appointment"
              />
            </div>{" "}
            <div className=" w-50per">
              <h1 className="dark rAlign ftSz10">
                { i18next.t(`TRACK`) }
                <br />
                { i18next.t(`PATIENT`) }
                <br />
                { i18next.t(`PROGRESSION`) }
              </h1>
              <p className="regular rAlign ftSz0 interRegular ftWght1">
                { i18next.t(`YOU_CAN_VIEW_AND_TRACK_YOUR_PATIENT_DATA`) }
                <br />
                { i18next.t(`MAKING_IT_EASY_TO_VISUALLY_SEE_PROGRESSION`) }
              </p>
            </div>
          </div>

          <div className="w-80per dFlex flexCol jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className=" w-100per">
              <h1 className="dark cAlign ftSz10">
                { i18next.t(`BUILD_YOUR_OWN`) }
                <br />
                { i18next.t(`VIDEO_SOLUTIONS`) }
              </h1>
              <p className="regular cAlign ftSz0 interRegular ftWght1">
                { i18next.t(`PRACTITIONERS_CAN_BUILD_THEIR_OWN`) }
                <br />
                { i18next.t(`SOLUTIONS_LIBRARY`) }
                <br />
                { i18next.t(`EXERCISES_AND_SOLUTIONS_TO_CHOOSE`) }
                <br />
                { i18next.t(`FROM_ABILITY_TO_UPLOAD`) }
                <br />
                { i18next.t(`YOUR_OWN_VIDEOS`) }
              </p>
            </div>{" "}
            <div className="w-100per cAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_videosolutions.svg")}
                alt="Practitioners_videosolutions"
              />
            </div>{" "}
          </div>

          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className="w-50per lAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_outcomes.svg")}
                alt="Landingpage_mockup1"
              />
            </div>{" "}
            <div className=" w-50per">
              <h1 className="dark rAlign ftSz10">
                { i18next.t(`OUTCOME`) }
                <br /> { i18next.t(`MEASURES`) }
              </h1>
              <p className="regular rAlign ftSz0 interRegular ftWght1">
                { i18next.t(`PHYXABLE_HAS_INTEGRATED_DIFFERENT_OUTCOME`) }
                <br />
                { i18next.t(`MEASURES_FORMS_AND_SCALES`) }
                <br />
                { i18next.t(`PATIENTS_CUSTOM_SOLUTIONS`) }
              </p>
            </div>
          </div>

          <div className="w-80per dFlex jCenter mlAuto mrAuto ptop100 mbottom10 lAlign ">
            <div className=" w-50per">
              <h1 className="dark lAlign ftSz10">{ i18next.t(`CREATE_A_PROFILE`) }</h1>
              <p className="regular lAlign ftSz0 interRegular ftWght1">
                { i18next.t(`BUILD_YOUR_PRACTITIONER_PROFILE_AND_SHARE`) }
                <br />
                { i18next.t(`SHOWCASE_YOUR_REVIEWS`) }
                <br />
                { i18next.t(`BUILT_ETC_ON`) }<br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div className="w-50per rAlign">
              <img
                className="w-100per"
                src={require("../../assets/Practitioners/Practitioners_profile.svg")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div
            id="book"
            className="book-demo-tablet w-90per mlAuto mrAuto mbottom10 "
          >
            <div className=" w-90per mlAuto mrAuto">
              <h2 className="pink cAlign">{ i18next.t(`BOOK_A_DEMO`) }</h2>
              <p className="gray cAlign">
                { i18next.t(`OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT`) }
              </p>
              <div>
                <input
                  id="name"
                  className="input-book-demo-tablet"
                  placeholder="NAME"
                />
                <input
                  id="email"
                  className="input-book-demo-tablet"
                  placeholder="EMAIL ADDRESS"
                />
                <input
                  id="corporation"
                  className="input-book-demo-tablet"
                  placeholder="CORPORATION NAME"
                />
                <textarea
                  id="message"
                  data-enable-grammarly="false"
                  style={{ height: "16rem" }}
                  className="input-book-demo-tablet"
                  placeholder="HOW CAN WE HELP?"
                />
              </div>
              <div className="padding-top-1rem cAlign">
                <button className="button" onClick={props.sendMail}>
                  {props.isLoading ? "Loading..." : "Book the demo"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Laptop = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-practitioner">
          <div className="home-container">
            <h1 className="white main-font">
              { i18next.t(`ALL_THE_TOOLS_NEEDED`) }
              <br />
              { i18next.t(`TO_HELP_YOUR_PATIENTS`) }
            </h1>
            <p className="white">
              { i18next.t(`TAKE_CONTROL`) }
              <br />
              { i18next.t(`CREATING_CUSTOMIZED_SOLUTIONS`) }
              <br />
              { i18next.t(`NOTIFICATIONS`) }
            </p>
            <div className="padding-top-1rem" />
            <button
              onClick={() => SmoothScrolling.scrollTo("book")}
              className="button"
            >
              { i18next.t(`STREAMLINE_YOUR_PATIENT_JOURNEY`) }
            </button>
          </div>
        </div>

        <div className="Content">
          <div className="responsive-container">
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_appointment.svg")}
                alt="Practitioners_appointment"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`APPOINTMENT`) }
                <br /> { i18next.t(`MANAGEMENT`) }
              </h1>
              <p className="regular">
                { i18next.t(`YOUR_PATIENTS_CAN_BOOK_AN_APPOINTMENT`) }
                <br />
                { i18next.t(`WITH_YOU_AS_PER_YOUR_AVAILABILITY_YOUR`) }<br />
                { i18next.t(`AVAILABILITY_SYNCS_WITH_YOUR_CALENDAR`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">{ i18next.t(`VIDEO_CALLS`) }</h1>
              <p className="regular">
                { i18next.t(`YOU_CAN_USE_THE_PHYXABLE_CALLS`) }
                <br />
                { i18next.t(`MODULE_TO_DO_VIDEO_CALLS_WITH_YOUR`) }
                <br />
                { i18next.t(`PATIENTS_HIPAA_PIPEDA_CERTIFIED`) }
                <br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_Videocalls.png")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_safari.svg")}
                alt="Practitioners_safari"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`TRACK`) }
                <br />
                { i18next.t(`PATIENT`) }
                <br />
                { i18next.t(`PROGRESSION`) }
              </h1>
              <p className="regular">
                { i18next.t(`YOU_CAN_VIEW_AND_TRACK_YOUR_PATIENT_DATA`) }
                <br />
                { i18next.t(`MAKING_IT_EASY_TO_VISUALLY_SEE_PROGRESSION`) }
              </p>
            </div>
          </div>

          <div className="padding-top-2rem">
            <div style={{ padding: "2rem 0" }}>
              <h1 className="dark">
                { i18next.t(`BUILD_YOUR_OWN`) }
                <br />
                { i18next.t(`VIDEO_SOLUTIONS`) }
              </h1>
              <p className="regular">
                { i18next.t(`PRACTITIONERS_CAN_BUILD_THEIR_OWN`) }
                <br />
                { i18next.t(`SOLUTIONS_LIBRARY`) }
                <br />
                { i18next.t(`EXERCISES_AND_SOLUTIONS_TO_CHOOSE`) }
                <br />
                { i18next.t(`FROM_ABILITY_TO_UPLOAD`) }
                <br />
                { i18next.t(`YOUR_OWN_VIDEOS`) }
              </p>
            </div>
            <div>
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_videosolutions.svg")}
                alt="Practitioners_videosolutions"
              />
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "4rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_outcomes.svg")}
                alt="Landingpage_mockup1"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`OUTCOME`) }
                <br /> { i18next.t(`MEASURES`) }
              </h1>
              <p className="regular">
                { i18next.t(`PHYXABLE_HAS_INTEGRATED_DIFFERENT_OUTCOME`) }
                <br />
                { i18next.t(`MEASURES_FORMS_AND_SCALES`) }
                <br />
                { i18next.t(`PATIENTS_CUSTOM_SOLUTIONS`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "4rem 0" }} className="left">
              <h1 className="dark">{ i18next.t(`CREATE_A_PROFILE`) }</h1>
              <p className="regular">
                { i18next.t(`BUILD_YOUR_PRACTITIONER_PROFILE_AND_SHARE`) }
                <br />
                { i18next.t(`SHOWCASE_YOUR_REVIEWS`) }
                <br />
                { i18next.t(`BUILT_ETC_ON`) }<br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_profile.svg")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div className="padding-top-2rem" />
          <div id="book" className="book-demo">
            <h2 className="pink">{ i18next.t(`BOOK_A_DEMO`) }</h2>
            <p className="gray">
              { i18next.t(`OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT`) }
            </p>
            <div style={{ display: "inline-block" }}>
              <input id="name" className="input-book-demo" placeholder="NAME" />
              <input
                id="email"
                className="input-book-demo"
                placeholder="EMAIL ADDRESS"
              />
              <input
                id="corporation"
                className="input-book-demo"
                placeholder="CORPORATION NAME"
              />
              <textarea
                id="message"
                data-enable-grammarly="false"
                style={{ height: "16rem" }}
                className="input-book-demo"
                placeholder="HOW CAN WE HELP?"
              />
            </div>
            <div className="padding-top-1rem">
              <button className="button" onClick={props.sendMail}>
                {props.isLoading ? "Loading..." : "Book the demo"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Desktop = (props) => {
  return (
    <div>
      <div>
        <div className="bg-desktop-practitioner">
          <div className="home-container">
            <h1 className="white main-font">
              { i18next.t(`ALL_THE_TOOLS_NEEDED`) }
              <br />
              { i18next.t(`TO_HELP_YOUR_PATIENTS`) }
            </h1>
            <p className="white">
              { i18next.t(`TAKE_CONTROL`) }
              <br />
              { i18next.t(`CREATING_CUSTOMIZED_SOLUTIONS`) }
              <br />
              { i18next.t(`NOTIFICATIONS`) }
            </p>
            <div className="padding-top-1rem" />
            <button
              onClick={() => SmoothScrolling.scrollTo("book")}
              className="button"
            >
              { i18next.t(`STREAMLINE_YOUR_PATIENT_JOURNEY`) }
            </button>
          </div>
        </div>

        <div className="Content">
          <div className="responsive-container">
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_appointment.svg")}
                alt="Practitioners_appointment"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`APPOINTMENT`) }
                <br /> { i18next.t(`MANAGEMENT`) }
              </h1>
              <p className="regular">
                { i18next.t(`YOUR_PATIENTS_CAN_BOOK_AN_APPOINTMENT`) }
                <br />
                { i18next.t(`WITH_YOU_AS_PER_YOUR_AVAILABILITY_YOUR`) }<br />
                { i18next.t(`AVAILABILITY_SYNCS_WITH_YOUR_CALENDAR`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div className="left">
              <h1 className="dark">{ i18next.t(`VIDEO_CALLS`) }</h1>
              <p className="regular">
                { i18next.t(`YOU_CAN_USE_THE_PHYXABLE_CALLS`) }
                <br />
                { i18next.t(`MODULE_TO_DO_VIDEO_CALLS_WITH_YOUR`) }
                <br />
                { i18next.t(`PATIENTS_HIPAA_PIPEDA_CERTIFIED`) }
                <br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_Videocalls.png")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_safari.svg")}
                alt="Practitioners_safari"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`TRACK`) }
                <br />
                { i18next.t(`PATIENT`) }
                <br />
                { i18next.t(`PROGRESSION`) }
              </h1>
              <p className="regular">
                { i18next.t(`YOU_CAN_VIEW_AND_TRACK_YOUR_PATIENT_DATA`) }
                <br />
                { i18next.t(`MAKING_IT_EASY_TO_VISUALLY_SEE_PROGRESSION`) }
              </p>
            </div>
          </div>

          <div className="padding-top-2rem">
            <div style={{ padding: "2rem 0" }}>
              <h1 className="dark">
                { i18next.t(`BUILD_YOUR_OWN`) }
                <br />
                { i18next.t(`VIDEO_SOLUTIONS`) }
              </h1>
              <p className="regular">
                { i18next.t(`PRACTITIONERS_CAN_BUILD_THEIR_OWN`) }
                <br />
                { i18next.t(`SOLUTIONS_LIBRARY`) }
                <br />
                { i18next.t(`EXERCISES_AND_SOLUTIONS_TO_CHOOSE`) }
                <br />
                { i18next.t(`FROM_ABILITY_TO_UPLOAD`) }
                <br />
                { i18next.t(`YOUR_OWN_VIDEOS`) }
              </p>
            </div>
            <div>
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_videosolutions.svg")}
                alt="Practitioners_videosolutions"
              />
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "4rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_outcomes.svg")}
                alt="Landingpage_mockup1"
              />
            </div>
            <div className="left">
              <h1 className="dark">
                { i18next.t(`OUTCOME`) }
                <br /> { i18next.t(`MEASURES`) }
              </h1>
              <p className="regular">
                { i18next.t(`PHYXABLE_HAS_INTEGRATED_DIFFERENT_OUTCOME`) }
                <br />
                { i18next.t(`MEASURES_FORMS_AND_SCALES`) }
                <br />
                { i18next.t(`PATIENTS_CUSTOM_SOLUTIONS`) }
              </p>
            </div>
          </div>

          <div className="responsive-container">
            <div style={{ padding: "4rem 0" }} className="left">
              <h1 className="dark">{ i18next.t(`CREATE_A_PROFILE`) }</h1>
              <p className="regular">
                { i18next.t(`BUILD_YOUR_PRACTITIONER_PROFILE_AND_SHARE`) }
                <br />
                { i18next.t(`SHOWCASE_YOUR_REVIEWS`) }
                <br />
                { i18next.t(`BUILT_ETC_ON`) }<br />
                { i18next.t(`VIDEO_CONFERENCING`) }
              </p>
            </div>
            <div style={{ padding: "2rem 0" }} className="right">
              <img
                className="max-100"
                src={require("../../assets/Practitioners/Practitioners_profile.svg")}
                alt="Landingpage_mockup1"
              />
            </div>
          </div>

          <div className="padding-top-2rem" />
          <div id="book" className="book-demo">
            <h2 className="pink">{ i18next.t(`BOOK_A_DEMO`) }</h2>
            <p className="gray">
              { i18next.t(`OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT`) }
            </p>
            <div style={{ display: "inline-block" }}>
              <input id="name" className="input-book-demo" placeholder={ i18next.t("NAME") } />
              <input
                id="email"
                className="input-book-demo"
                placeholder={ i18next.t("INPUT_BOOK_DEMO_EMAIL") }
              />
              <input
                id="corporation"
                className="input-book-demo"
                placeholder={ i18next.t("INPUT_BOOK_DEMO_CORPORATION") }
              />
              <textarea
                id="message"
                data-enable-grammarly="false"
                style={{ height: "16rem" }}
                className="input-book-demo"
                placeholder= {i18next.t("INPUT_BOOK_DEMO_HELP") }
              />
            </div>
            <div className="padding-top-1rem">
              <button className="button" onClick={props.sendMail}>
                {props.isLoading ? "Loading..." : "Book the demo"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(Practitioners);
