import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import './styles.css';
import './helpers/utility.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';
import OnlinePhysiotherapy from './components/OnlinePhysiotherapy/OnlinePhysiotherapy';
import Corporations from './components/Corporations/Corporations';
import CorporateWellness from './components/CorporateWellness/CorporateWellness';
import Practitioners from './components/Practitioners/Practitioners';
import AboutUs from './components/AboutUs/AboutUs';
import FAQ from './components/FAQ/FAQ';
import Blogs from './components/Blog/Blogs';
import Support from './components/Support/Support';
import Blog from './components/Blog/Blog';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse/TermsOfUse';
import SearchPractitioners from './components/SearchPractitioners/SearchPractitioners';
import Profile from './components/Profile/Profile';
import Booking from './components/Booking/Booking';
import provinces from './components/Booking/provinces';
import Category from './components/Blog/Category';
import BookingSelect from './components/Booking/BookingSelect';
import CaseStudy from './components/CaseStudy/caseStudy.jsx';
import CaseDetails from './components/CaseStudy/caseDetails.jsx';
import BookingCanada from './components/Booking/BookingCanada';
import BookingQuebec from './components/Booking/BookingQuebec';
import BookingUK from './components/Booking/BookingUK';
import firebase from 'firebase';
import { firebaseConfig } from './components/Booking/BookingComponents/base/helpers/FirebaseInit';
import LandingPage from './components/LandingPage/LandingPage';
import LandingPageContent from './components/LandingPage/LandingPageContent';
import Lifeworks from './components/Lifeworks/index';
import JaneApp from './components/Booking/janeApp';
import i18next from 'i18next';
import './translator/i18n'; // French
import LearntoRun from './components/Learntorun';
require('dotenv').config({path: __dirname + '/.env'});
firebase.initializeApp(firebaseConfig);

const lang = localStorage.getItem('lang') || 'en';
i18next.changeLanguage(lang);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { className: '' };
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.pageview(window.location.pathname);
  }

  handleScroll = () => {
    if (window.scrollY === 0) this.setState({ className: '' });
    else this.setState({ className: 'scroll' });
  };

  render() {
    return (
      <Router>
        <div className={this.state.className}>
          <Header />
        </div>{' '}
        <Switch>
          <Route exact path='/bookingBC'>
            {' '}
            {<Redirect to='/booking-select' />}
          </Route>
          <Route exact path='/booking'>
            {' '}
            {<Redirect to='/appointment-booking' />}
          </Route>{' '}
          <Route path='/' component={Home} exact />
          <Route path='/how-it-works' component={OnlinePhysiotherapy} />
          <Route path='/corporations' component={Corporations} />
          <Route path='/corporatewellnesstrial' component={CorporateWellness} />
          <Route
            path='/corporatewellnessmonthtrial'
            component={CorporateWellness}
          />{' '}
          <Route path='/lifeworks' component={Lifeworks} />{' '}
          <Route path='/learntorun' component={LearntoRun} />{' '}
          <Route path='/practitioners' component={Practitioners} />{' '}
          <Route path='/about-us' component={AboutUs} />{' '}
          <Route path='/faq' component={FAQ} />{' '}
          <Route path='/privacy-policy' component={PrivacyPolicy} />{' '}
          <Route path='/terms-of-use' component={TermsOfUse} />{' '}
          <Route path='/search-practitioners' component={SearchPractitioners} />{' '}
          <Route path='/profile/:id' component={Profile} />{' '}
          <Route path='/provinces' component={provinces} />
          <Route path="/case-study"
                                component={CaseStudy}
                            />
                            <Route path="/case-details"
                                component={CaseDetails}
                            />
          <Route path='/appointment-booking' component={Booking} exact />
          <Route
            path='/appointment-booking/details/:when?'
            render={props => <Booking {...props} step={'details'} />}
          />
          <Route
            path='/appointment-booking/sign-up'
            render={props => <Booking {...props} step={'signup'} />}
         />

          <Route
            path='/appointment-booking/thank-you'
            render={props => <Booking {...props} step={'thankYou'} />}
          />
          <Route path='/blog' component={Blogs} exact />
          <Route path='/support' component={Support} exact />
          <Route path='/blog/:id' component={Blog} exact />
          <Route path='/blog/category/:id' component={Category} exact />
          <Route path='/free-consultation' component={LandingPage} exact />
          <Route
            path='/free-consultation/:id'
            component={LandingPageContent}
            exact
          />
          <Route path='/booking-select' component={JaneApp} />{' '}
          <Route
            path='/online-physiotherapy-chiro-consultation'
            component={BookingCanada}
          />{' '}
          <Route
            path='/online-physiotherapy-consultation-quebec'
            component={BookingQuebec}
          />{' '}
          <Route
            path='/online-physiotherapy-and-chiro-consultation-uk'
            component={BookingUK}
          />{' '}
          <Route component={NotFound} />{' '}
        </Switch>{' '}
        <Footer />
      </Router>
    );
  }
}

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>{' '}
  </Provider>,
  rootElement
);
