import React, { Component } from "react";
import "./Profile.scss";

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { profile: null };
  }

  componentDidMount() {
    window.scroll(0, 0);
    document.title = "Phyxable | Profile";
    if (this.props.location.state)
      this.setState({ profile: this.props.location.state.profile });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    console.log(this.state.profile);
    const { profile } = this.state;
    return (
      this.props && (
        <div>
          {profile && (
            <>
              <div className="bg-profile">
                <div className="div-padder">
                  <p
                    className="padding-top-4rem backBtn"
                    style={{ textAlign: "left", cursor: "pointer" }}
                    onClick={() => this.props.history.goBack()}
                  >
                    &lt;
                    <span style={{ textDecoration: "underline" }}> Back</span>
                  </p>

                  <div className="avatar-container" style={{ border: "none" }}>
                    <div className="profile-cAlign">
                      <img
                        className="profile-practitioner"
                        src={profile.userProfile.avatarURL}
                        alt=""
                      />
                    </div>
                    <div className="info-container dark">
                      <p>
                        {profile.userProfile.type &&
                          profile.userProfile.type.toUpperCase()}
                      </p>
                      <h1>{profile.userProfile.name}</h1>
                      <p>{profile.userProfile.email}</p>
                      <h3>{profile.userProfile.clinic}</h3>
                      <div className="taggings">
                        {profile.userProfile.virtual && (
                          <p className="virtual mrAuto mlAuto">
                            VIRTUAL CLINIC
                          </p>
                        )}
                        <ul id="tags">
                          {profile.userProfile.specialization &&
                            profile.userProfile.specialization.map(
                              (tag, index) => (
                                <li key={index} className="tag">
                                  <span className="tag-title ">{tag}</span>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                      <div className="padding-top-1rem">
                        {profile.userProfile.linkedin_link && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link"
                            href={profile.userProfile.linkedin_link}
                          >
                            <img
                              src={require("../../assets/social/Linkedin-profile.svg")}
                              alt="linkedin"
                            />
                          </a>
                        )}
                        {profile.userProfile.instagram_link && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link"
                            href={profile.userProfile.instagram_link}
                          >
                            <img
                              src={require("../../assets/social/Instagram-profile.svg")}
                              alt="instagram"
                            />
                          </a>
                        )}
                        {profile.userProfile.fb_link && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link"
                            href={profile.userProfile.fb_link}
                          >
                            <img
                              src={require("../../assets/social/Facebook-profile.svg")}
                              alt="facebook"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="padding-top-2rem" style={{ paddingLeft: "3rem" }}>
                <div className="Profile-About">
                  {profile.userProfile.about && (
                    <>
                      <h2 className="dark">About</h2>
                      <p className="regular">{profile.userProfile.about}</p>
                    </>
                  )}
                  {profile.userProfile.language && (
                    <>
                      <h3 className="dark">Languages</h3>
                      <p className="regular">{profile.userProfile.language}</p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )
    );
  }
}

Profile = withTranslation()(Profile);
export default Profile;
