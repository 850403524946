import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import Booking from './components/Booking';
import { useHistory, useParams, Redirect, useLocation } from 'react-router-dom';
import BookingClicked from './components/BookingClicked';
// Import React Calendar
import 'react-calendar/dist/Calendar.css';
// Import globals
import './App.global.css';
// Import Tailwind
import './styles/main.css';
import moment from 'moment';
import ReactGA from 'react-ga';
import firebase from '@firebase/app';
import JustOneStep from './components/JustOneStep';
import ThankYou from './components/ThankYou';
import { authentication } from './helpers/FirebaseInit';
import axios from 'axios';
import dayjs from 'dayjs';
import { useScrollTrigger } from '@material-ui/core';
import Loading from './components/Loading';
import { memoize as memo } from 'lodash';
import { doCreateUserWithEmailAndPassword } from '../../BookingComponents/base/helpers/FirebaseInit';
import { convertTimeToEST } from '../../../../services/index';
import {
  convertToProvinceTime,
  getProvinceTimezoneName,
  getProvinceUtcOffset,
} from '../../../../helpers/provinces';

let axiosCache = {
  unavailable_blocks: null,
  daily_availabilities: null,
};

const appintFlattenFn = memo(appint =>
  (appint || []).reduce((acc, cur, idx, arr) => {
    // console.log(`Running appintFlatten w/ ${idx}`, { arr, appint });
    const curDate = moment(cur.startDate);
    const startBlock = moment(cur.startDate).set({ second: 0, millisecond: 0 });
    const endBlock = moment(cur.endDate).set({ second: 0, millisecond: 0 });
    // console.log("initialBlocks", {
    //   startBlock: [cur.startDate, moment(cur.startDate)],
    //   endBlock: [cur.endDate, moment(cur.endDate)],
    // });

    const curDateF = curDate.format('YYYY-MM-DD');
    const startBlockF = startBlock.format('HH:mm');
    const endBlockF = endBlock.format('HH:mm');
    let betweenBlocks = [];
    let lastBlock;

    while ((lastBlock = startBlock.format('HH:mm')) !== endBlockF) {
      betweenBlocks.push(`${curDateF} ${lastBlock}`);
      console.log({ curDateF, startBlockF, lastBlock, endBlockF });
      startBlock.add(30, 'minutes');
    }

    // console.log(betweenBlocks);
    const betweenBlocksK = betweenBlocks.reduce((acc, cur) => {
      return { ...acc, [cur]: true };
    }, {});

    return {
      ...acc,
      ...betweenBlocksK,
      // [`${curDateF} ${endBlockF}`]: true,
    };
  }, {})
);

const BASE_URL =
  process.env.NODE_ENV == 'development'
    ? 'http://phyxable.com'
    : 'https://phyxable.com';
// const BASE_URL = "http://localhost:3000"
const App = ({ step, location }) => {
  const [dateSelected, setDateSelected] = useState(false);
  const [dateSelectedValue, setDateSelectedValue] = useState('');
  const [fullDate, setFullDate] = useState(new Date());
  const [fullTime, setFullTime] = useState('');
  const [JustOneStepErrors, setFormError] = useState({});
  const [s, setS] = useState(false);
  const [createLoading, setcreateLoading] = useState(false);
  const [appint, setAppint] = useState([]);
  const [unavailable, setUnavailable] = useState([]);
  const [userSignupDetails, setuserSignupDetails] = useState({});
  const [BookingClickedStatus, setBookingClickedStatus] = useState(false);
  const [error, setError] = useState(false);
  const [province, setProvince] = useState('');

  let history = useHistory();
  // console.log("~App JS is Rendering");

  /** Change View Based on Dates */
  let acDate = '';
  let welcomeSubject = 'Welcome email';
  let welcomeMessage = 'Welcome body';

  const handleDate = useCallback(
    (date, time, actual) => {
      // console.log("handleData");
      acDate = moment(actual).format('YYYY-MM-DD');

      let newTime = moment(time, ["h:mm 'A"]).format('HH:mm');
      let baseTime = moment(newTime).add('minutes', 30);
      //   console.log("Base time: ", baseTime)
      // setDateSelected(true)
      setFullDate(date);
      setFullTime(time);
      setDateSelectedValue(acDate);
    },
    [dateSelected, dateSelectedValue]
  );

  const handleBookingClicked = useCallback(
    (time, actual) => {
      setBookingClickedStatus(true);
      console.log("handleBookingClicked");
      acDate = moment(actual).format('YYYY-MM-DD');
      let newTime = moment(time, ["h:mm 'A"]).format('HH:mm');
      history.push(`/appointment-booking/details/${acDate} ${newTime}`);

      let baseTime = moment(newTime).add('minutes', 30);
      //   console.log("Base time: ", baseTime)
      setDateSelected(true);
      //setFullDate(date);
      setFullTime(time);
      setDateSelectedValue(acDate);
    },
    [dateSelected, dateSelectedValue]
  );

  useEffect(() => {
    window.scroll(0, 0);
    document.title = 'Phyxable | Booking';
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Visitors to /Booking',
      action: '/booking',
      label: 'Booking',
    });
    console.log(location);
    if (!localStorage.getItem('province'))
      window.location.replace('/provinces');
    setProvince(localStorage.getItem('province'));
  }, []);

  let fetchOtherData = () => {
    // hackfix: make sure, we don't call fetchUnavailableBlocks or getAllMeetingsFromFirestore twice
    fetchOtherData = () => { };
    // console.log(
    //   "triggering unavailable blocks use effect ---------------------------------------------------"
    // );
    const _fetchUnavailableBlocks = () => {
      axios.get('https://phyxable.com/unavailable_blocks').then(response => {
        // console.log(
        //   "UNAVAILABLE BLOCKS",
        //   response,
        //   response.data.data.unavailable_blocks
        // );
        setUnavailable(unavailable => {
          // console.warn('unavailable', unavailable.concat(response.data.data.unavailable_blocks));
          return unavailable.concat(response.data.data.unavailable_blocks);
        });
        axiosCache.unavailable_blocks = response.data.data.unavailable_blocks;
      });
    };

    const getAllMeetingsFromFirestore = memo(async () => {
      const db = firebase.firestore();
      const appint = await db
        .collection('appointments')
        .get()
        .then(function (querySnapshot) {
          return querySnapshot.docs.map(function (doc) {
            return {
              startDate: doc.data().startDate,
              endDate: doc.data().endDate,
              // This line was a nightmare to discover/debug, for some reason it was formatting only endDate but not startDate
              // endDate: moment(doc.data().endDate).format("YYYY-MM-DD hh:mm"),
              title: doc.data().description,
              id: doc.data().meetingId,
              user: doc.data().selecteduser,
              // type: item.type
            };
          });
        });

      setAppint(appint);
      localStorage.setItem('apps', JSON.stringify(appint));
      setS(true);
    });

    return Promise.all([
      axiosCache.unavailable_blocks
        ? setUnavailable(unavailable =>
          unavailable.concat(axiosCache.unavailable_blocks)
        )
        : _fetchUnavailableBlocks(),

      getAllMeetingsFromFirestore(),
    ]);
  };

  const handleWeekChange = async week => {
    // console.log('handleWeekChange')
    // make sure, all data is loaded before handleWeekChange is done, because, afterwards, `loading` is set to false and the user can see the calendar.
    if (axiosCache.daily_availabilities) {
      await fetchOtherData();
      setUnavailable(unavailable =>
        unavailable.concat(axiosCache.daily_availabilities)
      );
      return;
    }
    const fetchDailyAvailabilities = () =>
      Promise.all([
        axios.get('https://phyxable.com/daily_availabilities'),
        fetchOtherData(),
      ]).then(([response]) => {
        // console.log(
        //   "DAILY UNAVAILABLE BLOCKS",
        //   response,
        //   response.data.data.daily_availabilities
        // );
        const blocks = response.data.data.daily_availabilities;

        const sortedWeek = Array.from(week).sort(function sortByDay(a, b) {
          let day1 = moment(a).isoWeekday();
          let day2 = moment(b).isoWeekday();
          return day1 - day2;
        });

        const unavailable_blocks = blocks.reduce((acc, cur) => {
          const currentDay = sortedWeek[cur.day_of_week - 1];
          const available_blocks = cur.availabilities.map(block => {
            const hoursStart = block.starts_at.split(':')[0];
            const minutesStart = block.starts_at.split(':')[1];
            const hoursEnd = block.ends_at.split(':')[0];
            const minutesEnd = block.ends_at.split(':')[1];
            const starts_at = moment(currentDay)
              .set({ hours: hoursStart, minutes: minutesStart, seconds: 0 })
              .format('HH:mm');
            const ends_at = moment(currentDay)
              .set({ hours: hoursEnd, minutes: minutesEnd, seconds: 0 })
              .format('HH:mm');
            // console.log({ currentDay, block, day_of_week: cur.day_of_week, sortedWeek, week, available: cur.availabilities })
            return {
              starts_at,
              ends_at,
            };
          });

          const curDateF = moment(currentDay).format('YYYY-MM-DD');
          const startBlock = moment(currentDay).set({ hours: 1, minutes: 0 });
          const endBlock = moment(currentDay).set({ hours: 23, minutes: 30 });
          const startBlockF = startBlock.format('HH:mm');
          const endBlockF = endBlock.format('HH:mm');

          let unavailableBlocks = [];
          let lastBlock;

          while (lastBlock !== endBlockF) {
            startBlock.add(30, 'minutes');
            lastBlock = startBlock.format('HH:mm');
            // console.log({ lastBlock, endBlockF })
            unavailableBlocks.push(lastBlock);

            available_blocks.forEach(block => {
              const [hours, minutes] = block.starts_at.split(':');
              let startBlock = moment(currentDay).set({ hours, minutes });
              let lastBlock;
              while (block.ends_at !== lastBlock) {
                startBlock.add(30, 'minutes');
                lastBlock = startBlock.format('HH:mm');
                unavailableBlocks = unavailableBlocks.filter(
                  c => c !== lastBlock
                );
              }
            });
          }

          // console.log({ available_blocks, unavailableBlocks, currentDay })
          return acc.concat(
            unavailableBlocks.map(block => {
              const blockDate = moment(currentDay).set({
                hours: block.split(':')[0],
                minutes: block.split(':')[1],
                seconds: 0,
              });

              const prevDate = blockDate.clone();
              prevDate.subtract(30, 'minutes');
              return {
                starts_at: prevDate.format('YYYY-MM-DD HH:mm:ss'),
                ends_at: blockDate.format('YYYY-MM-DD HH:mm:ss'),
              };
            })
          );
        }, []);

        // console.log({ unavailable_blocks })
        setUnavailable(unavailable => unavailable.concat(unavailable_blocks));
        axiosCache.daily_availabilities = unavailable_blocks;
      });

    // console.log({ axiosCache })
    // TODO: fetchDailyAvailabilities returns a promise (probably have to add `await`)
    return fetchDailyAvailabilities();
  };


  useEffect(() => {
    console.log("BookingClickedStatus");
    if (!BookingClickedStatus) {
      setBookingClickedStatus(BookingClickedStatus);
    }
  }, [dateSelected]);


  /** Return to Booking */
  const handleOpenBooking = () => {
    setDateSelected(false);
    setBookingClickedStatus(false);
    history.push('/appointment-booking');
  };

  const closeSignUp = () => {
    localStorage.setItem('show', false);
    setBookingClickedStatus(false);
    // TODO: fix URL
    //history.push('/appointment-booking/details');
    window.location.reload();
  };
  const handleGoogle = (e) => {
    e.preventDefault();

    const googleProvider = new firebase.auth.GoogleAuthProvider();

    //console.log('Handle google')
    authentication
      .signInWithPopup(googleProvider)
      .then(res => {
        localStorage.setItem('show', false);

        let userObject = {
          name: res.user.displayName,
          lastname: res.user.displayName.split(' ')[1],
          email: res.user.email,
          uid: res.user.uid,
          isGoogle: true,
        };
        createNewUser(userObject, true);
        //history.push('/appointment-booking/sign-up');

        //Send mail here
        // sendMail(res.user.email, welcomeSubject, welcomeMessage);
      })
      .catch(error => {
        console.log(error.message)
      });
  };
  const handleSignUpInputChange =
    e => {
      e.preventDefault();

      const value = e.target.value;
      // console.log(e.target.);
      //localStorage.setItem("companyCode", );
      setuserSignupDetails({
        ...userSignupDetails,
        [e.target.name]: value,
      });
    }

  const handleSignUpInputChange1 =
    e => {
      e.preventDefault();


      const value = e.target.value;
      localStorage.setItem('companyCode', e.target.value);
      // console.log(e.target.value);
      // localStorage.setItem("companyCode", );
      setuserSignupDetails({
        ...userSignupDetails,
        [e.target.name]: value,
      });
    }
  const array = ['Conexus', 'Dupuis', 'BMO', 'HUMI', 'Aviva', 'BPHA'];

  const handleCreateUser = (e) => {
    e.preventDefault();
    setFormError({});
    !companyCodes.includes(localStorage.getItem('companyCode')) &&
      setFormError(errors => ({
        ...errors,
        companyCode: 'The code you entered is not valid.',
      }));

    (!userSignupDetails.phone || userSignupDetails.phone.length === 0) &&
      setFormError(errors => ({
        ...errors,
        phone: 'Please provide your phone',
      }));

    (!userSignupDetails.name || userSignupDetails.name.length === 0) &&
      setFormError(errors => ({
        ...errors,
        name: 'Please provide your full name',
      }));

    (!userSignupDetails.email || userSignupDetails.email.length === 0) &&
      setFormError(errors => ({
        ...errors,
        email: 'Please provide your email',
      }));

    (!userSignupDetails.password || userSignupDetails.password.length === 0) &&
      setFormError(errors => ({
        ...errors,
        password: 'Please provide your password',
      }));

    (!userSignupDetails.confirmpassword ||
      userSignupDetails.confirmpassword.length === 0) &&
      setFormError(errors => ({
        ...errors,
        confirmpassword: 'Please provide your password confirmation',
      }));

    if (
      userSignupDetails.phone &&
      userSignupDetails.phone.length > 0 &&
      userSignupDetails.name &&
      userSignupDetails.name.length > 0 &&
      userSignupDetails.email &&
      userSignupDetails.email.length > 0 &&
      userSignupDetails.password &&
      userSignupDetails.password.length > 0 &&
      userSignupDetails.confirmpassword &&
      userSignupDetails.confirmpassword.length > 0
    ) {
      if (userSignupDetails.password.length < 6) {
        setFormError(errors => ({
          ...errors,
          password: 'Password must be more than 6 characters',
        }));
      } else if (
        userSignupDetails.password !== userSignupDetails.confirmpassword
      ) {
        setFormError(errors => ({
          ...errors,
          password: 'Password and confirm password do not match',
        }));
      } else {
        let newUserObject = {
          name: userSignupDetails.name,
          password: userSignupDetails.password,
          email: userSignupDetails.email,
          phone: userSignupDetails.phone,
          companyCode: localStorage.getItem('CompanyName')
            ? localStorage.getItem('CompanyName')
            : 'No',
        };

        // sendMail(userSignupDetails.email, welcomeSubject, welcomeMessage);

        if (
          localStorage.getItem('CompanyName') === 'No' &&
          !companyCodes.includes(localStorage.getItem('companyCode'))
        ) {
          setcreateLoading(true);
          createNewUser(newUserObject);

          // sendMail(userSignupDetails.email, welcomeSubject, welcomeMessage);
        } else if (
          array.includes(localStorage.getItem('CompanyName')) &&
          !companyCodes.includes(localStorage.getItem('companyCode'))
        ) {
          // console.log('chal ja baap');
        } else {
          setcreateLoading(true);
          createNewUser(newUserObject);

          // sendMail(userSignupDetails.email, welcomeSubject, welcomeMessage);
          //send mail here
          //console.log("Clicked on Sign Up button");
          ReactGA.event({
            category: 'Clicked on Sign Up button',
            action: '/booking',
            label: 'Booking',
          });
        }
      }
    } else {
      setcreateLoading(false);
      // alert("All fields are required");
    }
  };

  const companyCodes = [
    'CONEX6M',
    'BMO3M',
    'HUMI100',
    'AVIVA6M',
    'BPA6M',
    'LEAGUE30JUN',
    'ONOVA31AUG',
    'BMO+PHYXABLE',
    'WAVE+PHYXABLE',
    'VALIDERE+PHYXABLE',
    'COHERE+PHYXABLE',
    'GUUSTO+PHYXABLE',
    'HILO+PHYXABLE'
  ];

  const createNewUser = (userSignupDetails, isGoogle) => {
    // console.log(userSignupDetails.companyCode);

    let UIDate = localStorage.getItem('clicked_date'); // consider changing to UIdate if there are problem
    let time = localStorage.getItem('clicked_time'); // startTime1

    // TODO: only use one way to store date/time objects. Either Date or moment; prefer moment
    const dateTimeOfAppointment = new Date(UIDate);
    dateTimeOfAppointment.setHours(time.split(':')[0]);
    dateTimeOfAppointment.setMinutes(time.split(':')[1]);

    // TODO: timezone is wrong
    const province = localStorage.getItem('province');
    const timezone =
      province === 'British Columbia'
        ? 'PDT'
        : province === 'Saskatchewan' || province === 'Albert'
          ? 'CST'
          : province === 'Maintoba'
            ? 'CDT'
            : province === 'Nova Scotia'
              ? 'ADT'
              : province === 'Newfoundland and Labrador'
                ? 'NDT'
                : 'EST'; // manually enter

    // TODO: don't use string manipulation for date manipulation. Use moment ctor and/or add/subtract instead
    const dateTimeOfAppointmentArr = dateTimeOfAppointment
      .toString()
      .split(' ');
    const stringOfDateOfAppointment =
      dateTimeOfAppointmentArr[1] +
      ' ' +
      dateTimeOfAppointmentArr[2] +
      ' ' +
      dateTimeOfAppointmentArr[3] +
      ' ' +
      dateTimeOfAppointmentArr[4] +
      ' ' +
      timezone;

    // const dateTimeToSend = new Date(dateTimeOfAppointment);
    // dateTimeToSend.setHours(dateTimeToSend.getHours() - 1); // convert timezones on server side instead
    const dateTimeToSend = convertTimeToEST(
      dateTimeOfAppointment.setHours(dateTimeOfAppointment.getHours() - 1),
      timezone
    ); // reminder sent one hour before appointment
    console.log(stringOfDateOfAppointment, dateTimeToSend);

    // const tempTestDate = new Date();
    // tempTestDate.setMinutes(tempTestDate.getMinutes() + 1);

    let patientId = null;

    doCreateUserWithEmailAndPassword(
      userSignupDetails.email,
      userSignupDetails.password
    )
      .then(authUser => {
        axios({
          method: 'POST',
          url: 'https://app.phyxable.com/registerUser',
          crossDomain: true,
          data: {
            userId: authUser.user.uid,
            userData: {
              // TODO: joinDate is not normalized; joinDate is usually called "createdAt"
              joinDate: moment().format('YYYY/MM/DD'),
              userProfile: {
                subscription: {
                  paid: companyCodes.includes(
                    localStorage.getItem('companyCode')
                  )
                    ? true
                    : false,
                },
                progress: {},
                score: {
                  streak: 0,
                  longestStreak: 0,
                  totalTime: 0,
                  feelingGood: 0,
                },
                name: userSignupDetails.name,
                lastname: '',
                email: userSignupDetails.email.toLowerCase(),
                currentPhyx: 'posture',
                accountType: 'USER',
                signedIn: false,
                toolTip: false,
                companyName: localStorage.getItem('CompanyName'),
                phone: userSignupDetails.phone,
                timezone: timezone,
                bookingTime: stringOfDateOfAppointment,
              },
              SMS: [
                {
                  type: 'bookingReminder',
                  textSent: false,
                  // TODO: timeCreated is not normalized; usually called `createdAt`
                  timeCreated: new Date(),
                  timeToBeSent: dateTimeToSend,
                  formattedTimeToSend: stringOfDateOfAppointment,
                },
              ],
              agreedToTerms: true,
            },
          },
        }).then(response => {
          if (response.data.msg === 'success') {
            setcreateLoading(false);
            localStorage.setItem('show', false);
            history.push('/appointment-booking/thank-you');
            handleSubmitForm();
          }
        });
        patientId = authUser.user.uid;
        console.log(patientId);
        // return this.props.firebase.user(authUser.user.uid);
      })
      .catch(error => {
        alert(error);
        setcreateLoading(false);
        setError(true);
        // closeSignUp();
      });

    // console.clear();
    // console.log(dateTimeToSend);
    // console.log(stringOfDateOfAppointment);
  };

  const sendMail = (email, subject, message) => {
    //Makes request to backend to send receipt
    axios({
      method: 'POST',
      url: `${BASE_URL}/send`,
      data: {
        email: email,
        subject: subject,
        message: message,
      },
    })
      .then(() => {
        // console.log("Email Sent");
      })
      .catch(err => {
        // console.log("Sending Email ERR: ", err);
      });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    window.scroll(0, 0);

    let UIdate = localStorage.getItem('UIdate');
    let startTime1 = localStorage.getItem('startTime1');
    let selectedDateValue = localStorage.getItem('selectedDateValue');
    let patientDetails = JSON.parse(localStorage.getItem('patientDetails'));

    let oldUiTime = UIdate;
    let startDate = startTime1;
    let eendDate = moment(startDate).add(30, 'minutes');
    //console.log('checkkkkkkkkkk', startDate, endDate)
    let endDate = eendDate._i;

    let x11 = moment(startDate).format('HH:mm');
    let x12 = selectedDateValue;
    let x16 = dayjs(x12).add(30, 'minutes').format('YYYY-MM-DD HH:mm');
    // console.log('fxxxxxx', x12, x16)

    axios({
      method: 'POST',
      url: `${BASE_URL}/makeBooking`,
      data: {
        startDate: moment(x12, 'YYYY-MM-DD HH:mm:ss Z').utc().format(),
        endDate: moment(x16, 'YYYY-MM-DD HH:mm:ss Z').utc().format(),
        note: 'Reason For Booking:' + patientDetails.reason,
        patientName: userSignupDetails.name,
        email: userSignupDetails.email,
        phone: userSignupDetails.phone,
        patient_arrived: false,
        did_not_arrive: false,
      },
    })
      .then(response => {
        if (response.data.error === false) {
          //console.log('====**====', response)
          sendEmail(x12, x16, userSignupDetails, patientDetails);
          createMeeting(
            x12,
            x16,
            userSignupDetails,
            userSignupDetails.name,
            'Free',
            response.data.data.id
          );
        }
      })
      .catch(err => {
        console.error('ERR: ', err);
      });
  };
  const BookingClickedStatusFunction = useCallback(() => {
    setBookingClickedStatus(!BookingClickedStatus);
  }, []);

  const sendEmail = (x12, x16, userSignupDetails, patientDetails) => {
    //Makes request to backend to send receipt
    console.log('Sending mail...');
    const startDate = convertToProvinceTime(x12, province).format(
      'YYYY-MM-DD hh:mm A'
    );
    const endDate = convertToProvinceTime(x16, province).format(
      'YYYY-MM-DD hh:mm A'
    );
    const tz = getProvinceTimezoneName(province);
    axios({
      method: 'POST',
      url: `${BASE_URL}/sendReceipt`,
      data: {
        startDate,
        endDate,
        tz,
        name: userSignupDetails.name,
        email: userSignupDetails.email,
        title: 'Free',
        description: '30 minutes free',
      },
    })
      .then(() => {
        console.log('Email Sent___');
      })
      .catch(err => {
        console.error('Sending Email ERR: ', err);
      });
  };

  const createMeeting = (
    startDate,
    endDate,
    userSignupDetails,
    patientName,
    appointmentType,
    id
  ) => {
    let dDate = new Date();
    firebase
      .firestore()
      .collection('appointments')
      .add({
        // TODO: remove eid, since it's not ensured to be unqiue; replace with doc.id
        eid: Date.now(),

        // TODO: remove startDate endDate (or update, if changed in API)
        startDate: startDate,
        endDate: endDate,

        createdAt: moment().utc().format('YYYY-MM-DD HH:mm'),
        // TODO: remove utcOffset + tz; look it up by province instead
        utcOffset: getProvinceUtcOffset(province),
        tz: getProvinceTimezoneName(province),
        province,

        description: '30 Minutes Free',
        selecteduser: userSignupDetails.email,
        meetingId: id,
        type: appointmentType,
        email: userSignupDetails.email,
        phoneNumber: userSignupDetails.phone,
        currentPhyx: 'posture',
        patientName: patientName,
        campaign: 'canada',
      })
      .then(function (docRef) {
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error('Error adding document: ', error);
      });
  };

  const RenderView = () => {

    let { when: selectedDateString } = useParams();
    const dateRef = useRef(0);

    if (!province) {
      return 'loading...';
    }
    if (step === 'details') {
      if (dateRef.current === 3) {
        console.log(dateRef.current)
        return
      }
      
      //window.resizeTo(74,75)
      dateRef.current +=1;

      if (!selectedDateString) {
        window.scroll(0, 0)
        conosole.log('from details condition')
        selectedDateString = localStorage.getItem('selectedDateValue');
        if (selectedDateString) {
          console.log("loop")
          return (
            <Redirect
              to={`/appointment-booking/details/${selectedDateString}`}
            />
          );
        }
      }
      const selectedDateValue = moment(selectedDateString, 'YYYY-MM-DD HH:mm');
      const minutes = selectedDateValue.minutes();

      // console.warn('BookingClicked', axiosCache.daily_availabilities);
      if (
        // make sure it's not blocked out
        // NOTE: daily_availabilities is actually the "not available timeslots"
        // axiosCache.daily_availabilities.some(av => av.starts_at === selectedDateString) &&
        minutes % 30 // currently, only 30 minute blocks are allowed
      ) {
        console.error(`invalid date/time: ${selectedDateString}`);
        return <Redirect to='/appointment-booking' />;
      }
      return (
        <BookingClicked
          date={fullDate}
          // selectedDateValue={dateSelectedValue}
          // time={fullTime}
          handleClose={handleOpenBooking}
          BookingClickedStatus={BookingClickedStatus}
          BookingClickedStatusFunction={BookingClickedStatusFunction}
          province={province}
        />
      );
    }
    if (step === 'signup' || step === 'thankYou') {
      let location = useLocation()
      //console.log(`from sign condition :: ${step}`)
      //console.log(location)

      //window.scroll(0,0)
      // if (true) {
      return (
        <JustOneStep
          step={step}
          handleGoogle={handleGoogle}
          handleClose={closeSignUp}
          handleInputChange={handleSignUpInputChange}
          handleInputChange1={handleSignUpInputChange1}
          handleCreateUser={handleCreateUser}
          errors={JustOneStepErrors}
          loading={createLoading}
          companyCodes={companyCodes}
          userSignupDetails={userSignupDetails}
          selectedDateValue={dateSelectedValue}
          time={fullTime}
        />

      );

    }

    // if (step === 'thankYou') {
    //   // if (true) {
    //   return <ThankYou selectedDateValue={dateSelectedValue} time={fullTime} />;
    // }

    return '';
  }

  const appintFlatten = useMemo(() => appintFlattenFn(appint), [appint]);

  return (<>
    <Fragment>
      <RenderView />
      {
        <div
          style={{
            display: typeof step === 'undefined' ? 'block' : 'none',
          }}
        >
          <Booking
            handleDate={handleDate}
            bookingClicked={handleBookingClicked}
            appint={appint}
            appintFlatten={appintFlatten}
            unavailable={unavailable}
            BookingClickedStatusFunction={BookingClickedStatusFunction}
            handleWeekChange={handleWeekChange}
            province={province}
          />
        </div>
      }
    </Fragment>
  </>
  );
};

export default App;
