import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BookingIndex from "./BookingComponents/base/App";
import { connect } from "react-redux";

function Booking(props) {

  const { step, location } = props;

  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);
  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile step={step} location={location} />
      ) : viewPort === "TABLET" ? (
        <NonMobile step={step} location={location} />
      ) : viewPort === "LAPTOP" ? (
        <NonMobile step={step} location={location} />
      ) : (
        <NonMobile step={step} location={location} />
      )}
    </>
  );
}

const NonMobile = ({ step, location }) => {
  return (
    <div>
      <div className="Content">
        <br />
        {console.log(step,location)}
        <BookingIndex step={step} location={location} />
      </div>
    </div>
  );
};
const Mobile = ({ step, location }) => {
  return (
    <div>
      <div style={{ paddingTop: 80 + `px` }}>
        <br />
        {/* <Link to="/">Home</Link> */}
        <BookingIndex step={step} location={location} />
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(Booking);
