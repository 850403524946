import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import chroma from "chroma-js";
import { connect } from "react-redux";
import { useHistory, useParams } from 'react-router-dom';

import moment from "moment";

import firebase from "@firebase/app";
import axios from "axios";
import ReactGA from "react-ga";
import { Dropdown } from "semantic-ui-react";
import { HistoryOutlined } from "@material-ui/icons";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const companiesOptions = [
  { key: "No", value: "No", disabled: false },
  { key: "Conexus", value: "Conexus", disabled: false },
  { key: "Dupuis", value: "Dupuis", disabled: false },
  { key: "BMO", value: "BMO", disabled: false },
  { key: "Humi", value: "Humi", disabled: false },
  { key: "Aviva", value: "Aviva", disabled: false},
  { key: "BPA", value: "BPA", disabled: false},
  { key: "League", value: "League", disabled: false},
  { key: "Wave HQ", value: "Wave HQ", disabled: false},
  { key: "Validere", value: "Validere", disabled: false},
  { key: "Cohere", value: "Cohere", disabled: false},
  { key: "Guusto", value: "Guusto", disabled: false},
  { key: "HILO", value: "HILO", disabled: false},
  { key: "Myplanet", value: "Myplanet", disabled: false},
  { key: "Onova", value: "Onova", disabled: false},
  { key: "RBC", value: "RBC", disabled: true },
  { key: "Costco", value: "Costco", disabled: true },
  { key: "Red Cross", value: "Red Cross", disabled: true },
];

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://www.phyxable.com"
    : "https://www.phyxable.com";
require("firebase/firestore");

const selectBackgroundColor = '#EFF2F6';
const colorStyles = {
  control: styles => ({ ...styles, backgroundColor: selectBackgroundColor }),
  // input: styles => ({ ...styles, backgroundColor: 'yellow' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const DefaultColor = null;
    const colorBase = 'black';
    const color = chroma(colorBase);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? selectBackgroundColor
        : isSelected
          ? DefaultColor
          : isFocused
            ? DefaultColor// ? color.alpha(0.1).css()
            : selectBackgroundColor,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : colorBase,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? colorBase : color.alpha(0.3).css()),
      }
    };
  }
};

const BookingClicked = ({
  handleClose,
  currentViewPort,
  BookingClickedStatus,
  BookingClickedStatusFunction,
  province
}) => {
  const [UIdate, setUIdate] = useState("");
  const [UItime, setUItime] = useState("");
  const [patientDetails, setPatientDetails] = useState("");
  //const [appointmentType, setappointmentType] = useState("30 min Free");
  const [startTime1, setStartTime1] = useState("");
  const [endTime1, setEndTime1] = useState("");
  const [fullDate1, setFullDate1] = useState("");
  const [submit, setSubmit] = useState(false);
  const [viewPort, setViewPort] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const history = useHistory();
  const { when: selectedDateValue } = useParams();
  const selectedDate = moment(selectedDateValue, 'YYYY-MM-DD HH:mm');
  const newDate = selectedDate.format('YYYY-MM-DD');
  const time = selectedDate.format('HH:mm');

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // const newDate = `${date}`
    //   .split(" ")
    //   .filter((item, index) => index <= 3)
    //   .join(" ");
    const newFullDateAndTime = selectedDate.toDate();
    console.warn('selectedValue', selectedDateValue, selectedDate, newFullDateAndTime);
    const startTime =
    province === "Alberta" ? moment(newFullDateAndTime).subtract(2, 'hours').format("hh:mm") :
    province === "British Columbia" ? moment(newFullDateAndTime).subtract(3, 'hours').format("hh:mm") :
    province === "Manitoba" ? moment(newFullDateAndTime).subtract(1, 'hours').format("hh:mm") :
    province === "New Brunswick" ? moment(newFullDateAndTime).add(1, 'hours').format("hh:mm") :
    province === "Newfoundland and Labrador" ? moment(newFullDateAndTime).add(90, 'minutes').format("hh:mm") :
    province === "Northwest Territories" ? moment(newFullDateAndTime).subtract(2, 'hours').format("hh:mm") :
    province === "Nova Scotia" ? moment(newFullDateAndTime).add(1, 'hours').format("hh:mm") :
    province === "Nunavut" ? moment(newFullDateAndTime).subtract(2, 'hours').format("hh:mm") :
    province === "Ontario" ? moment(newFullDateAndTime).format("hh:mm") :
    province === "Prince Edward Island" ? moment(newFullDateAndTime).add(1, 'hours').format("hh:mm") :
    province === "Saskatchewan" ? moment(newFullDateAndTime).subtract(2, 'hours').format("hh:mm") :
    province === "Yukon" ? moment(newFullDateAndTime).subtract(3, 'hours').format("hh:mm") :
    
    moment(newFullDateAndTime, "H:mm").format("hh:mm")
    moment(newFullDateAndTime).format("hh:mm");

    let endTime =
    province === "Alberta" ? moment(newFullDateAndTime).subtract(2, 'hours') :
    province == "British Columbia" ? moment(newFullDateAndTime).subtract(3, 'hours') :
    province === "Manitoba" ? moment(newFullDateAndTime).subtract(1, 'hours') :
    province === "New Brunswick" ? moment(newFullDateAndTime).add(1, 'hours') :
    province === "Newfoundland and Labrador" ? moment(newFullDateAndTime).add(90, 'minutes') :
    province === "Northwest Territories" ? moment(newFullDateAndTime).subtract(2, 'hours') :
    province === "Nova Scotia" ? moment(newFullDateAndTime).add(1, 'hours') :
    province === "Nunavut" ? moment(newFullDateAndTime).subtract(2, 'hours') :
    province === "Ontario" ? moment(newFullDateAndTime) :
    province === "Prince Edward Island" ? moment(newFullDateAndTime).add(1, 'hours') :
    province == "Saskatchewan" ? moment(newFullDateAndTime).subtract(2, 'hours') :
    province === "Yukon" ? moment(newFullDateAndTime).subtract(3, 'hours') :
    moment(newFullDateAndTime);
    endTime = moment(endTime).add(30, "minutes").format("hh:mm A");

    setStartTime1(moment(newFullDateAndTime).format("YYYY-MM-DD HH:mm"));
    setEndTime1(endTime);
    setFullDate1(newFullDateAndTime);
    setUItime(`${startTime} - ${endTime}`);

    const fullDate = dayjs(selectedDateValue).format("dddd MMM DD, YYYY");
    localStorage.setItem("clicked_date", fullDate);
    localStorage.setItem("clicked_time", startTime);
    localStorage.setItem("clicked_time_2", endTime);
    setUIdate(fullDate);
    // console.log("Visitors on Appointment Details page");
    // ReactGA.event({
    //   category: "Visitors on Appointment Details page",
    //   action: "/booking",
    //   label: "Booking",
    // });
  }, [currentViewPort]);

  useEffect(() => {
    if (viewPort !== currentViewPort) {
      setViewPort(currentViewPort);
    }
  }, [currentViewPort]);

  const handleOnChange = (e) => {
    // e.preventDefault();
    const value = e.target.value;

    setPatientDetails({
      ...patientDetails,
      [e.target.name]: value,
    });
  };
  let startDate = "";
  let endDate = "";
  let x12 = "";
  let x16 = "";

  const sendMail = () => {
    //Makes request to backend to send receipt
    axios({
      method: "POST",
      url: `${BASE_URL}/sendReceipt`,
      data: {
        startDate: moment(x12).format("YYYY-MM-DD hh:mm A"),
        endDate: moment(x16).format("YYYY-MM-DD hh:mm A"),
        //	name: selecteduser.name,
        email: patientDetails.email,
        title: "Free",
        description: "30 minutes free",
      },
    })
      .then(() => {
        // console.log("Email Sent");
      })
      .catch((err) => {
        // console.log("Sending Email ERR: ", err);
      });
  };

  const createMeeting = (
    startDate,
    endDate,
    selecteduser,
    appointmentType,
    id
  ) => {
    let dDate = new Date();
    firebase
      .firestore()
      .collection("appointments")
      .add({
        eid: Date.now(),
        startDate: startDate,
        endDate: endDate,
        description: "30 Minutes Free",
        selecteduser: selecteduser,
        meetingId: id,
        type: appointmentType,
        // email: patientDetails.email,
        //phoneNumber: patientDetails.phoneNumber,
        currentPhyx: "posture",
      })
      .then(function (docRef) {
        // console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        // console.error("Error adding document: ", error);
      });
  };

  const handleDirect = (e) => {
    setSubmit(true);
    e.preventDefault();
    localStorage.setItem("UIdate", UIdate);
    localStorage.setItem("startTime1", startTime1);
    localStorage.setItem("selectedDateValue", selectedDateValue);
    localStorage.setItem("patientDetails", JSON.stringify(patientDetails));
    
    // history.push('/appointment-booking/thank-you')
    setTimeout(() => {
      localStorage.setItem("show", true);
      history.push('/appointment-booking/sign-up')
      window.location.reload();
    }, 3000);
    ReactGA.event({
      category: "Clicked on Make Appointment button",
      action: "/booking",
      label: "Booking",
    });
  };

  const getComboA = (selectObject) => {
    var value = selectObject.value;
    // console.log(value);
  }

  // console.log(selectedOption);
  // console.log(localStorage.getItem("CompanyName"));

  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile
          UIdate={UIdate}
          UItime={UItime}
          handleDirect={handleDirect}
          handleOnChange={handleOnChange}
          submit={submit}
          handleClose={handleClose}
          BookingClickedStatus={BookingClickedStatus}
          BookingClickedStatusFunction={BookingClickedStatusFunction}
        />
      ) : (
        <div>
          <div
            style={
              {
                textAlign: "left",
                paddingLeft: "60px"
              }
            }
          >
            <button
              type="button"
              style={{
              }}
              className="text-sm"
              onClick={() => {
                // BookingClickedStatusFunction();
                handleClose();
              }}
            >
              <div className="fixed flex justify-center items-center">
                <span><strong> &lt; Back</strong></span>
              </div>
            </button>
          </div>
          <div className="fixed inset-0 bg-white flex justify-center items-top">
            <div
              className="bg-white relative flex flex-col text-phyxable-black"
              style={{}}
            >
              <h2 className=" ProximaNova ftSz16 cAlign">{ i18next.t(`APPOINTMENT_DETAILS`) }</h2>
              <p className="ProximaNova ftSz7 cAlign mtop-1">{UIdate}</p>
              <p className="ProximaNova ftSz7 cAlign mtop-1">{UItime}</p>
              <p className="ProximaNova ftSz13 cAlign mtop-04">
                { i18next.t(`FREE_CONSULTATION`) }
              <span className="font-normal"> - 30 mins</span>
              </p>
              <form onSubmit={handleDirect}>
                {/* <div className="mb-6">
              <p className="font-bold text-lg mb-2">Phone Number</p>
              <input type="text" className="text-md rounded-md bg-gray-300 p-3 flex-1 w-full"
                placeholder="000-000-0000" name={"phoneNumber"} onChange={handleOnChange} required />
            </div> */}
                {/* <div className="mb-6">
              <p className="font-bold text-lg mb-2">Email</p>
              <input type="text" className="text-md rounded-md bg-gray-300 p-3 flex-1 w-full"
                placeholder="user@gmail.com" name={"email"} onChange={handleOnChange} required />
            </div> */}
                <div className=" mb-16">
                  <p className="ProximaNova ftSz5 lAlign">{ i18next.t(`REASON_FOR_BOOKING`) }</p>
                  <textarea
                    rows="4"
                    className="text-sm rounded-md bg-gray-300 p-3 flex-1 w-full"
                    onChange={handleOnChange}
                    name={"reason"}
                    placeholder={ i18next.t(`PLEASE_SHARE_ANYTHING_THAT_WILL_HELP`) }
                    required
                  />
                </div>
                <div className="mb-16">
                  <p className="ProximaNova ftSz5 lAlign">{ i18next.t(`IS_YOUR_COMPANY_REGISTERED`) }</p>
                  <Select
                    required
                    className="font-medium"
                    defaultValue={selectedOption}
                    isClearable={false}
                    placeholder={<div>{ i18next.t(`SELECT_ONE_OPTION`) }</div>}
                    isSearchable={false}
                    styles={colorStyles}
                    options={companiesOptions.map(({ key: value, value: label, disabled: isDisabled, ...options }) => (
                      { value, label, isDisabled, ...options }
                    ))}
                    onChange={selectedOption => { setSelectedOption(selectedOption.value); localStorage.setItem("CompanyName", selectedOption.value) }}
                  />
                  {/* <select Required className = "companies w-full" 
                    placeholder = {companiesOptions[0]}
                    value={selectedOption}
                    onChange={e => {setSelectedOption(e.target.value); localStorage.setItem("CompanyName",e.target.value)}}>
                    {companiesOptions.map(o => (
                      <option disabled= {o.disabled} key={o.key} value={o.key}>{o.value}</option>
                    ))}
              </select> */}
                </div>

                <div className="flex justify-center">
                  <button
                    disabled={submit}
                    style={{
                      backgroundColor: "#ff5a66",
                      color:'white',
                      borderRadius: 40 + `px`,
                    }}
                    type="submit"
                    className=" bg-phyxable-blue text-white px-8 py-3 "
                  >
                    {submit ? "Loading..." : "Make Appointment"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Mobile = ({
  UIdate,
  UItime,
  handleDirect,
  handleOnChange,
  submit,
  handleClose,
}) => {

  const [selectedOption, setSelectedOption] = useState();

  return (
    <div className="fixed inset-0  flex justify-center items-center ">
      <div
        className=" relative flex flex-col bg-white rounded-lg py-8 px-16 text-phyxable-black boxShadowBooking"
        style={{
          height: "89%",
          overflow: "auto",
          width: "100%",
          marginTop: "16%",
        }}
      >
        <h2 className=" ProximaNova ftSz7 cAlign">{ i18next.t(`APPOINTMENT_DETAILS`) }</h2>
        <p className="ProximaNova ftSz5 cAlign mtop-04">Free Consultation</p>
        <p className="ProximaNova ftSz5 cAlign mtop-1">{UIdate}</p>
        <p className="ProximaNova ftSz5 cAlign mtop-1">{UItime}</p>

        <form onSubmit={handleDirect}>
          <div className=" mb-1">
            <p className="ProximaNova ftSz5 lAlign">{ i18next.t(`REASON_FOR_BOOKING`) }</p>
            <textarea
              rows="4"
              style={{ width: 100 + `%` }}
              className="text-sm rounded-md bg-gray-300 p-3 flex-1 w-full"
              onChange={handleOnChange}
              name={"reason"}
              placeholder="Please share anything that will help the Practitioner prepare for your session"
              required
            />
          </div>
          <div className="mb-1">
            <p className="ProximaNova ftSz5 lAlign">Is your company registered with Phyxable?</p>
            <Select
              required
              className="font-medium"
              defaultValue={selectedOption}
              isClearable={false}
              placeholder={<div>{ i18next.t(`SELECT_ONE_OPTION`) }</div>}
              isSearchable={false}
              styles={colorStyles}
              options={companiesOptions.map(({ key: value, value: label, disabled: isDisabled, ...options }) => (
                { value, label, isDisabled, ...options }
              ))}
              onChange={selectedOption => { setSelectedOption(selectedOption.value); localStorage.setItem("CompanyName", selectedOption.value) }}
            />
            {/* <select Required className = "companies w-full" 
                    placeholder = {companiesOptions[0]}
                    value={selectedOption}
                    onChange={e => {setSelectedOption(e.target.value); localStorage.setItem("CompanyName",e.target.value)}}>
                    {companiesOptions.map(o => (
                      <option disabled= {o.disabled} key={o.key} value={o.key}>{o.value}</option>
                    ))}
              </select> */}
          </div>
          <div className="flex justify-center">
            <button
              disabled={submit}
              style={{
                backgroundColor: "#ff5a66",
                color:'white',
                borderRadius: 40 + `px`,
              }}
              type="submit"
              className=" bg-phyxable-blue text-white px-8 py-3 "
            >
              {submit ? "Loading..." : "Make Appointment"}
            </button>
          </div>
        </form>
        <button
          type="button"
          style={{
            position: "absolute",
            color: "#363B44",
            top: "0rem",
            right: "1rem",
          }}
          className="text-5xl font-light cursor-pointer"
          onClick={handleClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width,
});
const mapDispatchToProps = (dispatch) => ({
  onSetCurrentViewPort: (data) =>
    dispatch({ type: "CURRENT_VIEWPORT_SET", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingClicked);
