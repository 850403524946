import React, { useState, useEffect } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styles from './style.module.css';
import Popup from './Popup/index';

const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState(null);
  const [buttonText, setButtonText] = useState('Loading...');
  const [disabled, setDisabled] = useState(true);
  const [buttonStyle, setButtonStyle] = useState({
    width: '80%',
    backgroundColor: 'grey',
  });
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const getSubscriptionCode = () => {
    fetch('/get-subscription-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(res => {
        setSubscriptionCode(res.code);
      });
  };

  useEffect(() => {
    if (subscriptionCode === null) return;
    setDisplayPopup(true);
  }, [subscriptionCode]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      sessionStorage.removeItem('clientSecret');

      switch (paymentIntent.status) {
        case 'succeeded':
          getSubscriptionCode();
          break;
        case 'processing':
          alert('Your payment is processing.');
          break;
        case 'requires_payment_method':
          alert('Your payment was not successful, please try again!');
          break;
        default:
          alert('Something went wrong!!');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async e => {
    e.preventDefault();
    stripeInProgress();

    if (email === '' || firstName === '' || lastName === '') {
      alert('Please enter your email address, first and last name!');
      stripeReady();
      return;
    }

    fetch('/stripe-new-customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        name: `${firstName} ${lastName}`,
        optionPlan: sessionStorage.getItem('optionPlan'),
      }),
    })
      .then(res => res.json())
      .then(res => console.log(res));

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://phyxable.com/lifeworks',
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      alert(error.message);
    } else {
      alert('An unexpected error occured!');
      console.error(error);
    }
  };

  const stripeReady = () => {
    setButtonText('Subscribe');
    setDisabled(false);
    setButtonStyle({ width: '80%' });
  };

  const stripeInProgress = () => {
    setButtonText('Loading...');
    setDisabled(true);
    setButtonStyle({
      width: '80%',
      backgroundColor: 'grey',
    });
  };

  return (
    <>
      <form className={styles.stripeForm} onSubmit={handleSubmit}>
        <div className={styles.rowLayout}>
          <input
            type='text'
            placeholder='First name'
            onChange={e => setFirstName(e.target.value)}
            className={styles.nameInput}
          />
          <input
            type='text'
            placeholder='Last name'
            onChange={e => setLastName(e.target.value)}
            className={styles.nameInput}
          />
        </div>
        <input
          type='email'
          placeholder='Email address'
          className={styles.stripeInput}
          onChange={e => setEmail(e.target.value)}
        />
        <PaymentElement onReady={element => stripeReady()} />
        <div className={styles.stripeDisclaimer}>
          By subscribing, you agree to our Terms & Conditions and permit
          Phyxable to charge your default card or another card on file.
        </div>
        <div className={styles.stripeButtonContainer}>
          <button className='button' style={buttonStyle} disabled={disabled}>
            {buttonText}
          </button>
        </div>
      </form>
      <Popup showPopup={displayPopup} subCode={subscriptionCode} />
    </>
  );
};

export default StripeForm;
