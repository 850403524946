import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styles from './style.module.css';
import Section1Image from './assets/marathon.png';
import StripeLogo from './assets/stripe_logo.png';
import Step1 from './assets/step1.svg';
import Step2 from './assets/step2.svg';
import Step3 from './assets/step3.svg';
import Step4 from './assets/step4.svg';
import BackArrow from './assets/back_arrow.svg';
import StripeForm from './StripeForm';

// const stripePromise = loadStripe('pk_test_nuzEMRxJjxd4og2Qy0rJlQ8k');
const stripePromise = loadStripe('pk_live_0PuwvCaqspu1PDFD1DNOPL7x');

const LearnToRunUI = ({ scrollToPayment }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showStripe, setShowStripe] = useState(false);
  const [regularPrice, setRegularPrice] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);
  const [optionPlan, setOptionPlan] = useState(null);

  useEffect(() => {
    const _clientSecret = sessionStorage.getItem('clientSecret');

    if (!_clientSecret) {
      sessionStorage.removeItem('regularPrice');
      sessionStorage.removeItem('discountedPrice');
      sessionStorage.removeItem('optionPlan');
      return;
    }

    setShowStripe(true);
    setStripeOptions({
      clientSecret: _clientSecret,
    });
    setIsLoading(true);
    setClientSecret(_clientSecret);
    setRegularPrice(sessionStorage.getItem('regularPrice'));
    setDiscountedPrice(sessionStorage.getItem('discountedPrice'));
    setOptionPlan(sessionStorage.getItem('optionPlan'));
  }, []);

  useEffect(() => {
    if (clientSecret === null) return;

    setStripeOptions({
      clientSecret: clientSecret,
    });

    sessionStorage.setItem('clientSecret', clientSecret);
  }, [clientSecret]);

  const planChoosen = price => {
    setIsLoading(true);
    let planPrice;
    if (price === 40) {
      setRegularPrice('83.88');
      setDiscountedPrice('40.00');
      sessionStorage.setItem('regularPrice', '71.88');
      sessionStorage.setItem('discountedPrice', '71.88');
      sessionStorage.setItem('optionPlan', 'Runner Program');
      setOptionPlan('Runner Program');
      planPrice = 7188;
    } else {
      setRegularPrice('828.00');
      setDiscountedPrice('414.00');
      sessionStorage.setItem('regularPrice', '828.00');
      sessionStorage.setItem('discountedPrice', '414.00');
      sessionStorage.setItem('optionPlan', 'Practitioner Guided');
      setOptionPlan('Practitioner Guided');
      planPrice = 7188;
    }

    fetch('https://www.phyxable.com/lifeworks-payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ price: planPrice }),
    })
      .then(res => res.json())
      .then(res => setClientSecret(res.clientSecret));

    setShowStripe(true);
  };

  const closeStripe = () => {
    sessionStorage.removeItem('clientSecret');
    setClientSecret(null);
    setStripeOptions(null);
    setShowStripe(false);
    setOptionPlan(null);
    sessionStorage.removeItem('regularPrice');
    sessionStorage.removeItem('discountedPrice');
    sessionStorage.removeItem('optionPlan');
  };

  const planComponent = () => {
    return (
      <>
        {/* Left Panel */}
        {/* <div className={styles.InfoContainer}> */}
          <div className={styles.cardContainer}>
            {/* <div className={styles.bestValuetitle}>Best Value</div> */}
            <div className={styles.leftCardTitle} style={{ marginTop: '45px' }}>
              Runner's Program
            </div>
            <div className={styles.price}>
              {/* <span className={styles.cancelPrice}>$6.99</span> */}
              <span className={styles.offerPrice}>$5.99/Month</span>
            </div>
            <div style={{ fontSize: '14px' }}>*Paid annually</div>
            <div className={styles.listContainer}>
              <ul className={styles.list}>
                <li>Access to all self-guided programs</li>
                <br />
                <li>Daily Phyxes to stay pain free</li>
                <br />
                <li>Track your individual progress</li>
                <br />
                <li>
                  Add a 1 on 1 live session with a practitioner for an
                  additional $65.00 per session (billable through insurance
                  plan)
                </li>
                <br />
                <li>
                  Get 4 in-app messages a month to chat directly to a
                  practitioner
                </li>
              </ul>
            </div>
            <div className={styles.stripeButtonContainer}>
              <button
                className='button'
                style={{ width: '80%' }}
                onClick={() => planChoosen(40)}
              >
                Choose plan
              </button>
            </div>
          </div>
          <div className={styles.OfferDisclaimer} style={{ color: 'white' }}>
            *Additional practitioner sessions at $65.00 per session
          </div>
        {/* </div> */}
        {/* Right Panel */}
        {/* <div className={styles.InfoContainer}>
          <div className={styles.cardContainer}>
            <div className={styles.bestValuetitle}>Best Value</div>
            <div className={styles.leftCardTitle}>Practitioner Guided</div>
            <div className={styles.price}>
              <span className={styles.cancelPrice}>$69.00</span>
              <span className={styles.offerPrice}>$34.50/Month</span>
            </div>
            <div style={{ fontSize: '14px' }}>*Paid annually</div>
            <div className={styles.listContainer}>
              <ul className={styles.list}>
                <li>Access to all self-guided programs</li>
                <br />
                <li>Daily Phyxes to stay pain free</li>
                <br />
                <li>Track your individual progress</li>
                <br />
                <li>
                  Meet your practitioner once a month for a live 1 on 1 session
                </li>
                <br />
                <li>
                  Talk to your practitioner through unlimited in-app messaging
                </li>
                <br />
                <li>Customized treatment programming</li>
              </ul>
            </div>
            <div className={styles.stripeButtonContainer}>
              <button
                className='button'
                style={{ width: '80%' }}
                onClick={() => planChoosen(414)}
              >
                Choose plan
              </button>
            </div>
          </div>
          <div className={styles.OfferDisclaimer}>
            *Additional practitioner sessions at $65.00 per session
          </div>
        </div> */}
      </>
    );
  };

  const stripeComponent = () => {
    return (
      <div className={styles.StripeContainer}>
        <div className={styles.stripeCardContainer}>
          <div className={styles.rightCardTitle}>
            <div className={styles.backArrow} onClick={closeStripe}>
              <img src={BackArrow} height={20} />
              <div style={{ marginLeft: '5px' }}>Back</div>
            </div>
            Subscribe
          </div>
          <img src={StripeLogo} className={styles.stripeLogo} />
          <div className={styles.priceCard}>
            <span className={styles.priceCardTitle}>Runner's Program</span>
            <div className={styles.bestValueCard}>BEST VALUE</div>
            <div>
              <span className={styles.offerPrice}>$71.88/Year</span>
            </div>
          </div>
          {isLoading && clientSecret !== null && stripeOptions !== null ? (
            <Elements
              options={stripeOptions}
              stripe={stripePromise}
              onReady={element => console.log('Ready')}
            >
              <StripeForm />
            </Elements>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      {/* Section 1 */}
      <div className={styles.section1Container}>
        {/* Left Panel */}
        <div className={styles.textContainer}>
          <div className={styles.section1Heading}>
            Learn To Run
            <br />
            With
            <br />
            Phyxable
          </div>
          <div className={styles.section1Para}>
          We’ve created 10 weeks worth of virtual <br/>
          physical therapy programs that will complement <br/>
          the training you do before your runs, and help you rejuvenate afterwards.
          </div>
          <div className={styles.section1Button}>
            <button className='button' onClick={scrollToPayment}>
              Subscribe
            </button>
          </div>
        </div>
        {/* Right Panel */}
        <div className={styles.section1ImageContainer}>
          <img src={Section1Image} className={styles.section1Image} />
        </div>
      </div>
      {/* Section 2 */}
      <div className={styles.section2Container} id='stripeContainer'>
        {showStripe ? stripeComponent() : planComponent()}
      </div>
      {/* Section 3 */}
      <div className={styles.section3Container}>
        <div className={styles.section3Title}>How it works</div>
        <div className={styles.section3RightImage}>
          <div className={styles.section3LeftTextContainer}>
            <div className={styles.section3LeftStepTitle}>
              STEP 1 <br />
              Log in
            </div>
            <div className={styles.section3LeftStepPara}>
            Log in to your account and you will arrive at <br/>
            a dashboard with programs, messaging and a Call feature
            </div>
          </div>
          <div className={styles.section3RightImageContainer}>
            <img src={Step1} />
          </div>
        </div>
        <div className={styles.section3LeftImage}>
          <div className={styles.section3RightTextContainer}>
            <div className={styles.section3RightStepTitle}>
              STEP 2 <br /> Change Programs
            </div>
            <div className={styles.section3RightStepPara}>
            Navigate to programs once you are in your account. Once you are on this page you can switch to the Runner’s Program.
            </div>
          </div>
          <div className={styles.section3LeftImageContainer}>
            <img src={Step4} />
          </div>
        </div>
        <div className={styles.section3RightImage}>
          <div className={styles.section3LeftTextContainer}>
            <div className={styles.section3LeftStepTitle}>
              STEP 3 <br />
              Start Your Sessions
            </div>
            <div className={styles.section3LeftStepPara}>
            Once you’ve selected the runner’s program 
            as your default Phyx, you can start your sessions before and after runs
            </div>
          </div>
          <div className={styles.section3RightImageContainer}>
            <img src={Step3} />
          </div>
        </div>
        <div className={styles.section3LeftImage}>
          <div className={styles.section3RightTextContainer}>
            <div className={styles.section3RightStepTitle}>
              STEP 4 <br />
              Free Discovery Call
            </div>
            <div className={styles.section3RightStepPara}>
            You can use the Call feature to book a free consult with one of our practitioners if you require a more guided approach.
            </div>
          </div>
          <div className={styles.section3LeftImageContainer}>
            <img src={Step2} />
          </div>
        </div>
        <div className={styles.stripeButtonContainer}>
          <button className='button' onClick={scrollToPayment}>
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default LearnToRunUI;
