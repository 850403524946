export default function JaneApp() {

  const uri = process.env.REACT_APP_JANE_APP_URL;
  console.log(uri);

  const getStartedPressed = () => {
    window.open(uri, '_blank', 'noopener');
  };

  return (
    <div style={{ height: '600px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <button className='button' onClick={getStartedPressed}>
        Press here to book
      </button>
    </div>
  );
}