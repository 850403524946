import React, { Component } from "react";
import IMG_1 from "../../assets/home/patient-1.png";
import IMG_2 from "../../assets/home/patient-2.png";

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const arr = [
  {
    id: 1,
    image: IMG_1,
    patient: "Justin F.",
    about: "ABOUTPATIENT1",
    story: "STORY1",
  },
  {
    id: 2,
    image: IMG_2,
    patient: "Jade C.",
    about: "ABOUTPATIENT2",
    story: "STORY2",
  },
];

export default class PatientStories extends Component {
  
  state = {
    index: 0,
    selected: arr[0],
  };

  nextImage = () =>
    this.setState((prevState) => {
      const newIndex =
        prevState.index < arr.length - 1 ? prevState.index + 1 : 0;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });

  previousImage = () => 
    this.setState((prevState) => {
      const newIndex =
        prevState.index === 0 ? arr.length - 1 : prevState.index - 1;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });
    
  render = () => (
    <div>
      <div>
        <div className="responsive-container">
          <div className="left">
            <h1>{ i18next.t(`PATIENT_STORIES`) }</h1>
            <h4 className="dark">{this.state.selected.patient}</h4>
            <p className="regular">{ i18next.t(this.state.selected.about) }</p>
            <h2 className="regular">{ i18next.t(this.state.selected.story) }</h2>
            <div className="padding-top-1rem">
              <button className="next-button" onClick={this.previousImage}>
                &lt;
              </button>
              <button className="next-button" onClick={this.nextImage}>
                &gt;
              </button>
            </div>
          </div>
          <div className="right">
            <img
              className="patient-img"
              src={this.state.selected.image}
              alt="patient"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PatientStories = withTranslation()(PatientStories);
