import React from 'react';

export const Insurers = () => {
  return(
  <div className="center">
  <h1>Participating Insurers</h1>
    <div className="padding-top-2rem" />
      <div className="insurers">
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_Canada_life_EN_47db55e177.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_manulife_en_logo_7631e2368d.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_sunlife_675bd843a2.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_claimsecure_en_logo_1fdf3a2bf6.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/da_townley_logo_ea75f5a778.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_desjardins_en_logo_6e6d1b3927.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_ia_en_logo_6b11da4ce1.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_johnson_logo_8613551c44.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_johnston_group_9dacb85b8a.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_bpa_logo_green_a741db3277.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_ccwu_logo_7fb0056579.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_ccgip_en_logo_3fdcd29900.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_cinup_logo_430d992fef.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_cowan_logo_be85a6475c.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_First_Canadian_logo_e63269d579.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_GMS_logo_a89d983a5e.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_group_health_logo_b4f3cb97dd.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_group_source_logo_e342c8194b.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_local_183_logo_c2f0c0c6c7.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_local_506_logo_6ace8683e4.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_manion_logo_df4de65468.png" />
        <img src="https://phyxable-blogs.s3.amazonaws.com/th_insurer_maximum_benefit_en_logo_2826ec2509.png" />
      </div>
  </div>
  );
};