import React, { useState, useEffect } from "react";
import "./Header.css";
import { connect } from "react-redux";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Link, useLocation } from "react-router-dom";
import useViewport from "../../helpers/useViewPort";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import DropdownMenu from "../DropdownMenu/index.jsx";

function Header(props) {

  const { t, i18n } = useTranslation();
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(false);

  const { width } = useViewport();
  const mobileBreakpoint = 501;
  const tabletBreakpoint = 1025;
  const laptopBreakpoint = 1440;

  useEffect(() => {
    if (width !== currentWidth) {
      setCurrentWidth(width);
    }
  }, [width]);

  useEffect(() => {
    if (currentWidth) {
      if (currentWidth < mobileBreakpoint) {
        if (props.currentViewPort !== "MOBILE") {
          props.onSetCurrentViewPort("MOBILE");
        }
      } else if (currentWidth < tabletBreakpoint) {
        if (props.currentViewPort !== "TABLET") {
          props.onSetCurrentViewPort("TABLET");
        }
      } else if (currentWidth < laptopBreakpoint) {
        if (props.currentViewPort !== "LAPTOP") {
          props.onSetCurrentViewPort("LAPTOP");
        }
      } else {
        if (props.currentViewPort !== "DESKTOP") {
          props.onSetCurrentViewPort("DESKTOP");
        }
      }
    }
  }, [currentWidth]);

  let location = useLocation();
  location = location.pathname;

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const navOptions = [
    { id: 1, name: "HOW_IT_WORKS", url: "/how-it-works" },
    { id: 2, name: "FOR_CORPORATIONS", url: "/corporations" },
    { id: 3, name: "FIND_PRACTITIONERS", url: "/search-practitioners" },
    // { id: 4, name: "CASE_STUDY", url: "/case-study"}
  ];
  const navOptionsMobile = [
    { id: 1, name: "HOME", url: "/" },
    { id: 2, name: "SIGN_IN", url: "https://app.phyxable.com" },
    { id: 3, name: "ABOUT_US", url: "/about-us" },
    { id: 4, name: "HOW_IT_WORKS", url: "/how-it-works" },
    { id: 5, name: "CORPORATIONS", url: "/corporations" },
    // { id: 6, name: "CASE_STUDY", url: "/case-study"},
    { id: 6, name: "FIND_PRACTITIONERS", url: "/search-practitioners" },
    { id: 7, name: "BLOG", url: "/blog" },
    { id: 8, name: "F.A.Q", url: "/faq" },
  ];
  const navOptionsMobileSmall = [
    { id: 8, name: "TERMS_OF_USE", url: "/terms-of-use" },
    { id: 9, name: "PRIVACY_POLICY", url: "/privacy-policy" },
  ];

  const navWhite = [
    "/",
    "/about-us",
    "/practitioners",
    "/blog",
  ];

  const WhiteHeader = styled.a`
    color: #ffffff;
  `;
  const BlackHeader = styled.a`
    color: #000000;
  `;

  return (
    location === "/corporatewellnesstrial" || location === "/corporatewellnessmonthtrial" ? 
    <></>:
    <div style={{ width: 100 + `vw` }}>
      <header
        className={
          props.currentViewPort === "TABLET" ? "Header tabletGrid" : "Header"
        }
      >
        <div className="dFlex w-90per jSpace mrAuto mlAuto flexAlCenter">
            <Link to="/">
              <img
                src={require("../../assets/logo/logo.svg")}
                className={
                  props.currentViewPort === "TABLET" ? "LogoTablet" : "Logo"
                }
                alt="logo"
              />
            </Link>
            <div className="lang__mobile__dropdown">
              <DropdownMenu
                changeLang={() => this.changeLang()}
              />
            </div>
            <button onClick={toggleNav} className="Menu ">
              <div className={isNavVisible ? "change bars" : "bars "}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </button>
        </div>

        <div
          className="overlay"
          style={{ width: isNavVisible ? 100 + `%` : null }}
        >
          <div className="dFlex jSpaceB flexAlCenter">
            <div className="w-50per">
              <Link to="/">
                <img
                  src={require("../../assets/logo/logo.svg")}
                  className="Logo mbottom-15 "
                  alt="logo"
                  onClick={toggleNav}
                />
              </Link>
            </div>
            <div className="w-50per">
              <a className="closebtn" onClick={toggleNav}>
                &times;
              </a>
            </div>
          </div>
          <div
            className="overlay-content w-85per mlAuto mrAuto mtop15"
            style={{
              opacity: isNavVisible ? 1 : 0,
            }}
          >
            {navOptionsMobile.map((nav) =>
              nav.url === location ? (
                <a
                  style={{
                    color: "#FF5A66",
                    fontSize: 25 + "px",
                    fontWeight: 800,
                    transform: isNavVisible
                      ? `translateX(${0}%)`
                      : `translateX(${400}%)`,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </a>
              ) : navWhite.includes(location) ? (
                <a
                  style={{
                    color: isSmallScreen ? "#18214D" : null,
                    fontSize: 25 + "px",
                    fontWeight: 800,
                    transform: isNavVisible
                      ? `translateX(${0}%)`
                      : `translateX(${400}%)`,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </a>
              ) : (
                <a
                  style={{
                    color: isSmallScreen ? "#18214D" : "#000000",
                    fontSize: 25 + "px",
                    fontWeight: 800,
                    transform: isNavVisible
                      ? `translateX(${0}%)`
                      : `translateX(${400}%)`,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </a>
              )
            )}
            <div className="dFlex jStart lAlign ">
              {navOptionsMobileSmall.map((nav) => (
                <a
                  style={{
                    color: isSmallScreen ? "#474A57" : null,
                    fontSize: 14 + "px",
                    fontWeight: 800,
                    transform: isNavVisible
                      ? `translateX(${0}%)`
                      : `translateX(${400}%)`,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </a>
              ))}
            </div>
          </div>
        </div>

        {currentWidth < 700 ? null : (
          <div className="Nav">
            {navOptions.map((nav) =>
              nav.url === location ? (
                <a
                  className="star"
                  style={{
                    color: "#FF5A66",
                    fontSize:
                      props.currentViewPort === "TABLET" ? 12 + `px` : null,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </a>
              ) : navWhite.includes(location) ? (
                <WhiteHeader
                  style={{
                    fontSize:
                      props.currentViewPort === "TABLET" ? 12 + `px` : null,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </WhiteHeader>
              ) : (
                <BlackHeader
                  style={{
                    fontSize:
                      props.currentViewPort === "TABLET" ? 12 + `px` : null,
                  }}
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </BlackHeader>
              )
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.phyxable.com"
            >
              <button className="signin-button">
              { i18next.t("SIGN_IN") }&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8594;
              </button>
            </a>
            <DropdownMenu
              changeLang={() => this.changeLang()}
            />
          </div>
        )}

        {/* <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <nav className="Nav">
            {navOptions.map((nav) =>
              nav.url === location ? (
                <a style={{ color: "red" }} key={nav.id} href={nav.url}>
                  {nav.name}
                </a>
              ) : navWhite.includes(location) ? (
                isSmallScreen ?
                <BlackHeader
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </BlackHeader> :
                <WhiteHeader
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </WhiteHeader>
              ) : (
                isSmallScreen ?
                <WhiteHeader
                  key={nav.id}
                  href={nav.url}
                >
                  { i18next.t(nav.name) }
                </WhiteHeader> :
                <BlackHeader
                  key={nav.id}
                  href={nav.url}
                >
                  {nav.name}
                </BlackHeader>
              )
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.phyxable.com"
            >
              <button className="signin-button">
                Sign-in&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#8594;
              </button>
            </a>
          </nav>
        </CSSTransition> */}
      </header>
    </div>
  );
}
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentViewPort: (data) =>
    dispatch({ type: "CURRENT_VIEWPORT_SET", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);