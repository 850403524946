import React, { useEffect, useState } from 'react';
import './Style.css';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { BsQuestionCircle } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { IoCallOutline } from 'react-icons/io5';
import { BsArrowLeft } from 'react-icons/bs';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Emoji from './emoji.png';
import { Collapse } from 'react-collapse';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const Support = props => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);

  const history = useHistory();

  const aboutUs = () => {
    window.open('https://phyxable.com/about-us');
  };

  const faq = () => {
    window.open('https://phyxable.com/faq');
  };

  const goBack = () => {
    // history.replace('/settings');
  };
  const { t, i18n } = useTranslation();
  const [viewPort, setViewPort] = useState('');
  useEffect(() => {
    window.scroll(0, 0);
    document.title = 'Phyxable | Support';
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);
  return (
    <>
      {viewPort === 'MOBILE' ? (
        <Mobile />
      ) : viewPort === 'TABLET' ? (
        <Tablet />
      ) : viewPort === 'LAPTOP' ? (
        <Laptop />
      ) : (
        <Desktop />
      )}
    </>
  );
};

const Mobile = props => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);

  const history = useHistory();

  const aboutUs = () => {
    window.open('https://phyxable.com/about-us');
  };

  const faq = () => {
    window.open('https://phyxable.com/faq');
  };

  const goBack = () => {
    // history.replace('/settings');
  };

  return (
    <>
      <div className='home-container '></div>

      {/* Cards */}

      <div className='container-support container-support-media'>
        <span className='red-text red-text-media'>
          {i18next.t(`HOW_CAN_WE_HELP`)}
        </span>

        <div className='cards-container cards-container-media'>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoIosInformationCircleOutline size={30} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`ABOUT_US`)}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={aboutUs}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <BsQuestionCircle size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t('FAQ')}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={faq}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <HiOutlineMail size={25} className='icons' />
              <span className='heading-media text-media'>Email</span>
            </div>
            <span className='text text-media'> support@phyxable.com</span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoCallOutline size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`CALL`)}
              </span>
            </div>
            <span className='text-media'>1-855-749-9669</span>
          </div>
        </div>
        <span className='sub-heading sub-heading-media'>
          {i18next.t(`GETING_STARTED`)}
        </span>
        {/* placeholder card */}
        <div className='placeholder-mobile'>
          <div className='left-mobile'>
            <span className='text-media'> {i18next.t(`HOW_APP_WORKS`)}</span>
            <button className='button-tour'>
              {i18next.t(`TAKE_A_VIRTUAL_TOUR`)}
            </button>
          </div>
          <div className='right-mobile'>
            <img src={Emoji} />
          </div>
        </div>
        {/* Quesion segment */}
        <div className='question question-media'>
          <span className='sub-heading sub-heading-media'>
            {i18next.t(`TOP_QUESTIONS`)}
          </span>
          <div
            className='question-card question-card-media'
            onClick={() => setQue1(!que1)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`CANCEL_APPT`)}</span>
              {que1 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que1}>
                <p>{i18next.t(`CANCEL_APPT_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue2(!que2)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_2`)}</span>
              {que2 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que2}>
                <p>{i18next.t(`QUESTION_2_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue3(!que3)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_3`)}</span>
              {que3 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que3}>
                <p>{i18next.t(`QUESTION_3_ANS`)}</p>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Tablet = () => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);

  const history = useHistory();

  const aboutUs = () => {
    window.open('https://phyxable.com/about-us');
  };

  const faq = () => {
    window.open('https://phyxable.com/faq');
  };

  const goBack = () => {
    // history.replace('/settings');
  };

  return (
    <>
      <div className='home-container '></div>

      {/* Cards */}

      <div className='container-support container-support-media'>
        <span className='red-text red-text-media'>
          {i18next.t(`HOW_CAN_WE_HELP`)}
        </span>

        <div className='cards-container cards-container-media'>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoIosInformationCircleOutline size={30} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`ABOUT_US`)}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={aboutUs}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <BsQuestionCircle size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t('FAQ')}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={faq}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <HiOutlineMail size={25} className='icons' />
              <span className='heading-media text-media'>Email</span>
            </div>
            <span className='text text-media'>support@phyxable.com</span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoCallOutline size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`CALL`)}
              </span>
            </div>
            <span className='text text-media'>1-855-749-9669</span>
          </div>
        </div>
        <span className='sub-heading sub-heading-media'>
          {i18next.t(`GETING_STARTED`)}
        </span>
        {/* placeholder card */}
        <div className='placeholder-desktop'>
          <div className='left-desktop'>
            <span className='text-media'> {i18next.t(`HOW_APP_WORKS`)}</span>

            <button className='button-tour'>
              {i18next.t(`TAKE_A_VIRTUAL_TOUR`)}
            </button>
          </div>
          <div className='right-desktop'>
            <img src={Emoji} />
          </div>
        </div>
        {/* Quesion segment */}
        <div className='question question-media'>
          <span className='sub-heading sub-heading-media'>
            {i18next.t(`TOP_QUESTIONS`)}
          </span>
          <div
            className='question-card question-card-media'
            onClick={() => setQue1(!que1)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`CANCEL_APPT`)}</span>
              {que1 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que1}>
                <p>{i18next.t(`CANCEL_APPT_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue2(!que2)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_2`)}</span>
              {que2 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que2}>
                <p>{i18next.t(`QUESTION_2_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue3(!que3)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_3`)}</span>
              {que3 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que3}>
                <p>{i18next.t(`QUESTION_3_ANS`)}</p>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Laptop = () => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);

  const history = useHistory();

  const aboutUs = () => {
    window.open('https://phyxable.com/about-us');
  };

  const faq = () => {
    window.open('https://phyxable.com/faq');
  };

  const goBack = () => {
    // history.replace('/settings');
  };

  return (
    <>
      <div className='home-container '></div>

      {/* Cards */}

      <div className='container-support container-support-media'>
        <span className='red-text red-text-media'>
          {i18next.t(`HOW_CAN_WE_HELP`)}
        </span>

        <div className='cards-container cards-container-media'>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoIosInformationCircleOutline size={30} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`ABOUT_US`)}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={aboutUs}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <BsQuestionCircle size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t('FAQ')}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={faq}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <HiOutlineMail size={25} className='icons' />
              <span className='heading-media text-media'>Email</span>
            </div>
            <span className='text-media'>support@phyxable.com</span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoCallOutline size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`CALL`)}
              </span>
            </div>
            <span className='text-media'>1-855-749-9669</span>
          </div>
        </div>
        <span className='sub-heading sub-heading-media'>
          {i18next.t(`GETING_STARTED`)}
        </span>
        {/* placeholder card */}
        <div className='placeholder-desktop'>
          <div className='left-desktop'>
            <span className='text-media'> {i18next.t(`HOW_APP_WORKS`)}</span>
            <button className='button-tour'>
              {i18next.t(`TAKE_A_VIRTUAL_TOUR`)}
            </button>
          </div>
          <div className='right-desktop'>
            <img src={Emoji} />
          </div>
        </div>
        {/* Quesion segment */}
        <div className='question question-media'>
          <span className='sub-heading sub-heading-media'>
            {i18next.t(`TOP_QUESTIONS`)}
          </span>
          <div
            className='question-card question-card-media'
            onClick={() => setQue1(!que1)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`CANCEL_APPT`)}</span>
              {que1 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que1}>
                <p>{i18next.t(`CANCEL_APPT_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue2(!que2)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_2`)}</span>
              {que2 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que2}>
                <p>{i18next.t(`QUESTION_2_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue3(!que3)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_3`)}</span>
              {que3 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que3}>
                <p>{i18next.t(`QUESTION_3_ANS`)}</p>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Desktop = (state, props) => {
  const [que1, setQue1] = useState(false);
  const [que2, setQue2] = useState(false);
  const [que3, setQue3] = useState(false);

  const history = useHistory();

  const aboutUs = () => {
    window.open('https://phyxable.com/about-us');
  };

  const faq = () => {
    window.open('https://phyxable.com/faq');
  };

  const goBack = () => {
    // history.replace('/settings');
  };

  const buttonPressed = () => {
    window.open('https://app.phyxable.com/on-boarding');
  };
  return (
    <>
      <div className='home-container '></div>

      {/* Cards */}

      <div className='container-support container-support-media'>
        <span className='red-text red-text-media'>
          {i18next.t(`HOW_CAN_WE_HELP`)}
        </span>

        <div className='cards-container cards-container-media'>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoIosInformationCircleOutline size={30} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`ABOUT_US`)}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={aboutUs}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <BsQuestionCircle size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t('FAQ')}
              </span>
            </div>
            <span
              className='readmore text-media'
              style={{ cursor: 'pointer' }}
              onClick={faq}
            >
              {i18next.t(`READ_MORE`)}
            </span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <HiOutlineMail size={25} className='icons' />
              <span className='heading-media text-media'>Email</span>
            </div>
            <span className='text text-media'>support@phyxable.com</span>
          </div>
          <div className='card card-media'>
            <div className='title title-media'>
              <IoCallOutline size={25} className='icons' />
              <span className='heading-media text-media'>
                {i18next.t(`CALL`)}
              </span>
            </div>
            <span className='text-media'>1-855-749-9669</span>
          </div>
        </div>
        <span className='sub-heading sub-heading-media'>
          {i18next.t(`GETING_STARTED`)}
        </span>
        {/* placeholder card */}
        <div className='placeholder-desktop'>
          <div className='left-desktop'>
            <span className='text-media'>{i18next.t(`HOW_APP_WORKS`)}</span>
            <button className='button-tour' onClick={buttonPressed}>
              {' '}
              {i18next.t(`TAKE_A_VIRTUAL_TOUR`)}{' '}
            </button>
          </div>
          <div className='right-desktop'>
            <img src={Emoji} />
          </div>
        </div>
        {/* Quesion segment */}
        <div className='question question-media'>
          <span className='sub-heading sub-heading-media'>
            {i18next.t(`TOP_QUESTIONS`)}
          </span>
          <div
            className='question-card question-card-media'
            onClick={() => setQue1(!que1)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`CANCEL_APPT`)}</span>
              {que1 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que1}>
                <p>{i18next.t(`CANCEL_APPT_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue2(!que2)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_2`)}</span>
              {que2 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que2}>
                <p>{i18next.t(`QUESTION_2_ANS`)}</p>
              </Collapse>
            </div>
          </div>
          <div
            className='question-card question-card-media'
            onClick={() => setQue3(!que3)}
          >
            <div className='question-view'>
              <span className='red-text'>{i18next.t(`QUESTION_3`)}</span>
              {que3 ? (
                <MdKeyboardArrowUp size={25} />
              ) : (
                <MdKeyboardArrowDown size={25} />
              )}
            </div>
            {/* Collapse view */}
            <div className='collapse-view'>
              <Collapse isOpened={que3}>
                <p>{i18next.t(`QUESTION_3_ANS`)}</p>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(Support);
