import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import './Corporations.css';
import SmoothScrolling from './SmoothScrolling';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import PDF from '../../assets/Corporations/casestudy.pdf';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function Corporations(props) {
  const { t, i18n } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [viewPort, setViewPort] = useState('');
  const [form, setForm] = useState('bookDemo');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [corporation, setCorporation] = useState('');

  //Comment
  let location = useLocation();
  location = location.search;

  useEffect(() => {
    document.title = 'Phyxable | Corporations';
    ReactGA.initialize('UA-148659511-2', { debug: true });
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
    if (location.replace('?key=', '') === 'casestudy') {
      SmoothScrolling.scrollTo('casestudy');
    }
  }, [props.currentViewPort]);

  const canBeSubmitted = () => {
    return name.length > 0 && email.length > 0 && corporation.length > 0;
  };

  const sendMail = () => {
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const corporation = document.getElementById('corporation').value;
    const message = document.getElementById('message')
      ? document.getElementById('message').value
      : '';

    if (form === 'bookDemo') {
      if (name !== '' && email !== '' && corporation !== '' && message !== '') {
        setLoading(true);
        axios({
          method: 'POST',
          url: 'http://localhost:3000/corporations-mail',
          data: {
            name: name,
            email: email,
            corporation: corporation,
            message: message,
          },
        }).then(response => {
          if (response.data.msg === 'success') {
            ReactGA.event({
              category: 'Corporate Demos',
              action: '/booking',
              label: 'Booking',
            });
            ReactGA.event({
              category: '<Booking>',
              action: '<Corporate_Demo_Booking>',
              label: '<Corporate_Demo>',
            });
            document.getElementById('name').value = '';
            document.getElementById('email').value = '';
            document.getElementById('corporation').value = '';
            document.getElementById('message').value = '';
            setLoading(false);
            alert(
              'Thanks for reaching out!  We will be in touch via email or phone call shortly”'
            );
          } else if (response.data.msg === 'fail') {
            alert('Message failed to send.');
          }
        });
      } else {
        alert('Fill out data');
      }
    } else {
      if (name !== '' && email !== '' && corporation !== '') {
        ReactGA.event({
          category: '<Downloads>',
          action: '<Case_Study_Downloads>',
          label: '<Case_Study>',
        });
        document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('corporation').value = '';
        setName('');
        setCorporation('');
        setEmail('');
        axios({
          method: 'POST',
          url: 'https://practitioner.phyxable.com/sendFeedback',
          data: {
            name: name,
            email: email,
            corporation: corporation,
            type: 'CASE STUDY DOWNLOAD',
          },
        }).then(response => {
          if (response.data.msg === 'success') {
            console.log('success');
          }
        });
      } else {
        alert('Fill out data');
      }
    }
  };

  const googleAnalytics = () => {};

  const isEnabled = canBeSubmitted();

  return (
    <>
      {viewPort === 'MOBILE' ? (
        <Mobile
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : viewPort === 'TABLET' ? (
        <Tablet
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : viewPort === 'LAPTOP' ? (
        <Laptop
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      ) : (
        <Desktop
          sendMail={sendMail}
          isLoading={isLoading}
          form={form}
          setForm={setForm}
          setName={setName}
          setEmail={setEmail}
          setCorporation={setCorporation}
          isEnabled={isEnabled}
        />
      )}
    </>
  );
}
const Mobile = props => {
  return (
    <div>
      <div>
        <div className='bg-mobile-mobile cAlign '>
          <div className='home-container'>
            <h1 className='priColor interRegular ftSz13'>
              {i18next.t(`HAPPIER_EMPLOYEES_HIGHER_PRODUCTIVITY`)}{' '}
            </h1>
          </div>
          <div className='primaryBackground p6'>
            <p className='white  ProximaNova'>
              {i18next.t(`PHYXABLE_IS_A_BETTER_WAY_TO_EMPOWER_CONT`)}
            </p>
            <p className='white  ProximaNova'>
              {i18next.t(`BOOK_A_DEMO_WITH_US`)}
            </p>
            <div className='padding-top-1rem' />
            <div className='cAlign'>
              <button
                onClick={() => {
                  props.setForm('bookDemo');
                  SmoothScrolling.scrollTo('book');
                }}
                className='button '
              >
                {i18next.t(`BOOK_A_DEMO`)}
              </button>
            </div>
          </div>
        </div>{' '}
        <center className='padding-top-2rem'>
          <video
            style={{
              maxWidth: '100%',
              borderRadius: '10px',
              boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
            }}
            muted
            autoPlay
            loop
            controls
            src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
          />
        </center>
        <div className='our-value'>
          <h1 className='cAlign ftSz10'>{i18next.t(`OUR_VALUE`)}</h1>
          <ul className=' cAlign dFlex flexCol'>
            <div className=' dFlex flexRow jSpaceB w-100per mlAuto mrAuto '>
              <li className='p1 w-100per mlAuto mrAuto'>
                <img
                  style={{ width: '150px', margin: '-48px 0' }}
                  src={require('../../assets/Corporations/78.svg')}
                />
                <h1 className='ftSz10'>{i18next.t(`7_8`)}</h1>
                <p className='interRegular ftSz2'>{i18next.t(`TIME_SAVED`)}</p>
              </li>
              <li className='p1 w-100per mlAuto mrAuto'>
                <img
                  style={{ width: '150px', margin: '-48px 0' }}
                  src={require('../../assets/Corporations/48.svg')}
                />
                <h1 className='ftSz10'>{i18next.t(`4_8`)}</h1>
                <p className='interRegular ftSz2'>{i18next.t(`MSK_SAVINGS`)}</p>
              </li>
            </div>
            <div className=' dFlex flexRow jSpaceB w-100per mlAuto mrAuto '>
              <li className='p1 w-100per mlAuto mrAuto'>
                <img
                  style={{ width: '150px', margin: '-48px 0' }}
                  src={require('../../assets/Corporations/103.svg')}
                />
                <h1 className='ftSz10'>{i18next.t(`1_03`)}</h1>
                <p className='interRegular ftSz2'>
                  {i18next.t(`BETTER_ENGAGEMENT`)}
                </p>
              </li>
              <li className='p1 w-100per mlAuto mrAuto'>
                <img
                  style={{ width: '150px', margin: '-48px 0' }}
                  src={require('../../assets/Corporations/6.svg')}
                />
                <h1 className='ftSz10'>{i18next.t(`6_DAYS`)}</h1>
                <p className='interRegular ftSz2'>{i18next.t(`DAYS_LOST`)}</p>
              </li>
            </div>
          </ul>
        </div>
        <div>
          <div className='cAlign w-90per mrAuto mlAuto'>
            <div className='mtop15  '>
              <div>
                <h1 className='secColor1 interRegular ftSz11 '>
                  {i18next.t(`PHYXABLE_MAKES_EMPLOYEE_WELLNESS_A_BREEZE`)}
                </h1>
              </div>
              <p className='interRegular ftSz4 ftWght1 secColor1'>
                {i18next.t(`NOT_ONLY_DO_WE_HELP_YOUR_EMPLOYEES_CONT`)}
              </p>
            </div>
            <div
              className='mrAuto mlAuto w-90per'
              style={{ padding: '2rem 0' }}
            >
              <img
                className='max-100'
                src={require('../../assets/Corporations/Corporations_wellness.svg')}
                alt='Posture_Program'
              />
            </div>
          </div>

          <div className='mrAuto mlAuto w-90per cAlign'>
            <div>
              <h2 className='secColor1 interRegular ftSz5'>
                {i18next.t(`DIRECT_MESSAGING`)}
              </h2>
              <p className='interRegular ftSz3 ftWght1 secColor1'>
                {i18next.t(`MESSAGE_YOUR_PRAC`)}
              </p>
            </div>
            <div>
              <h2 className='secColor1 interRegular ftSz5'>
                {i18next.t(`10000_SIMULTANEOUS_CALLS`)}
              </h2>
              <p className='interRegular ftSz3 ftWght1 secColor1'>
                {i18next.t(`LARGE_CORPORATIONS`)}
              </p>
            </div>
            <div>
              <h2 className='secColor1 interRegular ftSz5'>
                {i18next.t(`EMPLOYEE_FOCUSED_SOLUTIONS`)}
              </h2>
              <p className='interRegular ftSz3 ftWght1 secColor1'>
                {i18next.t(`WE_HELP_EMPLOYEE_FEEL_BETTER_FASTER_CONT`)}
              </p>
            </div>
            <div>
              <h2 className='secColor1 interRegular ftSz5'>
                {i18next.t(`COVERED_BY_INSURANCE`)}
              </h2>
              <p className='interRegular ftSz3 ftWght1 secColor1'>
                {i18next.t(
                  `WE_WORK_WITH_ALL_THE_MAJOR_INSURANCE_PROVIDERS_CONT`
                )}
              </p>
            </div>
            <div>
              <h2 className='secColor1 interRegular ftSz5'>
                {i18next.t(`TRACK_YOUR_CONPANYS_PROGRESS`)}
              </h2>
              <p className='interRegular ftSz3 ftWght1 secColor1'>
                {i18next.t(
                  `SEE_HOW_YOUR_TEAM_IS_DOING_RIGHT_WITHIN_THE_APP_CONT`
                )}
              </p>
            </div>
          </div>

          <div
            id='casestudy'
            className='pink-container'
            style={{ margin: '2rem 0', padding: '0' }}
          >
            <div>
              <div>
                <h1 style={{ margin: '0.5rem 0' }}>
                  {i18next.t(`THE_COST_OF_PAIN`)}
                </h1>
                <p>
                  {i18next.t(`UNDERSTANDING_THE_IMPACT_OF`)}
                  <br /> {i18next.t(`MUSCULOSKELETAL_PAIN_ON_THE_EMPLOYER`)}
                </p>
                <div style={{ padding: '2rem 0' }}>
                  <button
                    onClick={() => {
                      props.setForm('caseStudy');
                      SmoothScrolling.scrollTo('book');
                    }}
                    className='button'
                    style={{ background: 'white', color: '#ff5a66' }}
                  >
                    {i18next.t(`DOWNLOAD_CASE_STUDY`)}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='w-90per mrAuto mlAuto mtop15 mbottom10'>
            <div id='book' className='book-demo-mobile cAlign'>
              <h2 className='ProximaNova blackColor ftSz3'>
                {props.form === 'bookDemo'
                  ? i18next.t('BOOK_A_DEMO')
                  : i18next.t('CASE_STUDY_DOWNLOAD')}
              </h2>
              <div className='w-90per mrAuto mlAuto'>
                <p className='ProximaNova ftSz1 '>
                  {props.form === 'bookDemo'
                    ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                    : i18next.t('PLEASE_PROVIDE_US_WITH')}
                </p>
              </div>
              <div style={{ display: 'inline-block' }}>
                <input
                  id='name'
                  className='input-book-demo'
                  placeholder={i18next.t('NAME')}
                  onChange={e => props.setName(e.target.value)}
                />
                <input
                  id='email'
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                  onChange={e => props.setEmail(e.target.value)}
                />
                <input
                  id='corporation'
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                  onChange={e => props.setCorporation(e.target.value)}
                />
                {props.form === 'bookDemo' && (
                  <textarea
                    id='message'
                    data-enable-grammarly='false'
                    style={{ height: '16rem' }}
                    className='input-book-demo'
                    placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                  />
                )}
              </div>
              <div className='padding-top-1rem'>
                {props.form === 'bookDemo' ? (
                  <button className='button' onClick={props.sendMail}>
                    {props.isLoading ? 'Loading...' : i18next.t('BOOK_A_DEMO')}
                  </button>
                ) : (
                  <Link
                    to={PDF}
                    rel='noopener noreferrer'
                    target='_blank'
                    download
                  >
                    <button
                      disabled={!props.isEnabled}
                      className='button'
                      onClick={props.sendMail}
                    >
                      Download
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Tablet = props => {
  return (
    <div>
      <div>
        <div className='bg-tablet-corporation'>
          <div className='home-container'>
            <h1 className='ftSz15'>
              {i18next.t(`HAPPIER_EMPLOYEES`)}
              <br />
              {i18next.t(`HIGHER_PRODUCTIVITY`)}{' '}
            </h1>
            <p className='ftSz1 interRegular ftWght1'>
              {i18next.t(`PHYXABLE_IS_A_BETTER_WAY_TO_EMPOWER_CONT`)}
              <br /> {i18next.t(`BOOK_A_DEMO_WITH_US`)}
            </p>
            <div className='padding-top-1rem' />
            <button
              onClick={() => {
                props.setForm('bookDemo');
                SmoothScrolling.scrollTo('book');
              }}
              className='button interRegular'
              style={{ borderStyle: 'none' }}
            >
              {i18next.t('BOOK_A_DEMO')}
            </button>
          </div>
          <center className='padding-top-4rem'>
            <video
              style={{
                borderRadius: '10px',
                boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
              }}
              height='auto'
              width='100%'
              muted
              autoPlay
              loop
              controls
              src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
            />
          </center>
        </div>

        <div className='our-value-tablet'>
          <h1 className='center'>{i18next.t(`OUR_VALUE`)}</h1>
          <ul className=' dFlex w-90per jSpace flexAlStart mlAuto mrAuto ftSz1'>
            <li className='mrAuto mlAuto w-25per cAlign'>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/103.svg')}
              />
              <h1 className=' ftSz11'>{i18next.t(`1_03`)} </h1>
              <p className='mtop-05'>{i18next.t(`BETTER_ENGAGEMENT`)}</p>
            </li>
            <li className='mrAuto mlAuto w-25per cAlign'>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/78.svg')}
              />
              <h1 className=' ftSz11'>{i18next.t(`7_8`)}</h1>
              <p className='mtop-05'>{i18next.t(`TIME_SAVED`)}</p>
            </li>
            <li className='mrAuto mlAuto w-25per cAlign'>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/48.svg')}
              />
              <h1 className=' ftSz11'>{i18next.t(`4_8`)}</h1>
              <p className='mtop-05'>{i18next.t(`MSK_SAVINGS`)}</p>
            </li>
            <li className='mrAuto mlAuto w-25per cAlign'>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/6.svg')}
              />
              <h1 className=' ftSz11'>{i18next.t(`6_DAYS`)}</h1>
              <p className='mtop-05'>{i18next.t(`DAYS_LOST`)}</p>
            </li>
          </ul>
        </div>

        <div>
          <div className=' dFlex jSpace w-90per mlAuto mrAuto flexAlCenter'>
            <div className='w-50per' style={{ padding: '2rem 0' }}>
              <img
                className='w-100per'
                src={require('../../assets/Corporations/Corporations_wellness.svg')}
                alt='Posture_Program'
              />
            </div>
            <div className='w-50per' style={{ textAlign: 'right' }}>
              <h1 className='dark ftSz15'>
                {i18next.t(`PHYXABLE_MAKES_EMPLOYEE_WELLNESS_A_BREEZE`)}
              </h1>
              <p className='regular interRegular ftWght1'>
                {i18next.t(`NOT_ONLY_DO_WE_HELP_YOUR_EMPLOYEES_CONT`)}
              </p>
            </div>
          </div>

          <div className='dFlex jSpace w-90per mlAuto mrAuto flexAlStart'>
            <div className=' w-3rdPer pTwo cAlign   '>
              <img
                className='grid-items w-90per '
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='cost'
              />
              <h2 className='pink ftSz3'>{i18next.t(`DIRECT_MESSAGING`)}</h2>
              <p className='regular ftSz1 interRegular ftWght1'>
                {i18next.t(`MESSAGE_YOUR_PRAC`)}
              </p>
            </div>
            <div className=' w-3rdPer pTwo cAlign  mlAuto mrAuto '>
              <img
                className='grid-items w-90per '
                src={require('../../assets/Corporations/easy_to_use.svg')}
                alt='easy_to_use'
              />
              <h2 className='pink ftSz3'>
                {i18next.t(`10000_SIMULTANEOUS_CALLS`)}
              </h2>
              <p className='regular ftSz1 interRegular ftWght1'>
                {i18next.t(`LARGE_CORPORATIONS`)}
              </p>
            </div>
            <div className=' w-3rdPer pTwo cAlign mlAuto mrAuto'>
              <img
                className='grid-items w-90per'
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink ftSz3'>
                {i18next.t(`EMPLOYEE_FOCUSED_SOLUTIONS`)}
              </h2>
              <p className='regular ftSz1 interRegular ftWght1'>
                {i18next.t(`WE_HELP_EMPLOYEE_FEEL_BETTER_FASTER_CONT`)}
              </p>
            </div>
          </div>

          <div className='dFlex jSpace w-90per mlAuto mrAuto flexAlStart'>
            <div className=' w-3rdPer pTwo cAlign mlAuto mrAuto'>
              <img
                className='grid-items w-90per '
                src={require('../../assets/home/prevent.svg')}
                alt='cost'
              />
              <h2 className='pink ftSz3'>
                {i18next.t(`COVERED_BY_INSURANCE`)}
              </h2>
              <p className='regular ftSz1 interRegular ftWght1'>
                {i18next.t(
                  `WE_WORK_WITH_ALL_THE_MAJOR_INSURANCE_PROVIDERS_CONT`
                )}
              </p>
            </div>
            <div className=' w-3rdPer pTwo cAlign mlAuto mrAuto'>
              <img
                className='grid-items w-90per'
                src={require('../../assets/home/perform.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink ftSz3'>
                {i18next.t(`TRACK_YOUR_CONPANYS_PROGRESS`)}
              </h2>
              <p className='regular ftSz1 interRegular ftWght1'>
                {i18next.t(
                  `SEE_HOW_YOUR_TEAM_IS_DOING_RIGHT_WITHIN_THE_APP_CONT`
                )}
              </p>
            </div>
          </div>

          <div
            id='casestudy'
            className='pink-container'
            style={{ margin: '4rem 0', padding: '0' }}
          >
            <div className='pink-corp-container'>
              <div style={{ padding: '1rem 4rem', textAlign: 'left' }}>
                <h1 style={{ margin: '0.5rem 0' }}>
                  {i18next.t(`THE_COST_OF_PAIN`)}
                </h1>
                <p>
                  {i18next.t(`UNDERSTANDING_THE_IMPACT_OF`)}
                  <br /> {i18next.t(`MUSCULOSKELETAL_PAIN_ON_THE_EMPLOYER`)}
                </p>
                <div style={{ padding: '2rem 0' }}>
                  <button
                    onClick={() => {
                      props.setForm('caseStudy');
                      SmoothScrolling.scrollTo('book');
                    }}
                    className='button'
                    style={{ background: 'white', color: '#ff5a66' }}
                  >
                    {i18next.t(`DOWNLOAD_CASE_STUDY`)}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            id='book'
            className='book-demo-tablet w-90per mlAuto mrAuto mbottom10'
          >
            <div className=' w-90per mlAuto mrAuto'>
              <h2 className='pink cAlign'>
                {props.form === 'bookDemo'
                  ? i18next.t('BOOK_A_DEMO')
                  : i18next.t('CASE_STUDY_DOWNLOAD')}
              </h2>
              <p className='gray cAlign'>
                {props.form === 'bookDemo'
                  ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                  : i18next.t('PLEASE_PROVIDE_US_WITH')}
              </p>
              <div>
                <input
                  id='name'
                  className='input-book-demo-tablet'
                  placeholder={i18next.t('NAME')}
                  onChange={e => props.setName(e.target.value)}
                />
                <input
                  id='email'
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                  onChange={e => props.setEmail(e.target.value)}
                />
                <input
                  id='corporation'
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                  onChange={e => props.setCorporation(e.target.value)}
                />
                {props.form === 'bookDemo' && (
                  <textarea
                    id='message'
                    data-enable-grammarly='false'
                    style={{ height: '16rem' }}
                    className='input-book-demo'
                    placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                  />
                )}
              </div>
              <div className='padding-top-1rem cAlign'>
                {props.form === 'bookDemo' ? (
                  <button className='button' onClick={props.sendMail}>
                    {props.isLoading ? 'Loading...' : i18next.t('BOOK_A_DEMO')}
                  </button>
                ) : (
                  <Link
                    to={PDF}
                    rel='noopener noreferrer'
                    target='_blank'
                    download
                  >
                    <button
                      disabled={!props.isEnabled}
                      className='button'
                      onClick={props.sendMail}
                    >
                      Download
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Laptop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-corporation'>
          <div className='corporation-first'>
            <h1 className='main-font' style={{ marginBottom: '20px' }}>
              {i18next.t(`HAPPIER_EMPLOYEES`)}
              <br />
              {i18next.t(`HIGHER_PRODUCTIVITY`)}{' '}
            </h1>
            <p>{i18next.t(`PHYXABLE_IS_A_BETTER_WAY_TO_EMPOWER_CONT`)}</p>
            <p>{i18next.t(`BOOK_A_DEMO_WITH_US`)}</p>
            <div className='padding-top-1rem' />
            <button
              onClick={() => {
                props.setForm('bookDemo');
                SmoothScrolling.scrollTo('book');
              }}
              className='button'
            >
              {i18next.t('BOOK_A_DEMO')}
            </button>
          </div>
          <center className='corporation-second'>
            <video
              style={{
                borderRadius: '10px',
                boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
              }}
              height='auto'
              width='100%'
              muted
              autoPlay
              loop
              controls
              src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
            />
          </center>
        </div>

        <div className='our-value center'>
          <h1>{i18next.t(`OUR_VALUE`)}</h1>
          <ul className='nav-our-value'>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/103.svg')}
              />
              <h1>{i18next.t(`1_03`)}</h1>
              <p>{i18next.t(`BETTER_ENGAGEMENT`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/78.svg')}
              />
              <h1>{i18next.t(`7_8`)}</h1>
              <p>{i18next.t(`TIME_SAVED`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/48.svg')}
              />
              <h1>{i18next.t(`4_8`)}</h1>
              <p>{i18next.t(`MSK_SAVINGS`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/6.svg')}
              />
              <h1>{i18next.t(`6_DAYS`)}</h1>
              <p>{i18next.t(`DAYS_LOST`)}</p>
            </li>
          </ul>
        </div>

        <div className='Content'>
          <div className='responsive-container'>
            <div className='left' style={{ padding: '2rem 0' }}>
              <img
                className='max-100'
                src={require('../../assets/Corporations/Corporations_wellness.svg')}
                alt='Posture_Program'
              />
            </div>
            <div className='right' style={{ textAlign: 'right' }}>
              <h1 className='dark'>
                {i18next.t(`PHYXABLE_MAKES`)}
                <br />
                {i18next.t(`EMPLOYEE_WELLNESS`)}
                <br />
                {i18next.t(`A_BREEZE`)}
              </h1>
              <p className='regular'>
                {i18next.t(`NOT_ONLY_DO_WE_HELP_YOUR_EMPLOYEES_CONT`)}
              </p>
            </div>
          </div>

          <div className='pain-container' style={{ paddingBottom: '0' }}>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='cost'
              />
              <h2 className='pink'>{i18next.t(`DIRECT_MESSAGING`)}</h2>
              <p className='regular'>{i18next.t(`MESSAGE_YOUR_PRAC`)}</p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/easy_to_use.svg')}
                alt='easy_to_use'
              />
              <h2 className='pink'>{i18next.t(`10000_SIMULTANEOUS_CALLS`)}</h2>
              <p className='regular'>{i18next.t(`LARGE_CORPORATIONS`)}</p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink'>
                {i18next.t(`EMPLOYEE_FOCUSED_SOLUTIONS`)}
              </h2>
              <p className='regular'>
                {i18next.t(`WE_HELP_EMPLOYEE_FEEL_BETTER_FASTER_CONT`)}
              </p>
            </div>
          </div>

          <div className='pain-container' style={{ padding: '0' }}>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/home/prevent.svg')}
                alt='cost'
              />
              <h2 className='pink'>{i18next.t(`COVERED_BY_INSURANCE`)}</h2>
              <p className='regular'>
                {i18next.t(
                  `WE_WORK_WITH_ALL_THE_MAJOR_INSURANCE_PROVIDERS_CONT`
                )}
              </p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/home/perform.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink'>
                {i18next.t(`TRACK_YOUR_CONPANYS_PROGRESS`)}
              </h2>
              <p className='regular'>
                {i18next.t(
                  `SEE_HOW_YOUR_TEAM_IS_DOING_RIGHT_WITHIN_THE_APP_CONT`
                )}
              </p>
            </div>
          </div>

          <div
            id='casestudy'
            className='pink-container'
            style={{ marginBottom: '2rem', padding: '0' }}
          >
            <div className='pink-corp-container'>
              <div style={{ padding: '1rem 4rem', textAlign: 'left' }}>
                <h1 style={{ margin: '0.5rem 0' }}>
                  {i18next.t(`THE_COST_OF_PAIN`)}
                </h1>
                <p style={{ fontSize: '1.2rem' }}>
                  {i18next.t(`UNDERSTANDING_THE_IMPACT_OF`)}
                  <br /> {i18next.t(`MUSCULOSKELETAL_PAIN_ON_THE_EMPLOYER`)}
                  musculoskeletal pain on the employer.
                </p>
                <div style={{ padding: '2rem 0' }}>
                  <button
                    onClick={() => {
                      props.setForm('caseStudy');
                      SmoothScrolling.scrollTo('book');
                    }}
                    className='button'
                    style={{ background: 'white', color: '#ff5a66' }}
                  >
                    {i18next.t(`DOWNLOAD_CASE_STUDY`)}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id='book' className='book-demo'>
            <h2 className='pink'>
              {props.form === 'bookDemo'
                ? i18next.t('BOOK_A_DEMO')
                : i18next.t('CASE_STUDY_DOWNLOAD')}
            </h2>
            <p className='gray'>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
            <div style={{ display: 'inline-block' }}>
              <input
                id='name'
                className='input-book-demo'
                placeholder={i18next.t('NAME')}
                onChange={e => props.setName(e.target.value)}
              />
              <input
                id='email'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                onChange={e => props.setEmail(e.target.value)}
              />
              <input
                id='corporation'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                onChange={e => props.setCorporation(e.target.value)}
              />
              {props.form === 'bookDemo' && (
                <textarea
                  id='message'
                  data-enable-grammarly='false'
                  style={{ height: '16rem' }}
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                />
              )}
            </div>
            <div className='padding-top-1rem'>
              {props.form === 'bookDemo' ? (
                <button className='button' onClick={props.sendMail}>
                  {props.isLoading ? 'Loading...' : i18next.t('BOOK_A_DEMO')}
                </button>
              ) : (
                <Link
                  to={PDF}
                  rel='noopener noreferrer'
                  target='_blank'
                  download
                >
                  <button
                    disabled={!props.isEnabled}
                    className='button'
                    onClick={props.sendMail}
                  >
                    Download
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Desktop = props => {
  return (
    <div>
      <div>
        <div className='bg-desktop-corporation'>
          <div class='corporation-first left-align'>
            <h1
              className='main-font'
              style={{
                fontSize: 54 + `px`,
                marginBottom: 30 + `px`,
                lineHeight: 1,
              }}
            >
              {i18next.t(`HAPPIER_EMPLOYEES`)}
              <br />
              {i18next.t(`HIGHER_PRODUCTIVITY`)}{' '}
            </h1>
            <p style={{ fontSize: 18 + `px` }}>
              {i18next.t(`PHYXABLE_IS_A_BETTER_WAY_TO_EMPOWER_CONT`)}
            </p>
            <p style={{ fontSize: 18 + `px` }}>
              {i18next.t(`BOOK_A_DEMO_WITH_US`)}
            </p>
            <div className='padding-top-1rem' />
            <button
              onClick={() => {
                props.setForm('bookDemo');
                SmoothScrolling.scrollTo('book');
              }}
              className='button'
              style={{
                fontSize: 22 + `px`,
                width: 310 + `px`,

                height: 60 + `px`,
              }}
            >
              {i18next.t('BOOK_A_DEMO')}
            </button>
          </div>
          <center className='corporation-second'>
            <video
              style={{
                borderRadius: '10px',
                boxShadow: '0px 9px 18px rgba(24, 33, 77, 0.2)',
              }}
              width='650'
              height='auto'
              muted
              autoPlay
              loop
              controls
              src='https://phyxable.s3.amazonaws.com/Phyxable+-+How+it+works+video+Youtube+version.mp4'
            />
          </center>
        </div>

        <div className='our-value center'>
          <h1>{i18next.t(`OUR_VALUE`)}</h1>
          <ul className='nav-our-value'>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/103.svg')}
              />
              <h1>{i18next.t(`1_03`)}</h1>
              <p>{i18next.t(`BETTER_ENGAGEMENT`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/78.svg')}
              />
              <h1>{i18next.t(`7_8`)}</h1>
              <p>{i18next.t(`TIME_SAVED`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/48.svg')}
              />
              <h1>{i18next.t(`4_8`)}</h1>
              <p>{i18next.t(`MSK_SAVINGS`)}</p>
            </li>
            <li>
              <img
                style={{ width: '150px', margin: '-48px 0' }}
                src={require('../../assets/Corporations/6.svg')}
              />
              <h1>{i18next.t(`6_DAYS`)}</h1>
              <p>{i18next.t(`DAYS_LOST`)}</p>
            </li>
          </ul>
        </div>

        <div className='Content'>
          <div className='responsive-container'>
            <div className='left' style={{ padding: '2rem 0' }}>
              <img
                className='max-100'
                src={require('../../assets/Corporations/Corporations_wellness.svg')}
                alt='Posture_Program'
              />
            </div>
            <div className='right' style={{ textAlign: 'right' }}>
              <h1 className='dark'>
                {i18next.t(`PHYXABLE_MAKES_EMPLOYEE_WELLNESS_A_BREEZE`)}
              </h1>
              <p className='regular ftSz4' style={{ paddingLeft: 8 + `px` }}>
                {i18next.t(`NOT_ONLY_DO_WE_HELP_YOUR_EMPLOYEES_CONT`)}
              </p>
            </div>
          </div>

          <div className='pain-container' style={{ paddingBottom: '0' }}>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='cost'
              />
              <h2 className='pink'>{i18next.t(`DIRECT_MESSAGING`)}</h2>
              <p className='regular ftSz4'>{i18next.t(`MESSAGE_YOUR_PRAC`)}</p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/easy_to_use.svg')}
                alt='easy_to_use'
              />
              <h2 className='pink'>{i18next.t(`10000_SIMULTANEOUS_CALLS`)}</h2>
              <p className='regular ftSz4'>{i18next.t(`LARGE_CORPORATIONS`)}</p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/Corporations/employee_sketch.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink'>
                {i18next.t(`EMPLOYEE_FOCUSED_SOLUTIONS`)}
              </h2>
              <p className='regular ftSz4'>
                {i18next.t(`WE_HELP_EMPLOYEE_FEEL_BETTER_FASTER_CONT`)}
              </p>
            </div>
          </div>

          <div className='pain-container' style={{ padding: '0' }}>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/home/prevent.svg')}
                alt='cost'
              />
              <h2 className='pink'>{i18next.t(`COVERED_BY_INSURANCE`)}</h2>
              <p className='regular ftSz4'>
                {i18next.t(
                  `WE_WORK_WITH_ALL_THE_MAJOR_INSURANCE_PROVIDERS_CONT`
                )}
              </p>
            </div>
            <div className='pain-wrap-corporation'>
              <img
                className='grid-items img-200'
                src={require('../../assets/home/perform.svg')}
                alt='employee_sketch'
              />
              <h2 className='pink'>
                {i18next.t(`TRACK_YOUR_CONPANYS_PROGRESS`)}
              </h2>
              <p className='regular ftSz4'>
                {i18next.t(
                  `SEE_HOW_YOUR_TEAM_IS_DOING_RIGHT_WITHIN_THE_APP_CONT`
                )}
              </p>
            </div>
          </div>

          <div
            id='casestudy'
            className='pink-container'
            style={{ marginBottom: '2rem', padding: '0' }}
          >
            <div className='pink-corp-container'>
              <div style={{ padding: '1rem 4rem', textAlign: 'left' }}>
                <h1 style={{ margin: '0.5rem 0' }}>
                  {i18next.t(`THE_COST_OF_PAIN`)}
                </h1>
                <p style={{ fontSize: '1.2rem' }}>
                  {i18next.t(`UNDERSTANDING_THE_IMPACT_OF`)}
                  <br /> {i18next.t(`MUSCULOSKELETAL_PAIN_ON_THE_EMPLOYER`)}
                </p>
                <div style={{ padding: '2rem 0' }}>
                  <button
                    onClick={() => {
                      props.setForm('caseStudy');
                      SmoothScrolling.scrollTo('book');
                    }}
                    className='button'
                    style={{ background: 'white', color: '#ff5a66' }}
                  >
                    {i18next.t(`DOWNLOAD_CASE_STUDY`)}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div id='book' className='book-demo'>
            <h2 className='pink'>
              {props.form === 'bookDemo'
                ? i18next.t('BOOK_A_DEMO')
                : i18next.t('CASE_STUDY_DOWNLOAD')}
            </h2>
            <p className='gray ftSz4'>
              {props.form === 'bookDemo'
                ? i18next.t('OUR_CORPORATE_WELLNESS_PROGRAM_IS_FREE_CONT')
                : i18next.t('PLEASE_PROVIDE_US_WITH')}
            </p>
            <div style={{ display: 'inline-block' }}>
              <input
                id='name'
                className='input-book-demo'
                placeholder={i18next.t('NAME')}
                onChange={e => props.setName(e.target.value)}
              />
              <input
                id='email'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_EMAIL')}
                onChange={e => props.setEmail(e.target.value)}
              />
              <input
                id='corporation'
                className='input-book-demo'
                placeholder={i18next.t('INPUT_BOOK_DEMO_CORPORATION')}
                onChange={e => props.setCorporation(e.target.value)}
              />
              {props.form === 'bookDemo' && (
                <textarea
                  id='message'
                  data-enable-grammarly='false'
                  style={{ height: '16rem' }}
                  className='input-book-demo'
                  placeholder={i18next.t('INPUT_BOOK_DEMO_HELP')}
                />
              )}
            </div>
            <div className='padding-top-1rem'>
              {props.form === 'bookDemo' ? (
                <button className='button' onClick={props.sendMail}>
                  {props.isLoading ? 'Loading...' : i18next.t('BOOK_A_DEMO')}
                </button>
              ) : (
                <Link
                  to={PDF}
                  rel='noopener noreferrer'
                  target='_blank'
                  download
                >
                  <button
                    disabled={!props.isEnabled}
                    className='button'
                    onClick={props.sendMail}
                  >
                    Download
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(Corporations);
