import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export default function BookingUK() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll(0, 0);
    document.title = 'Phyxable | Booking';
    ReactGA.initialize('UA-148659511-2', { debug: true });
    ReactGA.event({
      category: 'Visitors on LP UK',
      action: '/booking',
      label: 'Booking',
    });
  }, []);

  const handleReactGAClick = () => {
    ReactGA.event({
      category: 'Clicks on CTA UK',
      action: '/booking',
      label: 'Booking',
    });
  };

  return (
    <div className='Content padding-2rem'>
      <div className='padding-top-4rem'>
        <h1 className='dark'>
          Fix Your Pain
          <br />
          Anytime, Anywhere
        </h1>
        <p className='gray'>
          One-on-one Video Consultation Online
          <br />
          with UK’s Best Physiotherapist
        </p>

        <div className='padding-top-1rem' />
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://calendly.com/phyxable/free-video-consultation-uk?utm_campaign=uk&utm_medium=cpc&utm_source=google&utm_term=button1'
        >
          <button className='button' onClick={handleReactGAClick}>
            Book First Free Consultation
          </button>
        </a>

        <div className='padding-top-2rem' />

        <img
          className='max-100'
          src={require('../../assets/booking/phyxable+online+physiotherapy+consultation.png')}
          alt='booking'
        />

        <div className='padding-top-2rem' />

        <h1 className='dark'>How it works</h1>

        <div className='left-align gray'>
          <strong>1. Book Appointment</strong>
          <p className='padding-left-1rem'>
            Pick the time slot convenient for you.
          </p>
          <strong>2. Meet Your Practitioner Online</strong>
          <p className='padding-left-1rem'>
            Join the meeting via Phone / Tablet / Computer from anywhere.
          </p>
          <strong>3. Get your diagnosis & treatment online</strong>
          <p className='padding-left-1rem'>
            Phyxable physiotherapists will diagnose you and recommend you the
            personalized treatment and exercise program. Phyxable would track
            you to 100% recovery.
          </p>
        </div>

        <div className='padding-top-2rem' />

        <h1 className='dark'>Why Phyxable?</h1>

        <div className='responsive-container'>
          <div style={{ padding: '4rem 0' }} className='right'>
            <img
              className='max-100'
              src={require('../../assets/booking/Why+Phyxable.png')}
              alt='Landingpage_mockup1'
            />
          </div>
          <div className='left gray'>
            <p>1. UK’s selected, vetted and the best Physiotherapists.</p>
            <p>
              2. We cover only private patients for now. If you’ve insurance, we
              bill you directly. You can claim the money with your insurer.
            </p>
            <p>3. Track record of being 92% effective MSK Physiotherapy.</p>
            <p>4.Stay safe at home. Save the travel time.</p>
          </div>
        </div>

        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://calendly.com/phyxable/free-video-consultation-uk?utm_campaign=uk&utm_medium=cpc&utm_source=google&utm_term=button1'
        >
          <button className='button' onClick={handleReactGAClick}>
            Book First Free Consultation
          </button>
        </a>

        <div className='padding-top-2rem' />

        <h1 className='dark'>Meet our Award-winning Medical Team</h1>

        <div className='pain-container gray'>
          <div className='pain-wrap-corporation'>
            <img src={require('../../assets/booking/scott.png')} alt='scott' />
            <h4 className='gray'>SCOTT</h4>
            <p>
              PHYSIOTHERAPIST
              <br />
              Low back and hip
            </p>
          </div>
          <div className='pain-wrap-corporation'>
            <img
              src={require('../../assets/booking/afshin.png')}
              alt='afshin'
            />
            <h4 className='gray'>AFSHIN</h4>
            <p>
              CHIROPRACTOR
              <br />
              Ankles and knees
            </p>
          </div>
          <div className='pain-wrap-corporation'>
            <img src={require('../../assets/booking/steph.png')} alt='steph' />
            <h4 className='gray'>DR. STEPH ND</h4>
            <p>
              NATUROPATH
              <br />
              Stress, nutrition and immune support.
            </p>
          </div>
          <div className='pain-wrap-corporation'>
            <img src={require('../../assets/booking/tony.png')} alt='tony' />
            <h4 className='gray'>TONY</h4>
            <p>
              PHYSIOTHERAPIST
              <br />
              Shoulder and neck.
            </p>
          </div>
        </div>

        <div className='padding-top-2rem' />

        <h1 className='dark'> How we've helped</h1>

        <div className='responsive-container'>
          <div style={{ textAlign: 'center' }} className='right'>
            <img
              className='max-100'
              src={require('../../assets/booking/jade.png')}
              alt='jade'
            />
            <p>
              “My lower back pain has greatly reduced over the last 2 video
              consultations. It's insanely refreshing to feel little to no pain
              whilst sitting at my desk. ”
            </p>
            <h4> - JADE C.</h4>
          </div>
          <div style={{ textAlign: 'center' }} className='left'>
            <img
              className='max-100'
              src={require('../../assets/booking/justin.png')}
              alt='justin'
            />
            <p>
              “I’ve struggled with lower back pain all of my life. Having an
              easy way to deal with it at home has been game changing. In
              addition to helping my back, Phyxable has also helped increase my
              overall physical health.”
            </p>
            <h4> - JUSTIN F.</h4>
          </div>
        </div>

        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://calendly.com/phyxable/free-video-consultation-uk?utm_campaign=uk&utm_medium=cpc&utm_source=google&utm_term=button1'
        >
          <button className='button' onClick={handleReactGAClick}>
            Book First Free Consultation
          </button>
        </a>

        <div className='padding-top-2rem' />

        <h1 className='dark'>Pricing</h1>

        <div className='responsive-container'>
          <div className='right'>
            <img
              className='max-100'
              src={require('../../assets/booking/consult.png')}
              alt='consult'
            />
          </div>
          <div className='left gray' style={{ padding: '0', width: '30vw' }}>
            <h4>FIRST CONSULTATION FREE</h4>
            <p>Your first consultation with Phyxable is completely free.</p>
            <p>No credit card required. Get your diagnosis done now!</p>
          </div>
        </div>

        <div className='responsive-container'>
          <div className='right'>
            <img
              className='max-100'
              src={require('../../assets/booking/insurance.png')}
              alt='insurance'
            />
          </div>
          <div className='left gray' style={{ padding: '0', width: '30vw' }}>
            <h4>WE COVER BOTH PRIVATE & INSURANCE PATIENTS</h4>
            <p>
              We bill you directly. You can get the money reimbursed from your
              insurer.
            </p>
          </div>
        </div>

        <div className='responsive-container'>
          <div className='right'>
            <img
              className='max-100'
              src={require('../../assets/booking/pay.png')}
              alt='pay'
            />
          </div>
          <div className='left gray' style={{ padding: '0', width: '30vw' }}>
            <h4>PAY PER SESSION</h4>
            <p>
              For private physiotherapy, you can pay per session using a credit
              card or debit card.
            </p>
          </div>
        </div>

        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://calendly.com/phyxable/free-video-consultation-uk?utm_campaign=uk&utm_medium=cpc&utm_source=google&utm_term=button1'
        >
          <button className='button' onClick={handleReactGAClick}>
            Book First Free Consultation
          </button>
        </a>

        <p className='gray'>
          Any question? Feel free to call us at +1 855-749-9669
        </p>
      </div>
    </div>
  );
}
