import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { generateTimes } from "../helpers/helpers";
import styles from "./Booking.module.css";
import moment from "moment";
import firebase from "@firebase/app";
import { app } from "firebase";
import axios from "axios";
import Item from "antd/lib/list/Item";
import { memoize as memo, map, sortedUniq } from "lodash";
import VASLoading from "../../../../../assets/booking/Vas-Loading_1.gif";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const isSameBooking = (prevProps, nextProps) => {
  return !!((nextProps.date === prevProps.date) && (nextProps.date2 === prevProps.date2) && (nextProps.unavailable === prevProps.unavailable) && (nextProps.appint === prevProps.appint) && (nextProps.currentViewPort === prevProps.currentViewPort));
}

const BookingItem = React.memo(({
  odd = true,
  date = new Date(),
  onClick,
  date2,
  unavailable = [],
  appint,
  appintFlatten,
  currentViewPort,
  item,
  BookingClickedStatusFunction,
  province
}) => {
  const [viewPort, setViewPort] = useState("");

  let sortedDate = [];
  let checks = false;
  let sDate = [];
  let timeSlots = generateTimes(9, 19);
  const day = `${date}`.split(" ")[0].toUpperCase();
  const dayNum = `${date}`.split(" ")[2];
  let dayName = moment().format("ddd").toLocaleUpperCase();
  const dayNumber = moment().format("DD");
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function loaders() {
      //getAllMeetings()
    }
    loaders();
  }, []);

  useEffect(() => {
    if (viewPort !== currentViewPort) {
      setViewPort(currentViewPort);
    }
  }, [currentViewPort]);

  const { t, i18n } = useTranslation();
  
  const passers = memo((timex, datex) => {
    let x = false;
    let time = "";
    if (timex.split(" ")[1].trim() === "am") {
      time = timex.split(" ")[0];
      time = moment(timex, ["h:mm 'A"]).format("HH:mm");
    } else {
      let newTime = timex.split(" ")[0];
      time = moment(timex, ["h:mm 'A"]).format("HH:mm");
    }
    let correctedTime = moment(datex)
      .set({ hour: time.split(":")[0], minute: time.split(":")[1] })
      .toDate();
    let timers = moment(correctedTime).format("YYYY-MM-DD HH:mm");


    /*appint.forEach((item) => {
      let sd = moment(item.startDate).format("YYYY-MM-DD HH:mm");
      let ed = moment(item.endDate).format("YYYY-MM-DD HH:mm");
      moment(timers).isSame(sd) ? (x = true) : (x = x);
    });*/
    return appintFlatten[timers] ? true : false;

    // if (appint && appint.length > 0) {

    //   return appint && appint.findIndex(x => moment(timers).isBetween(moment(x.startDate, x.endDate))) > 0
    //   // return appoints && appoints.findIndex(x => x.startDate === timers) > 0 || appoints.findIndex(x => moment(x.startDate, "YYYY-MM-DD hh:mm").isBefore( moment(timers, "YYYY-MM-DD hh:mm")) )

    // } else {
    //   return null;
    // }
  });

  const passer2 =  memo((timex, datex) => {
    let time = "";
    if (timex.split(" ")[1].trim() === "am") {
      time = timex.split(" ")[0];
      time = moment(timex, ["h:mm 'A"]).format("HH:mm");
    } else {
      let newTime = timex.split(" ")[0];
      time = moment(timex, ["h:mm 'A"]).format("HH:mm");
    }
    let correctedTime = moment(datex)
      .set({ hour: time.split(":")[0], minute: time.split(":")[1] })
      .toDate();
    let timers = moment(correctedTime).format("YYYY-MM-DD HH:mm");

    var appoints = JSON.parse(localStorage.getItem("apps"));

    let actualDate = moment(datex).format("YYYY-MM-DD");
    let dateAndTime = actualDate + " " + timex;
    let timeNow = moment().format("YYYY-MM-DD HH:mm");

    return moment(dateAndTime, "YYYY-MM-DD HH:mm").isBefore(
      timeNow,
      "YYYY-MM-DD HH:mm"
    );
  });

  const formatTime = memo((timex, datex) => {
    let actualDate = moment(datex);
    const [hour, minute] = timex.split(':')

    actualDate.set({ hour, minute }).format("YYYY-MM-DD HH:mm")
    let dateAndTime = actualDate;
    return dateAndTime;
  });

  const unavailableStartDates = sortedUniq(map(unavailable, 'starts_at').map(d => moment(d).format('YYYY-MM-DD')));
  const unavailableEndDates = sortedUniq(map(unavailable, 'ends_at').map(d => moment(d).format('YYYY-MM-DD')));
  const unavailableDays = sortedUniq([...unavailableStartDates, ...unavailableEndDates]);
  const unavailableDaysWTime = unavailable.reduce((acc, cur) => {
    const currentDay = moment(cur.starts_at).format('YYYY-MM-DD');
    return { ...acc, [currentDay]: [...(acc[currentDay] || []), { sd: moment(cur.starts_at).format("YYYY-MM-DD HH:mm"), ed: moment(cur.ends_at).format("YYYY-MM-DD HH:mm") }]}
  }, {})

const checkDisabledAlt = (timex, datex) => {
  
  return false;
}

  const checkDisabled = memo((timex, datex) => {
    let x = false;
    let calDate = formatTime(timex, datex);
    let currentDay = moment(datex).format("YYYY-MM-DD");
    // if (currentDay.endsWith('28') && unavailable.length > 100) {
    //   console.warn('28 unavailable', unavailable.length, unavailableDaysWTime[currentDay] || [], unavailable.map(( cur) => {
    //     const currentDay = moment(cur.starts_at).format('YYYY-MM-DD');
    //     return [cur, currentDay];
    //   }, {}));
    // }
    (unavailableDaysWTime[currentDay] || []).forEach(item => {
      if(moment(calDate).isBetween(moment(item.sd), moment(item.ed)) ||
      moment(calDate).isSame(item.sd) ||
      moment(calDate).isSame(item.ed)) {
        x = true;
      }
    })

     /*unavailable.forEach((item) => {
      let sd = moment(item.starts_at).format("YYYY-MM-DD HH:mm");
      let ed = moment(item.ends_at).format("YYYY-MM-DD HH:mm");
      
      let calDate = formatTime(timex, datex);
      if(moment(calDate).isBetween(moment(sd), moment(ed)) ||
      moment(calDate).isSame(sd) ||
      moment(calDate).isSame(ed)) {
        x = true;
      }
    });*/

   
    return x;
  });

  //Retrieve all meetings
  const getAllMeetings = () => {
    const db = firebase.firestore();

    db.collection("appointments")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          //  allMeeting.push(doc.data());
        });
        querySnapshot.forEach(function (doc) {
          // allMeeting.push(doc.data());

          appint.push({
            startDate: moment(doc.data().startDate).format(
              "YYYY-MM-DD hh:mm A"
            ),
            endDate: moment(doc.data().endDate).format("YYYY-MM-DD hh:mm"),
            title: doc.data().description,
            id: doc.data().meetingId,
            user: doc.data().selecteduser,
            // type: item.type
          });
        });
        setTimeout(() => {
          checks = true;
        }, 2000);
      });
  };

  return show==true? (<div><img src={VASLoading}></img>  </div>) : (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile
          dayName={dayName}
          day={day}
          dayNum={dayNum}
          dayNumber={dayNumber}
          timeSlots={timeSlots}
          odd={odd}
          sDate={sDate}
          date2={date2}
          checkDisabled={checkDisabled}
          passers={passers}
          passer2={passer2}
          onClick={onClick}
          item={item}
          BookingClickedStatusFunction={BookingClickedStatusFunction}
          province={province}
        />
      ) : viewPort === "TABLET" ? (
        <NonMobile
          dayName={dayName}
          day={day}
          dayNum={dayNum}
          dayNumber={dayNumber}
          timeSlots={timeSlots}
          odd={odd}
          sDate={sDate}
          date2={date2}
          checkDisabled={checkDisabled}
          passers={passers}
          passer2={passer2}
          onClick={onClick}
          province={province}
        />
      ) : viewPort === "LAPTOP" ? (
        <NonMobile
          dayName={dayName}
          day={day}
          dayNum={dayNum}
          dayNumber={dayNumber}
          timeSlots={timeSlots}
          odd={odd}
          sDate={sDate}
          date2={date2}
          checkDisabled={checkDisabled}
          passers={passers}
          passer2={passer2}
          onClick={onClick}
          province={province}
        />
      ) : (
        <NonMobile
          dayName={dayName}
          day={day}
          dayNum={dayNum}
          dayNumber={dayNumber}
          timeSlots={timeSlots}
          odd={odd}
          sDate={sDate}
          date2={date2}
          checkDisabled={checkDisabled}
          passers={passers}
          passer2={passer2}
          onClick={onClick}
          province={province}
        />
      )}
    </>
  );
}, isSameBooking);

const NonMobile = React.memo(({
  dayName,
  day,
  dayNum,
  dayNumber,
  timeSlots,
  odd,
  sDate,
  date2,
  checkDisabled,
  passers,
  passer2,
  onClick,
  province
}) => {
  return (
    <div
      style={{ backgroundColor: `${odd ? "#d7dee95e" : "#eff2f696"}` }}
      className="flex flex-col px-2 py-4 flex-1"
      style={{
        border:
          dayName === day && dayNum === dayNumber ? "2px solid #FF5A66" : null,
      }}
    >
      {dayName === day && dayNum === dayNumber ? (
        <div
          className="base-item  mlAuto mrAuto lAlign"
          style={{
            alignItems: "center",
            justifyContent: "flex-start",
            margin: 0,
            padding: 10 + `px`,
            fontSize: 8 + `px`,
          }}
        >
          <p className="text-sm ">{day}</p>
          <p className="today">{ i18next.t(`TODAY`) }Today</p>
        </div>
      ) : (
        <div
          className=" w-90per  mlAuto mrAuto"
          style={{ paddingBottom: 11.65 + `px`, paddingTop: 11.65 + `px` }}
        >
          <p className="text-sm lAlign">{day}</p>
        </div>
      )}

      <div className="w-90per mlAuto mrAuto mtop-15">
        <p className="text-4xl font-bold lAlign ">{dayNum}</p>
      </div>

      <div className="flex flex-col mtop-10">
        {timeSlots.map((item, index) => {
          sDate.push(date2);
          return (
            <button
              type="button"
              key={index}
              className={`cursor-pointer text-phyxable-blue border-2 text-center px-2 font-bold rounded-sm mt-4 py-1 border-phyxable-blue ${styles.time}`}
              // disabled={!item.available} //Check here if time and day matches
              disabled={
                checkDisabled(item.time, date2) || passers(item.time, date2) || passer2(item.time, date2)
              } //Check here if time and day matches
              onClick={() => onClick(item.time, sDate)}
            >
              {/* TODO: replace this with helpers/province functions */}
              {province === "Alberta" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "British Columbia" ? moment(item.time.trim(), "H:mm").subtract(3, 'hours').format("h:mm a") :
               province === "Manitoba" ? moment(item.time.trim(), "H:mm").subtract(1, 'hours').format("h:mm a") :
               province === "New Brunswick" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Newfoundland and Labrador" ? moment(item.time.trim(), "H:mm").add(90, 'minutes').format("h:mm a") :
               province === "Northwest Territories" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "Nova Scotia" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Nunavut" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "Ontario" ? moment(item.time.trim(), "H:mm").format("h:mm a") :
               province === "Prince Edward Island" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Yukon" ? moment(item.time.trim(), "H:mm").subtract(3, 'hours').format("h:mm a") :
               province === "Saskatchewan" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               moment(item.time.trim(), "H:mm").format("h:mm a")
              }
            </button>
          );
        })}
      </div>
    </div>
  );
});

const Mobile = React.memo(({
  dayName,
  day,
  dayNum,
  dayNumber,
  timeSlots,
  odd,
  sDate,
  date2,
  checkDisabled,
  passers,
  passer2,
  onClick,
  item,
  BookingClickedStatusFunction,
  province
}) => {
  return (
    <div
      style={{ backgroundColor: `${odd ? "#d7dee95e" : "#eff2f696"}` }}
      className="w-100per secColor1"
      style={{
        border:
          dayName === day && dayNum === dayNumber ? "1px solid #FF5A66" : null,
        width: 100 + `%`,
      }}
    >
      {dayName === day && dayNum === dayNumber ? (
        <div
          className="base-item  mlAuto mrAuto lAlign"
          style={{
            alignItems: "center",
            justifyContent: "flex-start",
            margin: 0,
            padding: 10 + `px`,
            // fontSize: 8 + `px`,
          }}
        >
          <p className=" lAlign ftSz4">
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {moment(item).format("dddd D ")}
            </span>{" "}
            {moment(item).format("MMMM YYYY")}
          </p>
          <p className="today ftSz1">{ i18next.t(`Today`) }</p>
        </div>
      ) : (
        <div
          className=" w-90per  mlAuto mrAuto"
          style={{ paddingBottom: 11.65 + `px`, paddingTop: 11.65 + `px` }}
        >
          <p className=" lAlign ftSz4">
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {moment(item).format("dddd D ")}
            </span>{" "}
            {moment(item).format("MMMM YYYY")}
          </p>
        </div>
      )}

      <div className="dFlex jSpace mtop-10" style={{ overflowX: "scroll" }}>
        {timeSlots.map((item, index) => {
          sDate.push(date2);

          return (
            <div
              style={{
                marginRight: 1 + `rem`,
                marginLeft: 0,
                paddingLeft: 0,
              }}
            >
              <button
                type="button"
                key={index}
                style={{
                  marginRight: 3 + `rem`,
                  marginLeft: 3 + `rem`,
                  paddingTop: 0.85 + `rem`,
                  marginTop: 1 + `rem`,
                  paddingBottom: 0.85 + `rem`,
                  marginBottom: 1 + `rem`,
                  width: 100 + `%`,
                  backgroundColor: "#fff",
                }}
                className={`cursor-pointer text-phyxable-blue border-2 text-center  font-bold rounded-sm mt-4  border-phyxable-blue ${styles.time}`}
                // disabled={!item.available} //Check here if time and day matches
                disabled={
                  checkDisabled(item.time, date2) || passers(item.time, date2) || passer2(item.time, date2)
                } //Check here if time and day matches
                onClick={() => {
                  BookingClickedStatusFunction();
                  onClick(item.time, sDate);
                }}
              >
              {province === "Alberta" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "British Columbia" ? moment(item.time.trim(), "H:mm").subtract(3, 'hours').format("h:mm a") :
               province === "Manitoba" ? moment(item.time.trim(), "H:mm").subtract(1, 'hours').format("h:mm a") :
               province === "New Brunswick" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Newfoundland and Labrador" ? moment(item.time.trim(), "H:mm").add(90, 'minutes').format("h:mm a") :
               province === "Northwest Territories" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "Nova Scotia" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Nunavut" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               province === "Ontario" ? moment(item.time.trim(), "H:mm").format("h:mm a") :
               province === "Prince Edward Island" ? moment(item.time.trim(), "H:mm").add(1, 'hours').format("h:mm a") :
               province === "Yukon" ? moment(item.time.trim(), "H:mm").subtract(3, 'hours').format("h:mm a") :
               province === "Saskatchewan" ? moment(item.time.trim(), "H:mm").subtract(2, 'hours').format("h:mm a") :
               moment(item.time.trim(), "H:mm").format("h:mm a")
              }
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
});

const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(BookingItem);