import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./PrivacyPolicy.css";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy(props) {

  const { t, i18n } = useTranslation();

  const [viewPort, setViewPort] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Phyxable | Privacy Policy";
    if (viewPort !== props.currentViewPort) {
      setViewPort(props.currentViewPort);
    }
  }, [props.currentViewPort]);
  return (
    <>
      {viewPort === "MOBILE" ? (
        <Mobile />
      ) : viewPort === "TABLET" ? (
        <Tablet />
      ) : viewPort === "LAPTOP" ? (
        <Laptop />
      ) : (
        <Desktop />
      )}
    </>
  );
}

const Mobile = () => {
  return (
    <div className="interRegular">
      <div className="ptop100">
        <div className="w-85per mlAuto mrAuto blackColor">
          <h1 className=" lAlign ftSz13">
            { i18next.t(`PRIVACY_TITLE`) }<br />
            { i18next.t(`POLICY_TITLE`) }
          </h1>
        </div>
        <div className="w-85per mlAuto mrAuto blackColor">
          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
            { i18next.t(`POLICY1`) }
          </p>
          <p>
            { i18next.t(`POLICY2`) }
          </p>

          <p>
            { i18next.t(`POLICY3`) }
          </p>

          <p>
            { i18next.t(`POLICY4`) }
          </p>

          <p>
            { i18next.t(`POLICY5`) }
          </p>

          <p>
            { i18next.t(`POLICY6`) }
          </p>

          <p>
            { i18next.t(`POLICY7`) }
          </p>

          <p>
            { i18next.t(`POLICY8`) }
          </p>

          <p>
            { i18next.t(`POLICY9`) }
          </p>

          <p>
            { i18next.t(`POLICY10`) }
          </p>

          <p>
            { i18next.t(`POLICY11`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY12`) }
          </h2>
          <p>
            { i18next.t(`POLICY13`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY14`) }
          </h2>
          <p>
            { i18next.t(`POLICY15`) }
          </p>
          <p>
            { i18next.t(`POLICY16`) }
          </p>

          <p>
            { i18next.t(`POLICY17`) }
          </p>

          <p>
            { i18next.t(`POLICY18`) }
          </p>

          <p>
            { i18next.t(`POLICY19`) }
          </p>

          <p>
            { i18next.t(`POLICY20`) }
          </p>

          <p>
            { i18next.t(`POLICY21`) }
          </p>

          <p>
            { i18next.t(`POLICY22`) }
          </p>

          <p>
            { i18next.t(`POLICY23`) }
          </p>

          <p>
            { i18next.t(`POLICY24`) }
          </p>
          <p>
            { i18next.t(`POLICY25`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY26`) }
          </h2>
          <p>
            { i18next.t(`POLICY27`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY28`) }
          </h2>
          <p>
            { i18next.t(`POLICY29`) }
          </p>

          <p>
            { i18next.t(`POLICY30`) }
          </p>

          <p>
            { i18next.t(`POLICY31`) }
          </p>

          <p>
            { i18next.t(`POLICY32`) }
          </p>

          <p>
            { i18next.t(`POLICY33`) }
          </p>
          <p>
            { i18next.t(`POLICY34`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`POLICY35`) }</h2>
          <p>
            { i18next.t(`POLICY36`) }
          </p>

          <p>
            { i18next.t(`POLICY37`) }
          </p>

          <p>
            { i18next.t(`POLICY38`) }
          </p>

          <p>
            { i18next.t(`POLICY39`) }
          </p>

          <p>
           { i18next.t(`POLICY40`) }
          </p>

          <p>
            { i18next.t(`POLICY41`) }
          </p>

          <p>
            { i18next.t(`POLICY42`) }
          </p>

          <p>
            { i18next.t(`POLICY43`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY44`) }
          </h2>
          <p>
            { i18next.t(`POLICY45`) }
          </p>
          <p>
            { i18next.t(`POLICY46`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY47`) }
          </h2>
          <p>
            { i18next.t(`POLICY48`) }
          </p>

          <p>
            { i18next.t(`POLICY49`) }
          </p>

          <p>
            { i18next.t(`POLICY50`) }
          </p>

          <p>
            { i18next.t(`POLICY51`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Tablet = () => {
  return (
    <div className="interRegular">
      <div className="ptop100">
        <div className="w-85per mlAuto mrAuto blackColor">
          <h1 className=" lAlign ftSz13">{ i18next.t(`PRIVACY_TITLE`) } { i18next.t(`POLICY_TITLE`) }</h1>
        </div>
        <div className="w-75per mlAuto mrAuto blackColor">
          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
            { i18next.t(`POLICY1`) }
          </p>
          <p>
            { i18next.t(`POLICY2`) }
          </p>

          <p>
            { i18next.t(`POLICY3`) }
          </p>

          <p>
            { i18next.t(`POLICY4`) }
          </p>

          <p>
            { i18next.t(`POLICY5`) }
          </p>

          <p>
            { i18next.t(`POLICY6`) }
          </p>

          <p>
            { i18next.t(`POLICY7`) }.
          </p>

          <p>
            { i18next.t(`POLICY8`) }
          </p>

          <p>
           { i18next.t(`POLICY9`) }
          </p>

          <p>
            { i18next.t(`POLICY10`) }
          </p>

          <p>
            { i18next.t(`POLICY11`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY12`) }
          </h2>
          <p>
            { i18next.t(`POLICY13`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY14`) }
          </h2>
          <p>
            { i18next.t(`POLICY15`) }
          </p>
          <p>
            { i18next.t(`POLICY16`) }
          </p>

          <p>
            { i18next.t(`POLICY17`) }
          </p>

          <p>
           { i18next.t(`POLICY18`) }
          </p>

          <p>
            { i18next.t(`POLICY19`) }
          </p>

          <p>
            { i18next.t(`POLICY20`) }
          </p>

          <p>
            { i18next.t(`POLICY21`) }
          </p>

          <p>
            { i18next.t(`POLICY22`) }
          </p>

          <p>
            { i18next.t(`POLICY23`) }
          </p>

          <p>
            { i18next.t(`POLICY24`) }
          </p>
          <p>
            { i18next.t(`POLICY25`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
           { i18next.t(`POLICY26`) }
          </h2>
          <p>
            { i18next.t(`POLICY27`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY28`) }
          </h2>
          <p>
           { i18next.t(`POLICY29`) }
          </p>

          <p>
            { i18next.t(`POLICY30`) }
          </p>

          <p>
            { i18next.t(`POLICY31`) }
          </p>

          <p>
            { i18next.t(`POLICY32`) }
          </p>

          <p>
           { i18next.t(`POLICY33`) }
          </p>
          <p>
           { i18next.t(`POLICY34`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">{ i18next.t(`POLICY35`) }</h2>
          <p>
            { i18next.t(`POLICY36`) }
          </p>

          <p>
           { i18next.t(`POLICY37`) }
          </p>

          <p>
            { i18next.t(`POLICY38`) }
          </p>

          <p>
           { i18next.t(`POLICY39`) }
          </p>

          <p>
            { i18next.t(`POLICY40`) }
          </p>

          <p>
            { i18next.t(`POLICY41`) }
          </p>

          <p>
            { i18next.t(`POLICY42`) }
          </p>

          <p>
            { i18next.t(`POLICY43`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY44`) }
          </h2>
          <p>
            { i18next.t(`POLICY45`) }
          </p>
          <p>
            { i18next.t(`POLICY46`) }
          </p>

          <h2 className="padding-top-2rem blackColor ftSz7">
            { i18next.t(`POLICY47`) }
          </h2>
          <p>
            { i18next.t(`POLICY48`) }
          </p>

          <p>
            { i18next.t(`POLICY49`) }
          </p>

          <p>
            { i18next.t(`POLICY50`) }
          </p>

          <p>
            { i18next.t(`POLICY51`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Laptop = () => {
  return (
    <div>
      <div className="Content">
        <div className="padding-privacy">
          <h1 className="terms">{ i18next.t(`PRIVACY_TITLE`) } { i18next.t(`POLICY_TITLE`) }</h1>
        </div>
        <div className="privacy-background black">
          <h2 className="padding-top-2rem">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
           { i18next.t(`POLICY1`) }
          </p>
          <p>
          { i18next.t(`POLICY2`) }
          </p>

          <p>
            { i18next.t(`POLICY3`) }
          </p>

          <p>
           { i18next.t(`POLICY4`) }
          </p>

          <p>
           { i18next.t(`POLICY5`) }
          </p>

          <p>
            { i18next.t(`POLICY6`) }
          </p>

          <p>
            { i18next.t(`POLICY7`) }
          </p>

          <p>
            { i18next.t(`POLICY8`) }
          </p>

          <p>
            { i18next.t(`POLICY9`) }
          </p>

          <p>
            { i18next.t(`POLICY10`) }
          </p>

          <p>
           { i18next.t(`POLICY11`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY12`) }</h2>
          <p>
          { i18next.t(`POLICY13`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY14`) }</h2>
          <p>
            { i18next.t(`POLICY15`) }
          </p>
          <p>
           { i18next.t(`POLICY16`) }
          </p>

          <p>
           { i18next.t(`POLICY17`) }
          </p>

          <p>
           { i18next.t(`POLICY18`) }
          </p>

          <p>
            { i18next.t(`POLICY19`) }
          </p>

          <p>
            { i18next.t(`POLICY20`) }
          </p>

          <p>
            { i18next.t(`POLICY21`) }
          </p>

          <p>
            { i18next.t(`POLICY22`) }
          </p>

          <p>
           { i18next.t(`POLICY23`) }
          </p>

          <p>
           { i18next.t(`POLICY24`) }
          </p>
          <p>
            { i18next.t(`POLICY25`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY26`) }</h2>
          <p>
           { i18next.t(`POLICY27`) }
          </p>

          <h2 className="padding-top-2rem">
            { i18next.t(`POLICY28`) }
          </h2>
          <p>
           { i18next.t(`POLICY29`) }
          </p>

          <p>
           { i18next.t(`POLICY30`) }
          </p>

          <p>
            { i18next.t(`POLICY31`) }
          </p>

          <p>
            { i18next.t(`POLICY32`) }
          </p>

          <p>
            { i18next.t(`POLICY33`) }
          </p>
          <p>
            { i18next.t(`POLICY34`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY35`) }</h2>
          <p>
           { i18next.t(`POLICY36`) }
          </p>

          <p>
           { i18next.t(`POLICY37`) }
          </p>

          <p>
           { i18next.t(`POLICY38`) }
          </p>

          <p>
            { i18next.t(`POLICY39`) }
          </p>

          <p>
            { i18next.t(`POLICY40`) }.
          </p>

          <p>
            { i18next.t(`POLICY41`) }
          </p>

          <p>
         { i18next.t(`POLICY42`) }
          </p>

          <p>
        { i18next.t(`POLICY43`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY44`) }</h2>
          <p>
            { i18next.t(`POLICY45`) }
          </p>
          <p>
          { i18next.t(`POLICY46`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY47`) }</h2>
          <p>
          { i18next.t(`POLICY48`) }
          </p>

          <p>
           { i18next.t(`POLICY49`) }
          </p>

          <p>
           { i18next.t(`POLICY50`) }
          </p>

          <p>
           { i18next.t(`POLICY51`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const Desktop = () => {
  return (
    <div>
      <div className="Content">
        <div className="padding-privacy">
          <h1 className="terms">{ i18next.t(`PRIVACY_TITLE`) } { i18next.t(`POLICY_TITLE`) }</h1>
        </div>
        <div className="privacy-background black">
          <h2 className="padding-top-2rem">{ i18next.t(`BACKGROUND_TITLE`) }</h2>
          <p>
           { i18next.t(`POLICY1`) }
          </p>
          <p>
          { i18next.t(`POLICY2`) }
          </p>

          <p>
            { i18next.t(`POLICY3`) }
          </p>

          <p>
           { i18next.t(`POLICY4`) }
          </p>

          <p>
           { i18next.t(`POLICY5`) }
          </p>

          <p>
            { i18next.t(`POLICY6`) }
          </p>

          <p>
            { i18next.t(`POLICY7`) }
          </p>

          <p>
            { i18next.t(`POLICY8`) }
          </p>

          <p>
            { i18next.t(`POLICY9`) }
          </p>

          <p>
            { i18next.t(`POLICY10`) }
          </p>

          <p>
           { i18next.t(`POLICY11`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY12`) }</h2>
          <p>
          { i18next.t(`POLICY13`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY14`) }</h2>
          <p>
            { i18next.t(`POLICY15`) }
          </p>
          <p>
           { i18next.t(`POLICY16`) }
          </p>

          <p>
           { i18next.t(`POLICY17`) }
          </p>

          <p>
           { i18next.t(`POLICY18`) }
          </p>

          <p>
            { i18next.t(`POLICY19`) }
          </p>

          <p>
            { i18next.t(`POLICY20`) }
          </p>

          <p>
            { i18next.t(`POLICY21`) }
          </p>

          <p>
            { i18next.t(`POLICY22`) }
          </p>

          <p>
           { i18next.t(`POLICY23`) }
          </p>

          <p>
           { i18next.t(`POLICY24`) }
          </p>
          <p>
            { i18next.t(`POLICY25`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY26`) }</h2>
          <p>
           { i18next.t(`POLICY27`) }
          </p>

          <h2 className="padding-top-2rem">
            { i18next.t(`POLICY28`) }
          </h2>
          <p>
           { i18next.t(`POLICY29`) }
          </p>

          <p>
           { i18next.t(`POLICY30`) }
          </p>

          <p>
            { i18next.t(`POLICY31`) }
          </p>

          <p>
            { i18next.t(`POLICY32`) }
          </p>

          <p>
            { i18next.t(`POLICY33`) }
          </p>
          <p>
            { i18next.t(`POLICY34`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY35`) }</h2>
          <p>
           { i18next.t(`POLICY36`) }
          </p>

          <p>
           { i18next.t(`POLICY37`) }
          </p>

          <p>
           { i18next.t(`POLICY38`) }
          </p>

          <p>
            { i18next.t(`POLICY39`) }
          </p>

          <p>
            { i18next.t(`POLICY40`) }.
          </p>

          <p>
            { i18next.t(`POLICY41`) }
          </p>

          <p>
         { i18next.t(`POLICY42`) }
          </p>

          <p>
        { i18next.t(`POLICY43`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY44`) }</h2>
          <p>
            { i18next.t(`POLICY45`) }
          </p>
          <p>
          { i18next.t(`POLICY46`) }
          </p>

          <h2 className="padding-top-2rem">{ i18next.t(`POLICY47`) }</h2>
          <p>
          { i18next.t(`POLICY48`) }
          </p>

          <p>
           { i18next.t(`POLICY49`) }
          </p>

          <p>
           { i18next.t(`POLICY50`) }
          </p>

          <p>
           { i18next.t(`POLICY51`) }
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,
});

export default connect(mapStateToProps, null)(PrivacyPolicy);
