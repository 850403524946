import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import Modal from 'react-modal';

const Popup = ({ showPopup, subCode }) => {
  const [copiedToClipBoard, setCopiedToClipboard] = useState(false);
  const [copyText, setCopyText] = useState('Click the box to copy');
  const [emailAddress, setEmailAddress] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const getStartedPressed = () => {
    window.open('https://app.phyxable.com/on-boarding', '_self');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(subCode);
    setCopiedToClipboard(true);
    setCopyText('Copied to clipboard');
  };

  const sendEmail = () => {
    setEmailSubmitted(true);
    if (emailAddress === '') {
      alert('Please write in you email address');
      return;
    }

    fetch('/sub-code-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: emailAddress, code: subCode }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          alert('Mail sent succesfully');
          setEmailSubmitted(false);
        } else {
          alert('Unfortunately mail could not be sent');
          setEmailSubmitted(false);
        }
      });
  };

  return (
    <div>
      <Modal
        isOpen={showPopup}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <div className={styles.popup_container}>
          <div className={styles.heading}>Thank you for your purchase!</div>
          <div className={styles.content}>
            In order to activate your subscription, please use the code below in
            the “Referral Code” field during the signup process to get started.
          </div>
          <div className={styles.referralCodeContainer}>
            <span className={styles.referralCode} onClick={copyToClipboard}>
              {subCode}
            </span>
            <span
              className={
                copiedToClipBoard
                  ? styles.referralCodeCopied
                  : styles.referralCodeCopy
              }
            >
              {copyText}
            </span>
          </div>
          <div className={styles.emailContainer}>
            <span className={styles.emailText}>
              Would you like us to email you this code along with instructions
              on how to sign up?
            </span>
            <div className={styles.emailBox}>
              <input
                type='email'
                placeholder='Enter email address here'
                className={styles.input}
                onChange={e => setEmailAddress(e.target.value)}
              />
              <button
                className={styles.submitEmailButton}
                onClick={sendEmail}
                disabled={emailSubmitted}
              >
                SUBMIT
              </button>
            </div>
          </div>
          <div className={styles.getStartedButton}>
            <button className='button' onClick={getStartedPressed}>
              Get started
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Popup;
