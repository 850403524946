// French Support
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "./en/translation.json";
import common_fr from "./fr/translation.json";

const languages = ['en', 'fr']; // supported languages

const resources = {
  en: {
    translation: common_en
  },
  fr: {
    translation: common_fr
  }
}

export default i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // fallbackLng: "en",
    supportedLngs: ['en', 'fr'],
    interpolation: {
      escapeValue: false,
    },
  });