import React, { Component } from "react";
import { connect } from "react-redux";
import IMG_1 from "../../assets/home/patient-1.jpg";
import IMG_2 from "../../assets/home/patient-2.jpg";

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const arr = [
  {
    id: 1,
    image: IMG_1,
    patient: "Justin F.",
    about: "ABOUTPATIENT1",
    story: "STORY1",
  },
  {
    id: 2,
    image: IMG_2,
    patient: "Jade C.",
    about: "ABOUTPATIENT2",
    story: "STORY2",
  },
];

class PatientStoriesMobile extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    index: 0,
    selected: arr[0],
  };

  nextImage = () =>
    this.setState((prevState) => {
      const newIndex =
        prevState.index < arr.length - 1 ? prevState.index + 1 : 0;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });

  previousImage = () =>
    this.setState((prevState) => {
      const newIndex =
        prevState.index === 0 ? arr.length - 1 : prevState.index - 1;
      return {
        index: newIndex,
        selected: arr[newIndex],
      };
    });

  render = () => (
    <div>
      <div className="mrAuto mlAuto w-90per">
        <h1 className="dark lAlign">{ i18next.t(`PATIENT_STORIES`) }</h1>

        <div className="">
          <img
            className="patient-img-mobile"
            src={this.state.selected.image}
            alt="patient"
          />
        </div>

        <div className="">
          <h4 className=" dark interRegular ftSz5 w-90per ">
            {this.state.selected.patient}
          </h4>
          <p className="  regular interRegular ftSz2 mtop-1">
            { i18next.t(this.state.selected.about) }
          </p>
        </div>
        <div className="lAlign mtop10">
          <h2 className="regular interRegular ftSz9 ">
            { i18next.t(this.state.selected.story) }
          </h2>
        </div>
        <div className="padding-top-1rem ">
          <button className="next-button" onClick={this.previousImage}>
            &lt;
          </button>
          <button className="next-button" onClick={this.nextImage}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  mobileView: state.viewportsReducer.mobileView,
  tabletView: state.viewportsReducer.tabletView,
  laptopView: state.viewportsReducer.laptopView,
  desktopView: state.viewportsReducer.desktopView,
});

PatientStoriesMobile = withTranslation()(PatientStoriesMobile);
export default connect(mapStateToProps, null)(PatientStoriesMobile);
