import React from 'react';
import styles from './case.module.css';
import Graph1 from './assets/Graph1.png';
import Graph2 from './assets/Graph2.png';
import Practitioner from './assets/Practitioner.png';

const CaseDetails = () => {
  return(
    <div className={styles.container}>
      {/* Heading */}
      <div className={styles.headercontainer}>
        <div className={styles.heading}>
          <div className={styles.headerText}>
            <span>Overuse of a Computer Mouse Left Her Unable to Work</span>
          </div>
          <div className={styles.headPara}>
            Alice<sup>1</sup>, a female white collar worker from Ontario, Canada who had a full time job using a computer suffered a wrist and finger injury from excessive work.
          </div>
        </div>
        <div className={styles.subHeading}>
          <ul style={{listStyleType: 'disc'}}>
            <li>She was also experiencing shoulder and neck pain from poor ergonomics and poor posture while working</li><br/>
            <li>At the time of initial assessment, Alice was unable to continue working due to pain in her finger, wrist, forearms, shoulder and neck</li><br/>
            <li>She was also experiencing difficulty performing tasks around the house such as cleaning and housekeeping duties</li>
          </ul>
        </div>
      </div>
      {/* Image container */}
      <div className={styles.imageContainer}>
        <div className={styles.image}>
          <img src={Practitioner} height='370' width='350'/>
        </div>
        <div className={styles.imageDetails}>
          <div className={styles.imageHeading}>
            “Alice was unable to work her full time computer job due to a repetitive strain injury in her hand and wrist.”
          </div>
          <div className={styles.imageName}>
            Kelly Leong<br/>
            <span>Lead Practioner</span>
          </div>
        </div>
      </div>
      {/* Grey area */}
      <div className={styles.grayContainer}>
        <div className={styles.headercontainer}>
          <div className={styles.heading}>
            <div className={styles.headerText}>
              <span>The Phyxable Difference</span>
            </div>
            <div className={styles.headPara}>
              Alice met with Phyxable’s Lead Practitioner Kelly Leong who created a custom treatment plan delivered through 5 virtual appointments. The treatment included:
            </div>
          </div>
          <div className={styles.subHeading}>
            <ul style={{listStyleType: 'disc'}}>
              <li>Patient education about the nature of the overuse injury</li><br/>
              <li>Ergonomic assessment and recommendations</li><br/>
              <li>Home exercise program to strengthen muscles, improve posture and improve function</li><br/>
              <li>Pain management strategies and techniques</li><br/>
              <li>Guidance on increasing activity and return to work plan</li>
            </ul>
          </div>
        </div>
        {/* Graph container */}
        <div className={styles.graphContainer}>
          <div className={styles.graphHeading}>
            Back to Work With a Positive Outcome
          </div>
          <div className={styles.graphSubHead}>
            <span>After undergoing Phyxable’s treatment plan over a course of 6 weeks, Alice was able to return to work on modified hours, with the plan of returning to full time hours within 3 weeks.</span>
          </div>
          <div className={styles.graph}>
            <img src={Graph1}/>
          </div>
          <div className={styles.graph}>
            <img src={Graph2}/>
          </div>
        </div>
        {/* Ending para */}
        <div className={styles.headercontainer}>
          <div className={styles.heading}>
            <div className={styles.headerText}>
              <span>Feeling Empowered</span>
            </div>
            <div className={styles.headPara}>
              After seeing the resolution of her symptoms in a short period of time after her treatment plan, Alice is now back to work full time. On top of getting her life back, she:-
            </div>
          </div>
          <div className={styles.subHeading}>
            <ul style={{listStyleType: 'disc'}}>
              <li>Now has an improved work-life balance</li><br/>
              <li>Enjoys taking microbreaks more frequently throughout the day</li><br/>
              <li>Has an improved mindset and sees value in activity and exercise for improving health and wellness</li>
            </ul>
          </div>
        </div>
        {/* Footer */}
        <div className={styles.footer}>
          We asked her:<br/>
          <span>On a scale of 1 to 10 how confident are you to manage your pain through our self guided exercises if such an injury were to occur?</span>
          <b>She answered: 9</b>
          {/* Disclaimer */}
          <div className={styles.disclaimer}>
            1 - Real name changed to conceal identity
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseDetails;