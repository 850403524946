import dayjs from "dayjs";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Calendar from "react-calendar";
import styles from "./Booking.module.css";
import Loading from './Loading';
import BookingItem from "./BookingItem";
import ReactGA from "react-ga";
import { generateWeek, buildTitle } from "../helpers/helpers";
import calendarSVG from "./calendar.svg";
import MobileBooking from "../components/MobileBooking";
import firebase from "@firebase/app";
import axios from "axios";
import { getProvinceTimezoneName } from "../../../../../helpers/provinces";

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  // console.log('Booking areEqual', { prevProps, nextProps, cond: !!((nextProps.appint === prevProps.appint) && (nextProps.currentViewPort === prevProps.currentViewPort)) })
  return  !!((nextProps.unavailable === prevProps.unavailable) && (nextProps.appint === prevProps.appint) && (nextProps.currentViewPort === prevProps.currentViewPort))
}

const Booking = React.memo(
  ({ handleDate, unavailable, bookingClicked, appint, appintFlatten, currentViewPort, province, BookingClickedStatusFunction, handleWeekChange }) => {
   // console.log({ handleDate, appint, currentViewPort, BookingClickedStatusFunction } )
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentTime, setCurrentTime] = useState("");
    const [actualDate, setActualDate] = useState("");
    const [week, setWeek] = useState([]);
    const [title, setTitle] = useState([]);
    const [viewPort, setViewPort] = useState("");
    const [currentCalDate, setCurrentCalDate] = useState(false);
    const [loading, setLoading] = useState(true);
    // console.log('~Booking JS is Rendering')
    // console.log({ week, currentDate })

    const { t, i18n } = useTranslation();

    const handleCurrentDate = (value) => {
      setLoading(true);
      // console.log(value);
      setCurrentDate(value);
      const newWeek = generateWeek(value);
      setWeek(newWeek);
      ReactGA.event({
        category: "Clicked on Date Calendar",
        action: "/booking",
        label: "Booking",
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleCurrentTime = useCallback((time, item) => {
      setLoading(true);
      setCurrentTime(time);
      setActualDate(item[0]);
      bookingClicked(time, item[0]);
      ReactGA.event({
        category: "Clicked on Time Calendar",
        action: "/booking",
        label: "Booking",
      });
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }, [currentTime, actualDate, loading]);


    const handleCurrentDay = useCallback((date) => {
      setLoading(true);
      setCurrentCalDate(date);
      const newWeek = generateWeek(date);
      setCurrentDate(date);
      setWeek(newWeek);

      setActualDate(date);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }, [loading, currentDate, currentCalDate, week, actualDate]);

    useEffect(() => {
      window.scroll(0, 0);
      document.title = "Phyxable | Booking";
      if (viewPort !== currentViewPort) {
        setViewPort(currentViewPort);
      }
    }, [currentViewPort]);

    const handleNavigateWeek = (type) => {
      setLoading(true);
      let newDate = new Date();
      if (type === "next") {
        newDate = dayjs(currentDate).add(1, "week");
      } else {
        newDate = dayjs(currentDate).subtract(1, "week");
      }
      const newWeek = generateWeek(newDate.toDate());
      setCurrentDate(newDate.toDate());
      setWeek(newWeek);
      // setShow(false);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };

    useEffect(() => {
      async function loaders() {}
      loaders();
      const fullWeek = generateWeek(currentDate);
      setWeek(fullWeek);
      const newTitle = buildTitle(fullWeek);
      setTitle(newTitle);
    }, []);

    useEffect(() => {
      const newTitle = buildTitle(week);
      setTitle(newTitle);
      const changeWeek = async () => {
        setLoading(true)
        await handleWeekChange(week);
        setLoading(false);
      }
      week.length > 0 && typeof handleWeekChange === 'function' && changeWeek()
    }, [week]);

    useEffect(() => {
      if (currentDate && currentTime && actualDate) {
        handleDate(currentDate, currentTime, actualDate);
      }
    }, [actualDate]);

    /** Show varied background */
    const handleTile = (date) => {
      const mydate = `${date.date}`.split(" ")[0];
      const options = ["Mon", "Wed", "Fri"];
      return options.includes(mydate)
        ? `${styles.tile} ${styles["color-one"]}`
        : `${styles.tile} ${styles["color-two"]}`;
    };

    return (
      <>
        {loading && <Loading /> ||
        <div>
        {viewPort === "MOBILE" ? (
          <Mobile
            styles={styles}
            handleCurrentDate={handleCurrentDate}
            currentDate={currentDate}
            handleTile={handleTile}
            title={title}
            handleNavigateWeek={handleNavigateWeek}
            week={week}
            appint={appint}
            appintFlatten={appintFlatten}
            unavailable={unavailable}
            handleCurrentTime={handleCurrentTime}
            handleCurrentDay={handleCurrentDay}
            currentCalDate={currentCalDate}
            BookingClickedStatusFunction={BookingClickedStatusFunction}
            province={province}
            // hours={hours}
          />
        ) : viewPort === "TABLET" ? (
          <NonMobile
            styles={styles}
            handleCurrentDate={handleCurrentDate}
            currentDate={currentDate}
            handleTile={handleTile}
            title={title}
            handleNavigateWeek={handleNavigateWeek}
            week={week}
            appint={appint}
            appintFlatten={appintFlatten}
            unavailable={unavailable}
            handleCurrentTime={handleCurrentTime}
            province={province}
            // hours={hours}
          />
        ) : viewPort === "LAPTOP" ? (
          <NonMobile
            styles={styles}
            handleCurrentDate={handleCurrentDate}
            currentDate={currentDate}
            handleTile={handleTile}
            title={title}
            handleNavigateWeek={handleNavigateWeek}
            week={week}
            appint={appint}
            appintFlatten={appintFlatten}
            unavailable={unavailable}
            handleCurrentTime={handleCurrentTime}
            province={province}
            // hours={hours}
          />
        ) : (
          <NonMobile
            styles={styles}
            handleCurrentDate={handleCurrentDate}
            currentDate={currentDate}
            handleTile={handleTile}
            title={title}
            handleNavigateWeek={handleNavigateWeek}
            week={week}
            appint={appint}
            appintFlatten={appintFlatten}
            unavailable={unavailable}
            handleCurrentTime={handleCurrentTime}
            province={province}
            // hours={hours}
          />
        )}
        </div>}
      </>
    )
  }, areEqual);

const NonMobile = React.memo(({
  styles,
  handleCurrentDate,
  currentDate,
  handleTile,
  title,
  handleNavigateWeek,
  week,
  appint,
  appintFlatten,
  unavailable,
  handleCurrentTime,
  province
  // hours
}) => {
  return (
    <div className="w-10/12 my-0 mx-auto bg-white flex flex-col p-8 text-phyxable-black">
      <p className="font-extrabold text-4xl mb-6 text-center">
        { i18next.t(`APPOINTMENT_BOOKING`) }({getProvinceTimezoneName(localStorage.getItem('province'))} Timezone)
      </p>
      <div className=" shadow-2xl flex flex-col items-center rounded-lg px-4 pb-8 pt-4 mb-4">
        <div>
          <p className="font-extrabold text-2xl mb-6 text-center">
            { i18next.t(`YOURE_BOOKING_FOR_A_FREE_30`) }You're booking for a Free 30 minute consultation.
          </p>
          <p className="text-lg text-center">{ i18next.t(`PLEASE_SELECT_AN_AVAILABLE_TIME`) }</p>
        </div>
        {/** Calendar */}
        {/* <div className="w-1/2 md:w-1/3 pr-4"> */}
        {/*  <Calendar */}
        {/*     calendarType="US" */}
        {/*     className={styles.calendarContainer} */}
        {/*     onChange={handleCurrentDate} */}
        {/*     value={currentDate} */}
        {/*     tileClassName={handleTile} */}
        {/*     view="month" */}
        {/*   /> */}
        {/* </div> */}
        {/** Full View */}
        <div className="w-1/2 md:w-full text-phyxable-blue border-gray-300 pl-8">
          <div className="flex justify-between mb-8">
            <p className="text-2xl font-bold flex justify-center items-center">
              {title}
            </p>
            <div className="flex">
              {/* <button
                type="button"
                style={{ width: "40px", height: "40px" }}
                className="text-2xl flex justify-center items-center cursor-pointer"
                onClick={() => handleNavigateWeek("prev")}
              >
                ‹
              </button> */}
              {/* <button
                type="button"
                style={{ width: "40px", height: "40px" }}
                className="text-2xl flex justify-center items-center cursor-pointer"
                onClick={() => handleNavigateWeek("next")}
              >
                ›
              </button> */}
            </div>
          </div>
          {/** Booking Items */}
          <div className="flex rounded-lg">
            {week.map((item, index) => {
              return (
                <BookingItem
                  key={`${item}`.split(" ")[0]}
                  odd={!index % 2}
                  date={item}
                  date2={item}
                  appint={appint}
                  appintFlatten={appintFlatten}
                  unavailable={unavailable}
                  onClick={handleCurrentTime}
                  province={province}
                  // hours={hours}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <p className="text-phyxable-blue font-bold border-phyxable-blue border-2 rounded-full px-4 mr-3 text-sm">
          { i18next.t(`AVAILABLE`) }
        </p>
        <p
          style={{ backgroundColor: "#E4E7ED", color: "#A7ACBD" }}
          className="rounded-full px-4 ml-3 text-sm"
        >
          { i18next.t(`BOOKED`) }
        </p>
      </div>
    </div>
  );
});

const Mobile = React.memo(({
  styles,
  handleCurrentDate,
  currentDate,
  handleTile,
  title,
  handleNavigateWeek,
  week,
  appint,
  appintFlatten,
  unavailable,
  handleCurrentTime,
  handleCurrentDay,
  currentCalDate,
  BookingClickedStatusFunction,
  province
  // hours
}) => {
  const [calStatus, setCalStatus] = useState(false);

  // console.log({ calStatus, week })

  useEffect(() => {
    if (calStatus) {
      setCalStatus(!calStatus);
    }
  }, [currentCalDate]);

  return (
    <div
      style={{
        width: 100 + `%`,
        backgroundColor: "#EFF2F6",
      }}
    >
      <div
        className="secColor1"
        style={{
          width: 100 + `%`,
          backgroundColor: "#fff",
        }}
      >
        <p className="font-extrabold ftSz7 mb-6 text-center ">
          { i18next.t(`BOOKED_EST`) }
        </p>
        <p className="font-extrabold ftSz5  text-center mtop-05">
          <span style={{ fontWeight: 100 }}>Free Consultation</span> { i18next.t(`MINS`) }

        </p>
        <div className="mtop-05 w-90per mlAuto mrAuto">
          <p className=" font-extrabold ftSz5  mb-6 text-center mtop-05">
            { i18next.t(`PLEASE_SELECT_AN_AVAILABLE_TIME`) }
          </p>{" "}
        </div>
        <div className="dFlex jSpace w-80per mrAuto mlAuto flexAlCenter">
          {/* <div className=" lAlign w-90per">
            <img
              src={calendarSVG}
              alt="calendar menu"
              onClick={() => {
                setCalStatus(!calStatus);
              }}
            />{" "}
          </div> */}
          <div
            className="dFlex rAlign jSpace mrAuto mlAuto "
            style={{ width: 100 + `%` }}
          >
            {" "}
            {/* <button
              type="button"
              onClick={() => handleNavigateWeek("prev")}
              style={{ fontSize: 36 + `px`, fontWeight: "bold", float: "right" }}
            >
              ‹
            </button>
            <button
              type="button"
              onClick={() => handleNavigateWeek("next")}
              style={{ fontSize: 36 + `px`, fontWeight: "bold", float: "left" }}
            >
              ›
            </button> */}
          </div>
        </div>
      </div>

      <div>
        <div className="dFlex flex-col jCenter rounded-lg pbottom80">
          {week.map((item, index) => {
            return (
              <BookingItem
                key={`${item}`.split(" ")[0]}
                odd={!index % 2}
                date={item}
                date2={item}
                appint={appint}
                appintFlatten={appintFlatten}
                unavailable={unavailable}
                onClick={handleCurrentTime}
                item={item}
                BookingClickedStatusFunction={BookingClickedStatusFunction}
                province={province}
                // hours={hours}
              />
            );
          })}
        </div>
      </div>
      <div
        className="overlayMobile"
        style={{ display: calStatus ? "block" : "none" }}
        onClick={() => {
          setCalStatus(!calStatus);
        }}
      ></div>
      <div
        style={{
          display: calStatus ? "block" : "none",
          position: "absolute",
          top: 20 + `%`,
          left: 5 + `%`,
          zIndex: 3,
          width: 90 + `%`,
        }}
      >
        {/* <Calendar
          calendarType="US"
          className={styles.calendarContainer}
          onChange={handleCurrentDate}
          value={currentDate}
          tileClassName={handleTile}
          view="month"
        /> */}
        <MobileBooking
          currentDate={currentCalDate ? currentCalDate : currentDate}
          handleCurrentDay={handleCurrentDay}
        />
      </div>
    </div>
  );
});
const mapStateToProps = (state, props) => ({
  currentViewPort: state.currentViewPortReducer.currentViewPort,

  // width: state.widthReducer.width ? state.widthReducer.width : null,
});

export default connect(mapStateToProps, null)(Booking);