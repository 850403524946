import React, { Component } from 'react';
import './Blog.scss';
import Card from './Card';
import axios from 'axios';

import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

export default class Blogs extends Component {
    state = {
        blogs: null,
    }

    componentDidMount(){
        window.scroll(0, 0);
        document.title = "Phyxable | Blog";
        this.loadBlogs();
    }

    loadBlogs = async () => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blogs`)
        .then(response => this.setState({ blogs: response.data }))
        .catch(error => console.error(error));
    }

    showBlogs = blogs => {
        try {
            return(
            <div className="grid">
                {blogs && 
                    blogs.sort((a, b) => new Date(b.published_at) - new Date(a.published_at)).map(blog => 
                    <Card blog={blog} key={`blog__${blog.id}`} />
                )}
            </div>
            )
        } catch (error) {
            return error;
        }
    }

  render(){
    const { blogs } = this.state;

    return(
    <div>
        <div className="bg-desktop-blog">
        <div className="home-container">
            <h1 className="white main-font blog-header">Tips and advice<br/> 
            for physical <br/>
            well-being</h1>
            </div>
        </div>

        <div className="Content">
            { this.showBlogs(blogs) }
        </div>
    </div>
    )
  }
}

Blogs = withTranslation()(Blogs);